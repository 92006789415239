import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { unwrapResult } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import { useForm, useFormState } from 'react-final-form';

import { onPayRoom } from '../../actions/payments';
import { useDispatch, useIsLoading, usePod } from '../../hooks';
import { Form, FormProps } from '../../ui/atoms/Form';
import { FIELD_NAME_PAYMENT_AMOUNT, PaymentAmount } from '../../ui/molecules/Form';

export interface PodPaymentWizardContentProps {
  handleClose: () => void;
}

const tTitle = 'Make a Payment';
const tNext = 'Make Payment';
const tCancel = 'Cancel';

interface PaymentFormValues {
  [FIELD_NAME_PAYMENT_AMOUNT]: string;
}

const PaymentFormContent = ({ handleClose }: PodPaymentWizardContentProps) => {
  const isLoading = useIsLoading();
  const { hasValidationErrors, submitting } = useFormState<PaymentFormValues>();
  const { submit } = useForm();

  return (
    <>
      <DialogTitle id="alert-dialog-slide-title">{tTitle}</DialogTitle>
      <DialogContent>
        <PaymentAmount />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose} variant="outlined">
          {tCancel}
        </Button>
        <Button data-testid="pay-button" disabled={isLoading || hasValidationErrors || submitting} onClick={submit}>
          {tNext}
        </Button>
      </DialogActions>
    </>
  );
};

const PodPaymentWizardContent = ({ ...props }: PodPaymentWizardContentProps) => {
  const isLoading = useIsLoading();
  const pod = usePod();
  const dispatch = useDispatch();

  if (!pod) {
    throw new Error('must have a selected pod');
  }

  const handleSubmit = useCallback<FormProps<PaymentFormValues>['onSubmit']>(async (values) => {
    if (!isLoading) {
      const payload = { amount: Number(values[FIELD_NAME_PAYMENT_AMOUNT]), ownerId: pod.ownerId!, roomId: pod.id! };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore // redux-toolkit typing is impossible, switch this react-query
      const result = await dispatch(onPayRoom(payload)).then(unwrapResult);
      // send them off to Stripe
      window.location.assign(result.url!);
    }
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <PaymentFormContent {...props} />
    </Form>
  );
};

export default PodPaymentWizardContent;
