import { Check } from '@mui/icons-material';
import {
  Alert,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Route } from '../../../../services/router/Route';
import { getDate, getTime } from '../../../../utilities/utils';
import { TableCell } from '../TableCell';
import { TablePaginationActions } from '../TablePaginationActions';

const tRsvpd = 'Rsvp';
const tEventName = 'Event';
const tBeginEnd = 'Event Time';
const tRsvpSchedule = 'Upcoming Schedule';
const tUpcomingScheduleInfo = 'These are events for which you have signed up';

const ROW_HEIGHT = 45.02;
const ROWS_PER_PAGE = 3;

const getEmptyRows = (rows: any[], page: number, rowsPerPage: number) =>
  rows.length === 0 ? rowsPerPage : rowsPerPage - Math.min(rowsPerPage, Math.abs(rows.length - page * rowsPerPage));

const getPaginatedRows = (rows: any[], page: number, rowsPerPage: number) =>
  rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

export interface Rsvp {
  beginMeetingTime: Date;
  endMeetingTime: Date;
  rsvpUserId: string;
  eventId: string;
  eventName: string;
}

export interface RsvpProps {
  rsvps: Rsvp[];
  onClickRow: (rsvpUserId: string, eventId: string) => void;
}

/**
 * @deprecated Don't use this
 * @description The volunteer view should mimic the patter of the facilitator with a `Today` and `ThisWeek` view
 */
export const Rsvp: FC<RsvpProps> = ({ rsvps, onClickRow }) => {
  const [page, setPage] = useState(0);
  const [emptyRows, setEmptyRows] = useState(0);
  const [paginatedRows, setPaginatedRows] = useState<Rsvp[]>([]);

  useEffect(() => {
    const updatedEmptyRows = getEmptyRows(rsvps, page, ROWS_PER_PAGE);
    setEmptyRows(updatedEmptyRows);

    const updatedPaginatedRows = getPaginatedRows(rsvps, page, ROWS_PER_PAGE);
    setPaginatedRows(updatedPaginatedRows);
  }, [rsvps, page, setPaginatedRows]);

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => setPage(newPage);
  const HEADERS = [tRsvpd, tEventName, tBeginEnd];
  return (
    <>
      <Typography variant="h5">{tRsvpSchedule}</Typography>
      <Alert severity="info">{tUpcomingScheduleInfo}</Alert>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {HEADERS.map((header) => (
                <TableCell
                  key={header}
                  typographyProps={{ sx: { fontWeight: 'bold' }, variant: 'overline' }}
                  value={header}
                />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows.length > 0 &&
              paginatedRows.map(({ beginMeetingTime, endMeetingTime, rsvpUserId, eventId, eventName }, index) => (
                <TableRow
                  component={Link}
                  data-testid="rsvp-row"
                  key={index}
                  onClick={() => onClickRow(rsvpUserId, eventId)}
                  style={{ textDecoration: 'none' }}
                  to={Route.eventView({ eventId })}
                >
                  <TableCell
                    typographyProps={{ variant: 'body2' }}
                    value={<Check color="primary" style={{ padding: '8px' }} />}
                  />
                  <TableCell typographyProps={{ variant: 'body2' }} value={eventName} />
                  <TableCell
                    typographyProps={{ variant: 'body2' }}
                    value={`
                ${getDate(beginMeetingTime) + ' @' + getTime(beginMeetingTime)}-${getTime(endMeetingTime)}`}
                  />
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: ROW_HEIGHT * emptyRows }}>
                <TableCell tableCellProps={{ colSpan: 6 }} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                ActionsComponent={TablePaginationActions}
                colSpan={Number.MAX_SAFE_INTEGER}
                count={rsvps.length}
                onPageChange={handleChangePage}
                page={page}
                rowsPerPage={ROWS_PER_PAGE}
                rowsPerPageOptions={[]}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};
