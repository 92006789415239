import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import { MeetingTimeSummary, RoomSummary, useMyScheduleOwnerQuery } from '../generated/react-query';

dayjs.extend(isBetween);

export interface FormattedMeetingTime extends MeetingTimeSummary {
  eventId: string;
  sessionName: string;
}

export interface FormattedData extends RoomSummary {
  thisWeek: FormattedMeetingTime[];
}

const sortFormattedMeetingTime = (a: FormattedMeetingTime, b: FormattedMeetingTime) => {
  if (a.beginMeetingTime > b.beginMeetingTime) {
    return 1;
  }
  if (a.beginMeetingTime < b.beginMeetingTime) {
    return -1;
  }
  return 0;
};

export const useMyScheduleOwner = () => {
  return useMyScheduleOwnerQuery(undefined, {
    select: (data) => ({
      ...data,
      myRooms: data.myRooms.map((room) => {
        const thisWeek = room.sessions
          .map(
            (session) =>
              session.meetingTimes
                .filter((meetingTime) =>
                  dayjs(meetingTime.beginMeetingTime).isBetween(dayjs(), dayjs().add(6, 'days'), 'day', '[]')
                )
                .map((meetingTime) => ({
                  ...meetingTime,
                  eventId: session.id,
                  sessionName: session.name,
                })) as FormattedMeetingTime[]
          )
          .reduce((accumulator, currentValue) => [...accumulator, ...currentValue], [])
          .sort(sortFormattedMeetingTime);

        return { ...room, thisWeek };
      }) as FormattedData[],
    }),
  });
};
