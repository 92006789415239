import { generatePath, useNavigate } from 'react-router-dom';

import { removeMember, requestMembership } from '../../actions/pods';
import { MemberActionButton } from '../../cc-ui';
import { MemberActionButtonProps } from '../../cc-ui/components/MemberActionButton/MemberActionButton';
import { useDispatch, useSelector } from '../../hooks/useTypedRedux';
import { Route } from '../../router';
import { errorToast } from '../../state/notifications';

const tManagePod = 'Members';
const tLeavePod = 'Leave';
const tLeaveTitle = 'Leave $podName';
const tLeaveBody =
  'Leaving $podName cannot be undone. You will have to request membership and await the approval process if you want to rejoin this room.';
const tJoinPod = 'Join';
const tPending = 'Request Pending';

interface PodActionButtonProps {
  onJoinRequested?: () => void;
  onLeave?: () => void;
  isRoomLeader: boolean;
  isFacilitator: boolean;
  isMember: boolean;
  isPending: boolean;
  podName: string;
  roomId: string;
}

const PodActionButton: React.FC<PodActionButtonProps> = ({
  isRoomLeader,
  isFacilitator,
  isMember,
  isPending,
  roomId,
  podName,
  onJoinRequested,
  onLeave,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user!);
  const userId = user.id;

  const btnProps: MemberActionButtonProps = {
    isFacilitator,
    isMember,
    isPending,
    isRoomLeader,
    join: {
      onClick: async () => {
        // If user is incomplete
        if (
          user?.firstName === undefined ||
          user?.firstName === '' ||
          user?.firstName === null ||
          user?.lastName === undefined ||
          user?.lastName === '' ||
          user?.lastName === null ||
          user?.zipCode === undefined ||
          user?.zipCode === '' ||
          user?.zipCode === null ||
          user?.email === undefined ||
          user?.email === '' ||
          user?.email === null ||
          user?.phoneNumber === undefined ||
          user?.phoneNumber === '' ||
          user?.phoneNumber === null
        ) {
          dispatch(
            errorToast({
              message:
                'Redirected to the Profile page...\n\nPlease fill out the required information indicated by an asterisk (*) to interact with rooms and events.',
            })
          );
          navigate('/profile');
          return;
        }
        await dispatch(requestMembership({ roomId: roomId, userId }));
        onJoinRequested && onJoinRequested();
      },
      text: tJoinPod,
    },
    leave: {
      body: tLeaveBody.replaceAll('$podName', podName),
      onConfirm: async () => {
        await dispatch(removeMember({ roomId: roomId, userId }));
        onLeave && onLeave();
      },
      text: tLeavePod,
      title: tLeaveTitle.replaceAll('$podName', podName),
    },
    manage: {
      route: generatePath(Route.POD_MEMBERS, { roomId }),
      text: tManagePod,
    },
    pendingText: tPending,
  };
  return <MemberActionButton {...btnProps} />;
};

export default PodActionButton;
