import { TextField } from '../../../atoms/Form';
import { ConstructedTextField } from '../types';

export const FIELD_NAME_EMAIL = 'email';
const SIMPLE_EMAIL_REGEX = /.+@.+\..+/;

const tLabel = 'Email';

const tRequired = 'Email required';
const tFormatEmail = 'Email must be of format "a@b.c"';
const tLengthEmail = 'Email must contain at least 5 characters, e.g. "a@b.c"';

export type EmailProps = ConstructedTextField;

export const Email = ({ textFieldProps, ...props }: EmailProps) => {
  return (
    <TextField
      name={FIELD_NAME_EMAIL}
      textFieldProps={{
        label: tLabel,
        ...props,
        ...textFieldProps,
      }}
      validate={(value, _, meta) => {
        if (props.required && meta?.modified && !value) {
          return tRequired;
        }

        if (meta?.dirty && value) {
          if (value.length < 5) {
            return tLengthEmail;
          }

          if (!value.match(SIMPLE_EMAIL_REGEX)) {
            return tFormatEmail;
          }
        }

        return '';
      }}
    />
  );
};
