import { Avatar, Grid, List, ListItem, ListItemAvatar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC, Fragment } from 'react';

import { ChatMessage } from '../../hooks/useChat';
import { getDate, getTime, makeProfileAvatarUrl } from '../../utilities/utils';

interface ChatWindowProps {
  isConnected: boolean;
  messages?: ChatMessage[];
}

const tConnecting = 'Connecting to chat...';

const HorizontalRule = styled('hr')(({ theme }) => ({
  backgroundColor: theme.palette.crowdCoursing.GREY['200']?.main ?? 'white',
  border: 'none',
  height: '1px',
  position: 'absolute',
  width: '100%',
  zIndex: 100,
}));

let dateTracker = '';

const DateDivider: React.FC<{ date: string }> = ({ date }) => (
  <Grid
    sx={{
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      margin: '0.5rem',
      position: 'relative',
    }}
  >
    <HorizontalRule />
    <Grid
      sx={{
        backgroundColor: 'white',
        border: '0.25rem solid white',
        padding: '0.125rem 1rem',
        position: 'absolute',
        zIndex: 200,
      }}
    >
      <Typography variant="body2">{date}</Typography>
    </Grid>
  </Grid>
);

const ChatWindow: FC<ChatWindowProps> = ({ isConnected, messages = [] }) => {
  if (!isConnected) {
    return <Typography>{tConnecting}</Typography>;
  }

  return (
    <List>
      {[...messages].reverse().map(({ body, id, timestamp, userId, userName }) => {
        let shouldAddDateMarker = false;
        const date = getDate(timestamp);
        const time = getTime(timestamp);
        if (dateTracker !== date) {
          shouldAddDateMarker = true;
          dateTracker = date;
        }
        return (
          <Fragment key={id}>
            {shouldAddDateMarker ? <DateDivider date={date} /> : null}
            <ListItem sx={{ alignItems: 'initial', padding: '0.5rem 0' }}>
              <ListItemAvatar>
                <Avatar
                  alt={userName}
                  src={makeProfileAvatarUrl(userId)}
                  sx={{
                    border: '1px solid lightgrey',
                    borderRadius: '0.5rem',
                    marginTop: '0.375rem',
                  }}
                  title={userName}
                />
              </ListItemAvatar>
              <Grid>
                <Typography display="inline" variant="subtitle2">
                  {userName}
                </Typography>
                <Typography sx={{ color: 'darkgrey', marginLeft: '1rem' }} variant="caption">
                  {`${date} ${time}`}
                </Typography>
                <Typography variant="body2">{body}</Typography>
              </Grid>
            </ListItem>
          </Fragment>
        );
      })}
    </List>
  );
};

export default ChatWindow;
