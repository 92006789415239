import { LaunchOutlined as LaunchOutlinedIcon } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { IconButton, Tooltip } from '@mui/material';
import { useEffect } from 'react';

import { getMyPods } from '../../actions/pods';
import { useDispatch, useSelector } from '../../hooks/useTypedRedux';
import { CalendarService } from '../../services/ApiService';
import FeatureGuard from '../FeatureGuard';
import { ThisWeekContainer as FacilitatorThisWeekContainer } from './Schedule/Facilitator/ThisWeek';
import { Rsvp as VolunteerRsvpContainer } from './Schedule/Volunteer/Rsvp';

/**
 * Button that downloads the logged-in user's calendar
 *
 * adapted from https://stackoverflow.com/a/44435573, uses XHR to get the ics
 * contents with our authentication header, then some HTML5 shenanigans to
 * initiate saving the file.
 */
const DownloadCalendar = () => {
  const download = async () => {
    const ical = (await CalendarService.calendar()) as string;
    const blob = new Blob([ical], { type: 'text/calendar' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'CrowdCoursing.ics';
    link.click();
  };

  return (
    <IconButton id="calendar-export" onClick={download}>
      <Typography>Calendar Export</Typography>
      <LaunchOutlinedIcon />
    </IconButton>
  );
};

const MySchedule = () => {
  const userId = useSelector((state) => state.user?.id);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userId) {
      dispatch(getMyPods(userId));
    }
  }, [userId, dispatch]);

  return (
    <>
      <Grid id="upcoming-events" item>
        <VolunteerRsvpContainer />
      </Grid>
      <Grid id="my-week" item marginTop="2em">
        <FacilitatorThisWeekContainer />
      </Grid>
    </>
  );
};

const MySchedules = () => {
  return (
    <>
      <Grid container direction="row">
        <FeatureGuard feature="calendarExport">
          <Grid item justifyContent="flex-end" justifyItems="flex-end" xs={5}>
            <Tooltip placement="bottom-end" title={'Calendar'}>
              <DownloadCalendar />
            </Tooltip>
          </Grid>
        </FeatureGuard>
      </Grid>
      <Grid item xs={12}>
        <MySchedule />
      </Grid>
    </>
  );
};

export default MySchedules;
