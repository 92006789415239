import { AttachMoney as AttachMoneyIcon } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';

import { TextField } from '../../../atoms/Form';
import { ConstructedTextField } from '../types';

export const FIELD_NAME_PAYMENT_AMOUNT = 'paymentAmount';
const MIN_VALUE = 5;

const tLabel = 'Amount';
const tRequired = 'Amount required';
const tFormat = 'Must be a number';
const tMinValue = `Must be at least ${MIN_VALUE}`;

export type PaymentAmountProps = ConstructedTextField;

export const PaymentAmount = ({ textFieldProps }: PaymentAmountProps) => {
  return (
    <TextField
      name={FIELD_NAME_PAYMENT_AMOUNT}
      textFieldProps={{
        InputProps: {
          startAdornment: (
            <InputAdornment position="start">
              <AttachMoneyIcon fontSize="small" />
            </InputAdornment>
          ),
        },
        autoComplete: 'off',
        'data-testid': 'form-field-amount',
        label: tLabel,
        required: true,
        ...textFieldProps,
      }}
      type="tel"
      validate={(value, _, meta) => {
        if (meta?.dirty) {
          if (!value) {
            return tRequired;
          }

          if (value.split('').some((character) => isNaN(Number(character)))) {
            return tFormat;
          }

          if (Number(value) < MIN_VALUE) {
            return tMinValue;
          }
        }

        return '';
      }}
    />
  );
};
