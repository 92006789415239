import { TextField } from '../../../atoms/Form';
import { ConstructedTextField } from '../types';

export const FIELD_NAME_NAME = 'name';
export const DEFAULT_MIN = 2;
export const DEFAULT_MAX = 255;

const tLabel = 'Name';
const tRequired = 'Name is required';
const tLengthMax = 'Name cannot exceed 255 characters';
const tLengthMin = 'Name must be at least 2 characters';

export type NameProps = ConstructedTextField & { required?: boolean };

export const Name = ({ textFieldProps, ...props }: NameProps) => {
  return (
    <TextField
      name={FIELD_NAME_NAME}
      textFieldProps={{
        inputProps: { maxLength: DEFAULT_MAX, minLength: DEFAULT_MIN },
        label: tLabel,
        ...props,
        ...textFieldProps,
      }}
      validate={(value, _, meta) => {
        if (props.required && meta?.modified && !value) {
          return tRequired;
        }

        if (meta?.dirty && value) {
          if (value.length < (textFieldProps?.inputProps?.minLength ?? DEFAULT_MIN)) {
            return tLengthMin;
          }

          if (value.length > (textFieldProps?.inputProps?.maxLength ?? DEFAULT_MAX)) {
            return tLengthMax;
          }
        }

        return '';
      }}
      {...props}
    />
  );
};
