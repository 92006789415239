import { Avatar as MuiAvatar, List, ListItem, ListItemAvatar, styled, Typography } from '@mui/material';
import React from 'react';

import { useSelector } from '../../hooks';
import { makeProfileAvatarUrl } from '../../utilities/utils';

const tChatMembers = 'Active Chat Members';
const tLoading = 'Loading chat members...';

const Avatar = styled(MuiAvatar)(() => {
  return {
    border: '1px solid lightgrey',
    borderRadius: '0.5rem',
    marginTop: '0.375rem',
  };
});

const ChatMembersContent: React.FC = () => {
  const members = useSelector((state) => state.app.chatMembers);

  return (
    <>
      <Typography variant="h6">{tChatMembers}</Typography>
      {!members || members.length === 0 ? (
        <Typography marginTop="0.5rem">{tLoading}</Typography>
      ) : (
        <List>
          {members.map(({ userId, userName }) => (
            <ListItem key={userId} sx={{ padding: '0.5rem 0' }}>
              <ListItemAvatar>
                <Avatar alt={userName} src={makeProfileAvatarUrl(userId)} title={userName} />
              </ListItemAvatar>
              <Typography display="block" variant="subtitle2">
                {userName}
              </Typography>
            </ListItem>
          ))}
        </List>
      )}
    </>
  );
};

export default ChatMembersContent;
