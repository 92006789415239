import { combineReducers } from 'redux';

import app from './app';
import auth from './auth';
import features from './features';
import notifications from './notifications';
import { pods } from './pods';
import { profileOptionsReducer } from './profileOptions';
import { actions, userReducer } from './userSlice';

const reducer = combineReducers({
  app,
  auth,
  features,
  notifications,
  pods: pods.reducer,
  profileOptions: profileOptionsReducer,
  user: userReducer,
});

// todo: (low priority) figure out types
export const rootReducer = (state: any, action: any) => {
  // catch the logout action and reset everything
  if (action.type === actions.logout.type) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
