import { Button, Typography } from '@mui/material';
import { AsyncThunkAction } from '@reduxjs/toolkit';
import { useCallback, useState } from 'react';

import { createSeries, deleteSeries, updateSeries } from '../../../actions/pods';
import { useDispatch } from '../../../hooks/useTypedRedux';
import { MeetingTimeTable } from './MeetingTimeTable';
import { SeriesDetailHeader } from './SeriesDetailHeader';
import { DetailsState, SeriesDetails } from './SeriesDetails';
import { Series, SeriesList } from './SeriesList';

const tNewSeries = 'Create New Series';
const tInfoSeries =
  "A series is a way to tag any combination of event times as a group. RSVP'ing for a single event time in a series means that volunteer will be RSVP'd for all meetings in that series.";

const tMeetingTimes = 'Event Times';

export interface ManageSeriesProps {
  roomId: string;
  series: Series[];
  refetch: () => void;
}

/** based on the state of the manager, decide the right redux action to dispatch */
const getAction = (
  roomId: string,
  state: DetailsState,
  selected: Series | undefined,
  formInputs: Partial<Series> | undefined
): AsyncThunkAction<any, any, any> => {
  switch (state) {
    case 'CREATE':
      return createSeries({
        body: {
          roomId,
          seriesDescription: formInputs?.description,
          seriesName: formInputs?.name,
        },
        roomId,
      });
    case 'UPDATE':
      if (!selected) {
        throw new Error('cannot update without a selected series');
      }
      return updateSeries({
        body: {
          roomId,
          seriesDescription: formInputs?.description,
          seriesId: selected.id,
          seriesName: formInputs?.name,
        },
        meetingTimeSeriesId: selected.id,
        roomId,
      });
    case 'DELETE':
      if (!selected) {
        throw new Error('cannot update without a selected series');
      }
      return deleteSeries({
        args: { meetingTimeSeriesId: selected.id, roomId },
        seriesName: selected.name,
      });
    default:
      throw new Error(`unhandled state ${state}`);
  }
};

/** Handles CRUD for series */
export const ManageSeries = ({ roomId, refetch, series }: ManageSeriesProps) => {
  const [selectedSeries, setSelectedSeries] = useState<Series | undefined>(undefined);
  const [detailsState, setDetailsState] = useState<DetailsState | undefined>(undefined);
  const dispatch = useDispatch();

  // pass event handlers to child components, with useCallback to reduce pointless redraws
  const onCancel = useCallback(() => {
    setDetailsState(undefined);
    setSelectedSeries(undefined);
  }, []);

  const onCreate = useCallback(() => {
    setSelectedSeries(undefined);
    setDetailsState('CREATE');
  }, []);
  const onClick = useCallback(
    (s: Series) => {
      if (s === selectedSeries) {
        return onCancel();
      }
      setDetailsState('READ');
      setSelectedSeries(s);
    },
    [selectedSeries, onCancel]
  );

  const onDelete = useCallback((s?: Series) => {
    setDetailsState('DELETE');
    if (s) {
      setSelectedSeries(s);
    }
  }, []);
  const onUpdate = useCallback(() => setDetailsState('UPDATE'), []);

  const onSubmit = useCallback(
    async (s: Partial<Series>) => {
      await dispatch(getAction(roomId, detailsState!, selectedSeries, s));
      onCancel();
      refetch();
    },
    [roomId, refetch, detailsState, selectedSeries]
  );

  return (
    <>
      <Typography>{tInfoSeries}</Typography>
      <Button
        data-testid="create-series-button"
        onClick={onCreate}
        sx={{ alignSelf: 'flex-end', width: 'fit-content' }}
        variant="text"
      >
        {tNewSeries}
      </Button>
      <SeriesList onClick={onClick} onDelete={onDelete} selectedSeriesId={selectedSeries?.id} series={series} />
      {detailsState && (
        <>
          <SeriesDetailHeader onDelete={onDelete} onEdit={onUpdate} series={selectedSeries} state={detailsState} />
          <SeriesDetails onCancel={onCancel} onSubmit={onSubmit} series={selectedSeries} state={detailsState} />
          {selectedSeries && (
            <>
              <Typography>{tMeetingTimes}</Typography>
              <MeetingTimeTable roomId={roomId} seriesId={selectedSeries.id} />
            </>
          )}
        </>
      )}
    </>
  );
};
