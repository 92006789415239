import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';

import { WorksheetGeneral } from './useSheetReader';

const EMAIL_REGEX = /[^\s@]+@[^\s@]+\.[^\s@]+/;
const STRING = 'string';

const Message = {
  EMAIL_REQUIRED: 'Email is required',
  EMAIL_TYPE: 'Email must be a string',
  FIRST_REQUIRED: 'First name is required',
  FIRST_TYPE: 'First name must be a string',
  LAST_REQUIRED: 'Last name is required',
  LAST_TYPE: 'Last name must be a string',
  ROLE_REQUIRED: 'Role is required',
  ROLE_TYPE: 'Role must be a string',
  SCHOOL_REQUIRED: 'School is required when Role is "educator"',
  SCHOOL_TYPE: 'School must be a string',
  SHEET_INVALID: 'Sheet must contain "First", "Last", "Email", "School", and "Role" columns in that order',
};

type Role = 'admin' | 'educator' | 'mod';

export interface GeneralError {
  count: number;
  messages: string[];
}

export interface Error {
  invalid: GeneralError;
  required: GeneralError;
  type: GeneralError;
}

export interface WorksheetErrors {
  email: Error;
  first: Error;
  last: Error;
  role: Error;
  school: Error;
  sheet: Error;
}

export interface UseBulkUploadSheetValidationOptions {
  schools?: string[];
  worksheet?: WorksheetGeneral;
}

export interface UseBulkUploadSheetValidationReturn {
  errorsRaw: WorksheetErrors;
  errors: string[];
}

const ROLES: Role[] = ['admin', 'educator', 'mod'];

const ERROR_TRACKER_INITIAL: WorksheetErrors = {
  email: {
    invalid: {
      count: 0,
      messages: [],
    },
    required: {
      count: 0,
      messages: [Message.EMAIL_REQUIRED],
    },
    type: {
      count: 0,
      messages: [Message.EMAIL_TYPE],
    },
  },
  first: {
    invalid: {
      count: 0,
      messages: [],
    },
    required: {
      count: 0,
      messages: [Message.FIRST_REQUIRED],
    },
    type: {
      count: 0,
      messages: [Message.FIRST_TYPE],
    },
  },
  last: {
    invalid: {
      count: 0,
      messages: [],
    },
    required: {
      count: 0,
      messages: [Message.LAST_REQUIRED],
    },
    type: {
      count: 0,
      messages: [Message.LAST_TYPE],
    },
  },
  role: {
    invalid: {
      count: 0,
      messages: [],
    },
    required: {
      count: 0,
      messages: [Message.ROLE_REQUIRED],
    },
    type: {
      count: 0,
      messages: [Message.ROLE_TYPE],
    },
  },
  school: {
    invalid: {
      count: 0,
      messages: [],
    },
    required: {
      count: 0,
      messages: [Message.SCHOOL_REQUIRED],
    },
    type: {
      count: 0,
      messages: [Message.SCHOOL_TYPE],
    },
  },
  sheet: {
    invalid: {
      count: 0,
      messages: [Message.SHEET_INVALID],
    },
    required: {
      count: 0,
      messages: [],
    },
    type: {
      count: 0,
      messages: [],
    },
  },
};

export const useBulkUploadSheetValidation: (
  options: UseBulkUploadSheetValidationOptions
) => UseBulkUploadSheetValidationReturn = ({ schools: knownSchools, worksheet }) => {
  const [errorsRaw, setErrorsRaw] = useState(cloneDeep(ERROR_TRACKER_INITIAL));
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    if (!worksheet) {
      setErrorsRaw(ERROR_TRACKER_INITIAL);
      setErrors([]);
    }
  }, [worksheet]);

  useEffect(() => {
    if (worksheet) {
      const updatedErrorsRaw = cloneDeep(errorsRaw);

      if (worksheet.headers && worksheet.headers[0].length < 5) {
        updatedErrorsRaw.sheet.invalid.count += 1;
        setErrorsRaw(updatedErrorsRaw);
        return;
      }

      for (let i = 0; i < worksheet.rows.length; i++) {
        const values = worksheet.rows[i];

        const [first, last, email, school, role] = values;

        // required
        if (!first) {
          updatedErrorsRaw.first.required.count += 1;
        }
        if (!last) {
          updatedErrorsRaw.last.required.count += 1;
        }
        if (!email) {
          updatedErrorsRaw.email.required.count += 1;
        }
        if (!school && (role as Role) === 'educator') {
          updatedErrorsRaw.school.required.count += 1;
        }
        if (!school && (role as Role) === 'admin') {
          updatedErrorsRaw.school.required.count += 1;
        }
        if (!role) {
          updatedErrorsRaw.role.required.count += 1;
        }

        // type
        if (first && typeof first !== STRING) {
          updatedErrorsRaw.first.type.count += 1;
        }
        if (last && typeof last !== STRING) {
          updatedErrorsRaw.last.type.count += 1;
        }
        if (email && typeof email !== STRING) {
          updatedErrorsRaw.email.type.count += 1;
        }
        if (school && typeof school !== STRING) {
          updatedErrorsRaw.school.type.count += 1;
        }
        if (role && typeof role !== STRING) {
          updatedErrorsRaw.role.type.count += 1;
        }

        // values
        if (role && !ROLES.includes(role.toLocaleLowerCase())) {
          updatedErrorsRaw.role.invalid.count += 1;
          const message = `Role '${role}' invalid. Valid roles include: admin, educator, mod`;
          if (!updatedErrorsRaw.role.invalid.messages.includes(message)) {
            updatedErrorsRaw.role.invalid.messages.push(message);
          }
        }
        if (email && !EMAIL_REGEX.test(email)) {
          updatedErrorsRaw.email.invalid.count += 1;
          const message = `Email '${email}' invalid. Valid emails look like 'mycoolemail@someprovider.com`;
          if (!updatedErrorsRaw.email.invalid.messages.includes(message)) {
            updatedErrorsRaw.email.invalid.messages.push(message);
          }
        }
        if (school && !knownSchools?.includes(school)) {
          updatedErrorsRaw.school.invalid.count += 1;
          const message = `School '${school}' invalid. School names are case sensitive and must match the list of known schools`;
          if (!updatedErrorsRaw.school.invalid.messages.includes(message)) {
            updatedErrorsRaw.school.invalid.messages.push(message);
          }
        }
      }

      if (!isEqual(errorsRaw, updatedErrorsRaw)) {
        setErrorsRaw(updatedErrorsRaw);
      }
    }
  }, [worksheet]);

  useEffect(() => {
    const updatedErrors = Object.keys(errorsRaw)
      .map((k) => {
        const typedK = k as keyof WorksheetErrors;
        const worksheetErrorField = errorsRaw[typedK];

        return Object.keys(worksheetErrorField)
          .map((kk) => {
            const typedKk = kk as keyof Error;
            const errorField = worksheetErrorField[typedKk];

            if (errorField.count) {
              return typedKk === 'invalid' && ['role', 'school', 'sheet'].includes(typedK)
                ? errorField.messages
                : errorField.messages.map((msg) => `${errorField.count} error(s): ${msg}`);
            }
          })
          .filter((value) => (Array.isArray(value) ? !!value.length : !!value))
          .reduce<string[]>((a, b) => (a && b ? [...a, ...b] : [...a]), []);
      })
      .filter((value) => (Array.isArray(value) ? !!value.length : !!value))
      .reduce<string[]>((a, b) => (a && b ? [...a, ...b] : [...a]), [])
      .sort();
    setErrors(updatedErrors);
  }, [errorsRaw]);

  return { errors, errorsRaw };
};
