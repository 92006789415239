import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';

import { RoomSessionsQuery } from '../../services/graphql/generated/react-query';
import { useEventDeleteEvent } from '../../services/openapi/generated/CrowdCoursingComponents';

interface DeletePodContentProps {
  handleClose: () => void;
  roomId: string;
  eventId: string;
  eventName: string;
}

const tTitle = 'Delete $eventName?';
const tBody =
  'You are about to delete $eventName and all associated events. This action cannot be undone. Enter "confirm" in the text field below if you wish to continue.';
const tConfirmation = 'confirm';
const tDelete = 'confirm';
const tCancel = 'Cancel';
const tYourSession = 'Your Event';

const DeleteSessionContent: React.FC<DeletePodContentProps> = ({ handleClose, roomId, eventId, eventName }) => {
  const [value, setValue] = React.useState<string>('');
  const queryClient = useQueryClient();
  const queryKey = ['RoomSessions', { roomId }];

  const { mutate } = useEventDeleteEvent({
    onError: (_, __, context) =>
      queryClient.setQueryData(queryKey, (context as Record<'previous', RoomSessionsQuery>).previous),
    onMutate: async (variables) => {
      await queryClient.cancelQueries({ queryKey });
      const previous = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, (current?: RoomSessionsQuery) => {
        if (!current?.room) {
          return current;
        }

        return {
          ...current,
          room: {
            ...current.room,
            sessions: current.room.sessions.filter((session) => session.id !== variables.pathParams.eventId),
          },
        } satisfies typeof current;
      });
      return { previous };
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
      queryClient.invalidateQueries({ queryKey: ['UpcomingRsvps'] });
      queryClient.invalidateQueries({ queryKey: ['RoomSummary'] });
    },
  });

  const isDisabled = !value || value.toLowerCase() !== tDelete.toLowerCase();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleClickDelete = () => {
    mutate({ pathParams: { eventId } });
    handleClose();
  };

  return (
    <>
      <DialogTitle id="alert-dialog-slide-title">{tTitle.replace('$eventName', eventName ?? tYourSession)}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {tBody.replace('$eventName', eventName ?? tYourSession)}
        </DialogContentText>
        <TextField onChange={handleChange} placeholder={tConfirmation} sx={{ margin: '2rem 0' }} value={value} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{tCancel}</Button>
        <Button color="error" disabled={isDisabled} onClick={handleClickDelete}>
          {tDelete}
        </Button>
      </DialogActions>
    </>
  );
};

export default DeleteSessionContent;
