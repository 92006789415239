import { Chip } from '../../../ui/atoms/Chip';
import { ManageSeriesProps } from './ManageSeries';
import { ManageSeriesSidebar } from './ManageSeriesSidebar';

const tLabelWithCount = (count: number) => `${count} Series`;

export type ManageSeriesChipProps = ManageSeriesProps;

export const ManageSeriesChip = (props: ManageSeriesChipProps) => {
  const label = tLabelWithCount(props.series.length);
  return (
    <ManageSeriesSidebar
      label={(onclick) => <Chip data-testid="manage-series-button" label={label} onclick={onclick} />}
      {...props}
    />
  );
};
