import { useEffect } from 'react';

import { LoginActions } from '../actions/login';
import { isTokenExpired } from '../utilities/utils';
import { useDispatch, useSelector } from './useTypedRedux';

/**
 * If auth token becomes expired, logout.
 */
export const useAutoLogout = () => {
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  useEffect(() => {
    if (token === null) {
      return;
    }

    const interval = setInterval(() => {
      if (isTokenExpired(token)) {
        dispatch(LoginActions.logout());
      }
    }, 5 * 60 * 1000 /* 5m */);

    return () => clearInterval(interval);
  }, [token, dispatch]);
};
