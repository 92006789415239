import {
  FileUploadRounded as FileUploadBase,
  FolderOutlined as FolderZipBase,
  InsertDriveFileOutlined as UnknownFileBase,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { FC } from 'react';

export const UploadIcon = styled(FileUploadBase)(({ theme }) => ({
  color: theme.palette.crowdCoursing.ORANGE['500']?.main ?? 'initial',
  fontSize: '164px',
}));
export const UnknownFileIcon = styled(UnknownFileBase)(({ theme }) => ({
  color: theme.palette.crowdCoursing.ORANGE['500']?.main ?? 'initial',
  fontSize: '164px',
}));
export const FolderZipIcon = styled(FolderZipBase)(({ theme }) => ({
  color: theme.palette.crowdCoursing.ORANGE['500']?.main ?? 'initial',
  fontSize: '164px',
}));

export const ImagePreview = styled('img')({ maxHeight: '164px' });
export const EmbedPreview = styled('embed')({ maxHeight: '164px' });

interface FilePreviewProps {
  files?: FileList | null;
}

export const FilePreview: FC<FilePreviewProps> = ({ files }) => {
  if (!files || files.length === 0) {
    return <UploadIcon />;
  }

  const { type } = files[0];
  if (!type) {
    return <UnknownFileIcon />;
  }

  if (type === 'application/zip') {
    return <FolderZipIcon />;
  }

  // images
  if (type.startsWith('image/')) {
    return <ImagePreview src={URL.createObjectURL(files[0])} />;
  }

  // known embeddable types
  if (type.startsWith('text/') || type.startsWith('video/') || ['application/pdf', 'application/json'].includes(type)) {
    return <EmbedPreview src={URL.createObjectURL(files[0])} />;
  }

  // anything else
  if (type) {
    return <UnknownFileIcon />;
  }

  return <UploadIcon />;
};
