import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs as DateAdapter } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LicenseInfo } from '@mui/x-license-pro';

import { ThemeProvider } from '../../cc-ui';

if (!LicenseInfo.getLicenseKey()) {
  LicenseInfo.setLicenseKey(
    'a070d18e9e73c661202fcc8d5146eb98Tz05NDcwMyxFPTE3NTMyODA5NjcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
  );
}

export const MaterialProvider = ({ children }: React.PropsWithChildren) => {
  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <ThemeProvider>{children}</ThemeProvider>
    </LocalizationProvider>
  );
};
