import axios, { AxiosRequestConfig } from 'axios';

import { LoginActions } from '../../actions/login';
import { useDispatch, useSelector } from '../../hooks/useTypedRedux';

export const useReactQueryFetch = <Data, Variables>(
  query: string,
  requestHeaders?: RequestInit['headers']
): ((variables?: Variables) => Promise<Data>) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);

  if (!token) {
    throw new Error('(fetcher)(useReactQueryFetch): auth token is null');
  }

  const headers: AxiosRequestConfig['headers'] = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };

  Object.entries(requestHeaders ?? {}).forEach(([key, value]) => {
    headers[key] = value;
  });

  return async (variables?: Variables) => {
    const payload = query
      .split('{')[0]
      .split(/(query|mutation)/)[2]
      .trim();

    dispatch({ payload, type: 'graphql.pending' });

    try {
      const result = await axios.post(
        `${import.meta.env.REACT_APP_BACKEND_URL}graphql`,
        JSON.stringify({ query, variables }),
        {
          headers,
        }
      );

      if (result.data.errors) {
        dispatch({ payload, type: 'graphql.rejected' });
        const { message } = result.data.errors[0] || {};
        if (result.data.errors[0]?.extensions?.code === 'AUTH_NOT_AUTHENTICATED') {
          dispatch(LoginActions.logout());
        }
        throw new Error(message || '(fetcher)(useReactQueryFetch): Unknown GraphQL Error');
      }

      dispatch({ payload, type: 'graphql.fulfilled' });
      return result.data.data;
    } catch (err) {
      // 500s from the server are exceptions
      dispatch({ payload, type: 'graphql.rejected' });
      throw err;
    }
  };
};
