import { Delete as DeleteIcon } from '@mui/icons-material';
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import { useState } from 'react';

import { TablePaginationActions } from '../../cc-ui';
import { getDate, getTime } from '../../utilities/utils';
import { SeriesDropdown, SeriesDropdownProps } from './SeriesDropdown';

const tBeginTimeLabel = 'Begin Time';
const tEndTimeLabel = 'End Time';
const tSeriesLabel = 'Series';
const tDate = 'Date';
const tCapacity = 'Capacity';

export interface MeetingTime {
  capacity: number;
  id: string;
  eventId: string;
  beginMeetingTime: Date | string;
  endMeetingTime: Date | string;
  seriesId?: string;
}

interface MeetingTimeTableProps extends Pick<SeriesDropdownProps, 'series'> {
  meetingTimes: MeetingTime[];
  onChangeCapacity: (meetingTime: MeetingTime) => void;
  onDelete: (m: MeetingTime) => void;
  onChangeSeries: (m: MeetingTime) => void;
}

export const MeetingTimeTable = ({
  meetingTimes,
  onDelete,
  onChangeCapacity,
  onChangeSeries,
  series,
}: MeetingTimeTableProps) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const headers = [tCapacity, tDate, tBeginTimeLabel, tEndTimeLabel, tSeriesLabel, ''];

  const handlePageChange = (_event: React.MouseEvent<HTMLButtonElement> | null, pageNumber: number) =>
    setPage(pageNumber);

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setRowsPerPage(parseInt(event.target.value, 10));

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell key={`${header} - ${index}`}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {meetingTimes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((meetingTime) => (
            <TableRow data-testid="meetingTime-row" hover={true} key={meetingTime.id}>
              <TableCell>
                <TextField
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeCapacity({ ...meetingTime, capacity: Number(event.target.value) })
                  }
                  sx={{ width: '64px' }}
                  value={meetingTime.capacity}
                />
              </TableCell>
              <TableCell>{getDate(meetingTime.beginMeetingTime)}</TableCell>
              <TableCell>{getTime(meetingTime.beginMeetingTime)}</TableCell>
              <TableCell>{getTime(meetingTime.endMeetingTime)}</TableCell>
              <TableCell>
                <SeriesDropdown
                  onChange={(seriesId?: string) => onChangeSeries({ ...meetingTime, seriesId })}
                  selectedId={meetingTime.seriesId}
                  series={series}
                  variant="compact"
                />
              </TableCell>
              <TableCell>
                <DeleteIcon color="error" onClick={() => onDelete(meetingTime)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              ActionsComponent={TablePaginationActions}
              colSpan={headers.length}
              count={meetingTimes.length}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[10, 20, 50]}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </>
  );
};
