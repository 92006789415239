import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from '@mui/material';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';

export type ModifiedSelectProps = Omit<
  MuiSelectProps,
  'onBlur' | 'onFocus' | 'value' | 'checked' | 'type' | 'multiple' | 'onChange'
> & { 'data-cy'?: string; 'data-testid'?: string };

/** Internal item value type */
export type SelectItem = { id: string | number; value: string };

/** External item value type */
export type JSONSelectItem = `{ "id": ${string | number}, "value": ${string} }`;

/** JSON */
export type SelectValueSingle = JSONSelectItem | undefined;

/** JSON[] */
export type SelectValueMultiple = JSONSelectItem[] | undefined;

/** JSON[] | JSON | undefined */
export type SelectValue = SelectValueSingle | SelectValueMultiple;

export interface SelectProps extends FieldProps<SelectValue, FieldRenderProps<SelectValue>> {
  helperText?: string;
  label?: string;
  required?: boolean;
  selectProps?: ModifiedSelectProps;
  selectItems: SelectItem[];
  formControlProps?: Omit<FormControlProps, 'error'>;
}

/** Parse SelectValueSingle */
export const getParsedSelected = (selected: string) => JSON.parse(selected) as SelectItem;

/** Parse SelectValueMultiple */
export const getParsedSelectedMultiple = (selected: string[]) => selected.map(getParsedSelected);

export const formatSelectValue = (value: SelectItem) => JSON.stringify(value) as JSONSelectItem;

export const Select = ({
  required,
  selectItems,
  formControlProps,
  selectProps,
  helperText,
  label,
  ...props
}: SelectProps) => {
  return (
    <Field
      render={({ input, meta: { error } }) => {
        const labelId = `${input.name}-label`;
        return (
          <>
            <FormControl error={Boolean(error)} fullWidth {...formControlProps}>
              {label && (
                <InputLabel id={labelId} sx={{ marginLeft: '-0.875rem' }}>
                  {label}
                </InputLabel>
              )}
              <MuiSelect<string | string[]>
                renderValue={(selected) => {
                  if (input.multiple) {
                    return (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
                        {getParsedSelectedMultiple(selected as string[])?.map((item) => (
                          <Chip
                            key={item.id}
                            label={item.value}
                            size="small"
                            sx={{ display: 'flex', flexWrap: 'wrap' }}
                          />
                        ))}
                      </Box>
                    );
                  }

                  return <>{getParsedSelected(selected as string).value}</>;
                }}
                {...selectProps}
                defaultValue={props.defaultValue}
                required={required}
                {...input}
                labelId={labelId}
                value={input.multiple ? ((input.value || []) as string[]) : (input.value as string)}
              >
                {selectItems.map((item, key) => (
                  <MenuItem key={key} value={JSON.stringify(item)}>
                    {input.multiple && (
                      <Checkbox
                        checked={
                          getParsedSelectedMultiple((input.value || []) as string[]).findIndex(
                            ({ id }) => id === item.id
                          ) > -1
                        }
                      />
                    )}
                    <ListItemText primary={item.value} />
                  </MenuItem>
                ))}
              </MuiSelect>
              {(error || helperText) && <FormHelperText>{error || helperText}</FormHelperText>}
            </FormControl>
          </>
        );
      }}
      {...props}
    />
  );
};
