import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { generateMeetingTime } from '../../utilities/utils';

export interface Row {
  id?: string;
  name?: string | null;
  beginMeetingTime?: Date | string;
  endMeetingTime?: Date | string;
}

export interface SessionsTableProps {
  canViewSessions?: boolean;
  onClickRow?: (event: Row) => (e: React.MouseEvent<HTMLTableRowElement>) => void;
  sessions?: Row[];
}

const tName = 'Event Name';
const tMeetingTime = 'Next Event';

const headers = [tName, tMeetingTime];

const onClickRowDefault = () => () => {};

const SessionsTable = ({ canViewSessions, onClickRow = onClickRowDefault, sessions }: SessionsTableProps) => {
  if (sessions === undefined || sessions.length === 0) {
    return null;
  }

  return (
    <Table sx={canViewSessions ? { '&:hover': { backgroundColor: '#f8f8f8' } } : {}}>
      <TableHead>
        <TableRow>
          {headers.map((header, index) => (
            <TableCell key={`${header} - ${index}`}>{header}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {sessions.map((session, index) => (
          <TableRow
            data-testid="event-row"
            hover={canViewSessions}
            key={`${session.name ?? 0}-${index}`}
            onClick={onClickRow(session)}
            sx={{ cursor: canViewSessions ? 'pointer' : 'initial' }}
          >
            <TableCell>{session.name}</TableCell>
            <TableCell>{generateMeetingTime(session.beginMeetingTime, session.endMeetingTime)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default SessionsTable;
