import { Grid, Skeleton, Typography } from '@mui/material';
import { FC, VFC } from 'react';

const tEmpty = "You're all caught up!";
const tError = 'Error fetching list';

export interface ListContainerProps {
  children?: React.ReactNode;
  Header?: React.ReactNode;
  loading: boolean;
  error: boolean;
  errorMessage?: string;
  empty: boolean;
  emptyMessage?: string;
  success: boolean;
}

export const Loading = () => (
  <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
    {new Array(4).fill(0).map((_, index) => (
      <Skeleton height="72px" key={index} variant="rectangular" width="100%" />
    ))}
  </Grid>
);

export const Empty: VFC<{ message?: string; 'data-testid'?: string }> = ({ message = tEmpty, ...props }) => (
  <Grid
    data-testid={props['data-testid']}
    sx={{
      alignItems: 'center',
      backgroundColor: (theme) => theme.palette.crowdCoursing.GREY[200]?.main ?? 'initial',
      border: '1px solid transparent',
      borderColor: (theme) => theme.palette.crowdCoursing.GREY[300]?.main ?? 'initial',
      borderRadius: '4px',
      display: 'flex',
      height: '304px',
      justifyContent: 'center',
    }}
  >
    <Typography color="success.primaryText" variant="body1">
      {message}
    </Typography>
  </Grid>
);

export const Error: VFC<{ message?: string }> = ({ message = tError }) => {
  return <Grid>{message}</Grid>;
};

export const ListContainer: FC<ListContainerProps> = ({
  children,
  empty,
  emptyMessage,
  error,
  errorMessage,
  loading,
  success,
  Header,
}) => {
  return (
    <Grid sx={{ display: 'grid', rowGap: '2rem' }}>
      {Header}
      {loading && <Loading />}
      {error && <Error message={errorMessage} />}
      {empty && <Empty message={emptyMessage} />}
      {success && children}
    </Grid>
  );
};
