/** @deprecated prefer src/services/router/Route */
export enum Route {
  HOME = '/',

  // admin
  ADMIN = '/admin',
  ADMIN_SCHOOL_MEMBERSHIP = '/admin/schools/:id/membership',

  // organization
  ORGANIZATIONS = '/organizations',

  // profile
  PROFILE = '/profile',
  // pods
  POD_CREATE = '/pod/create',
  POD_MEMBERS = '/rooms/:roomId/members',
  POD_UPDATE = '/rooms/:roomId/update',
  POD_VIEW = '/rooms/:roomId',
  POD_QR = '/rooms/:roomId/check-in-code',
  PODS = '/pods',
  SCHEDULES = '/schedules',
  PODS_SEARCH = '/search',

  // events
  SESSION_CREATE = '/events/create',
  SESSION_EDIT = '/events/:eventId/update',
  SESSION_MEMBERS = '/events/:eventId/members',
  EVENT_VIEW = '/events/:eventId',

  // notifications
  NOTIFICATIONS = '/notifications',

  // authentication routes
  LOGIN = '/login',
  LOGOUT = '/logout',
}
