import {
  AddModerator as AddModeratorIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  Email as EmailIcon,
  LocalPolice as LocalPoliceIcon,
  MoreVert as MoreIcon,
  RemoveModerator as RemoveModeratorIcon,
} from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  ListItemIcon,
  ListItemText as MuiListItemText,
  ListItemTextProps,
  Menu,
  MenuItem,
  MenuItemProps,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useCallback, useState } from 'react';

import { Member, MemberCallback } from './types';
import { UserList } from './UserList';

const tCancel = 'Cancel';
const tConfirm = 'Confirm';
const tDialogTitle = 'Transfer ownership';
// todo: update this text
const tDialogContent = (name: string) => `
  Promoting ${name} to Owner means you will;
    • be demoted to Facilitator
    • no longer be able to manage facilitators
    • be sent back to the room's main page
`;
const tNoMembers = 'No members';
const tRemoveMember = 'Remove member';
const tEmail = 'Email';
const tAddFacilitator = 'Promote to facilitator';
const tAddOwner = 'Transfer ownership';
const tRemoveFacilitator = 'Demote to member';

const ListItemText = ({ ...props }: ListItemTextProps) => (
  <MuiListItemText
    primaryTypographyProps={{
      color: (theme) => theme.palette.crowdCoursing.GREY[800]?.main ?? 'initial',
      fontSize: '1rem',
    }}
    {...props}
  />
);

export type MemberActionProps<Component extends React.ElementType> = Omit<MenuItemProps<Component>, 'onClick'> & {
  icon: React.ReactElement;
  onClick?: () => void;
  text: string;
};

export const MemberAction = <Component extends React.ElementType>({
  children,
  icon,
  onClick,
  text,
  ...props
}: MemberActionProps<Component>) => (
  <MenuItem onClick={onClick} {...props}>
    {/* eslint-disable-next-line react/no-children-prop */}
    <ListItemIcon children={icon} />
    <ListItemText>{text}</ListItemText>
    {children}
  </MenuItem>
);

export const PromoteToOwner = ({
  name,
  onConfirm: onConfirmCallback,
  onClose: onCloseCallback,
}: {
  name: string;
  onClose: () => void;
  onConfirm: () => void;
}) => {
  const isFullScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = useCallback(() => setIsDialogOpen(true), []);
  const closeDialog = useCallback(() => setIsDialogOpen(false), []);

  const onClose = useCallback(() => {
    onCloseCallback();
    closeDialog();
  }, [onCloseCallback]);

  const onCloseDialog: DialogProps['onClose'] = useCallback(() => onClose(), [onClose]);
  const onConfirm = useCallback(() => {
    onConfirmCallback();
    onClose();
  }, [onClose, onConfirmCallback]);

  return (
    <MemberAction icon={<LocalPoliceIcon fontSize="small" />} onClick={openDialog} text={tAddOwner}>
      <Dialog fullScreen={isFullScreen} onClose={onCloseDialog} open={isDialogOpen}>
        <DialogTitle sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
          {tDialogTitle}
          <IconButton onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ whiteSpace: 'pre' }}>{tDialogContent(name)}</DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="text">
            {tCancel}
          </Button>
          <Button onClick={onConfirm}>{tConfirm}</Button>
        </DialogActions>
      </Dialog>
    </MemberAction>
  );
};

interface MemberActionsProps {
  isOwner?: boolean;
  member: Member;
  onAddFacilitator: () => void;
  onAddOwner: () => void;
  onRemoveFacilitator: () => void;
  onRemove: () => void;
}

const MemberActions = ({
  isOwner,
  member: { email, firstName, isFacilitator, lastName },
  onAddFacilitator,
  onAddOwner,
  onRemove,
  onRemoveFacilitator,
}: MemberActionsProps) => {
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);

  const open = Boolean(anchorEl);

  const handleClickOpenMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget),
    []
  );

  const handleClose = useCallback(() => setAnchorEl(null), []);
  const handleClickItem = useCallback(
    (callback: () => void) => () => {
      callback();
      handleClose();
    },
    []
  );

  return (
    <>
      <IconButton
        aria-controls={open ? 'more-actions-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        aria-label="More actions"
        data-testid="more-actions-button"
        id="more-actions-button"
        onClick={handleClickOpenMenu}
      >
        <MoreIcon />
      </IconButton>
      <Menu
        MenuListProps={{
          'aria-labelledby': 'more-actions-button',
        }}
        anchorEl={anchorEl}
        id="more-actions-menu"
        onClose={handleClose}
        open={open}
      >
        {isOwner && (
          <>
            {/* promote to owner */}
            <PromoteToOwner name={`${firstName} ${lastName}`} onClose={handleClose} onConfirm={onAddOwner} />

            {/* promote to facilitator _or_ demote to member */}
            <MemberAction
              icon={isFacilitator ? <RemoveModeratorIcon fontSize="small" /> : <AddModeratorIcon fontSize="small" />}
              onClick={handleClickItem(isFacilitator ? onRemoveFacilitator : onAddFacilitator)}
              text={isFacilitator ? tRemoveFacilitator : tAddFacilitator}
            />
          </>
        )}

        {/* send email */}
        {email && (
          <MemberAction
            component="a"
            href={`mailto:${email}`}
            icon={<EmailIcon fontSize="small" />}
            rel="nofollower noopener noreferrer"
            text={tEmail}
            title={`email ${email}`}
          />
        )}

        {/* remove member */}
        <MemberAction
          data-testid="remove-member-button"
          icon={<DeleteIcon color="error" fontSize="small" />}
          onClick={handleClickItem(onRemove)}
          text={tRemoveMember}
        />
      </Menu>
    </>
  );
};

export interface MemberListProps {
  isOwner?: boolean;
  members: Member[];
  onAddFacilitator: MemberCallback;
  onAddOwner: MemberCallback;
  onRemoveFacilitator: MemberCallback;
  onRemove: MemberCallback;
  onClick?: MemberCallback;
}

export const MemberList = ({
  isOwner,
  members,
  onAddFacilitator,
  onAddOwner,
  onRemove,
  onRemoveFacilitator,
  onClick,
}: MemberListProps) => {
  const hasMembers = members.length > 0;

  if (!hasMembers) {
    return <Typography>{tNoMembers}</Typography>;
  }

  return (
    <UserList
      actions={(member) => (
        <MemberActions
          isOwner={isOwner}
          member={member}
          onAddFacilitator={() => onAddFacilitator(member)}
          onAddOwner={() => onAddOwner(member)}
          onRemove={() => onRemove(member)}
          onRemoveFacilitator={() => onRemoveFacilitator(member)}
        />
      )}
      onClick={onClick}
      users={members}
    />
  );
};
