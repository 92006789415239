import { Member } from './types';

export const sortAlphabetical = (a: Member, b: Member) => {
  if ((a.firstName ?? '') < (b.firstName ?? '')) {
    return -1;
  }

  if ((a.firstName ?? '') > (b.firstName ?? '')) {
    return 1;
  }

  return 0;
};

export const sortMembers = (a: Member, b: Member) => {
  const vettedAndFacilitatorA = Number(Boolean(a.isVetted && a.isFacilitator));
  const vettedAndFacilitatorB = Number(Boolean(b.isVetted && b.isFacilitator));
  const vettedAndNotFacilitatorA = Number(Boolean(a.isVetted && !a.isFacilitator));
  const vettedAndNotFacilitatorB = Number(Boolean(b.isVetted && !b.isFacilitator));
  const notVettedAndFacilitatorA = Number(Boolean(!a.isVetted && a.isFacilitator));
  const notVettedAndFacilitatorB = Number(Boolean(!b.isVetted && b.isFacilitator));

  if (vettedAndFacilitatorA < vettedAndFacilitatorB) {
    return 1;
  }

  if (vettedAndFacilitatorA > vettedAndFacilitatorB) {
    return -1;
  }

  if (vettedAndNotFacilitatorA < vettedAndNotFacilitatorB) {
    return 1;
  }

  if (vettedAndNotFacilitatorA > vettedAndNotFacilitatorB) {
    return -1;
  }

  if (notVettedAndFacilitatorA < notVettedAndFacilitatorB) {
    return 1;
  }

  if (notVettedAndFacilitatorA > notVettedAndFacilitatorB) {
    return -1;
  }

  return sortAlphabetical(a, b);
};
