import { Close } from '@mui/icons-material';
import { Avatar, Box, Drawer, Grid, IconButton, Theme, Typography, useMediaQuery } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';

type RenderOpener = (onclick: () => void) => ReactNode;

export interface RightSidebarProps {
  label: string | RenderOpener;
  headingText: string;
  children: ReactNode;
  /** should the sidebar start open? */
  open?: boolean;
  onClose?: () => void;
  avatar?: {
    alt?: string;
    src?: string | null;
  };
  subtitle?: string | null;
}

/** Displays its children in a collapsible sidebar on the right */
export const RightSidebar = ({
  label,
  children,
  headingText,
  avatar,
  subtitle,
  onClose = () => {},
  ...props
}: RightSidebarProps) => {
  const [isOpen, setIsOpen] = useState(props.open);
  useEffect(() => setIsOpen(props.open), [props.open]);

  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const open = () => setIsOpen(true);
  const close = () => {
    setIsOpen(false);
    onClose();
  };

  const opener: ReactNode = typeof label === 'string' ? <span onClick={open}>{label}</span> : label(open);

  return (
    <>
      {opener}
      <Drawer anchor="right" data-testid="drawer-root" onClose={close} open={isOpen} variant="temporary">
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2rem',
            height: '100vh',
            padding: '1rem',
            width: isMobile ? '100vw' : '50vw',
          }}
        >
          <Grid
            borderBottom="1px solid lightgrey"
            sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}
          >
            <Box sx={{ alignItems: 'center', display: 'flex', gap: '1rem' }}>
              {avatar && <Avatar alt={avatar.alt ?? ''} src={avatar.src ?? undefined} sx={{ height: 64, width: 64 }} />}
              <Box>
                <Typography variant="h5">{headingText}</Typography>
                {subtitle && (
                  <Typography color="text.secondary" variant="body2">
                    {subtitle}
                  </Typography>
                )}
              </Box>
            </Box>
            <IconButton data-testid="close-sidebar-button" onClick={close}>
              <Close />
            </IconButton>
          </Grid>

          {children}
        </Grid>
      </Drawer>
    </>
  );
};
