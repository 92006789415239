import { TextField } from '../../../atoms/Form';
import { ConstructedTextField } from '../types';

export const FIELD_NAME_LAST_NAME = 'lastName';

const tLabel = 'Last Name';
const tRequired = 'Last name is required';
const tLengthMax = 'Last name cannot exceed 255 characters';
const tLengthMin = 'Last name must be at least 2 characters';

export type LastNameProps = ConstructedTextField & { required?: boolean };

export const LastName = ({ textFieldProps, ...props }: LastNameProps) => {
  return (
    <TextField
      name={FIELD_NAME_LAST_NAME}
      textFieldProps={{
        inputProps: { maxLength: 255, minLength: 2 },
        label: tLabel,
        ...props,
        ...textFieldProps,
      }}
      validate={(value, _, meta) => {
        if (props.required && meta?.modified && !value) {
          return tRequired;
        }

        if (meta?.dirty && value) {
          if (value.length < 2) {
            return tLengthMin;
          }

          if (value.length > 255) {
            return tLengthMax;
          }
        }

        return '';
      }}
      {...props}
    />
  );
};
