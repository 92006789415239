import { Drawer, Grid } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { pageMenuClose, PageMenuContentType } from '../../state/app';
import AppNavigationContent from './AppNavigationContent';
import ChatMembersContent from './ChatMembersContent';

const PageMenuContent: React.FC<{ contentType: PageMenuContentType }> = ({ contentType }) => {
  switch (contentType) {
    case 'chat-members':
      return <ChatMembersContent />;
    case 'app-navigation':
    default:
      return <AppNavigationContent />;
  }
};

const PageMenu: React.FC = () => {
  const dispatch = useDispatch();
  const isVisible = useSelector(({ app }: any) => app?.isPageMenuVisible ?? false);
  const pageMenuContentType = useSelector((state: any) => state.app.pageMenuContentType);

  const handleClose = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event?.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    dispatch(pageMenuClose());
  };

  return (
    <Drawer anchor="right" onClose={handleClose} open={isVisible}>
      <Grid onClick={handleClose} onKeyDown={handleClose} role="presentation" sx={{ height: '100%', padding: '1rem' }}>
        <PageMenuContent contentType={pageMenuContentType} />
      </Grid>
    </Drawer>
  );
};

export default PageMenu;
