import axios, { AxiosInstance, InternalAxiosRequestConfig, isAxiosError } from 'axios';

import { LoginActions } from '../actions/login';
import { Store } from '../hooks/useTypedRedux';

/** read the store and add HTTP headers to the config */
const addHeadersFromStore = (config: InternalAxiosRequestConfig, store: Store): InternalAxiosRequestConfig => {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
};

/**
 * Create an axios instance that uses the given redux store to lookup
 * values for custom headers immediately before making each request
 */
export const createInstance = (store: Store): AxiosInstance => {
  const instance = axios.create({
    baseURL: import.meta.env.REACT_APP_BACKEND_URL,
    withCredentials: false,
  });

  instance.interceptors.request.use((config) => addHeadersFromStore(config, store));
  instance.interceptors.response.use(undefined, (err) => {
    if (isAxiosError(err) && err.response?.status == 401) {
      store.dispatch(LoginActions.logout());
    }
  });

  return instance;
};
