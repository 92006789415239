import { MenuItem, MenuItemProps, TextField, TextFieldProps, Tooltip, Typography } from '@mui/material';
import { FC, ReactNode, useEffect, useState } from 'react';

import { useConditionalTooltip } from '../../hooks/useConditionalTooltip';

const DEFAULT_SELECT_ID = 'crowdcoursing-select';
const tNoOptions = 'Coming Soon!';

export interface SelectOption {
  id: string;
  name: string | ReactNode;
}

export type SelectMenuItemProps = MenuItemProps;

export const SelectMenuItem: FC<SelectMenuItemProps> = ({ children, ...props }) => {
  const { ref, disableListeners } = useConditionalTooltip<HTMLLIElement>();

  return (
    <>
      {typeof children === 'string' ? (
        <Tooltip {...disableListeners} title={children}>
          <MenuItem {...props}>
            <Typography noWrap ref={ref} variant="body2">
              {children}
            </Typography>
          </MenuItem>
        </Tooltip>
      ) : (
        <MenuItem {...props}>{children}</MenuItem>
      )}
    </>
  );
};

export interface SelectBaseProps {
  onSelectOption: (selectedOption: SelectOption) => void;
  options: SelectOption[];
  defaultSelectedId?: string;
}

export type SelectProps = Partial<SelectBaseProps> & TextFieldProps;

export const Select: FC<Partial<SelectProps>> = ({
  id,
  options,
  defaultSelectedId,
  onChange,
  onSelectOption,
  ...props
}) => {
  const [value, setValue] = useState('');

  const selectValue = (optionId: string) => {
    setValue(optionId);
    if (onSelectOption) {
      const selectedOption = options?.find(({ id }) => id === optionId);
      selectedOption && onSelectOption(selectedOption);
    }
  };

  useEffect(() => {
    if (!options) {
      return;
    }
    if (options.length === 1) {
      setValue(options[0].id);
    } else if (defaultSelectedId) {
      setValue(defaultSelectedId);
    }
  }, [options, defaultSelectedId]);

  if (!options || (options && options.length === 0)) {
    return (
      <>
        <Typography fontWeight="bold" variant="subtitle2">
          {tNoOptions}
        </Typography>
      </>
    );
  }

  const onlyOneOption = options && options.length === 1;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    selectValue(event.target.value);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <TextField
      disabled={onlyOneOption}
      id={id ?? DEFAULT_SELECT_ID}
      {...props}
      fullWidth
      onChange={handleChange}
      select
      value={value}
    >
      {options.map(({ id, name }) => (
        <SelectMenuItem id={id} key={id} value={id}>
          {name}
        </SelectMenuItem>
      ))}
    </TextField>
  );
};
