import { makeOrganizationAvatarUrl } from '../utilities/utils';
import { useSelector } from './useTypedRedux';

/**
 * builds a URL to this organization's image, with a cache-busting parameter
 *
 * @param organizationId
 * @returns URL for the organization image
 */
export const useOrganizationImageUrl = (organizationId: string): string => {
  const cacheBuster = useSelector((state) => state.app.organizationImageUrlCacheBusters[organizationId]);
  const url = new URL(makeOrganizationAvatarUrl(organizationId));
  if (cacheBuster) {
    // silly way to bust caches. TODO: use HTTP cache headers/etags on the
    // backend
    url.searchParams.append('t', cacheBuster);
  }
  return url.href;
};
