import { lazy } from 'react';

export const AdminPage = lazy(() => import('./AdminPage/AdminPage').then((module) => ({ default: module.AdminPage })));

export const AppNavigationLayout = lazy(() =>
  import('./AppNavigationLayout/AppNavigationLayout').then((module) => ({ default: module.AppNavigationLayout }))
);

export const Home = lazy(() => import('./Home/Home').then((module) => ({ default: module.Home })));

export const LegacyAppNavigationLayout = lazy(() =>
  import('./AppNavigationLayout/AppNavigationLayout').then((module) => ({ default: module.LegacyAppNavigationLayout }))
);

export const Logout = lazy(() => import('./Logout/Logout').then((module) => ({ default: module.Logout })));

export const Organization = lazy(() =>
  import('./Organization/Organization').then((module) => ({ default: module.Organization }))
);
