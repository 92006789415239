import { DatePicker } from '@mui/lab';
import { Link, styled, TextField, Typography } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useMemo, useState } from 'react';

import { Table } from '../../../../cc-ui/components';
import { useUpcomingRsvpsQuery } from '../../../../services/graphql/generated/react-query';
import { generateMeetingTime } from '../../../../utilities/utils';

const tTitle = 'Upcoming Attendees';
const tUnknownUser = 'Unknown User';
const tDateFormat = 'MM/DD/YYYY';
const tStartDate = 'Start Date';
const tEndDate = 'End Date';

const HEADERS = ['Name', 'Contact', 'Event', 'Event Time'];

const getDefaultDates = () => {
  return {
    end: dayjs().startOf('day').add(3, 'months'),
    start: dayjs().startOf('day'),
  };
};

const getUserName = (first?: string | null, last?: string | null) => {
  if (first && last) {
    return `${first} ${last}`;
  }

  if (first) {
    return first;
  }

  if (last) {
    return last;
  }

  return tUnknownUser;
};

const Container = styled('div')(() => {
  return {
    display: 'grid',
    gap: '2.5rem',
  };
});

const WidgetBarContainer = styled('div')(({ theme }) => {
  return {
    alignItems: 'center',
    display: 'grid',
    gap: '2rem',
    gridAutoColumns: '1fr',
    gridAutoFlow: 'column',
    [theme.breakpoints.down('sm')]: {
      gap: '1.5rem',
      gridAutoColumns: 'unset',
      gridAutoFlow: 'row',
      gridAutoRows: 'auto',
    },
  };
});

const WidgetContainer = styled('div')(({ theme }) => {
  return {
    columnGap: '2rem',
    display: 'grid',
    gridAutoColumns: '1fr',
    gridAutoFlow: 'column',
    justifySelf: 'end',
    [theme.breakpoints.down('sm')]: {
      justifySelf: 'unset',
    },
  };
});

const WidgetBar = ({ children }: React.PropsWithChildren) => {
  return (
    <WidgetBarContainer>
      <Typography variant="h5">{tTitle}</Typography>
      <WidgetContainer>{children}</WidgetContainer>
    </WidgetBarContainer>
  );
};

const ContactLink = styled(Link)(() => {
  return {
    '&:visited': { color: 'initial' },
    display: 'block',
    whiteSpace: 'nowrap',
  };
});

const Contact = ({ email, phone }: { email?: string | null; phone?: string | null }) => {
  return (
    <>
      {phone && <ContactLink href={`tel:${phone}`}>{phone}</ContactLink>}
      {email && <ContactLink href={`mailto:${email}`}>{email}</ContactLink>}
    </>
  );
};

export interface RsvpRecord {
  begin: Date;
  contact?: {
    phone?: string;
    email?: string;
  };
  end: Date;
  eventName: string;
  name: string;
}

export interface RsvpTableProps {
  roomId: string;
}

export const RsvpTable = ({ roomId }: RsvpTableProps) => {
  const defaultDates = useMemo(() => getDefaultDates(), []);
  const [end, setEnd] = useState<Dayjs>(defaultDates.end);
  const [start, setStart] = useState<Dayjs>(defaultDates.start);

  const handleOnChangeEndDate = useCallback((date: Dayjs | null) => {
    if (!date) {
      setEnd(defaultDates.end);
      return;
    }

    setEnd(date);
  }, []);

  const handleOnChangeStartDate = useCallback((date: Dayjs | null) => {
    if (!date) {
      setStart(defaultDates.start);
      return;
    }

    setStart(date);
  }, []);

  const handleClickRetry = useCallback(() => {
    refetch();
  }, []);

  const { data, isLoading, isError, refetch } = useUpcomingRsvpsQuery({
    end: end.toISOString(),
    roomId,
    start: start.toISOString(),
  });

  const records =
    data?.room?.roomUpcomingRsvps.map((record, index) => {
      return [
        getUserName(record.user.firstName, record.user.lastName),
        <Contact email={record.user.email} key={index} phone={record.user.phoneNumber} />,
        record.event.name,
        generateMeetingTime(record.meetingTime.beginMeetingTime, record.meetingTime.endMeetingTime),
      ];
    }) ?? [];

  return (
    <Container>
      <WidgetBar>
        <DatePicker
          inputFormat={tDateFormat}
          label={tStartDate}
          maxDate={end.subtract(1, 'days')}
          minDate={start}
          onChange={handleOnChangeStartDate}
          renderInput={(params: any) => <TextField {...params} fullWidth />}
          value={start}
        />
        <DatePicker
          inputFormat={tDateFormat}
          label={tEndDate}
          maxDate={start.add(1, 'years')}
          minDate={start.add(1, 'days')}
          onChange={handleOnChangeEndDate}
          renderInput={(params: any) => <TextField {...params} fullWidth />}
          value={end}
        />
      </WidgetBar>
      <Table error={isError} headers={HEADERS} loading={isLoading} onClickRetry={handleClickRetry} records={records} />
    </Container>
  );
};
