import { Edit as EditIcon, Share as ShareIcon } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  styled,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { useDispatch } from '../../hooks/useTypedRedux';
import { useRoomSessionsQuery } from '../../services/graphql/generated/react-query';
import { Route } from '../../services/router/Route';
import { pageDialogOpen, setSessionId } from '../../state/app';
import { successToast } from '../../state/notifications';
import { fullyQualifiedUrl } from '../../utilities/utils';
import type { Event as SDSession } from './EventDescription';
import EventDescription from './EventDescription';
import type { Series } from './MeetingTimesContainer';

interface EventProps {
  userIsFacilitator: boolean;
  userIsMember: boolean;
  userIsVetted: boolean;
  roomId: string;
  event: SDSession & { id: string; name: string };
  series: Series[];
  /** callback for if we changed data */
  refetch: () => void;
}
const tRoomSessions = 'All Events';
const tGoToSession = 'Go to Event';
const tCreateSession = 'Create';
const tCopyTooltip = 'Copies a link to this event';
const tCopySuccess = 'Event link copied to clipboard!';

const Link = styled(RouterLink)({ color: 'inherit', textDecoration: 'none' });

const Event: React.FC<EventProps> = ({ userIsFacilitator, userIsMember, event, series, roomId, refetch }) => {
  const dispatch = useDispatch();
  const { id: eventId, name } = event;

  // TODO: remove when we are no longer using redux for current session
  const handleClickLink = () => dispatch(setSessionId(eventId));

  const handleClickDelete = (event: React.MouseEvent) => {
    event.stopPropagation();
    dispatch(
      pageDialogOpen({
        contentProps: { eventId: eventId, eventName: name, roomId },
        contentType: 'delete-session',
      })
    );
  };

  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const sharableSessionLink = fullyQualifiedUrl(Route.roomViewEvent({ eventId, roomId }));

  return (
    <Grid marginBottom={0.5}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Box alignItems="center" display="flex" justifyContent="space-between" width="100%">
              <Box alignItems="center" display="flex">
                <Link
                  data-testid="open-event-link"
                  onClick={handleClickLink}
                  style={{ color: 'inherit', textDecoration: 'none' }}
                  to={Route.roomViewEvent({ eventId, roomId })}
                >
                  <Grid marginLeft={1}>
                    <Typography>{name}</Typography>
                  </Grid>
                </Link>
              </Box>
              {!isMobile && (
                <Grid sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Tooltip placement="top-start" title={tCopyTooltip}>
                    <ShareIcon
                      onClick={() => {
                        navigator.clipboard.writeText(sharableSessionLink);
                        dispatch(successToast({ message: tCopySuccess }));
                      }}
                      style={{
                        cursor: 'pointer',
                        filter:
                          'invert(88%) sepia(37%) saturate(4038%) hue-rotate(162deg) brightness(92%) contrast(83%)',
                        width: '100px',
                      }}
                    />
                  </Tooltip>
                  {userIsFacilitator && (
                    <>
                      <Link onClick={handleClickLink} to={Route.roomEditEvent({ eventId, roomId })}>
                        <EditIcon color="info" />
                      </Link>
                      <DeleteIcon color="error" onClick={handleClickDelete} sx={{ marginLeft: '1rem' }} />
                    </>
                  )}
                </Grid>
              )}
            </Box>
          </Grid>
        </AccordionSummary>
        <AccordionDetails sx={{ display: 'block' }}>
          {isMobile && (
            <Grid sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Tooltip placement="top-start" title={tCopyTooltip}>
                <ShareIcon
                  onClick={() => {
                    navigator.clipboard.writeText(sharableSessionLink);
                    dispatch(successToast({ message: tCopySuccess }));
                  }}
                  style={{
                    cursor: 'pointer',
                    filter: 'invert(88%) sepia(37%) saturate(4038%) hue-rotate(162deg) brightness(92%) contrast(83%)',
                    width: '100px',
                  }}
                />
              </Tooltip>
              {userIsFacilitator && (
                <>
                  <Link onClick={handleClickLink} to={Route.roomEditEvent({ eventId, roomId })}>
                    <EditIcon color="info" />
                  </Link>
                  <DeleteIcon color="error" onClick={handleClickDelete} sx={{ marginLeft: '1rem' }} />
                </>
              )}
            </Grid>
          )}
          <EventDescription
            event={event}
            isFacilitator={userIsFacilitator}
            isMember={userIsMember}
            refetch={refetch}
            series={series}
          />
          <Grid container direction="row" justifyContent="flex-end" marginTop="1em">
            <Link onClick={handleClickLink} to={Route.roomViewEvent({ eventId, roomId })}>
              <Button>{tGoToSession}</Button>
            </Link>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

const EventAccordions = () => {
  // TODO: we need to refresh the data when the series changes
  const { roomId } = useParams();
  if (!roomId) {
    return null;
  }
  const { data: room, refetch } = useRoomSessionsQuery({ roomId }, { select: (data) => data?.room });

  if (!room) {
    return null;
  }
  const hasSessions = room.sessions !== undefined && room.sessions != null && room.sessions.length > 0;

  return (
    <Grid alignItems="center" container direction="row" justifyContent="center">
      <Grid container direction="column" justifyContent="justify-end">
        <Typography variant="h5">{tRoomSessions}</Typography>
      </Grid>
      {hasSessions && (
        <Grid container direction="row">
          <Grid item marginTop="1em" xs={12}>
            {room.sessions.map((event) => (
              <Event
                event={{
                  ...event,
                  meetingTimes: event.meetingTimes.map((meetingTime) => ({
                    ...meetingTime,
                    eventId: meetingTime.eventId ?? null,
                  })),
                }}
                key={`event-${event.id}`}
                refetch={refetch}
                roomId={roomId}
                series={room.series}
                userIsFacilitator={event.userIsFacilitator}
                userIsMember={event.userIsMember}
                userIsVetted={event.userIsVetted}
              />
            ))}
          </Grid>
        </Grid>
      )}
      {!hasSessions && (
        <Grid container direction="row">
          <Typography variant="body1">No events</Typography>
        </Grid>
      )}
      {room.userIsFacilitator && (
        <Grid container justifyContent="flex-end" marginTop="1em">
          <Link sx={{ display: 'block' }} to={Route.roomCreateEvent({ roomId: room.id })}>
            <Button data-testid="add-session-button">{tCreateSession}</Button>
          </Link>
        </Grid>
      )}
    </Grid>
  );
};

export default EventAccordions;
