import { useMemo } from 'react';

import { Switch, SwitchProps } from '../../../atoms/Form';

export const FIELD_NAME_IS_AUTOJOIN = 'isAutojoin';

const tLabel = 'Autojoin?';

export type IsAutojoinProps = Pick<SwitchProps, 'initialValue'> & { useDefault?: boolean };

export const IsAutojoin = ({ initialValue, useDefault = true }: IsAutojoinProps) => {
  const defaultInitialValue = useMemo(() => [FIELD_NAME_IS_AUTOJOIN], []);

  return (
    <Switch
      initialValue={useDefault ? defaultInitialValue : initialValue}
      name={FIELD_NAME_IS_AUTOJOIN}
      switches={[
        {
          formControlLabelProps: { label: tLabel, sx: { height: '2rem' } },
          switchProps: { defaultChecked: true, name: FIELD_NAME_IS_AUTOJOIN },
        },
      ]}
    />
  );
};
