import { Box, BoxProps, Button, ButtonProps, Typography, TypographyProps } from '@mui/material';

const tError = 'An error occurred. Please try again.';
const tButtonLabel = 'Try again';

export interface ErrorStateProps {
  buttonLabel?: string;
  message?: string;
  slotProps?: {
    button?: ButtonProps;
    buttonContainer?: BoxProps;
    container?: BoxProps;
    message?: TypographyProps;
  };
}

export const ErrorState = ({ buttonLabel, message, slotProps }: ErrorStateProps) => {
  return (
    <Box
      {...slotProps?.container}
      sx={{
        display: 'grid',
        gap: '1rem',
        gridAutoFlow: 'row',
        placeContent: 'center',
        ...slotProps?.container?.sx,
      }}
    >
      <Typography {...slotProps?.message}>{message ?? tError}</Typography>
      <Box
        {...slotProps?.buttonContainer}
        sx={{ display: 'grid', gridAutoFlow: 'row', placeContent: 'center', ...slotProps?.buttonContainer?.sx }}
      >
        <Button {...slotProps?.button} sx={{ width: 'fit-content', ...slotProps?.button?.sx }}>
          {buttonLabel ?? tButtonLabel}
        </Button>
      </Box>
    </Box>
  );
};
