import { Switch, SwitchProps } from '../../../atoms/Form';

export const FIELD_NAME_IS_SEARCHABLE = 'isSearchable';

const tLabel = 'Searchable?';

export type IsSearchableProps = Pick<SwitchProps, 'initialValue'>;

export const IsSearchable = ({ initialValue }: IsSearchableProps) => {
  return (
    <Switch
      initialValue={initialValue}
      name={FIELD_NAME_IS_SEARCHABLE}
      switches={[
        {
          formControlLabelProps: { label: tLabel, sx: { height: '2rem' } },
          switchProps: { name: FIELD_NAME_IS_SEARCHABLE },
        },
      ]}
    />
  );
};
