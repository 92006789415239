import { createAsyncThunk } from '@reduxjs/toolkit';

import { ProfileDto, ProfileService } from '../services/ApiService';
import { errorToast, successToast } from '../state/notifications';
import { Address, State, UserPersonalInfo } from '../state/state';

export const loadProfileOptionStates = createAsyncThunk('formOptions/states', ProfileService.states);

export const getProfile = createAsyncThunk<ProfileDto, undefined, { state: State }>(
  'user/getProfile',
  async (_, { getState }) => {
    try {
      const { user } = getState();
      if (!user) {
        throw new Error('`userId` not found');
      }
      const res = await ProfileService.profile({ userId: user.id });
      return res;
    } catch (exception) {
      window.console.error(exception);
    }

    return [];
  }
);

/**
 * the user has asked to save personal info
 */
export const savePersonalInfo = createAsyncThunk<UserPersonalInfo, UserPersonalInfo, { state: State }>(
  'user/savePersonalInfo',
  async (payload, thunkAPI) => {
    const userId = thunkAPI.getState().user!.id;

    try {
      await ProfileService.personalInformation({
        body: payload,
        userId,
      });
      thunkAPI.dispatch(successToast({ message: 'Save successful!' }));
    } catch (err: any) {
      if (err.response) {
        const { data } = err.response;

        if (data?.type === 'user-email-already-exists') {
          thunkAPI.dispatch(errorToast({ message: 'Email address already exists.' }));
        } else if (data?.type === 'user-phone-number-already-exists') {
          thunkAPI.dispatch(errorToast({ message: 'Phone number already exists.' }));
        } else {
          thunkAPI.dispatch(
            errorToast({ message: 'There was an error processing your request, please try again shortly.' })
          );
        }
      }

      throw err;
    }

    return payload;
  }
);

/**
 * the user has asked to save address info
 */
export const saveAddress = createAsyncThunk<Address, Address, { state: State }>(
  'user/saveAddress',
  async (payload, thunkAPI) => {
    const userId = thunkAPI.getState().user!.id;
    await ProfileService.address({ body: payload, userId });
    thunkAPI.dispatch(successToast({ message: 'Save successful!' }));
    return payload;
  }
);

/**
 * the user has asked to save a new profile pic
 */
export const saveProfilePicture = createAsyncThunk<void, File, { state: State }>(
  'user/savePicture',
  async (formFile, thunkAPI) => {
    const userId = thunkAPI.getState().user!.id;
    await ProfileService.picture({ formFile, userId });
  }
);

/**
 * the user has asked to delete their profile pic
 */
export const deleteProfilePicture = createAsyncThunk<void, void, { state: State }>(
  'user/deletePicture',
  async (_, thunkAPI) => {
    const userId = thunkAPI.getState().user!.id;
    await ProfileService.picture1({ userId });
  }
);
