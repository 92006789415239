import { Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from '../../../../hooks/useTypedRedux';
import { Route } from '../../../../services/router/Route';
import { setSessionId } from '../../../../state/app';
import SessionsTable, { SessionsTableProps } from '../../../Events/EventsTable';
import { Session, SessionsProps } from './types';

const tNoSessions = 'No events available';

export interface TableProps {
  canViewSession?: boolean;
  onClickRow: SessionsTableProps['onClickRow'];
  sessions: Session[];
}

export const Table = ({ canViewSession, onClickRow, sessions }: TableProps) => {
  if (sessions.length === 0) {
    return <Typography>{tNoSessions}</Typography>;
  }

  return (
    <SessionsTable
      canViewSessions={canViewSession}
      onClickRow={onClickRow}
      sessions={sessions
        .filter((x) => x.nextMeetingTime !== undefined && x.nextMeetingTime !== null)
        .map((session) => ({
          beginMeetingTime: session.nextMeetingTime?.beginMeetingTime,
          endMeetingTime: session.nextMeetingTime?.endMeetingTime,
          ...session,
        }))}
    />
  );
};

export type UseTablePropsOptions = Pick<SessionsProps, 'isFacilitator' | 'isMember' | 'sessions' | 'roomId'>;

export const useTableProps = ({ roomId, isFacilitator, isMember, sessions }: UseTablePropsOptions) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const canViewSession = useMemo(() => isFacilitator || isMember, [isFacilitator, isMember]);
  const onClickRow = useCallback(
    (event: { id?: string }) => () => {
      if (canViewSession && event.id) {
        dispatch(setSessionId(event.id));
        navigate(Route.roomViewEvent({ eventId: event.id, roomId }));
      }
    },
    [canViewSession]
  );
  return { tableProps: { canViewSession, onClickRow, sessions } };
};
