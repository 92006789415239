import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import React from 'react';

import { useOrganizationDeleteOrganization } from '../../services/openapi/generated/CrowdCoursingComponents';

interface DeleteOrganizationContentProps {
  handleClose: () => void;
  organizationId: string;
  organizationName: string;
  onDelete?: () => void;
}

const tTitle = 'Delete $organizationName?';
const tBody =
  'You are about to delete $organizationName. This action cannot be undone. Enter "confirm" in the text field below if you wish to continue.';
const tConfirmation = 'confirm';
const tDelete = 'confirm';
const tCancel = 'Cancel';

const DeleteOrganizationContent: React.FC<DeleteOrganizationContentProps> = ({
  handleClose,
  organizationId,
  organizationName,
  onDelete,
}) => {
  const [value, setValue] = React.useState<string>('');
  const { mutateAsync } = useOrganizationDeleteOrganization();

  const isDisabled = !value || value.toLowerCase() !== tDelete.toLowerCase();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleClickDelete = async () => {
    handleClose();
    await mutateAsync({ pathParams: { organizationId } });
    onDelete?.();
  };

  return (
    <>
      <DialogTitle id="alert-dialog-slide-title">{tTitle.replace('$organizationName', organizationName)}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {tBody.replace('$organizationName', organizationName)}
        </DialogContentText>
        <TextField onChange={handleChange} placeholder={tConfirmation} sx={{ margin: '2rem 0' }} value={value} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{tCancel}</Button>
        <Button color="error" disabled={isDisabled} onClick={handleClickDelete}>
          {tDelete}
        </Button>
      </DialogActions>
    </>
  );
};

export default DeleteOrganizationContent;
