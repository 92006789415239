import { Avatar, Button, Checkbox, Divider, Grid, Link, Typography } from '@mui/material';
import { Box } from '@mui/material';
import { FC, Fragment, useCallback, useMemo, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import { useRoomImageUrl } from '../../../src/hooks/useRoomImageUrl.ts';
import { useGetUserNotificationsQuery } from '../../../src/services/graphql/generated/react-query';
import {
  useUserMarkNotificationsAsArchived,
  useUserMarkNotificationsAsRead,
} from '../../../src/services/openapi/generated/CrowdCoursingComponents.ts';

interface UserNotification {
  __typename?: string;
  id?: string;
  dateTime: string;
  description: string;
  isArchived?: boolean;
  isRead: boolean;
  notificationType: string;
  roomId?: string;
  roomName?: string | null;
  eventName?: string | null;
  relativeUri?: string;
}

interface UserNotificationProps {
  notification: UserNotification;
  onSelect: (id: string) => void;
  isSelected: boolean;
}

const formatNotificationDate = (dateTime: string) => {
  const date = new Date(dateTime);
  const today = new Date();
  if (date.setHours(0, 0, 0, 0) == today.setHours(0, 0, 0, 0)) {
    const options: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
    };
    return new Date(dateTime).toLocaleString('en-US', options);
  }
  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    month: 'short',
  };
  return new Date(dateTime).toLocaleString('en-US', options);
};

const NotificationItem: FC<UserNotificationProps> = ({ notification, onSelect, isSelected }) => {
  const navigate = useNavigate();
  const linkUri = notification.relativeUri;
  const avatarUrl = useRoomImageUrl(notification.roomId || '');
  const isEventNotification =
    notification.eventName != null && notification.eventName != undefined && notification.eventName.length > 0;
  const hasRoomName =
    notification.roomName != null && notification.roomName != undefined && notification.roomName.length > 0;
  const title =
    isEventNotification && hasRoomName
      ? `${notification.eventName} in ${notification.roomName}`
      : isEventNotification && !hasRoomName
      ? `${notification.eventName}`
      : !isEventNotification && hasRoomName
      ? `${notification.roomName}`
      : 'System Notification';

  const isArchived = 'isArchived' in notification ? notification.isArchived : false;

  const { data } = useGetUserNotificationsQuery();
  const markNotificationsAsRead = useUserMarkNotificationsAsRead();
  const userId = data?.me?.id as string;

  const handleNotificationClick = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (linkUri) {
      if (!notification.isRead) {
        try {
          await markNotificationsAsRead.mutateAsync({
            body: {
              notificationIds: [notification.id || ''],
            },
            pathParams: { userId: userId || '' },
          });
        } catch (error) {
          console.error('Error marking notification as read:', error);
        }
      }
      navigate(linkUri, { replace: true });
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: notification.isRead ? 'rgba(0, 0, 0, 0.05)' : 'inherit',
        borderRadius: '8px',
        margin: '8px 0',
        opacity: isArchived ? 0.7 : 1,
        padding: '16px',
      }}
    >
      <Grid alignItems="center" container spacing={3}>
        <Grid item>
          <Checkbox checked={isSelected} onChange={() => onSelect(notification.id || '')} />
        </Grid>
        <Grid item xs>
          <Grid alignItems="center" container spacing={3}>
            <Grid item>
              <Avatar src={avatarUrl} sx={{ height: '3.5rem', width: '3.5rem' }} />
            </Grid>
            <Grid item xs>
              <Link
                component={RouterLink}
                onClick={handleNotificationClick}
                style={{
                  color: notification.isRead ? 'rgba(0, 0, 0, 0.6)' : 'inherit',
                  textDecoration: 'none',
                }}
                to={linkUri || '/notifications'}
              >
                <Grid container direction="column">
                  <Grid item>
                    <Typography variant="h6">{title}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">{notification.description}</Typography>
                  </Grid>
                </Grid>
              </Link>
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  color: notification.isRead ? 'rgba(0, 0, 0, 0.6)' : 'inherit',
                  textAlign: { sm: 'right', xs: 'left' },
                }}
                variant="subtitle2"
              >
                {formatNotificationDate(notification.dateTime)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

interface NotificationsProps {
  userNotifications?: UserNotification[];
}

const Notifications: FC<NotificationsProps> = (props) => {
  const { data, isLoading, error, refetch } = useGetUserNotificationsQuery();
  const [selectedNotifications, setSelectedNotifications] = useState<Set<string>>(new Set());
  const markNotificationsAsRead = useUserMarkNotificationsAsRead();
  const markNotificationsAsArchived = useUserMarkNotificationsAsArchived();
  const location = useLocation();

  const userNotifications = props.userNotifications || data?.me?.notifications || [];
  const userId = data?.me?.id;

  const isArchiveView = useMemo(
    () => new URLSearchParams(location.search).get('archive') === 'true',
    [location.search]
  );

  const filteredNotifications = useMemo(() => {
    return userNotifications.filter((notification) => {
      if ('isArchived' in notification) {
        return notification.isArchived === isArchiveView;
      }
      return !isArchiveView;
    });
  }, [userNotifications, isArchiveView]);

  const handleSelect = (id: string) => {
    setSelectedNotifications((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  const handleMarkAsRead = async () => {
    if (!userId) {
      console.error('User ID not available');
      return;
    }

    try {
      await markNotificationsAsRead.mutateAsync({
        body: {
          notificationIds: Array.from(selectedNotifications),
        },
        pathParams: { userId },
      });

      setSelectedNotifications(new Set());
      refetch();
    } catch (error) {
      console.error('Error marking notifications as read:', error);
    }
  };

  const handleMarkAsArchived = async () => {
    if (!userId) {
      console.error('User ID not available');
      return;
    }

    try {
      await markNotificationsAsArchived.mutateAsync({
        body: {
          notificationIds: Array.from(selectedNotifications),
        },
        pathParams: { userId },
      });
      setSelectedNotifications(new Set());
      refetch();
    } catch (error) {
      console.error('Error marking notifications as archived:', error);
    }
  };

  const handleSelectAll = useCallback(() => {
    const allIds = filteredNotifications.map((notification) => notification.id || '').filter((id) => id !== '');
    setSelectedNotifications((prev) => {
      if (prev.size === allIds.length) {
        return new Set();
      } else {
        return new Set(allIds);
      }
    });
  }, [filteredNotifications]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    console.error(error);
    return <div>Error loading notifications</div>;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography gutterBottom variant="h4">
          {isArchiveView ? 'Archived Notifications' : 'Notifications'}
        </Typography>
        <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
          <Button
            color="primary"
            disabled={filteredNotifications.length === 0}
            onClick={handleSelectAll}
            variant="contained"
          >
            {selectedNotifications.size === filteredNotifications.length ? 'Deselect All' : 'Select All'}
          </Button>
          <Button
            color="primary"
            disabled={selectedNotifications.size === 0}
            onClick={handleMarkAsRead}
            variant="contained"
          >
            Mark as Read
          </Button>
          <Button
            color="primary"
            disabled={selectedNotifications.size === 0 || isArchiveView}
            onClick={handleMarkAsArchived}
            variant="contained"
          >
            Mark as Archived
          </Button>
        </Box>
        <Grid item marginTop="2em">
          {filteredNotifications.map((notification, idx) => (
            <Fragment key={idx}>
              <NotificationItem
                isSelected={selectedNotifications.has(notification.id || '')}
                notification={notification}
                onSelect={handleSelect}
              />
              {idx !== filteredNotifications.length - 1 && <Divider sx={{ my: 1 }} />}
            </Fragment>
          ))}
          {filteredNotifications.length === 0 && (
            <Typography>
              {isArchiveView
                ? 'No archived notifications; nothing to see here!'
                : 'No new notifications; nothing to see here!'}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Notifications;
