//import { useFeature } from '~hooks/useFeature';

import { useFeature } from '~hooks/useFeature';

import { CrowdCoursingLogo, SpaceFloridaFullLogo, SpaceFloridaNavLogo } from '../../ui/atoms/Icons';

interface StyleProps {
  height?: string;
}

type Logo = 'navBarLogo' | 'logoWithTagline';

interface LogoProps {
  logo?: Logo;
  style?: StyleProps;
}

const Logo: React.FC<LogoProps> = ({ logo, style }) => {
  const sx = {
    height: style && style.height ? `${style.height} !important` : 'auto',
    width: style && style.height ? 'auto' : '100%',
  };

  const isSpaceFlorida = useFeature('isSpaceFlorida');
  if (isSpaceFlorida) {
    if (logo == 'navBarLogo') {
      return <SpaceFloridaNavLogo sx={sx} />;
    }
    return <SpaceFloridaFullLogo sx={sx} />;
  }

  return <CrowdCoursingLogo sx={sx} />;
};

export default Logo;
