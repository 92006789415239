import { Skeleton, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Dayjs } from 'dayjs';
import isEmpty from 'lodash/isEmpty';

import { ResumeBuilderFormFieldValues } from './Forms/utils';

function getFormattedDate(date?: Dayjs | null) {
  return Intl.DateTimeFormat('en-US', { month: 'short', year: 'numeric' }).format(date?.toDate());
}

function getFormattedDateRange(start?: Dayjs | null, end?: Dayjs | null) {
  if (!start) {
    return '';
  }

  return `${getFormattedDate(start)} – ${getFormattedDate(end) || 'Present'}`;
}

function ConditionalRender<T>({ children, condition }: React.PropsWithChildren & { condition: T }) {
  if (!condition || (typeof condition === 'object' && isEmpty(condition))) {
    return null;
  }

  return <>{children}</>;
}

const HorizontalRule = styled(Box)(function () {
  return { backgroundColor: 'lightgrey', borderRadius: '0.25in', width: '100%' };
});

function Loading() {
  return <Skeleton height="100%" sx={{ margin: '-1.5rem 0 0 -1.5rem', padding: '0 3rem 3rem 0' }} width="100%" />;
}

const ResumePreviewContainer = styled(Box)(function () {
  return {
    border: '1px solid lightgrey',
    borderRadius: '4px',
    display: 'grid',
    gap: '0.25in',
    gridAutoRows: 'auto 1fr auto',
    minHeight: '11in',
    padding: '0.25in',
    width: '8.5in',
  };
});

export type ResumePreviewBaseProps = { values?: Partial<ResumeBuilderFormFieldValues>; status: Utils.QueryStatus };

export type ResumePreviewSlotProps = { slotProps?: object };

export type ResumePreviewProps = ResumePreviewBaseProps & ResumePreviewSlotProps;

export function ResumePreview({ values, status }: ResumePreviewProps) {
  switch (status) {
    case 'pending':
      return (
        <ResumePreviewContainer data-testid="ResumePreview-pending" sx={{ border: 'unset' }}>
          <Loading />
        </ResumePreviewContainer>
      );
    case 'error':
      // todo: we get an error status (404) when user has no resume data yet; we will come back and handle this case
      // return <ResumePreviewContainer data-testid="ResumePreview-error" />
      break;
    case 'success': {
      if (isEmpty(values)) {
        return <ResumePreviewContainer data-testid="ResumePreview-empty" />;
      }
      break;
    }
    default:
      status satisfies never;
      break;
  }

  return (
    <ResumePreviewContainer data-testid="ResumePreview">
      <Box
        component="header"
        data-testid={'ResumePreview-PersonalInformation'}
        sx={{ display: 'grid', gap: '0.125in' }}
      >
        <ConditionalRender condition={values?.personalInformation}>
          <ConditionalRender condition={values?.personalInformation?.name}>
            <Typography sx={{ fontSize: (theme) => theme.typography.h5.fontSize, fontWeight: 'bold', lineHeight: '1' }}>
              {values?.personalInformation?.name}
            </Typography>
          </ConditionalRender>
          <Box
            sx={{
              alignItems: 'center',
              display: 'grid',
              gridAutoColumns: 'fit-content',
              gridAutoFlow: 'column',
              justifyContent: 'space-between',
              marginTop: '-0.125in',
            }}
          >
            <ConditionalRender condition={values?.personalInformation?.location}>
              <Typography>{values?.personalInformation?.location}</Typography>
            </ConditionalRender>
            <ConditionalRender condition={values?.personalInformation?.phone}>
              <Typography>{values?.personalInformation?.phone}</Typography>
            </ConditionalRender>
            <ConditionalRender condition={values?.personalInformation?.email}>
              <Typography>{values?.personalInformation?.email}</Typography>
            </ConditionalRender>
            <ConditionalRender condition={values?.personalInformation?.linkedin}>
              <Typography>{values?.personalInformation?.linkedin}</Typography>
            </ConditionalRender>
          </Box>
          <HorizontalRule sx={{ height: '0.03125in' }} />
        </ConditionalRender>
      </Box>
      <Box
        component="main"
        data-testid={'ResumePreview-Education'}
        sx={{ display: 'grid', gap: '0.25in', gridAutoRows: 'min-content' }}
      >
        <Box>
          <ConditionalRender condition={values?.education}>
            <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Education</Typography>
            <Box
              sx={{ alignItems: 'center', display: 'grid', gridAutoFlow: 'column', justifyContent: 'space-between' }}
            >
              <Box sx={{ alignItems: 'center', display: 'grid', gridAutoFlow: 'column' }}>
                <ConditionalRender condition={values?.education?.school}>
                  <Typography>{values?.education?.school}</Typography>
                </ConditionalRender>
                <ConditionalRender condition={values?.education?.program}>
                  <Typography>&nbsp;–&nbsp;</Typography>
                  <Typography>{values?.education?.program}</Typography>
                </ConditionalRender>
              </Box>
              <ConditionalRender condition={values?.education?.graduationYear}>
                <Typography>{values?.education?.graduationYear}</Typography>
              </ConditionalRender>
            </Box>
            <Box sx={{ display: 'inline-grid', gap: '0.25in', gridAutoColumn: 'min-content', gridAutoFlow: 'column' }}>
              <ConditionalRender condition={values?.education?.gpaUnweighted}>
                <Typography variant="body2">{`Unweighted GPA: ${values?.education?.gpaUnweighted}`}</Typography>
              </ConditionalRender>
              <ConditionalRender condition={values?.education?.gpaWeighted}>
                <Typography variant="body2">{`Weighted GPA: ${values?.education?.gpaWeighted}`}</Typography>
              </ConditionalRender>
            </Box>
            <Box>
              <ul style={{ margin: '0.0625in 0 0', padding: '0 0.25in' }}>
                {values?.education?.achievements?.map(function (achievement) {
                  return (
                    <li key={achievement}>
                      <Typography variant="body2">{achievement}</Typography>
                    </li>
                  );
                })}
              </ul>
            </Box>
          </ConditionalRender>
        </Box>
        <Box data-testid={'ResumePreview-Certifications'}>
          <ConditionalRender condition={values?.certifications}>
            <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Certifications</Typography>
            <Typography sx={{ fontStyle: 'italic' }}>{values?.certifications?.join(', ')}</Typography>
          </ConditionalRender>
        </Box>
        <Box data-testid={'ResumePreview-Skills'}>
          <ConditionalRender condition={values?.skills}>
            <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Skills</Typography>
            <Typography sx={{ fontStyle: 'italic' }}>{values?.skills?.join(', ')}</Typography>
          </ConditionalRender>
        </Box>
        <Box data-testid={'ResumePreview-Projects'}>
          <ConditionalRender condition={values?.projects}>
            <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Projects</Typography>
            <ul
              style={{
                display: 'grid',
                gap: '0.125in',
                listStyle: 'none',
                margin: '0.0625in 0 0',
                padding: 'unset',
              }}
            >
              {values?.projects?.map(function ({ description, name }) {
                return (
                  <li key={name}>
                    <Typography>{name}</Typography>
                    <Typography variant="body2">{description}</Typography>
                  </li>
                );
              })}
            </ul>
          </ConditionalRender>
        </Box>
        <Box data-testid={'ResumePreview-ProfessionalExperiences'}>
          <ConditionalRender condition={values?.professionalExperiences}>
            <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Professional Experience</Typography>
            <ul
              style={{
                display: 'grid',
                gap: '0.25in',
                listStyle: 'none',
                margin: '0.0625in 0 0',
                padding: 'unset',
              }}
            >
              {values?.professionalExperiences?.map(function ({ achievements, companyName, end, jobTitle, start }) {
                return (
                  <li key={jobTitle}>
                    <Typography sx={{ fontWeight: 'bold' }}>{companyName}</Typography>
                    <ConditionalRender condition={jobTitle}>
                      <HorizontalRule sx={{ height: '2px', margin: '0 0 0.0625in' }} />
                    </ConditionalRender>
                    <Box sx={{ display: 'grid', gridAutoColumns: '1fr auto', gridAutoFlow: 'column' }}>
                      <Typography>{jobTitle}</Typography>
                      <Typography>{`${getFormattedDateRange(start, end)}`}</Typography>
                    </Box>
                    <ConditionalRender condition={achievements}>
                      <ul
                        style={{
                          listStyleType: 'disc',
                          margin: '0.0625in 0 0',
                          padding: '0 0.25in',
                        }}
                      >
                        {achievements?.map(function (achievement) {
                          return (
                            <li key={achievement}>
                              <Typography variant="body2">{achievement}</Typography>
                            </li>
                          );
                        })}
                      </ul>
                    </ConditionalRender>
                  </li>
                );
              })}
            </ul>
          </ConditionalRender>
        </Box>
      </Box>
    </ResumePreviewContainer>
  );
}
