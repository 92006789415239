import { AsyncThunkAction } from '@reduxjs/toolkit';
import { useState } from 'react';

import { Member, MemberManagement, MemberManagementProps, RemovalConfirmDialog } from '../../cc-ui';
import { useDispatch } from '../../hooks/useTypedRedux';
import FacilitatorGuard from '../FacilitatorGuard';

/** what we expect back from the member query */
interface MemberManagementQueryResults {
  members: Member[];
  requests: Member[];
}

/** callback to create the appropriate member redux actions */
type RequestActionCreator = (userId: string) => AsyncThunkAction<any, any, any>;

export interface MemberManagementContainerProps {
  isOwner?: boolean;
  heading: string;
  roomId?: string;
  eventId?: string;
  subHeading: string | undefined;
  approveRequestActionCreator: RequestActionCreator;
  addFacilitatorRequestActionCreator?: RequestActionCreator;
  addOwnerRequestActionCreator?: RequestActionCreator;
  removeFacilitatorRequestActionCreator?: RequestActionCreator;
  rejectRequestActionCreator: RequestActionCreator;
  removeMemberActionCreator: RequestActionCreator;
  query: () => {
    data?: MemberManagementQueryResults | null;
    loading?: boolean;
    isLoading?: boolean;
    refetch(): void;
  };
  removeDialog: {
    title: string;
    body: string;
    actionText: string;
  };
  onClick?: (m: Member) => void;
}

/**
 * Container component wrapping `MemberManagement`, intended for rooms and
 * sessions
 *
 * Ensure the user is a facilitator, and then runs the given query, updating the
 * display after management actions are taken.
 */
export const MemberManagementContainer = ({
  isOwner,
  heading,
  subHeading,
  roomId,
  eventId,
  onClick = () => {},
  ...props
}: MemberManagementContainerProps) => {
  const { data, isLoading, refetch } = props.query();
  const dispatch = useDispatch();
  const [pendingRemoval, setPendingRemoval] = useState<Member>();

  if (isLoading || !data) return null;

  // create a callback that fires the action, then refreshes the query when it
  // completes
  const dispatchAndRefresh = (actionCreator: RequestActionCreator) => (user: Member) =>
    dispatch(actionCreator(user.id)).then(refetch);

  const { members, requests } = data;

  const memberProps: MemberManagementProps = {
    eventId,
    heading,
    isOwner,
    members,
    onAddFacilitator: props.addFacilitatorRequestActionCreator
      ? dispatchAndRefresh(props.addFacilitatorRequestActionCreator)
      : () => {},
    onAddOwner: props.addOwnerRequestActionCreator ? dispatchAndRefresh(props.addOwnerRequestActionCreator) : () => {},
    onApprove: dispatchAndRefresh(props.approveRequestActionCreator),
    onClick,
    onReject: dispatchAndRefresh(props.rejectRequestActionCreator),
    onRemove: setPendingRemoval,
    onRemoveFacilitator: props.removeFacilitatorRequestActionCreator
      ? dispatchAndRefresh(props.removeFacilitatorRequestActionCreator)
      : () => {},
    requests,
    roomId,
    subHeading,
  };

  const dialogProps = {
    ...props.removeDialog,
    isOpen: pendingRemoval !== undefined,
    onCancel: () => setPendingRemoval(undefined),
    onConfirm: async () => {
      if (pendingRemoval) {
        await dispatchAndRefresh(props.removeMemberActionCreator)(pendingRemoval);
        setPendingRemoval(undefined);
      }
    },
  };

  return (
    <>
      <RemovalConfirmDialog {...dialogProps} />
      <MemberManagement {...memberProps} />
    </>
  );
};

/**
 * a MemberManagementContainer that ensures the current user facilitators the
 * current pod/room */
export const FacilitatedMemberManagementContainer = (props: MemberManagementContainerProps) => {
  return (
    <FacilitatorGuard>
      <MemberManagementContainer {...props} />
    </FacilitatorGuard>
  );
};

export default FacilitatedMemberManagementContainer;
