import { AnyAction, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { onPayRoom } from '../actions/payments';
import {
  clearSelectedPod,
  deletePicture,
  getSelectedPod,
  savePicture,
  setSelectedPod as setSelectedPodAction,
} from '../actions/pods';
import { ChatMember } from '../hooks/useChat';
import { RoomDto } from '../services/ApiService';

export type PageMenuContentType = 'app-navigation' | 'chat-members';

interface DefaultPageDialogContentState {
  contentType: 'unknown';
  contentProps: Record<string, unknown>;
}

export interface DeletePodDialogContentState {
  contentType: 'delete-pod';
  contentProps: {
    roomId: string;
    podName: string;
  };
}

export interface DeleteOrganizationDialogContentState {
  contentType: 'delete-organization';
  contentProps: {
    organizationId: string;
    organizationName: string;
    onDelete?: () => void;
  };
}

export interface DeleteSessionDialogContentState {
  contentType: 'delete-session';
  contentProps: {
    roomId: string;
    eventId: string;
    eventName: string;
  };
}

export interface PodPaymentWizardDialogContentState {
  contentType: 'pod-payment-wizard';
  contentProps: Record<string, unknown>;
}

export type PageDialogContentState =
  | DefaultPageDialogContentState
  | DeletePodDialogContentState
  | DeleteSessionDialogContentState
  | PodPaymentWizardDialogContentState
  | DeleteOrganizationDialogContentState;

export interface AppState {
  // todo: make chat reducer; add chatMembers, selectedPod, sessionId to chat reducer;
  chatMembers: ChatMember[];
  isLoading: number;
  isPageDialogVisible: boolean;
  isPageMenuVisible: boolean;
  pageDialogContentState: PageDialogContentState;
  pageMenuContentType: PageMenuContentType;
  selectedPod?: RoomDto;
  sessionId?: string;
  /**
   * suffixes for room images to bust caches so changes take effect instantly
   * for the user
   * */
  roomImageUrlCacheBusters: { [roomId: string]: string | undefined };
  organizationImageUrlCacheBusters: { [roomId: string]: string | undefined };
  bubbleImageUrlCacheBusters: { [roomId: string]: string | undefined };
  /** what room are we going to pay for? Controls where we get redirected after the payment flow. */
  paymentRoomId?: string;
}

const initialState: AppState = {
  bubbleImageUrlCacheBusters: {},
  chatMembers: [],
  isLoading: 0,
  isPageDialogVisible: false,
  isPageMenuVisible: false,
  organizationImageUrlCacheBusters: {},
  pageDialogContentState: {
    contentProps: {},
    contentType: 'unknown',
  },
  pageMenuContentType: 'app-navigation',
  roomImageUrlCacheBusters: {},
  selectedPod: undefined,
  sessionId: undefined,
};

const slice = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(savePicture.fulfilled, (state, action) => {
        state.roomImageUrlCacheBusters[action.payload.roomId] = new Date().getTime().toString();
      })
      .addCase(deletePicture.fulfilled, (state, action) => {
        state.roomImageUrlCacheBusters[action.payload.roomId] = new Date().getTime().toString();
      })
      .addCase(onPayRoom.pending, (state, action) => {
        state.paymentRoomId = action.meta.arg.roomId;
      })
      .addCase(getSelectedPod.fulfilled, (state, action) => {
        state.selectedPod = action.payload;
      })
      .addMatcher(
        (action: AnyAction) => action.type.endsWith('pending'),
        (state: AppState) => {
          state.isLoading = state.isLoading + 1;
        }
      )
      .addMatcher(
        (action: AnyAction) => action.type.endsWith('fulfilled') || action.type.endsWith('rejected'),
        (state: AppState) => {
          if (state.isLoading > 0) {
            state.isLoading = state.isLoading - 1;
          }
        }
      )
      .addMatcher(isAnyOf(clearSelectedPod.fulfilled), (state: AppState) => {
        state.selectedPod = undefined;
      })
      .addMatcher(isAnyOf(setSelectedPodAction.fulfilled), (state: AppState, action: PayloadAction<RoomDto>) => {
        state.selectedPod = action.payload;
      }),
  initialState,
  name: 'app',
  reducers: {
    pageDialogClose: (state: AppState) => {
      state.isPageDialogVisible = false;
    },
    pageDialogOpen: (state: AppState, action: PayloadAction<PageDialogContentState>) => {
      state.isPageDialogVisible = true;
      state.pageDialogContentState = {
        ...action.payload,
      };
    },
    pageMenuClose: (state: AppState) => {
      state.isPageMenuVisible = false;
    },
    pageMenuOpen: (state: AppState, action: PayloadAction<PageMenuContentType | undefined>) => {
      state.isPageMenuVisible = true;
      state.pageMenuContentType = action.payload ?? 'app-navigation';
    },
    setChatMembers: (state: AppState, action: PayloadAction<ChatMember[]>) => {
      state.chatMembers = action.payload;
    },
    setSelectedPod: (state: AppState, action: PayloadAction<RoomDto>) => {
      state.selectedPod = action.payload;
    },
    setSessionId: (state: AppState, action: PayloadAction<string | undefined>) => {
      state.sessionId = action.payload;
    },
  },
});

export const {
  pageDialogClose,
  pageDialogOpen,
  pageMenuClose,
  pageMenuOpen,
  setChatMembers,
  setSelectedPod,
  setSessionId,
} = slice.actions;

export default persistReducer(
  {
    // always use values from initialState, not localStorage
    blacklist: ['isLoading', 'roomImageUrlCacheBusters'],
    key: slice.name,
    storage,
  },
  slice.reducer
);
