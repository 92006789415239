import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { type PartialDeep } from 'type-fest';

import { TextArrayField } from '~ui/atoms/Form';

import { ResumeWizardNavigation } from '../ResumeWizardNavigation';
import { TypedFormSpy } from './utils';

export type CertificationsFieldValues = {
  certifications: string[];
};

export function getFieldName(name: `${keyof CertificationsFieldValues}`) {
  return name;
}

export type CertificationsBaseProps = { initialValues?: PartialDeep<CertificationsFieldValues['certifications']> };
export type CertificationsSlotProps = { slotProps?: object };
export type CertificationsProps = CertificationsBaseProps & CertificationsSlotProps;

export function Certifications({ initialValues }: CertificationsProps) {
  return (
    <Box data-testid="Certifications Form" display="grid" gap="1.5rem">
      <TextArrayField
        initialValue={initialValues}
        name={getFieldName('certifications')}
        textFieldProps={{
          'data-testid': getFieldName('certifications'),
          helperText: 'Each line is a separate certification',
          label: 'Certifications',
          variant: 'filled',
        }}
      />
      <TypedFormSpy>
        {function ({ hasValidationErrors, form: { submit: onClick }, submitError }) {
          const disabled = hasValidationErrors;
          return (
            <>
              <Typography color="error.main" variant="overline">
                {submitError}
              </Typography>
              <ResumeWizardNavigation slotProps={{ next: { disabled, onClick } }} />
            </>
          );
        }}
      </TypedFormSpy>
    </Box>
  );
}
