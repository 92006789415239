import { Box, BoxProps, Button, ButtonProps } from '@mui/material';
import { useCallback } from 'react';

import { useResumeWizard } from './ResumeWizardProvider';

export type ResumeWizardNavigationBaseProps = object;
export type ResumeWizardNavigationSlotProps = {
  slotProps?: { container?: BoxProps; next?: ButtonProps; previous?: ButtonProps };
};
export type ResumeWizardNavigationProps = ResumeWizardNavigationBaseProps & ResumeWizardNavigationSlotProps;

export function ResumeWizardNavigation({ slotProps }: ResumeWizardNavigationProps) {
  const { isFirstStep, isLastStep, onClickNext, onClickPrevious } = useResumeWizard();

  const handleClickNext = useCallback<NonNullable<ButtonProps['onClick']>>((event) => {
    onClickNext();
    slotProps?.next?.onClick?.(event);
  }, []);

  const handleClickPrevious = useCallback<NonNullable<ButtonProps['onClick']>>((event) => {
    onClickPrevious();
    slotProps?.previous?.onClick?.(event);
  }, []);

  return (
    <Box display="flex" gap="1rem" {...slotProps?.container}>
      {!isFirstStep && (
        <Button {...slotProps?.previous} disabled={slotProps?.previous?.disabled} onClick={handleClickPrevious}>
          Previous
        </Button>
      )}
      {!isLastStep && (
        <Button {...slotProps?.next} disabled={slotProps?.next?.disabled} onClick={handleClickNext}>
          Next
        </Button>
      )}
      {isLastStep && <Button onClick={slotProps?.next?.onClick}>Submit</Button>}
    </Box>
  );
}
