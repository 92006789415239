import { createBrowserRouter, createMemoryRouter, Navigate, Outlet, RouteObject } from 'react-router-dom';

import { AdminPage, Home, LegacyAppNavigationLayout, Logout, Organization } from '~ui/pages';
import { ResumeBuilderAsync } from '~ui/pages/ResumeBuilder/ResumeBuilder';

import { AppLayout } from '../../AppLayout';
import { AdminSchoolMembers } from '../../components/Admin';
import { CheckIn as EventCheckIn } from '../../components/Events/CheckIn';
import { CheckInCode as EventCheckInCode } from '../../components/Events/CheckInCode';
import CreateEvent from '../../components/Events/CreateSession';
import EditEvent from '../../components/Events/EditEvent';
import EventMembers from '../../components/Events/EventMembers';
import Events from '../../components/Events/Events';
import ViewEvent from '../../components/Events/ViewEvent';
import { Login, Verification } from '../../components/Login';
import Notifications from '../../components/Notifications/Notifications';
import Organizations from '../../components/Organizations/Organizations';
import { Cancel as PaymentCancel, Success as PaymentSuccess } from '../../components/Payments';
import { CreatePod, MySchedules, PodMembers, Search, UpdatePod, ViewPod } from '../../components/Pods';
import { CheckIn as PodCheckIn } from '../../components/Pods/CheckIn';
import { CheckInCode as PodCheckInCode } from '../../components/Pods/CheckInCode';
import CreateProfile from '../../components/Profiles/CreateProfile';
import { ProfileRefresh } from '../../components/Profiles/PaymentAccountForm';
import Profile from '../../components/Profiles/Profile';
import { RedirectToLogin } from '../../components/RedirectToLogin';
import { useIsLoggedIn } from '../../hooks';
import { RawRoute, Route } from './Route';

const LoginGuard = () => {
  const isLoggedIn = useIsLoggedIn();
  if (!isLoggedIn) {
    return <RedirectToLogin />;
  }

  return <Outlet />;
};

const Index = () => {
  const isLoggedIn = useIsLoggedIn();
  if (!isLoggedIn) {
    return <RedirectToLogin />;
  }

  return <Navigate to={Route.home()} />;
};

export const routes: RouteObject[] = [
  // index
  { element: <Index />, path: RawRoute.index },

  // auth
  {
    children: [
      { element: <Login />, path: RawRoute.login },
      { element: <Logout />, path: RawRoute.logout },
      { element: <Verification />, path: RawRoute.verify },
    ],
    element: <LegacyAppNavigationLayout />,
  },

  {
    children: [
      // with app navigation layout
      {
        children: [
          // home
          { element: <Home />, path: RawRoute.home },

          { element: <MySchedules />, path: RawRoute.schedules },

          // admin
          { element: <AdminPage />, path: RawRoute.admin },
          { element: <AdminSchoolMembers />, path: RawRoute.adminSchoolMembership },

          // organizations
          {
            element: <Organizations />,
            path: RawRoute.organizations,
          },
          {
            element: <Organization />,
            path: RawRoute.organization,
          },

          // notifications
          { element: <Notifications />, path: RawRoute.notifications },

          // payments
          { element: <ProfileRefresh />, path: RawRoute.profileRefresh },
          { element: <PaymentSuccess />, path: RawRoute.paymentSuccess },
          { element: <PaymentCancel />, path: RawRoute.paymentCancel },

          // profile
          { element: <Profile />, path: RawRoute.profile },
          { element: <CreateProfile />, path: RawRoute.profileCreate },

          // rooms
          { element: <ViewPod />, path: RawRoute.podView },
          { element: <UpdatePod />, path: RawRoute.podUpdate },
          { element: <CreatePod />, path: RawRoute.podCreate },
          { element: <Search />, path: RawRoute.podsSearch },
          { element: <PodMembers />, path: RawRoute.podMembers },
          { element: <CreateEvent />, path: RawRoute.roomCreateEvent },
          { element: <EditEvent />, path: RawRoute.roomEditEvent },
          { element: <ViewEvent />, path: RawRoute.roomViewEvent },

          // events
          { element: <ViewEvent />, path: RawRoute.eventView },
          { element: <EditEvent />, path: RawRoute.eventEdit },
          { element: <EventMembers />, path: RawRoute.eventMembers },
          { element: <CreateEvent />, path: RawRoute.eventCreate },
          { element: <Events />, path: RawRoute.sessions },

          // resume
          { element: <ResumeBuilderAsync />, path: RawRoute.resumeBuilder },
        ],
        element: <AppLayout />,
      },

      // with legacy app navigation layout
      {
        children: [
          { element: <PodCheckInCode />, path: RawRoute.podQr },
          { element: <EventCheckInCode />, path: RawRoute.eventQr },
          { element: <PodCheckIn />, path: RawRoute.podCheckIn },
          { element: <EventCheckIn />, path: RawRoute.eventCheckIn },
        ],
        element: <LegacyAppNavigationLayout />,
      },
    ],
    element: <LoginGuard />,
  },
];

export const browserRouter = createBrowserRouter(routes);

export const memoryRouter = createMemoryRouter(routes);
