import { Button, ButtonProps } from '@mui/material';
import { useCallback } from 'react';
import { useForm } from 'react-final-form';

const tLabel = 'Reset';

export interface ResetButtonProps extends ButtonProps {
  /** Contents of reset button. Default: "Reset" */
  children?: React.ReactNode;
}

/** Must be used with react-final-form Form. */
export const ResetButton = ({ children, onClick, ...props }: ResetButtonProps) => {
  const { initialize } = useForm();

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (onClick) {
        onClick(event);
      }
      initialize({});
    },
    [onClick]
  );

  return (
    <Button onClick={handleClick} variant="outlined" {...props}>
      {children ?? tLabel}
    </Button>
  );
};
