import { useEffect } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { useDispatch } from '../../hooks';
import { Route } from '../../router';
import { RoomService } from '../../services/ApiService';
import { successToast } from '../../state/notifications';

const tCheckingIn = 'Checking in...';
const tCheckInSuccess = 'You have been checked in';

/** checks volunteers into the given room */
export const CheckIn = () => {
  const { roomId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    RoomService.checkIn({ roomId: roomId! }).then(() => {
      navigate(generatePath(Route.POD_VIEW, { roomId: roomId! }));
      dispatch(successToast({ message: tCheckInSuccess }));
    });
  }, [roomId, navigate]);

  return <>{tCheckingIn}</>;
};
