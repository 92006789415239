import React from 'react';
import { Location } from 'react-router-dom';
import { Navigate, useLocation } from 'react-router-dom';

import { Route as AppRoute } from '../router';

export const RedirectToLogin: React.FC = () => {
  const location = useLocation();
  const target: Location = {
    ...location,
    pathname: AppRoute.LOGIN,
  };
  const state = location.pathname !== AppRoute.HOME && { from: location };

  return <Navigate state={state} to={target} />;
};
