import {
  Button,
  InputAdornment,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import { ChangeEvent, useState } from 'react';

import { Member } from '../../cc-ui';
import { useEditRsvpsMutation, useSessionMemberQuery } from '../../services/graphql/generated/react-query';
import { getTimeRangeWithDate } from '../../utilities/utils';

// TODO: de-dupe with ../Pods/Member.tsx

interface MemberProps {
  member: Member;
  eventId: string;
}

export const MemberDetails = (props: MemberProps) => {
  const { isLoading, data: rsvps } = useSessionMemberQuery(
    { eventId: props.eventId, userId: props.member.id },
    { select: (data) => data?.session?.rsvps ?? [] }
  );
  const [diffs, setDiffs] = useState(new Map<string, RsvpChangeEvent>());
  const { mutateAsync } = useEditRsvpsMutation();

  if (isLoading || !rsvps) {
    return null;
  }

  const onChange = (e: RsvpChangeEvent) => {
    setDiffs((d) => d.set(e.id, e));
  };

  const onSave = async () => {
    await mutateAsync({ requests: [...diffs.values()] });
    setDiffs((d) => {
      d.clear();
      return d;
    });
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width={'50%'}>Meeting</TableCell>
          <TableCell width={'30%'}>Minutes</TableCell>
          <TableCell width={'20%'}>No show?</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rsvps.map((rsvp) => (
          <RsvpRow key={rsvp.id} {...rsvp} onChange={onChange} />
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell align="right" colSpan={4}>
            <Button onClick={onSave}>Save</Button>
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  );
};

interface RsvpChangeEvent {
  id: string;
  minutesConfirmed: number;
  isMarkedNoShow: boolean;
}

interface RsvpRowProps {
  id: string;
  beginMeetingTime: Date | string;
  endMeetingTime: Date | string;
  minutesConfirmed: number;
  isMarkedNoShow: boolean;
  onChange: (e: RsvpChangeEvent) => void;
}

const RsvpRow = (props: RsvpRowProps) => {
  const [mins, setMins] = useState(props.minutesConfirmed);
  const [noShow, setNoShow] = useState(props.isMarkedNoShow);

  const onChangeMins = (e: ChangeEvent<HTMLInputElement>) => {
    const m = parseInt(e.target.value);
    setMins(m);
    if (m > 0) {
      setNoShow(false);
    }
    props.onChange({
      id: props.id,
      isMarkedNoShow: m > 0 ? false : noShow,
      minutesConfirmed: m,
    });
  };

  const onChangeNoShow = (v: boolean) => {
    setNoShow(v);
    if (v) {
      setMins(0);
    }
    props.onChange({
      id: props.id,
      isMarkedNoShow: v,
      minutesConfirmed: v ? 0 : mins,
    });
  };

  const meetingDurationMins = dayjs(props.endMeetingTime).diff(props.beginMeetingTime) / 1000 / 60;

  return (
    <TableRow>
      <TableCell>{getTimeRangeWithDate(props.beginMeetingTime, props.endMeetingTime)}</TableCell>
      <TableCell>
        <TextField
          InputProps={{
            endAdornment: <InputAdornment position="end">of {meetingDurationMins} mins</InputAdornment>,
          }}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          onChange={onChangeMins}
          value={isNaN(mins) ? '' : mins}
        />
      </TableCell>
      <TableCell>
        <Switch checked={noShow} inputProps={{ 'aria-label': 'controlled' }} onChange={(_, v) => onChangeNoShow(v)} />
      </TableCell>
    </TableRow>
  );
};
