import { Grid, styled } from '@mui/material';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { ActionButton, RemovalConfirmDialog } from '../..';

interface ManageButtonProps {
  route: string;
  text: string;
}

const Link = styled(RouterLink)(() => {
  return { color: 'inherit', textDecoration: 'none' };
});

const ManageButton: React.FC<ManageButtonProps> = (props) => {
  return (
    <Link data-cy="manage-members" to={props.route}>
      <ActionButton data-cy="manage-members">{props.text}</ActionButton>
    </Link>
  );
};

interface LeaveButtonProps {
  text: string;
  title: string;
  body: string;
  onConfirm: () => void;
}

export const LeaveButton: React.FC<LeaveButtonProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const onCancel = () => setIsOpen(false);
  const onClick = () => {
    setIsOpen(true);
  };
  const onConfirm = () => {
    props.onConfirm();
    setIsOpen(false);
  };

  const dialogProps = {
    actionText: props.text,
    body: props.body,
    isOpen,
    onCancel,
    onConfirm,
    title: props.title,
  };

  return (
    <Grid item>
      <RemovalConfirmDialog {...dialogProps} />
      <ActionButton data-testid="leave-button" onClick={onClick}>
        {props.text}
      </ActionButton>
    </Grid>
  );
};

interface JoinButtonProps {
  text: string;
  onClick: () => void;
}

const JoinButton: React.FC<JoinButtonProps> = ({ onClick, text }) => {
  return (
    <Grid>
      <ActionButton data-testid="join-button" onClick={onClick}>
        {text}
      </ActionButton>
    </Grid>
  );
};

export interface MemberActionButtonProps {
  isRoomLeader: boolean;
  isFacilitator: boolean;
  isMember: boolean;
  isPending: boolean;
  manage: ManageButtonProps;
  leave: LeaveButtonProps;
  join: JoinButtonProps;
  pendingText: string;
}

const MemberActionButton: React.FC<MemberActionButtonProps> = (props) => {
  if (props.isFacilitator) {
    if (props.isMember) {
      return (
        <Grid container direction="row" xs={12}>
          <Grid item>
            <ManageButton {...props.manage} />
          </Grid>
          {!props.isRoomLeader && (
            <Grid item marginLeft="1em">
              <LeaveButton {...props.leave} />
            </Grid>
          )}
        </Grid>
      );
    } else {
      return (
        <Grid container direction="row" xs={12}>
          <Grid item>
            <ManageButton {...props.manage} />
          </Grid>
          {!props.isRoomLeader && (
            <Grid item marginLeft="1em">
              <JoinButton {...props.join} />
            </Grid>
          )}
        </Grid>
      );
    }
  }
  if (props.isMember) {
    return <LeaveButton {...props.leave} />;
  }
  if (props.isPending) {
    return (
      <Grid item>
        <ActionButton data-testid="pending-button" disabled={true}>
          {props.pendingText}
        </ActionButton>
      </Grid>
    );
  }
  if (props.isRoomLeader) {
    return null;
  }
  return <JoinButton {...props.join} />;
};

export default MemberActionButton;
