import { Grid } from '@mui/material';
import { FormState } from 'final-form';
import { useFormState } from 'react-final-form';

import { useSelector } from '../../hooks';
import { Form, FormProps } from '../../ui/atoms/Form';
import { FIELD_NAME_ZIPCODE, Zipcode } from '../../ui/molecules/Form';

export interface AddressFormFieldValues {
  [FIELD_NAME_ZIPCODE]: string;
}

const FormContent = ({
  children,
}: {
  children?: React.ReactNode | ((formState: FormState<AddressFormFieldValues>) => React.ReactNode);
}) => {
  const zipcode = useSelector((state) => state.user?.zipCode ?? '');
  const formState = useFormState<AddressFormFieldValues>();

  return (
    <>
      <Grid container spacing={3}>
        <Grid item sm={3} xs={12}>
          <Zipcode initialValue={zipcode} />
        </Grid>
      </Grid>
      {typeof children === 'function' ? children(formState) : children}
    </>
  );
};

export interface AddressFormProps {
  children?: React.ReactNode | ((formState: FormState<AddressFormFieldValues>) => React.ReactNode);
  onSubmit: FormProps<AddressFormFieldValues>['onSubmit'];
}

export const AddressForm = ({ children, onSubmit }: AddressFormProps) => {
  return (
    <Form onSubmit={onSubmit}>
      {/* eslint-disable-next-line react/no-children-prop */}
      <FormContent children={children} />
    </Form>
  );
};
