import React from 'react';

import { Notification } from '../../cc-ui';
import { useDispatch, useSelector } from '../../hooks/useTypedRedux';
import { cancelToastNotification } from '../../state/notifications';

const ToastNotificationsContainer: React.FC = () => {
  const dispatch = useDispatch();
  const toasts = useSelector((state) => state.notifications.toasts);
  const [open, setOpen] = React.useState<{ [key: string]: boolean }>({});

  React.useEffect(() => {
    const updatedOpen = toasts.map(({ id }) => ({ [id]: true })).reduce((a, b) => ({ ...a, ...b }), {});
    setOpen(updatedOpen);
  }, [toasts, setOpen]);

  const handleClose = (id: string) => () => {
    setOpen({ ...open, [id]: false });
    setTimeout(() => {
      dispatch(cancelToastNotification({ id }));
    }, 2000);
  };

  return (
    <>
      {toasts &&
        toasts.map(({ id, message, severity }) => (
          <Notification isOpen={open[id]} key={id} message={message} onClose={handleClose(id)} severity={severity} />
        ))}
    </>
  );
};

export default ToastNotificationsContainer;
