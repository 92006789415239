import { Grid, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';

import { createPod } from '../../actions/pods';
import { PictureUpload } from '../../cc-ui';
import { useDispatch } from '../../hooks/useTypedRedux';
import { useMyBubblesQuery } from '../../services/graphql/generated/react-query';
import {
  Form,
  FormProps,
  getParsedSelected,
  SelectValueSingle,
  SubmitButton,
  SwitchValue,
  TextFieldValue,
} from '../../ui/atoms/Form';
import {
  BubbleSelect,
  BubbleSelectProps,
  Description,
  FIELD_NAME_BUBBLE_SELECT,
  FIELD_NAME_DESCRIPTION,
  FIELD_NAME_IS_AUTOJOIN,
  FIELD_NAME_IS_SEARCHABLE,
  FIELD_NAME_ROOM_NAME,
  FIELD_NAME_ROOM_OWNER_NAME,
  FIELD_NAME_ZIPCODE,
  IsAutojoin,
  IsSearchable,
  RoomName,
  RoomOwnerName,
  Zipcode,
} from '../../ui/molecules/Form';

const tCreateNewRoomTitle = 'Create New Room';
const tCreateNewRoomButton = 'Create';
const tAutojoinTooltip = 'Toggle this ON to allow individuals to join without needing manual room owner approval';
const tSearchableTooltip =
  'Toggle this ON to allow the room to be publicly searchable in the site search. This means that it would show up in search results in the “Search Rooms” section of the site';
const tRequiredField = '* Required Field';
const tAfterCreationText = 'You will be able to add events & event times for this room after you create the room.';

interface FormValues {
  [FIELD_NAME_BUBBLE_SELECT]: SelectValueSingle;
  [FIELD_NAME_DESCRIPTION]: TextFieldValue;
  [FIELD_NAME_IS_AUTOJOIN]: SwitchValue;
  [FIELD_NAME_IS_SEARCHABLE]: SwitchValue;
  [FIELD_NAME_ROOM_NAME]: TextFieldValue;
  [FIELD_NAME_ROOM_OWNER_NAME]: TextFieldValue;
  [FIELD_NAME_ZIPCODE]: TextFieldValue;
}

const requiredFields: Array<keyof FormValues> = ['bubbleSelect', 'description', 'roomName', 'zipCode'];

const FormContent = ({
  showBubbleSelect,
  bubbleSelectItems,
}: {
  showBubbleSelect: boolean;
  bubbleSelectItems: BubbleSelectProps['selectItems'];
}) => {
  return (
    <>
      <Grid container marginBottom="2rem" spacing={3}>
        {showBubbleSelect && (
          <Grid item xs={12}>
            <BubbleSelect required selectItems={bubbleSelectItems} />
          </Grid>
        )}
        <Grid item md={6} xs={12}>
          <RoomName />
        </Grid>
        <Grid alignItems="center" container direction="row" item md={6} spacing={5} xs={12}>
          <Tooltip placement="top" title={tAutojoinTooltip}>
            <Grid container item justifyContent="center" md={6} xs={12}>
              <IsAutojoin />
            </Grid>
          </Tooltip>
          <Tooltip placement="top" title={tSearchableTooltip}>
            <Grid container item justifyContent="center" md={6} xs={12}>
              <IsSearchable />
            </Grid>
          </Tooltip>
        </Grid>
        <Grid item md={6} xs={12}>
          <RoomOwnerName />
        </Grid>
        <Grid item md={6} xs={12}>
          <Zipcode />
        </Grid>
        <Grid item xs={12}>
          <Description required />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">{tRequiredField}</Typography>
        <Typography variant="body1">{tAfterCreationText}</Typography>
      </Grid>
      <Grid container direction="row">
        <Grid alignItems="flex-end" container direction="column">
          <Grid item xs={6}>
            <SubmitButton
              data-testid="create-room-button"
              requiredFields={requiredFields.filter((field) => (showBubbleSelect ? true : field !== 'bubbleSelect'))}
            >
              {tCreateNewRoomButton}
            </SubmitButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const CreatePod: React.FC = () => {
  const dispatch = useDispatch();
  const { data, isLoading } = useMyBubblesQuery();

  const [roomUrl, setRoomUrl] = useState<string>();
  const [roomImage, setRoomImage] = useState<File>();

  const handleSubmit = useCallback<FormProps<FormValues>['onSubmit']>(
    ({ bubbleSelect, description, isAutojoin, isSearchable, roomName: name, zipCode }) => {
      let bubbleId: string | undefined = undefined;

      if (defaultBubbleId) {
        bubbleId = defaultBubbleId;
      } else if (bubbleSelect) {
        bubbleId = String(getParsedSelected(bubbleSelect).id);
      }

      const request = {
        bubbleId,
        description,
        image: roomImage,
        isPublic: Boolean(isAutojoin),
        isSearchable: Boolean(isSearchable),
        maximumParticipants: 2,
        name,
        zipCode,
      };
      dispatch(createPod(request));
    },
    []
  );

  if (isLoading || data?.me.bubbles === undefined || data?.me.id === undefined) {
    return null;
  }

  const {
    me: { bubbles },
  } = data;

  if (bubbles.length === 0) {
    throw new Error('Cannot create room without access to bubbles. How did you get here?');
  }

  const defaultBubbleId = bubbles.length === 1 ? bubbles[0].id : null;

  const onPictureChange = (f: File) => {
    setRoomImage(f);
    // convert to a data URI that we can show in the `PictureUpload` before we save
    const reader = new FileReader();
    reader.addEventListener('load', (e) => {
      const res = e.target?.result;
      if (!res || res instanceof ArrayBuffer) {
        throw new Error('incorrect FileReader usage');
      }
      setRoomUrl(res);
    });
    reader.readAsDataURL(f);
  };

  return (
    <div data-testid="CreatePod">
      <Grid alignItems="center" container direction="column" item xs={12}>
        <Grid item xs={12}>
          <Typography variant="h4">{tCreateNewRoomTitle}</Typography>
        </Grid>
        <Grid container direction="row" item justifyContent="center" justifyItems="center" marginTop="1em" xs={12}>
          <PictureUpload imageUrl={roomUrl} name="" onChange={onPictureChange} />
        </Grid>
      </Grid>
      <Grid
        alignItems="center"
        container
        direction="column"
        justifyContent="center"
        justifyItems="center"
        marginTop="1em"
      >
        <Form onSubmit={handleSubmit}>
          <FormContent
            bubbleSelectItems={bubbles.map(({ id, name: value }) => ({ id, value }))}
            showBubbleSelect={!defaultBubbleId}
          />
        </Form>
      </Grid>
    </div>
  );
};

export default CreatePod;
