import { Grid, Typography } from '@mui/material';
import Linkify from 'linkify-react';
import React from 'react';

import CollapsibleText from '../Shared/CollapsibleText';

interface PodDescriptionProps {
  children?: React.ReactNode;
  // TODO: simplify types once they don't need to support REST and GQL
  pod: {
    description?: string | null;
    id?: string | null;
  };
}

const tDescription = 'Description';

const PodDescription: React.FC<PodDescriptionProps> = ({ children, pod }) => {
  const { description } = pod;
  if (description === undefined || description === null || description.length === 0) {
    return null;
  }
  return (
    <>
      <Grid container marginBottom="1em">
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Typography variant="h5">{tDescription}</Typography>
          </Grid>
          <Grid item marginTop="1em" xs={12}>
            <CollapsibleText>
              <Typography style={{ whiteSpace: 'pre-line' }} variant="body1">
                <Linkify options={{ target: '_blank' }}>{description}</Linkify>
              </Typography>
            </CollapsibleText>
          </Grid>
        </Grid>
      </Grid>

      {children}
    </>
  );
};

export default PodDescription;
