import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { useReactQueryFetch } from '../fetcher';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: string; output: string };
  Decimal: { input: number; output: number };
  URL: { input: any; output: any };
  UUID: { input: any; output: any };
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
}

/** optional extra fields for BackgroundCheckSummary */
export type BackgroundCheckSummary = {
  __typename?: 'BackgroundCheckSummary';
  id: Scalars['ID']['output'];
  isCompleted: Scalars['Boolean']['output'];
  schoolDistrictId: Scalars['ID']['output'];
  schoolDistrictName: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type BubbleSummary = {
  __typename?: 'BubbleSummary';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type CurrentDistrictStats = {
  __typename?: 'CurrentDistrictStats';
  ageGroups: Array<Scalars['Int']['output']>;
  backgroundCheckComplete: Scalars['Int']['output'];
  backgroundCheckFailed: Scalars['Int']['output'];
  backgroundCheckInProgress: Scalars['Int']['output'];
  economicImpactAllTime: Scalars['Decimal']['output'];
  economicImpactMonth: Scalars['Decimal']['output'];
  economicImpactWeek: Scalars['Decimal']['output'];
  economicImpactYTD: Scalars['Decimal']['output'];
  genderDistribution: Array<Scalars['Int']['output']>;
  minutesAllTime: Scalars['Int']['output'];
  minutesScheduledThisNextYear: Scalars['Int']['output'];
  minutesThisMonth: Scalars['Int']['output'];
  minutesThisWeek: Scalars['Int']['output'];
  minutesThisYear: Scalars['Int']['output'];
  minutesUnconfirmed: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  participatingEducators: Scalars['Int']['output'];
  schoolCount: Scalars['Int']['output'];
  schoolsWithMembersCount: Scalars['Int']['output'];
  totalApprovedVolunteers: Scalars['Int']['output'];
  totalEducators: Scalars['Int']['output'];
  totalPendingVolunteers: Scalars['Int']['output'];
  totalVolunteersMonth: Scalars['Int']['output'];
  totalVolunteersWeek: Scalars['Int']['output'];
  totalVolunteersYear: Scalars['Int']['output'];
};

export type CurrentEducatorStats = {
  __typename?: 'CurrentEducatorStats';
  economicImpactAllTime: Scalars['Decimal']['output'];
  economicImpactMonth: Scalars['Decimal']['output'];
  economicImpactWeek: Scalars['Decimal']['output'];
  economicImpactYTD: Scalars['Decimal']['output'];
  minutesAllTime: Scalars['Int']['output'];
  minutesScheduledThisNextYear: Scalars['Int']['output'];
  minutesThisMonth: Scalars['Int']['output'];
  minutesThisWeek: Scalars['Int']['output'];
  minutesThisYear: Scalars['Int']['output'];
  minutesUnconfirmed: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type CurrentSchoolStats = {
  __typename?: 'CurrentSchoolStats';
  ageGroups: Array<Scalars['Int']['output']>;
  backgroundCheckComplete: Scalars['Int']['output'];
  backgroundCheckFailed: Scalars['Int']['output'];
  backgroundCheckInProgress: Scalars['Int']['output'];
  economicImpactAllTime: Scalars['Decimal']['output'];
  economicImpactMonth: Scalars['Decimal']['output'];
  economicImpactWeek: Scalars['Decimal']['output'];
  economicImpactYTD: Scalars['Decimal']['output'];
  genderDistribution: Array<Scalars['Int']['output']>;
  minutesAllTime: Scalars['Int']['output'];
  minutesScheduledThisNextYear: Scalars['Int']['output'];
  minutesThisMonth: Scalars['Int']['output'];
  minutesThisWeek: Scalars['Int']['output'];
  minutesThisYear: Scalars['Int']['output'];
  minutesUnconfirmed: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  participatingEducators: Scalars['Int']['output'];
  totalApprovedVolunteers: Scalars['Int']['output'];
  totalEducators: Scalars['Int']['output'];
  totalPendingVolunteers: Scalars['Int']['output'];
  totalVolunteersMonth: Scalars['Int']['output'];
  totalVolunteersWeek: Scalars['Int']['output'];
  totalVolunteersYear: Scalars['Int']['output'];
};

export type CurrentVolunteerStats = {
  __typename?: 'CurrentVolunteerStats';
  economicImpactAllTime: Scalars['Decimal']['output'];
  economicImpactMonth: Scalars['Decimal']['output'];
  economicImpactWeek: Scalars['Decimal']['output'];
  economicImpactYTD: Scalars['Decimal']['output'];
  minutesAllTime: Scalars['Int']['output'];
  minutesScheduledThisNextYear: Scalars['Int']['output'];
  minutesThisMonth: Scalars['Int']['output'];
  minutesThisWeek: Scalars['Int']['output'];
  minutesThisYear: Scalars['Int']['output'];
  minutesUnconfirmed: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type DeleteEventResourceInput = {
  request: DeleteEventResourceRequestInput;
};

export type DeleteEventResourcePayload = {
  __typename?: 'DeleteEventResourcePayload';
  deleteEventResourceResult?: Maybe<DeleteEventResourceResult>;
};

export type DeleteEventResourceRequestInput = {
  eventId: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
};

export type DeleteEventResourceResult = {
  __typename?: 'DeleteEventResourceResult';
  wasDeleted: Scalars['Boolean']['output'];
};

export type DeleteRoomResourceInput = {
  request: DeleteRoomResourceRequestInput;
};

export type DeleteRoomResourcePayload = {
  __typename?: 'DeleteRoomResourcePayload';
  deleteRoomResourceResult?: Maybe<DeleteRoomResourceResult>;
};

export type DeleteRoomResourceRequestInput = {
  id: Scalars['UUID']['input'];
  roomId: Scalars['UUID']['input'];
};

export type DeleteRoomResourceResult = {
  __typename?: 'DeleteRoomResourceResult';
  wasDeleted: Scalars['Boolean']['output'];
};

export type EditRsvpRequestInput = {
  id: Scalars['UUID']['input'];
  isMarkedNoShow: Scalars['Boolean']['input'];
  minutesConfirmed: Scalars['Int']['input'];
};

export type EventDto = {
  __typename?: 'EventDto';
  beginMeetingTime?: Maybe<Scalars['DateTime']['output']>;
  bubbleId: Scalars['UUID']['output'];
  description?: Maybe<Scalars['String']['output']>;
  endMeetingTime?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['UUID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isPublic: Scalars['Boolean']['output'];
  isSearchable: Scalars['Boolean']['output'];
  maxParticipants?: Maybe<Scalars['Int']['output']>;
  meetingTimes?: Maybe<Array<Maybe<MeetingTimeDto>>>;
  members?: Maybe<Array<Scalars['UUID']['output']>>;
  name?: Maybe<Scalars['String']['output']>;
  ownerFirstName?: Maybe<Scalars['String']['output']>;
  ownerId: Scalars['UUID']['output'];
  ownerLastName?: Maybe<Scalars['String']['output']>;
  requests?: Maybe<Array<Scalars['UUID']['output']>>;
  roomId?: Maybe<Scalars['UUID']['output']>;
  roomName?: Maybe<Scalars['String']['output']>;
  rules?: Maybe<Array<Maybe<EventRuleDto>>>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  timestamp: Scalars['DateTime']['output'];
};

export type EventMilestoneSummary = {
  __typename?: 'EventMilestoneSummary';
  description: Scalars['String']['output'];
  dueDate: Scalars['DateTime']['output'];
  eventId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export type EventRuleDto = {
  __typename?: 'EventRuleDto';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order: Scalars['Int']['output'];
};

export type EventRuleSummary = {
  __typename?: 'EventRuleSummary';
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
};

/** optional extra fields for EventSummary */
export type EventSummary = {
  __typename?: 'EventSummary';
  /** meeting times in the future */
  allMeetingTimes: Array<MeetingTimeSummary>;
  bubbleId: Scalars['ID']['output'];
  /** URL to the QR code image for checking into this event */
  checkInImageUri: Scalars['String']['output'];
  description: Scalars['String']['output'];
  /** Get Event Milestones */
  eventMilestones: Array<EventMilestoneSummary>;
  /** Get Event Tasks */
  eventTasks: Array<EventTaskSummary>;
  /** the non-owner facilitators for this session */
  facilitators: Array<UserSummary>;
  id: Scalars['ID']['output'];
  isPublic: Scalars['Boolean']['output'];
  isSearchable: Scalars['Boolean']['output'];
  maxParticipants?: Maybe<Scalars['Int']['output']>;
  /** meeting times in the near future for this session */
  meetingTimes: Array<MeetingTimeSummary>;
  members: Array<UserSummary>;
  name: Scalars['String']['output'];
  nextMeetingTime?: Maybe<MeetingTimeSummary>;
  owner: UserSummary;
  ownerId: Scalars['UUID']['output'];
  requests: Array<UserSummary>;
  /** Resources for this session/event */
  resources: Array<ResourceSummary>;
  roomId?: Maybe<Scalars['ID']['output']>;
  /** RSVPS for a specific user in this session */
  rsvps: Array<RsvpSummary>;
  /** RSVPS for a specific user in this session */
  rules: Array<EventRuleSummary>;
  /** is the logged in user considered a facilitator of this session? */
  userIsFacilitator: Scalars['Boolean']['output'];
  /** is the logged in user considered a member of this session? */
  userIsMember: Scalars['Boolean']['output'];
  /** is the logged in user the owner of this session? */
  userIsOwner: Scalars['Boolean']['output'];
  /** is the logged in user awaiting their request to join? */
  userIsPending: Scalars['Boolean']['output'];
  /** is the logged in user vetted for this bubble? */
  userIsVetted: Scalars['Boolean']['output'];
};

/** optional extra fields for EventSummary */
export type EventSummaryRsvpsArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};

/** optional extra fields for EventSummary */
export type EventSummaryRulesArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type EventTaskSummary = {
  __typename?: 'EventTaskSummary';
  description: Scalars['String']['output'];
  dueDate: Scalars['DateTime']['output'];
  eventId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  userId: Scalars['UUID']['output'];
};

export type MeetingTimeDto = {
  __typename?: 'MeetingTimeDto';
  beginMeetingTime: Scalars['DateTime']['output'];
  capacity: Scalars['Int']['output'];
  endMeetingTime: Scalars['DateTime']['output'];
  eventId: Scalars['UUID']['output'];
  eventName?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  isFilled: Scalars['Boolean']['output'];
  organizerName?: Maybe<Scalars['String']['output']>;
  roomName?: Maybe<Scalars['String']['output']>;
  rsvpUserIds?: Maybe<Array<Scalars['UUID']['output']>>;
  seriesId?: Maybe<Scalars['UUID']['output']>;
};

/** extra optional fields for a RoomSummary. */
export type MeetingTimeSummary = {
  __typename?: 'MeetingTimeSummary';
  beginMeetingTime: Scalars['DateTime']['output'];
  capacity: Scalars['Int']['output'];
  endMeetingTime: Scalars['DateTime']['output'];
  eventId: Scalars['ID']['output'];
  eventName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isFilled: Scalars['Boolean']['output'];
  rsvps: Array<UserSummary>;
  seriesId?: Maybe<Scalars['ID']['output']>;
  seriesName?: Maybe<Scalars['String']['output']>;
  /** has the logged in user RSVPd for this meeting? */
  userHasRsvpd: Scalars['Boolean']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Delete an event resource */
  deleteEventResource: DeleteEventResourcePayload;
  /** Delete a room resource */
  deleteRoomResource: DeleteRoomResourcePayload;
  /** Add or edit event resources */
  saveEventResource: SaveEventResourcePayload;
  /** Add or edit room resources */
  saveRoomResource: SaveRoomResourcePayload;
  /** Update RSVP statuses */
  updateRsvp: UpdateRsvpPayload;
};

export type MutationDeleteEventResourceArgs = {
  input: DeleteEventResourceInput;
};

export type MutationDeleteRoomResourceArgs = {
  input: DeleteRoomResourceInput;
};

export type MutationSaveEventResourceArgs = {
  input: SaveEventResourceInput;
};

export type MutationSaveRoomResourceArgs = {
  input: SaveRoomResourceInput;
};

export type MutationUpdateRsvpArgs = {
  input: UpdateRsvpInput;
};

export type NotificationSummary = {
  __typename?: 'NotificationSummary';
  dateTime: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  eventName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isArchived: Scalars['Boolean']['output'];
  isRead: Scalars['Boolean']['output'];
  notificationType: Scalars['String']['output'];
  relativeUri: Scalars['URL']['output'];
  roomId?: Maybe<Scalars['UUID']['output']>;
  roomName?: Maybe<Scalars['String']['output']>;
};

export type OrganizationSummary = {
  __typename?: 'OrganizationSummary';
  address?: Maybe<Scalars['String']['output']>;
  bubbleId: Scalars['UUID']['output'];
  canCreateEvents: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isExternal: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

/** root graphql query */
export type Query = {
  __typename?: 'Query';
  /**
   * Gets the names of the active school districts
   *
   *
   * **Returns:**
   * A set of District Names as strings
   */
  activeSchoolDistricts: Array<SchoolDistrictSummary>;
  districtStats: CurrentDistrictStats;
  educatorStats: CurrentEducatorStats;
  /** finds the valid grade levels for use in UI validation */
  gradeLevels: Array<Scalars['String']['output']>;
  /** details about the logged in user. Errors out if no-one is logged in. */
  me: UserSummary;
  /** finds the valid meeting days for use in UI validation */
  meetingDays: Array<Scalars['String']['output']>;
  /** return rooms the authenticated user owns */
  myRooms: Array<RoomSummary>;
  /**
   * return school district information the authenticated user is
   * responsible for (marked as bubble admin)
   */
  mySchoolDistricts: Array<SchoolDistrictSummary>;
  /**
   * return school information the authenticated user is
   * responsible for (marked as bubble admin)
   */
  mySchools: Array<SchoolSummary>;
  /** List all organizations */
  organizations: Array<OrganizationSummary>;
  room?: Maybe<RoomSummary>;
  /** details about the given school */
  school?: Maybe<SchoolSummary>;
  /**
   * return school district information for the provided schoolDistrictId
   * only bubble admins will see results
   */
  schoolDistrict?: Maybe<SchoolDistrictSummary>;
  schoolStats: CurrentSchoolStats;
  /** details about the given session id */
  session?: Maybe<EventSummary>;
  /** finds the valid States for use in UI validation */
  states: Array<Scalars['String']['output']>;
  /** finds the valid class subjects for use in UI validation */
  subjects: Array<Scalars['String']['output']>;
  volunteerStats: CurrentVolunteerStats;
};

/** root graphql query */
export type QueryDistrictStatsArgs = {
  districtId: Scalars['ID']['input'];
};

/** root graphql query */
export type QueryRoomArgs = {
  roomId: Scalars['ID']['input'];
};

/** root graphql query */
export type QuerySchoolArgs = {
  schoolId: Scalars['ID']['input'];
};

/** root graphql query */
export type QuerySchoolDistrictArgs = {
  schoolDistrictId: Scalars['ID']['input'];
};

/** root graphql query */
export type QuerySchoolStatsArgs = {
  schoolId: Scalars['ID']['input'];
};

/** root graphql query */
export type QuerySessionArgs = {
  eventId: Scalars['ID']['input'];
};

export type ResourceSummary = {
  __typename?: 'ResourceSummary';
  description?: Maybe<Scalars['String']['output']>;
  eventId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  roomId?: Maybe<Scalars['ID']['output']>;
  url: Scalars['String']['output'];
};

export type RoomMilestoneSummary = {
  __typename?: 'RoomMilestoneSummary';
  description: Scalars['String']['output'];
  dueDate: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  roomId: Scalars['UUID']['output'];
};

/**
 * extra optional fields for a RoomSummary.
 *
 * EF and HotChocolate fought when I tried to put these on RoomSummary directly.
 */
export type RoomSummary = {
  __typename?: 'RoomSummary';
  bubbleId?: Maybe<Scalars['UUID']['output']>;
  /** URL to the QR code image for checking into this room */
  checkInImageUri: Scalars['String']['output'];
  description: Scalars['String']['output'];
  /** the non-owner facilitators for this room */
  facilitators: Array<UserSummary>;
  id: Scalars['ID']['output'];
  isPublic: Scalars['Boolean']['output'];
  isSearchable: Scalars['Boolean']['output'];
  members: Array<UserSummary>;
  name: Scalars['String']['output'];
  owner: UserSummary;
  requests: Array<UserSummary>;
  /** Resources for this room */
  resources: Array<ResourceSummary>;
  /** Get Room Milestones */
  roomMilestones: Array<RoomMilestoneSummary>;
  /** Get Room Tasks */
  roomTasks: Array<RoomTaskSummary>;
  /** Get Room Upcoming RSVPs */
  roomUpcomingRsvps: Array<RsvpSummary>;
  /** RSVPS for a specific user in this room */
  rsvps: Array<RsvpSummary>;
  series: Array<SeriesSummary>;
  sessions: Array<EventSummary>;
  /** is the logged in user considered a an admin of the bubble of this room? */
  userIsAdmin: Scalars['Boolean']['output'];
  /** is the logged in user considered a facilitator of this room? */
  userIsFacilitator: Scalars['Boolean']['output'];
  /** is the logged in user considered a member of this room? */
  userIsMember: Scalars['Boolean']['output'];
  /** is the logged in user the owner of this room? */
  userIsOwner: Scalars['Boolean']['output'];
  /** is the logged in user awaiting their request to join? */
  userIsPending: Scalars['Boolean']['output'];
  zipCode: Scalars['String']['output'];
};

/**
 * extra optional fields for a RoomSummary.
 *
 * EF and HotChocolate fought when I tried to put these on RoomSummary directly.
 */
export type RoomSummaryRoomUpcomingRsvpsArgs = {
  end: Scalars['DateTime']['input'];
  start: Scalars['DateTime']['input'];
};

/**
 * extra optional fields for a RoomSummary.
 *
 * EF and HotChocolate fought when I tried to put these on RoomSummary directly.
 */
export type RoomSummaryRsvpsArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * extra optional fields for a RoomSummary.
 *
 * EF and HotChocolate fought when I tried to put these on RoomSummary directly.
 */
export type RoomSummarySeriesArgs = {
  seriesId?: InputMaybe<Scalars['ID']['input']>;
};

export type RoomTaskSummary = {
  __typename?: 'RoomTaskSummary';
  description: Scalars['String']['output'];
  dueDate: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  roomId: Scalars['UUID']['output'];
  userId: Scalars['UUID']['output'];
};

export type RsvpSummary = {
  __typename?: 'RsvpSummary';
  beginMeetingTime: Scalars['DateTime']['output'];
  endMeetingTime: Scalars['DateTime']['output'];
  event: EventDto;
  eventId: Scalars['UUID']['output'];
  eventName: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  isMarkedNoShow: Scalars['Boolean']['output'];
  meetingTime: MeetingTimeDto;
  minutesConfirmed: Scalars['Int']['output'];
  roomName: Scalars['String']['output'];
  schoolName?: Maybe<Scalars['String']['output']>;
  user: UserDto;
  userId: Scalars['UUID']['output'];
};

export type SaveEventResourceInput = {
  request: SaveEventResourceRequestInput;
};

export type SaveEventResourcePayload = {
  __typename?: 'SaveEventResourcePayload';
  resourceSummary?: Maybe<ResourceSummary>;
};

export type SaveEventResourceRequestInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['UUID']['input'];
  id?: InputMaybe<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
  url: Scalars['URL']['input'];
};

export type SaveRoomResourceInput = {
  request: SaveRoomResourceRequestInput;
};

export type SaveRoomResourcePayload = {
  __typename?: 'SaveRoomResourcePayload';
  resourceSummary?: Maybe<ResourceSummary>;
};

export type SaveRoomResourceRequestInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
  roomId: Scalars['UUID']['input'];
  url: Scalars['URL']['input'];
};

/** extra optional fields for a SchoolDistrictSummary. */
export type SchoolDistrictSummary = {
  __typename?: 'SchoolDistrictSummary';
  avatarUri: Scalars['String']['output'];
  bubbleId: Scalars['ID']['output'];
  districtStats: CurrentDistrictStats;
  id: Scalars['ID']['output'];
  members: Array<UserSummary>;
  name: Scalars['String']['output'];
  schoolDistrictId: Scalars['ID']['output'];
  schools: Array<SchoolSummary>;
};

/**
 * extra optional fields for a SchoolSummary.
 * ///
 */
export type SchoolSummary = {
  __typename?: 'SchoolSummary';
  avatarUri: Scalars['String']['output'];
  bubbleId: Scalars['ID']['output'];
  city: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  memberCount: Scalars['Int']['output'];
  members: Array<UserSummary>;
  name: Scalars['String']['output'];
  requestCount: Scalars['Int']['output'];
  requests: Array<UserSummary>;
  rsvps: Array<RsvpSummary>;
  schoolStats: CurrentSchoolStats;
  zipCode: Scalars['String']['output'];
};

/** optional extra fields for SeriesSummary */
export type SeriesSummary = {
  __typename?: 'SeriesSummary';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** all meeting times for this series */
  meetingTimes: Array<MeetingTimeSummary>;
  name: Scalars['String']['output'];
  roomId: Scalars['ID']['output'];
};

export type UpdateRsvpInput = {
  requests: Array<EditRsvpRequestInput>;
};

export type UpdateRsvpPayload = {
  __typename?: 'UpdateRsvpPayload';
  rsvpSummary?: Maybe<Array<RsvpSummary>>;
};

export type UserDto = {
  __typename?: 'UserDto';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  isAdministrator: Scalars['Boolean']['output'];
  isDailyNotificationsOn: Scalars['Boolean']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isEducator: Scalars['Boolean']['output'];
  isWeeklyNotificationsOn: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['DateTime']['output'];
  zipCode?: Maybe<Scalars['String']['output']>;
};

/** optional extra fields for UserSummary */
export type UserSummary = {
  __typename?: 'UserSummary';
  avatarUrl?: Maybe<Scalars['URL']['output']>;
  backgroundChecks: Array<BackgroundCheckSummary>;
  /** Per-bubble permissions */
  bubbles: Array<BubbleSummary>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isAdmin: Scalars['Boolean']['output'];
  isEducator: Scalars['Boolean']['output'];
  isVetted: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  notifications: Array<NotificationSummary>;
  parentId: Scalars['UUID']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  schools: Array<SchoolSummary>;
  userStats: CurrentVolunteerStats;
};

export type EditRsvpsMutationVariables = Exact<{
  requests: Array<EditRsvpRequestInput> | EditRsvpRequestInput;
}>;

export type EditRsvpsMutation = {
  __typename?: 'Mutation';
  updateRsvp: {
    __typename?: 'UpdateRsvpPayload';
    rsvpSummary?: Array<{
      __typename?: 'RsvpSummary';
      id: any;
      minutesConfirmed: number;
      isMarkedNoShow: boolean;
    }> | null;
  };
};

export type DeleteEventResourceMutationVariables = Exact<{
  request: DeleteEventResourceRequestInput;
}>;

export type DeleteEventResourceMutation = {
  __typename?: 'Mutation';
  deleteEventResource: {
    __typename?: 'DeleteEventResourcePayload';
    deleteEventResourceResult?: { __typename?: 'DeleteEventResourceResult'; wasDeleted: boolean } | null;
  };
};

export type SaveEventResourceMutationVariables = Exact<{
  request: SaveEventResourceRequestInput;
}>;

export type SaveEventResourceMutation = {
  __typename?: 'Mutation';
  saveEventResource: {
    __typename?: 'SaveEventResourcePayload';
    resourceSummary?: { __typename?: 'ResourceSummary'; id: string } | null;
  };
};

export type DeleteRoomResourceMutationVariables = Exact<{
  request: DeleteRoomResourceRequestInput;
}>;

export type DeleteRoomResourceMutation = {
  __typename?: 'Mutation';
  deleteRoomResource: {
    __typename?: 'DeleteRoomResourcePayload';
    deleteRoomResourceResult?: { __typename?: 'DeleteRoomResourceResult'; wasDeleted: boolean } | null;
  };
};

export type SaveRoomResourceMutationVariables = Exact<{
  request: SaveRoomResourceRequestInput;
}>;

export type SaveRoomResourceMutation = {
  __typename?: 'Mutation';
  saveRoomResource: {
    __typename?: 'SaveRoomResourcePayload';
    resourceSummary?: { __typename?: 'ResourceSummary'; id: string } | null;
  };
};

export type AdminSelectQueryVariables = Exact<{ [key: string]: never }>;

export type AdminSelectQuery = {
  __typename?: 'Query';
  mySchoolDistricts: Array<{
    __typename?: 'SchoolDistrictSummary';
    id: string;
    schoolDistrictId: string;
    bubbleId: string;
    name: string;
    avatarUri: string;
  }>;
  mySchools: Array<{ __typename?: 'SchoolSummary'; id: string; bubbleId: string; name: string; avatarUri: string }>;
};

export type MyBackgroundChecksQueryVariables = Exact<{ [key: string]: never }>;

export type MyBackgroundChecksQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'UserSummary';
    id: string;
    backgroundChecks: Array<{
      __typename?: 'BackgroundCheckSummary';
      id: string;
      schoolDistrictId: string;
      schoolDistrictName: string;
      url?: string | null;
      isCompleted: boolean;
    }>;
  };
  activeSchoolDistricts: Array<{
    __typename?: 'SchoolDistrictSummary';
    schoolDistrictId: string;
    bubbleId: string;
    name: string;
  }>;
};

export type DistrictAdminDistrictStatsQueryVariables = Exact<{
  schoolDistrictId: Scalars['ID']['input'];
}>;

export type DistrictAdminDistrictStatsQuery = {
  __typename?: 'Query';
  schoolDistrict?: {
    __typename?: 'SchoolDistrictSummary';
    bubbleId: string;
    id: string;
    name: string;
    schoolDistrictId: string;
    districtStats: {
      __typename?: 'CurrentDistrictStats';
      ageGroups: Array<number>;
      backgroundCheckFailed: number;
      backgroundCheckInProgress: number;
      backgroundCheckComplete: number;
      economicImpactMonth: number;
      economicImpactWeek: number;
      economicImpactYTD: number;
      economicImpactAllTime: number;
      genderDistribution: Array<number>;
      minutesThisWeek: number;
      minutesThisMonth: number;
      minutesThisYear: number;
      minutesAllTime: number;
      minutesScheduledThisNextYear: number;
      minutesUnconfirmed: number;
      name: string;
      schoolCount: number;
      schoolsWithMembersCount: number;
      totalApprovedVolunteers: number;
      totalPendingVolunteers: number;
      totalVolunteersMonth: number;
      totalVolunteersWeek: number;
      totalVolunteersYear: number;
      participatingEducators: number;
      totalEducators: number;
    };
  } | null;
};

export type DistrictAdminMembersQueryVariables = Exact<{
  schoolDistrictId: Scalars['ID']['input'];
}>;

export type DistrictAdminMembersQuery = {
  __typename?: 'Query';
  schoolDistrict?: {
    __typename?: 'SchoolDistrictSummary';
    bubbleId: string;
    id: string;
    name: string;
    schoolDistrictId: string;
    members: Array<{
      __typename?: 'UserSummary';
      email?: string | null;
      id: string;
      isAdmin: boolean;
      isEducator: boolean;
      isVetted: boolean;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber?: string | null;
      schools: Array<{ __typename?: 'SchoolSummary'; bubbleId: string; id: string; name: string }>;
    }>;
  } | null;
};

export type DistrictAdminSchoolStatsQueryVariables = Exact<{
  schoolId: Scalars['ID']['input'];
}>;

export type DistrictAdminSchoolStatsQuery = {
  __typename?: 'Query';
  school?: {
    __typename?: 'SchoolSummary';
    bubbleId: string;
    id: string;
    name: string;
    schoolStats: {
      __typename?: 'CurrentSchoolStats';
      name: string;
      totalApprovedVolunteers: number;
      totalPendingVolunteers: number;
      totalEducators: number;
      participatingEducators: number;
      totalVolunteersWeek: number;
      totalVolunteersMonth: number;
      totalVolunteersYear: number;
      backgroundCheckFailed: number;
      backgroundCheckInProgress: number;
      backgroundCheckComplete: number;
      economicImpactMonth: number;
      economicImpactWeek: number;
      economicImpactYTD: number;
      economicImpactAllTime: number;
      ageGroups: Array<number>;
      genderDistribution: Array<number>;
      minutesThisWeek: number;
      minutesThisMonth: number;
      minutesThisYear: number;
      minutesAllTime: number;
      minutesScheduledThisNextYear: number;
      minutesUnconfirmed: number;
    };
  } | null;
};

export type DistrictAdminSchoolsQueryVariables = Exact<{
  schoolDistrictId: Scalars['ID']['input'];
}>;

export type DistrictAdminSchoolsQuery = {
  __typename?: 'Query';
  schoolDistrict?: {
    __typename?: 'SchoolDistrictSummary';
    bubbleId: string;
    id: string;
    name: string;
    schoolDistrictId: string;
    schools: Array<{
      __typename?: 'SchoolSummary';
      id: string;
      name: string;
      bubbleId: string;
      memberCount: number;
      requestCount: number;
      city: string;
      zipCode: string;
    }>;
  } | null;
};

export type DistrictStatsQueryVariables = Exact<{
  districtId: Scalars['ID']['input'];
}>;

export type DistrictStatsQuery = {
  __typename?: 'Query';
  districtStats: {
    __typename?: 'CurrentDistrictStats';
    ageGroups: Array<number>;
    backgroundCheckFailed: number;
    backgroundCheckInProgress: number;
    backgroundCheckComplete: number;
    economicImpactMonth: number;
    economicImpactWeek: number;
    economicImpactYTD: number;
    economicImpactAllTime: number;
    genderDistribution: Array<number>;
    minutesThisWeek: number;
    minutesThisMonth: number;
    minutesThisYear: number;
    minutesAllTime: number;
    minutesScheduledThisNextYear: number;
    minutesUnconfirmed: number;
    name: string;
    schoolCount: number;
    schoolsWithMembersCount: number;
    totalApprovedVolunteers: number;
    totalPendingVolunteers: number;
    totalVolunteersMonth: number;
    totalVolunteersWeek: number;
    totalVolunteersYear: number;
    participatingEducators: number;
    totalEducators: number;
  };
};

export type EventQrQueryVariables = Exact<{
  eventId: Scalars['ID']['input'];
}>;

export type EventQrQuery = {
  __typename?: 'Query';
  session?: { __typename?: 'EventSummary'; id: string; name: string; checkInImageUri: string } | null;
};

export type EventRoomSummaryQueryVariables = Exact<{
  roomId: Scalars['ID']['input'];
}>;

export type EventRoomSummaryQuery = {
  __typename?: 'Query';
  room?: {
    __typename?: 'RoomSummary';
    id: string;
    name: string;
    series: Array<{ __typename?: 'SeriesSummary'; id: string; name: string; description: string }>;
  } | null;
};

export type GetSessionRulesQueryVariables = Exact<{
  eventId: Scalars['ID']['input'];
}>;

export type GetSessionRulesQuery = {
  __typename?: 'Query';
  session?: {
    __typename?: 'EventSummary';
    rules: Array<{ __typename?: 'EventRuleSummary'; description: string; name: string; order: number }>;
  } | null;
};

export type MyBubblesQueryVariables = Exact<{ [key: string]: never }>;

export type MyBubblesQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'UserSummary';
    id: string;
    bubbles: Array<{ __typename?: 'BubbleSummary'; id: string; name: string }>;
  };
};

export type MyScheduleOwnerQueryVariables = Exact<{ [key: string]: never }>;

export type MyScheduleOwnerQuery = {
  __typename?: 'Query';
  myRooms: Array<{
    __typename?: 'RoomSummary';
    id: string;
    name: string;
    description: string;
    sessions: Array<{
      __typename?: 'EventSummary';
      id: string;
      name: string;
      meetingTimes: Array<{
        __typename?: 'MeetingTimeSummary';
        id: string;
        isFilled: boolean;
        beginMeetingTime: string;
        endMeetingTime: string;
        rsvps: Array<{
          __typename?: 'UserSummary';
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
        }>;
      }>;
    }>;
  }>;
};

export type MySchoolDistrictsQueryVariables = Exact<{ [key: string]: never }>;

export type MySchoolDistrictsQuery = {
  __typename?: 'Query';
  mySchoolDistricts: Array<{
    __typename?: 'SchoolDistrictSummary';
    id: string;
    schoolDistrictId: string;
    bubbleId: string;
    name: string;
  }>;
};

export type MySchoolDistrictsRequestsQueryVariables = Exact<{ [key: string]: never }>;

export type MySchoolDistrictsRequestsQuery = {
  __typename?: 'Query';
  mySchoolDistricts: Array<{
    __typename?: 'SchoolDistrictSummary';
    id: string;
    schoolDistrictId: string;
    bubbleId: string;
    name: string;
    schools: Array<{
      __typename?: 'SchoolSummary';
      id: string;
      bubbleId: string;
      name: string;
      requests: Array<{
        __typename?: 'UserSummary';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
      }>;
    }>;
  }>;
};

export type MySchoolDistrictsSchoolsQueryVariables = Exact<{ [key: string]: never }>;

export type MySchoolDistrictsSchoolsQuery = {
  __typename?: 'Query';
  mySchoolDistricts: Array<{
    __typename?: 'SchoolDistrictSummary';
    schoolDistrictId: string;
    schools: Array<{
      __typename?: 'SchoolSummary';
      id: string;
      name: string;
      memberCount: number;
      requestCount: number;
    }>;
  }>;
};

export type NavigationBarQueryVariables = Exact<{ [key: string]: never }>;

export type NavigationBarQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'UserSummary';
    avatarUrl?: any | null;
    firstName?: string | null;
    id: string;
    isAdmin: boolean;
    isEducator: boolean;
    lastName?: string | null;
  };
};

export type GetOrganizationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetOrganizationsQuery = {
  __typename?: 'Query';
  organizations: Array<{
    __typename?: 'OrganizationSummary';
    id: string;
    name: string;
    address?: string | null;
    description?: string | null;
    isExternal: boolean;
    canCreateEvents: boolean;
  }>;
};

export type RoomDescriptionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RoomDescriptionQuery = {
  __typename?: 'Query';
  room?: { __typename?: 'RoomSummary'; id: string; description: string } | null;
};

export type RoomEditableQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RoomEditableQuery = {
  __typename?: 'Query';
  room?: {
    __typename?: 'RoomSummary';
    id: string;
    name: string;
    zipCode: string;
    description: string;
    isPublic: boolean;
    isSearchable: boolean;
    owner: { __typename?: 'UserSummary'; id: string };
  } | null;
};

export type RoomEventFieldsQueryVariables = Exact<{
  roomId: Scalars['ID']['input'];
}>;

export type RoomEventFieldsQuery = {
  __typename?: 'Query';
  room?: {
    __typename?: 'RoomSummary';
    id: string;
    name: string;
    series: Array<{ __typename?: 'SeriesSummary'; id: string; description: string; name: string }>;
  } | null;
};

export type RoomMemberQueryVariables = Exact<{
  roomId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
}>;

export type RoomMemberQuery = {
  __typename?: 'Query';
  room?: {
    __typename?: 'RoomSummary';
    id: string;
    rsvps: Array<{
      __typename?: 'RsvpSummary';
      id: any;
      eventName: string;
      beginMeetingTime: string;
      endMeetingTime: string;
      minutesConfirmed: number;
      isMarkedNoShow: boolean;
    }>;
  } | null;
};

export type RoomMemberManagementQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RoomMemberManagementQuery = {
  __typename?: 'Query';
  room?: {
    __typename?: 'RoomSummary';
    isPublic: boolean;
    facilitators: Array<{ __typename?: 'UserSummary'; id: string }>;
    members: Array<{
      __typename?: 'UserSummary';
      id: string;
      isAdmin: boolean;
      isVetted: boolean;
      isEducator: boolean;
      email?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    }>;
    owner: {
      __typename?: 'UserSummary';
      id: string;
      isAdmin: boolean;
      isVetted: boolean;
      isEducator: boolean;
      email?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    };
    requests: Array<{
      __typename?: 'UserSummary';
      id: string;
      isAdmin: boolean;
      isVetted: boolean;
      isEducator: boolean;
      email?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    }>;
  } | null;
};

export type RoomNameQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RoomNameQuery = { __typename?: 'Query'; room?: { __typename?: 'RoomSummary'; name: string } | null };

export type RoomQrQueryVariables = Exact<{
  roomId: Scalars['ID']['input'];
}>;

export type RoomQrQuery = {
  __typename?: 'Query';
  room?: { __typename?: 'RoomSummary'; id: string; name: string; checkInImageUri: string } | null;
};

export type RoomSessionsQueryVariables = Exact<{
  roomId: Scalars['ID']['input'];
}>;

export type RoomSessionsQuery = {
  __typename?: 'Query';
  room?: {
    __typename?: 'RoomSummary';
    id: string;
    userIsFacilitator: boolean;
    userIsAdmin: boolean;
    name: string;
    sessions: Array<{
      __typename?: 'EventSummary';
      id: string;
      bubbleId: string;
      roomId?: string | null;
      name: string;
      description: string;
      maxParticipants?: number | null;
      userIsFacilitator: boolean;
      userIsMember: boolean;
      userIsVetted: boolean;
      meetingTimes: Array<{
        __typename?: 'MeetingTimeSummary';
        capacity: number;
        id: string;
        isFilled: boolean;
        seriesId?: string | null;
        beginMeetingTime: string;
        endMeetingTime: string;
        userHasRsvpd: boolean;
        eventId: string;
      }>;
    }>;
    series: Array<{ __typename?: 'SeriesSummary'; id: string; name: string }>;
  } | null;
};

export type RoomSummaryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RoomSummaryQuery = {
  __typename?: 'Query';
  room?: {
    __typename?: 'RoomSummary';
    bubbleId?: any | null;
    userIsFacilitator: boolean;
    userIsOwner: boolean;
    userIsMember: boolean;
    userIsPending: boolean;
    userIsAdmin: boolean;
    name: string;
    isPublic: boolean;
    zipCode: string;
    isSearchable: boolean;
    id: string;
    description: string;
    roomMilestones: Array<{
      __typename?: 'RoomMilestoneSummary';
      id: any;
      description: string;
      dueDate: string;
      name: string;
      roomId: any;
    }>;
    roomTasks: Array<{
      __typename?: 'RoomTaskSummary';
      id: any;
      description: string;
      dueDate: string;
      name: string;
      roomId: any;
      userId: any;
    }>;
    series: Array<{ __typename?: 'SeriesSummary'; id: string; description: string; name: string }>;
    owner: { __typename?: 'UserSummary'; id: string };
    sessions: Array<{
      __typename?: 'EventSummary';
      id: string;
      name: string;
      nextMeetingTime?: {
        __typename?: 'MeetingTimeSummary';
        capacity: number;
        id: string;
        beginMeetingTime: string;
        endMeetingTime: string;
      } | null;
    }>;
    resources: Array<{
      __typename?: 'ResourceSummary';
      id: string;
      name: string;
      description?: string | null;
      url: string;
    }>;
  } | null;
};

export type SchoolAdminSchoolMembersQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SchoolAdminSchoolMembersQuery = {
  __typename?: 'Query';
  school?: {
    __typename?: 'SchoolSummary';
    name: string;
    bubbleId: string;
    members: Array<{
      __typename?: 'UserSummary';
      id: string;
      email?: string | null;
      phoneNumber?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      isAdmin: boolean;
      isVetted: boolean;
      isEducator: boolean;
    }>;
  } | null;
};

export type SchoolAdminSchoolRequestsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SchoolAdminSchoolRequestsQuery = {
  __typename?: 'Query';
  school?: {
    __typename?: 'SchoolSummary';
    name: string;
    bubbleId: string;
    requests: Array<{
      __typename?: 'UserSummary';
      id: string;
      email?: string | null;
      phoneNumber?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    }>;
  } | null;
};

export type SchoolAdminSchoolRsvpsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SchoolAdminSchoolRsvpsQuery = {
  __typename?: 'Query';
  school?: {
    __typename?: 'SchoolSummary';
    rsvps: Array<{
      __typename?: 'RsvpSummary';
      beginMeetingTime: string;
      endMeetingTime: string;
      id: any;
      eventName: string;
      user: {
        __typename?: 'UserDto';
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber?: string | null;
        email?: string | null;
      };
    }>;
  } | null;
};

export type SchoolMemberManagementQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SchoolMemberManagementQuery = {
  __typename?: 'Query';
  school?: {
    __typename?: 'SchoolSummary';
    name: string;
    bubbleId: string;
    members: Array<{
      __typename?: 'UserSummary';
      id: string;
      email?: string | null;
      phoneNumber?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      isAdmin: boolean;
      isVetted: boolean;
      isEducator: boolean;
    }>;
    requests: Array<{
      __typename?: 'UserSummary';
      id: string;
      email?: string | null;
      phoneNumber?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      isAdmin: boolean;
      isVetted: boolean;
      isEducator: boolean;
    }>;
  } | null;
};

export type SchoolStatsQueryVariables = Exact<{
  schoolId: Scalars['ID']['input'];
}>;

export type SchoolStatsQuery = {
  __typename?: 'Query';
  schoolStats: {
    __typename?: 'CurrentSchoolStats';
    name: string;
    totalApprovedVolunteers: number;
    totalPendingVolunteers: number;
    totalEducators: number;
    participatingEducators: number;
    totalVolunteersWeek: number;
    totalVolunteersMonth: number;
    totalVolunteersYear: number;
    backgroundCheckFailed: number;
    backgroundCheckInProgress: number;
    backgroundCheckComplete: number;
    economicImpactMonth: number;
    economicImpactWeek: number;
    economicImpactYTD: number;
    economicImpactAllTime: number;
    ageGroups: Array<number>;
    genderDistribution: Array<number>;
    minutesThisWeek: number;
    minutesThisMonth: number;
    minutesThisYear: number;
    minutesAllTime: number;
    minutesScheduledThisNextYear: number;
    minutesUnconfirmed: number;
  };
};

export type SearchOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type SearchOptionsQuery = {
  __typename?: 'Query';
  meetingDays: Array<string>;
  subjects: Array<string>;
  grades: Array<string>;
  activeSchoolDistricts: Array<{
    __typename?: 'SchoolDistrictSummary';
    id: string;
    name: string;
    schoolDistrictId: string;
  }>;
};

export type SeriesMeetingsQueryVariables = Exact<{
  roomId: Scalars['ID']['input'];
  seriesId: Scalars['ID']['input'];
}>;

export type SeriesMeetingsQuery = {
  __typename?: 'Query';
  room?: {
    __typename?: 'RoomSummary';
    id: string;
    series: Array<{
      __typename?: 'SeriesSummary';
      id: string;
      meetingTimes: Array<{
        __typename?: 'MeetingTimeSummary';
        id: string;
        isFilled: boolean;
        eventName: string;
        beginMeetingTime: string;
        endMeetingTime: string;
      }>;
    }>;
  } | null;
};

export type EventEditQueryVariables = Exact<{
  eventId: Scalars['ID']['input'];
}>;

export type EventEditQuery = {
  __typename?: 'Query';
  session?: {
    __typename?: 'EventSummary';
    id: string;
    name: string;
    description: string;
    isSearchable: boolean;
    isPublic: boolean;
    bubbleId: string;
    roomId?: string | null;
    allMeetingTimes: Array<{
      __typename?: 'MeetingTimeSummary';
      capacity: number;
      id: string;
      isFilled: boolean;
      beginMeetingTime: string;
      endMeetingTime: string;
      seriesId?: string | null;
      eventId: string;
    }>;
  } | null;
};

export type SessionMemberQueryVariables = Exact<{
  eventId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
}>;

export type SessionMemberQuery = {
  __typename?: 'Query';
  session?: {
    __typename?: 'EventSummary';
    id: string;
    rsvps: Array<{
      __typename?: 'RsvpSummary';
      id: any;
      beginMeetingTime: string;
      endMeetingTime: string;
      minutesConfirmed: number;
      isMarkedNoShow: boolean;
    }>;
  } | null;
};

export type SessionMemberManagementQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SessionMemberManagementQuery = {
  __typename?: 'Query';
  session?: {
    __typename?: 'EventSummary';
    isPublic: boolean;
    facilitators: Array<{ __typename?: 'UserSummary'; id: string }>;
    members: Array<{
      __typename?: 'UserSummary';
      id: string;
      isAdmin: boolean;
      isVetted: boolean;
      isEducator: boolean;
      email?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    }>;
    owner: {
      __typename?: 'UserSummary';
      id: string;
      isAdmin: boolean;
      isVetted: boolean;
      isEducator: boolean;
      email?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    };
    requests: Array<{
      __typename?: 'UserSummary';
      id: string;
      isAdmin: boolean;
      isVetted: boolean;
      isEducator: boolean;
      email?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    }>;
  } | null;
};

export type SessionNameQueryVariables = Exact<{
  eventId: Scalars['ID']['input'];
}>;

export type SessionNameQuery = {
  __typename?: 'Query';
  session?: { __typename?: 'EventSummary'; id: string; name: string } | null;
};

export type SessionSummaryQueryVariables = Exact<{
  eventId: Scalars['ID']['input'];
}>;

export type SessionSummaryQuery = {
  __typename?: 'Query';
  session?: {
    __typename?: 'EventSummary';
    id: string;
    bubbleId: string;
    ownerId: any;
    name: string;
    description: string;
    maxParticipants?: number | null;
    userIsFacilitator: boolean;
    userIsMember: boolean;
    userIsVetted: boolean;
    roomId?: string | null;
    isPublic: boolean;
    isSearchable: boolean;
    userIsPending: boolean;
    meetingTimes: Array<{
      __typename?: 'MeetingTimeSummary';
      capacity: number;
      id: string;
      isFilled: boolean;
      seriesId?: string | null;
      beginMeetingTime: string;
      endMeetingTime: string;
      userHasRsvpd: boolean;
      eventId: string;
    }>;
    eventMilestones: Array<{
      __typename?: 'EventMilestoneSummary';
      id: any;
      description: string;
      dueDate: string;
      name: string;
      eventId: any;
    }>;
    eventTasks: Array<{
      __typename?: 'EventTaskSummary';
      id: any;
      description: string;
      dueDate: string;
      name: string;
      eventId: any;
      userId: any;
    }>;
    resources: Array<{
      __typename?: 'ResourceSummary';
      id: string;
      name: string;
      description?: string | null;
      url: string;
    }>;
  } | null;
};

export type UpcomingRsvpsQueryVariables = Exact<{
  roomId: Scalars['ID']['input'];
  start: Scalars['DateTime']['input'];
  end: Scalars['DateTime']['input'];
}>;

export type UpcomingRsvpsQuery = {
  __typename?: 'Query';
  room?: {
    __typename?: 'RoomSummary';
    roomUpcomingRsvps: Array<{
      __typename?: 'RsvpSummary';
      meetingTime: { __typename?: 'MeetingTimeDto'; beginMeetingTime: string; endMeetingTime: string };
      event: { __typename?: 'EventDto'; id: any; name?: string | null };
      user: {
        __typename?: 'UserDto';
        phoneNumber?: string | null;
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      };
    }>;
  } | null;
};

export type GetUserNotificationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserNotificationsQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'UserSummary';
    id: string;
    notifications: Array<{
      __typename?: 'NotificationSummary';
      id: string;
      dateTime: string;
      description: string;
      isRead: boolean;
      isArchived: boolean;
      roomId?: any | null;
      roomName?: string | null;
      notificationType: string;
      eventName?: string | null;
      relativeUri: any;
    }>;
  };
};

export type VolunteerStatsQueryVariables = Exact<{ [key: string]: never }>;

export type VolunteerStatsQuery = {
  __typename?: 'Query';
  volunteerStats: {
    __typename?: 'CurrentVolunteerStats';
    minutesThisMonth: number;
    minutesScheduledThisNextYear: number;
    minutesThisYear: number;
    minutesAllTime: number;
    minutesUnconfirmed: number;
  };
};

export const EditRsvpsDocument = `
    mutation EditRsvps($requests: [EditRsvpRequestInput!]!) {
  updateRsvp(input: {requests: $requests}) {
    rsvpSummary {
      id
      minutesConfirmed
      isMarkedNoShow
    }
  }
}
    `;

export const useEditRsvpsMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<EditRsvpsMutation, TError, EditRsvpsMutationVariables, TContext>
) => {
  return useMutation<EditRsvpsMutation, TError, EditRsvpsMutationVariables, TContext>({
    mutationFn: useReactQueryFetch<EditRsvpsMutation, EditRsvpsMutationVariables>(EditRsvpsDocument),
    mutationKey: ['EditRsvps'],
    ...options,
  });
};

useEditRsvpsMutation.getKey = () => ['EditRsvps'];

export const DeleteEventResourceDocument = `
    mutation DeleteEventResource($request: DeleteEventResourceRequestInput!) {
  deleteEventResource(input: {request: $request}) {
    deleteEventResourceResult {
      wasDeleted
    }
  }
}
    `;

export const useDeleteEventResourceMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<DeleteEventResourceMutation, TError, DeleteEventResourceMutationVariables, TContext>
) => {
  return useMutation<DeleteEventResourceMutation, TError, DeleteEventResourceMutationVariables, TContext>({
    mutationFn: useReactQueryFetch<DeleteEventResourceMutation, DeleteEventResourceMutationVariables>(
      DeleteEventResourceDocument
    ),
    mutationKey: ['DeleteEventResource'],
    ...options,
  });
};

useDeleteEventResourceMutation.getKey = () => ['DeleteEventResource'];

export const SaveEventResourceDocument = `
    mutation SaveEventResource($request: SaveEventResourceRequestInput!) {
  saveEventResource(input: {request: $request}) {
    resourceSummary {
      id
    }
  }
}
    `;

export const useSaveEventResourceMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<SaveEventResourceMutation, TError, SaveEventResourceMutationVariables, TContext>
) => {
  return useMutation<SaveEventResourceMutation, TError, SaveEventResourceMutationVariables, TContext>({
    mutationFn: useReactQueryFetch<SaveEventResourceMutation, SaveEventResourceMutationVariables>(
      SaveEventResourceDocument
    ),
    mutationKey: ['SaveEventResource'],
    ...options,
  });
};

useSaveEventResourceMutation.getKey = () => ['SaveEventResource'];

export const DeleteRoomResourceDocument = `
    mutation DeleteRoomResource($request: DeleteRoomResourceRequestInput!) {
  deleteRoomResource(input: {request: $request}) {
    deleteRoomResourceResult {
      wasDeleted
    }
  }
}
    `;

export const useDeleteRoomResourceMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<DeleteRoomResourceMutation, TError, DeleteRoomResourceMutationVariables, TContext>
) => {
  return useMutation<DeleteRoomResourceMutation, TError, DeleteRoomResourceMutationVariables, TContext>({
    mutationFn: useReactQueryFetch<DeleteRoomResourceMutation, DeleteRoomResourceMutationVariables>(
      DeleteRoomResourceDocument
    ),
    mutationKey: ['DeleteRoomResource'],
    ...options,
  });
};

useDeleteRoomResourceMutation.getKey = () => ['DeleteRoomResource'];

export const SaveRoomResourceDocument = `
    mutation SaveRoomResource($request: SaveRoomResourceRequestInput!) {
  saveRoomResource(input: {request: $request}) {
    resourceSummary {
      id
    }
  }
}
    `;

export const useSaveRoomResourceMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<SaveRoomResourceMutation, TError, SaveRoomResourceMutationVariables, TContext>
) => {
  return useMutation<SaveRoomResourceMutation, TError, SaveRoomResourceMutationVariables, TContext>({
    mutationFn: useReactQueryFetch<SaveRoomResourceMutation, SaveRoomResourceMutationVariables>(
      SaveRoomResourceDocument
    ),
    mutationKey: ['SaveRoomResource'],
    ...options,
  });
};

useSaveRoomResourceMutation.getKey = () => ['SaveRoomResource'];

export const AdminSelectDocument = `
    query AdminSelect {
  mySchoolDistricts {
    id
    schoolDistrictId
    bubbleId
    name
    avatarUri
  }
  mySchools {
    id
    bubbleId
    name
    avatarUri
  }
}
    `;

export const useAdminSelectQuery = <TData = AdminSelectQuery, TError = unknown>(
  variables?: AdminSelectQueryVariables,
  options?: Omit<UseQueryOptions<AdminSelectQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<AdminSelectQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<AdminSelectQuery, TError, TData>({
    queryFn: useReactQueryFetch<AdminSelectQuery, AdminSelectQueryVariables>(AdminSelectDocument).bind(null, variables),
    queryKey: variables === undefined ? ['AdminSelect'] : ['AdminSelect', variables],
    ...options,
  });
};

useAdminSelectQuery.document = AdminSelectDocument;

useAdminSelectQuery.getKey = (variables?: AdminSelectQueryVariables) =>
  variables === undefined ? ['AdminSelect'] : ['AdminSelect', variables];

export const MyBackgroundChecksDocument = `
    query MyBackgroundChecks {
  me {
    id
    backgroundChecks {
      id
      schoolDistrictId
      schoolDistrictName
      url
      isCompleted
    }
  }
  activeSchoolDistricts {
    schoolDistrictId
    bubbleId
    name
  }
}
    `;

export const useMyBackgroundChecksQuery = <TData = MyBackgroundChecksQuery, TError = unknown>(
  variables?: MyBackgroundChecksQueryVariables,
  options?: Omit<UseQueryOptions<MyBackgroundChecksQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<MyBackgroundChecksQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<MyBackgroundChecksQuery, TError, TData>({
    queryFn: useReactQueryFetch<MyBackgroundChecksQuery, MyBackgroundChecksQueryVariables>(
      MyBackgroundChecksDocument
    ).bind(null, variables),
    queryKey: variables === undefined ? ['MyBackgroundChecks'] : ['MyBackgroundChecks', variables],
    ...options,
  });
};

useMyBackgroundChecksQuery.document = MyBackgroundChecksDocument;

useMyBackgroundChecksQuery.getKey = (variables?: MyBackgroundChecksQueryVariables) =>
  variables === undefined ? ['MyBackgroundChecks'] : ['MyBackgroundChecks', variables];

export const DistrictAdminDistrictStatsDocument = `
    query DistrictAdminDistrictStats($schoolDistrictId: ID!) {
  schoolDistrict(schoolDistrictId: $schoolDistrictId) {
    bubbleId
    districtStats {
      ageGroups
      backgroundCheckFailed
      backgroundCheckInProgress
      backgroundCheckComplete
      economicImpactMonth
      economicImpactWeek
      economicImpactYTD
      economicImpactAllTime
      genderDistribution
      minutesThisWeek
      minutesThisMonth
      minutesThisYear
      minutesAllTime
      minutesScheduledThisNextYear
      minutesUnconfirmed
      name
      schoolCount
      schoolsWithMembersCount
      totalApprovedVolunteers
      totalPendingVolunteers
      totalVolunteersMonth
      totalVolunteersWeek
      totalVolunteersYear
      participatingEducators
      totalEducators
    }
    id
    name
    schoolDistrictId
  }
}
    `;

export const useDistrictAdminDistrictStatsQuery = <TData = DistrictAdminDistrictStatsQuery, TError = unknown>(
  variables: DistrictAdminDistrictStatsQueryVariables,
  options?: Omit<UseQueryOptions<DistrictAdminDistrictStatsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<DistrictAdminDistrictStatsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<DistrictAdminDistrictStatsQuery, TError, TData>({
    queryFn: useReactQueryFetch<DistrictAdminDistrictStatsQuery, DistrictAdminDistrictStatsQueryVariables>(
      DistrictAdminDistrictStatsDocument
    ).bind(null, variables),
    queryKey: ['DistrictAdminDistrictStats', variables],
    ...options,
  });
};

useDistrictAdminDistrictStatsQuery.document = DistrictAdminDistrictStatsDocument;

useDistrictAdminDistrictStatsQuery.getKey = (variables: DistrictAdminDistrictStatsQueryVariables) => [
  'DistrictAdminDistrictStats',
  variables,
];

export const DistrictAdminMembersDocument = `
    query DistrictAdminMembers($schoolDistrictId: ID!) {
  schoolDistrict(schoolDistrictId: $schoolDistrictId) {
    bubbleId
    id
    members {
      email
      id
      isAdmin
      isEducator
      isVetted
      firstName
      lastName
      phoneNumber
      schools {
        bubbleId
        id
        name
      }
    }
    name
    schoolDistrictId
  }
}
    `;

export const useDistrictAdminMembersQuery = <TData = DistrictAdminMembersQuery, TError = unknown>(
  variables: DistrictAdminMembersQueryVariables,
  options?: Omit<UseQueryOptions<DistrictAdminMembersQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<DistrictAdminMembersQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<DistrictAdminMembersQuery, TError, TData>({
    queryFn: useReactQueryFetch<DistrictAdminMembersQuery, DistrictAdminMembersQueryVariables>(
      DistrictAdminMembersDocument
    ).bind(null, variables),
    queryKey: ['DistrictAdminMembers', variables],
    ...options,
  });
};

useDistrictAdminMembersQuery.document = DistrictAdminMembersDocument;

useDistrictAdminMembersQuery.getKey = (variables: DistrictAdminMembersQueryVariables) => [
  'DistrictAdminMembers',
  variables,
];

export const DistrictAdminSchoolStatsDocument = `
    query DistrictAdminSchoolStats($schoolId: ID!) {
  school(schoolId: $schoolId) {
    bubbleId
    id
    name
    schoolStats {
      name
      totalApprovedVolunteers
      totalPendingVolunteers
      totalEducators
      participatingEducators
      totalVolunteersWeek
      totalVolunteersMonth
      totalVolunteersYear
      backgroundCheckFailed
      backgroundCheckInProgress
      backgroundCheckComplete
      economicImpactMonth
      economicImpactWeek
      economicImpactYTD
      economicImpactAllTime
      ageGroups
      genderDistribution
      minutesThisWeek
      minutesThisMonth
      minutesThisYear
      minutesAllTime
      minutesScheduledThisNextYear
      minutesUnconfirmed
    }
  }
}
    `;

export const useDistrictAdminSchoolStatsQuery = <TData = DistrictAdminSchoolStatsQuery, TError = unknown>(
  variables: DistrictAdminSchoolStatsQueryVariables,
  options?: Omit<UseQueryOptions<DistrictAdminSchoolStatsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<DistrictAdminSchoolStatsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<DistrictAdminSchoolStatsQuery, TError, TData>({
    queryFn: useReactQueryFetch<DistrictAdminSchoolStatsQuery, DistrictAdminSchoolStatsQueryVariables>(
      DistrictAdminSchoolStatsDocument
    ).bind(null, variables),
    queryKey: ['DistrictAdminSchoolStats', variables],
    ...options,
  });
};

useDistrictAdminSchoolStatsQuery.document = DistrictAdminSchoolStatsDocument;

useDistrictAdminSchoolStatsQuery.getKey = (variables: DistrictAdminSchoolStatsQueryVariables) => [
  'DistrictAdminSchoolStats',
  variables,
];

export const DistrictAdminSchoolsDocument = `
    query DistrictAdminSchools($schoolDistrictId: ID!) {
  schoolDistrict(schoolDistrictId: $schoolDistrictId) {
    bubbleId
    id
    name
    schoolDistrictId
    schools {
      id
      name
      bubbleId
      memberCount
      requestCount
      city
      zipCode
    }
  }
}
    `;

export const useDistrictAdminSchoolsQuery = <TData = DistrictAdminSchoolsQuery, TError = unknown>(
  variables: DistrictAdminSchoolsQueryVariables,
  options?: Omit<UseQueryOptions<DistrictAdminSchoolsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<DistrictAdminSchoolsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<DistrictAdminSchoolsQuery, TError, TData>({
    queryFn: useReactQueryFetch<DistrictAdminSchoolsQuery, DistrictAdminSchoolsQueryVariables>(
      DistrictAdminSchoolsDocument
    ).bind(null, variables),
    queryKey: ['DistrictAdminSchools', variables],
    ...options,
  });
};

useDistrictAdminSchoolsQuery.document = DistrictAdminSchoolsDocument;

useDistrictAdminSchoolsQuery.getKey = (variables: DistrictAdminSchoolsQueryVariables) => [
  'DistrictAdminSchools',
  variables,
];

export const DistrictStatsDocument = `
    query DistrictStats($districtId: ID!) {
  districtStats(districtId: $districtId) {
    ageGroups
    backgroundCheckFailed
    backgroundCheckInProgress
    backgroundCheckComplete
    economicImpactMonth
    economicImpactWeek
    economicImpactYTD
    economicImpactAllTime
    genderDistribution
    minutesThisWeek
    minutesThisMonth
    minutesThisYear
    minutesAllTime
    minutesScheduledThisNextYear
    minutesUnconfirmed
    name
    schoolCount
    schoolsWithMembersCount
    totalApprovedVolunteers
    totalPendingVolunteers
    totalVolunteersMonth
    totalVolunteersWeek
    totalVolunteersYear
    minutesUnconfirmed
    participatingEducators
    totalEducators
  }
}
    `;

export const useDistrictStatsQuery = <TData = DistrictStatsQuery, TError = unknown>(
  variables: DistrictStatsQueryVariables,
  options?: Omit<UseQueryOptions<DistrictStatsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<DistrictStatsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<DistrictStatsQuery, TError, TData>({
    queryFn: useReactQueryFetch<DistrictStatsQuery, DistrictStatsQueryVariables>(DistrictStatsDocument).bind(
      null,
      variables
    ),
    queryKey: ['DistrictStats', variables],
    ...options,
  });
};

useDistrictStatsQuery.document = DistrictStatsDocument;

useDistrictStatsQuery.getKey = (variables: DistrictStatsQueryVariables) => ['DistrictStats', variables];

export const EventQrDocument = `
    query EventQR($eventId: ID!) {
  session(eventId: $eventId) {
    id
    name
    checkInImageUri
  }
}
    `;

export const useEventQrQuery = <TData = EventQrQuery, TError = unknown>(
  variables: EventQrQueryVariables,
  options?: Omit<UseQueryOptions<EventQrQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<EventQrQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<EventQrQuery, TError, TData>({
    queryFn: useReactQueryFetch<EventQrQuery, EventQrQueryVariables>(EventQrDocument).bind(null, variables),
    queryKey: ['EventQR', variables],
    ...options,
  });
};

useEventQrQuery.document = EventQrDocument;

useEventQrQuery.getKey = (variables: EventQrQueryVariables) => ['EventQR', variables];

export const EventRoomSummaryDocument = `
    query eventRoomSummary($roomId: ID!) {
  room(roomId: $roomId) {
    id
    name
    series {
      id
      name
      description
    }
  }
}
    `;

export const useEventRoomSummaryQuery = <TData = EventRoomSummaryQuery, TError = unknown>(
  variables: EventRoomSummaryQueryVariables,
  options?: Omit<UseQueryOptions<EventRoomSummaryQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<EventRoomSummaryQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<EventRoomSummaryQuery, TError, TData>({
    queryFn: useReactQueryFetch<EventRoomSummaryQuery, EventRoomSummaryQueryVariables>(EventRoomSummaryDocument).bind(
      null,
      variables
    ),
    queryKey: ['eventRoomSummary', variables],
    ...options,
  });
};

useEventRoomSummaryQuery.document = EventRoomSummaryDocument;

useEventRoomSummaryQuery.getKey = (variables: EventRoomSummaryQueryVariables) => ['eventRoomSummary', variables];

export const GetSessionRulesDocument = `
    query GetSessionRules($eventId: ID!) {
  session(eventId: $eventId) {
    rules {
      description
      name
      order
    }
  }
}
    `;

export const useGetSessionRulesQuery = <TData = GetSessionRulesQuery, TError = unknown>(
  variables: GetSessionRulesQueryVariables,
  options?: Omit<UseQueryOptions<GetSessionRulesQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<GetSessionRulesQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<GetSessionRulesQuery, TError, TData>({
    queryFn: useReactQueryFetch<GetSessionRulesQuery, GetSessionRulesQueryVariables>(GetSessionRulesDocument).bind(
      null,
      variables
    ),
    queryKey: ['GetSessionRules', variables],
    ...options,
  });
};

useGetSessionRulesQuery.document = GetSessionRulesDocument;

useGetSessionRulesQuery.getKey = (variables: GetSessionRulesQueryVariables) => ['GetSessionRules', variables];

export const MyBubblesDocument = `
    query MyBubbles {
  me {
    id
    bubbles {
      id
      name
    }
  }
}
    `;

export const useMyBubblesQuery = <TData = MyBubblesQuery, TError = unknown>(
  variables?: MyBubblesQueryVariables,
  options?: Omit<UseQueryOptions<MyBubblesQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<MyBubblesQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<MyBubblesQuery, TError, TData>({
    queryFn: useReactQueryFetch<MyBubblesQuery, MyBubblesQueryVariables>(MyBubblesDocument).bind(null, variables),
    queryKey: variables === undefined ? ['MyBubbles'] : ['MyBubbles', variables],
    ...options,
  });
};

useMyBubblesQuery.document = MyBubblesDocument;

useMyBubblesQuery.getKey = (variables?: MyBubblesQueryVariables) =>
  variables === undefined ? ['MyBubbles'] : ['MyBubbles', variables];

export const MyScheduleOwnerDocument = `
    query MyScheduleOwner {
  myRooms {
    id
    name
    description
    sessions {
      id
      name
      meetingTimes {
        id
        isFilled
        beginMeetingTime
        endMeetingTime
        rsvps {
          firstName
          lastName
          email
        }
      }
    }
  }
}
    `;

export const useMyScheduleOwnerQuery = <TData = MyScheduleOwnerQuery, TError = unknown>(
  variables?: MyScheduleOwnerQueryVariables,
  options?: Omit<UseQueryOptions<MyScheduleOwnerQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<MyScheduleOwnerQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<MyScheduleOwnerQuery, TError, TData>({
    queryFn: useReactQueryFetch<MyScheduleOwnerQuery, MyScheduleOwnerQueryVariables>(MyScheduleOwnerDocument).bind(
      null,
      variables
    ),
    queryKey: variables === undefined ? ['MyScheduleOwner'] : ['MyScheduleOwner', variables],
    ...options,
  });
};

useMyScheduleOwnerQuery.document = MyScheduleOwnerDocument;

useMyScheduleOwnerQuery.getKey = (variables?: MyScheduleOwnerQueryVariables) =>
  variables === undefined ? ['MyScheduleOwner'] : ['MyScheduleOwner', variables];

export const MySchoolDistrictsDocument = `
    query MySchoolDistricts {
  mySchoolDistricts {
    id
    schoolDistrictId
    bubbleId
    name
  }
}
    `;

export const useMySchoolDistrictsQuery = <TData = MySchoolDistrictsQuery, TError = unknown>(
  variables?: MySchoolDistrictsQueryVariables,
  options?: Omit<UseQueryOptions<MySchoolDistrictsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<MySchoolDistrictsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<MySchoolDistrictsQuery, TError, TData>({
    queryFn: useReactQueryFetch<MySchoolDistrictsQuery, MySchoolDistrictsQueryVariables>(
      MySchoolDistrictsDocument
    ).bind(null, variables),
    queryKey: variables === undefined ? ['MySchoolDistricts'] : ['MySchoolDistricts', variables],
    ...options,
  });
};

useMySchoolDistrictsQuery.document = MySchoolDistrictsDocument;

useMySchoolDistrictsQuery.getKey = (variables?: MySchoolDistrictsQueryVariables) =>
  variables === undefined ? ['MySchoolDistricts'] : ['MySchoolDistricts', variables];

export const MySchoolDistrictsRequestsDocument = `
    query MySchoolDistrictsRequests {
  mySchoolDistricts {
    id
    schoolDistrictId
    bubbleId
    name
    schools {
      id
      bubbleId
      name
      requests {
        id
        firstName
        lastName
        email
      }
    }
  }
}
    `;

export const useMySchoolDistrictsRequestsQuery = <TData = MySchoolDistrictsRequestsQuery, TError = unknown>(
  variables?: MySchoolDistrictsRequestsQueryVariables,
  options?: Omit<UseQueryOptions<MySchoolDistrictsRequestsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<MySchoolDistrictsRequestsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<MySchoolDistrictsRequestsQuery, TError, TData>({
    queryFn: useReactQueryFetch<MySchoolDistrictsRequestsQuery, MySchoolDistrictsRequestsQueryVariables>(
      MySchoolDistrictsRequestsDocument
    ).bind(null, variables),
    queryKey: variables === undefined ? ['MySchoolDistrictsRequests'] : ['MySchoolDistrictsRequests', variables],
    ...options,
  });
};

useMySchoolDistrictsRequestsQuery.document = MySchoolDistrictsRequestsDocument;

useMySchoolDistrictsRequestsQuery.getKey = (variables?: MySchoolDistrictsRequestsQueryVariables) =>
  variables === undefined ? ['MySchoolDistrictsRequests'] : ['MySchoolDistrictsRequests', variables];

export const MySchoolDistrictsSchoolsDocument = `
    query MySchoolDistrictsSchools {
  mySchoolDistricts {
    schoolDistrictId
    schools {
      id
      name
      memberCount
      requestCount
    }
  }
}
    `;

export const useMySchoolDistrictsSchoolsQuery = <TData = MySchoolDistrictsSchoolsQuery, TError = unknown>(
  variables?: MySchoolDistrictsSchoolsQueryVariables,
  options?: Omit<UseQueryOptions<MySchoolDistrictsSchoolsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<MySchoolDistrictsSchoolsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<MySchoolDistrictsSchoolsQuery, TError, TData>({
    queryFn: useReactQueryFetch<MySchoolDistrictsSchoolsQuery, MySchoolDistrictsSchoolsQueryVariables>(
      MySchoolDistrictsSchoolsDocument
    ).bind(null, variables),
    queryKey: variables === undefined ? ['MySchoolDistrictsSchools'] : ['MySchoolDistrictsSchools', variables],
    ...options,
  });
};

useMySchoolDistrictsSchoolsQuery.document = MySchoolDistrictsSchoolsDocument;

useMySchoolDistrictsSchoolsQuery.getKey = (variables?: MySchoolDistrictsSchoolsQueryVariables) =>
  variables === undefined ? ['MySchoolDistrictsSchools'] : ['MySchoolDistrictsSchools', variables];

export const NavigationBarDocument = `
    query NavigationBar {
  me {
    avatarUrl
    firstName
    id
    isAdmin
    isEducator
    lastName
  }
}
    `;

export const useNavigationBarQuery = <TData = NavigationBarQuery, TError = unknown>(
  variables?: NavigationBarQueryVariables,
  options?: Omit<UseQueryOptions<NavigationBarQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<NavigationBarQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<NavigationBarQuery, TError, TData>({
    queryFn: useReactQueryFetch<NavigationBarQuery, NavigationBarQueryVariables>(NavigationBarDocument).bind(
      null,
      variables
    ),
    queryKey: variables === undefined ? ['NavigationBar'] : ['NavigationBar', variables],
    ...options,
  });
};

useNavigationBarQuery.document = NavigationBarDocument;

useNavigationBarQuery.getKey = (variables?: NavigationBarQueryVariables) =>
  variables === undefined ? ['NavigationBar'] : ['NavigationBar', variables];

export const GetOrganizationsDocument = `
    query GetOrganizations {
  organizations {
    id
    name
    address
    description
    isExternal
    canCreateEvents
  }
}
    `;

export const useGetOrganizationsQuery = <TData = GetOrganizationsQuery, TError = unknown>(
  variables?: GetOrganizationsQueryVariables,
  options?: Omit<UseQueryOptions<GetOrganizationsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<GetOrganizationsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<GetOrganizationsQuery, TError, TData>({
    queryFn: useReactQueryFetch<GetOrganizationsQuery, GetOrganizationsQueryVariables>(GetOrganizationsDocument).bind(
      null,
      variables
    ),
    queryKey: variables === undefined ? ['GetOrganizations'] : ['GetOrganizations', variables],
    ...options,
  });
};

useGetOrganizationsQuery.document = GetOrganizationsDocument;

useGetOrganizationsQuery.getKey = (variables?: GetOrganizationsQueryVariables) =>
  variables === undefined ? ['GetOrganizations'] : ['GetOrganizations', variables];

export const RoomDescriptionDocument = `
    query RoomDescription($id: ID!) {
  room(roomId: $id) {
    id
    description
  }
}
    `;

export const useRoomDescriptionQuery = <TData = RoomDescriptionQuery, TError = unknown>(
  variables: RoomDescriptionQueryVariables,
  options?: Omit<UseQueryOptions<RoomDescriptionQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<RoomDescriptionQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<RoomDescriptionQuery, TError, TData>({
    queryFn: useReactQueryFetch<RoomDescriptionQuery, RoomDescriptionQueryVariables>(RoomDescriptionDocument).bind(
      null,
      variables
    ),
    queryKey: ['RoomDescription', variables],
    ...options,
  });
};

useRoomDescriptionQuery.document = RoomDescriptionDocument;

useRoomDescriptionQuery.getKey = (variables: RoomDescriptionQueryVariables) => ['RoomDescription', variables];

export const RoomEditableDocument = `
    query RoomEditable($id: ID!) {
  room(roomId: $id) {
    id
    owner {
      id
    }
    name
    zipCode
    description
    isPublic
    isSearchable
  }
}
    `;

export const useRoomEditableQuery = <TData = RoomEditableQuery, TError = unknown>(
  variables: RoomEditableQueryVariables,
  options?: Omit<UseQueryOptions<RoomEditableQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<RoomEditableQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<RoomEditableQuery, TError, TData>({
    queryFn: useReactQueryFetch<RoomEditableQuery, RoomEditableQueryVariables>(RoomEditableDocument).bind(
      null,
      variables
    ),
    queryKey: ['RoomEditable', variables],
    ...options,
  });
};

useRoomEditableQuery.document = RoomEditableDocument;

useRoomEditableQuery.getKey = (variables: RoomEditableQueryVariables) => ['RoomEditable', variables];

export const RoomEventFieldsDocument = `
    query RoomEventFields($roomId: ID!) {
  room(roomId: $roomId) {
    id
    name
    series {
      id
      description
      name
    }
  }
}
    `;

export const useRoomEventFieldsQuery = <TData = RoomEventFieldsQuery, TError = unknown>(
  variables: RoomEventFieldsQueryVariables,
  options?: Omit<UseQueryOptions<RoomEventFieldsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<RoomEventFieldsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<RoomEventFieldsQuery, TError, TData>({
    queryFn: useReactQueryFetch<RoomEventFieldsQuery, RoomEventFieldsQueryVariables>(RoomEventFieldsDocument).bind(
      null,
      variables
    ),
    queryKey: ['RoomEventFields', variables],
    ...options,
  });
};

useRoomEventFieldsQuery.document = RoomEventFieldsDocument;

useRoomEventFieldsQuery.getKey = (variables: RoomEventFieldsQueryVariables) => ['RoomEventFields', variables];

export const RoomMemberDocument = `
    query RoomMember($roomId: ID!, $userId: ID!) {
  room(roomId: $roomId) {
    id
    rsvps(userId: $userId) {
      id
      eventName
      beginMeetingTime
      endMeetingTime
      minutesConfirmed
      isMarkedNoShow
    }
  }
}
    `;

export const useRoomMemberQuery = <TData = RoomMemberQuery, TError = unknown>(
  variables: RoomMemberQueryVariables,
  options?: Omit<UseQueryOptions<RoomMemberQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<RoomMemberQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<RoomMemberQuery, TError, TData>({
    queryFn: useReactQueryFetch<RoomMemberQuery, RoomMemberQueryVariables>(RoomMemberDocument).bind(null, variables),
    queryKey: ['RoomMember', variables],
    ...options,
  });
};

useRoomMemberQuery.document = RoomMemberDocument;

useRoomMemberQuery.getKey = (variables: RoomMemberQueryVariables) => ['RoomMember', variables];

export const RoomMemberManagementDocument = `
    query RoomMemberManagement($id: ID!) {
  room(roomId: $id) {
    isPublic
    facilitators {
      id
    }
    members {
      id
      isAdmin
      isVetted
      isEducator
      email
      firstName
      lastName
    }
    owner {
      id
      isAdmin
      isVetted
      isEducator
      email
      firstName
      lastName
    }
    requests {
      id
      isAdmin
      isVetted
      isEducator
      email
      firstName
      lastName
    }
  }
}
    `;

export const useRoomMemberManagementQuery = <TData = RoomMemberManagementQuery, TError = unknown>(
  variables: RoomMemberManagementQueryVariables,
  options?: Omit<UseQueryOptions<RoomMemberManagementQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<RoomMemberManagementQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<RoomMemberManagementQuery, TError, TData>({
    queryFn: useReactQueryFetch<RoomMemberManagementQuery, RoomMemberManagementQueryVariables>(
      RoomMemberManagementDocument
    ).bind(null, variables),
    queryKey: ['RoomMemberManagement', variables],
    ...options,
  });
};

useRoomMemberManagementQuery.document = RoomMemberManagementDocument;

useRoomMemberManagementQuery.getKey = (variables: RoomMemberManagementQueryVariables) => [
  'RoomMemberManagement',
  variables,
];

export const RoomNameDocument = `
    query RoomName($id: ID!) {
  room(roomId: $id) {
    name
  }
}
    `;

export const useRoomNameQuery = <TData = RoomNameQuery, TError = unknown>(
  variables: RoomNameQueryVariables,
  options?: Omit<UseQueryOptions<RoomNameQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<RoomNameQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<RoomNameQuery, TError, TData>({
    queryFn: useReactQueryFetch<RoomNameQuery, RoomNameQueryVariables>(RoomNameDocument).bind(null, variables),
    queryKey: ['RoomName', variables],
    ...options,
  });
};

useRoomNameQuery.document = RoomNameDocument;

useRoomNameQuery.getKey = (variables: RoomNameQueryVariables) => ['RoomName', variables];

export const RoomQrDocument = `
    query RoomQR($roomId: ID!) {
  room(roomId: $roomId) {
    id
    name
    checkInImageUri
  }
}
    `;

export const useRoomQrQuery = <TData = RoomQrQuery, TError = unknown>(
  variables: RoomQrQueryVariables,
  options?: Omit<UseQueryOptions<RoomQrQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<RoomQrQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<RoomQrQuery, TError, TData>({
    queryFn: useReactQueryFetch<RoomQrQuery, RoomQrQueryVariables>(RoomQrDocument).bind(null, variables),
    queryKey: ['RoomQR', variables],
    ...options,
  });
};

useRoomQrQuery.document = RoomQrDocument;

useRoomQrQuery.getKey = (variables: RoomQrQueryVariables) => ['RoomQR', variables];

export const RoomSessionsDocument = `
    query RoomSessions($roomId: ID!) {
  room(roomId: $roomId) {
    id
    userIsFacilitator
    userIsAdmin
    name
    sessions {
      id
      bubbleId
      roomId
      name
      description
      maxParticipants
      userIsFacilitator
      userIsMember
      userIsVetted
      meetingTimes {
        capacity
        id
        isFilled
        seriesId
        beginMeetingTime
        endMeetingTime
        userHasRsvpd
        eventId
      }
    }
    series {
      id
      name
    }
  }
}
    `;

export const useRoomSessionsQuery = <TData = RoomSessionsQuery, TError = unknown>(
  variables: RoomSessionsQueryVariables,
  options?: Omit<UseQueryOptions<RoomSessionsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<RoomSessionsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<RoomSessionsQuery, TError, TData>({
    queryFn: useReactQueryFetch<RoomSessionsQuery, RoomSessionsQueryVariables>(RoomSessionsDocument).bind(
      null,
      variables
    ),
    queryKey: ['RoomSessions', variables],
    ...options,
  });
};

useRoomSessionsQuery.document = RoomSessionsDocument;

useRoomSessionsQuery.getKey = (variables: RoomSessionsQueryVariables) => ['RoomSessions', variables];

export const RoomSummaryDocument = `
    query RoomSummary($id: ID!) {
  room(roomId: $id) {
    bubbleId
    userIsFacilitator
    userIsOwner
    userIsMember
    userIsPending
    userIsAdmin
    name
    isPublic
    zipCode
    isSearchable
    id
    description
    roomMilestones {
      id
      description
      dueDate
      name
      roomId
    }
    roomTasks {
      id
      description
      dueDate
      name
      roomId
      userId
    }
    series {
      id
      description
      name
    }
    owner {
      id
    }
    sessions {
      id
      name
      nextMeetingTime {
        capacity
        id
        beginMeetingTime
        endMeetingTime
      }
    }
    resources {
      id
      name
      description
      url
    }
  }
}
    `;

export const useRoomSummaryQuery = <TData = RoomSummaryQuery, TError = unknown>(
  variables: RoomSummaryQueryVariables,
  options?: Omit<UseQueryOptions<RoomSummaryQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<RoomSummaryQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<RoomSummaryQuery, TError, TData>({
    queryFn: useReactQueryFetch<RoomSummaryQuery, RoomSummaryQueryVariables>(RoomSummaryDocument).bind(null, variables),
    queryKey: ['RoomSummary', variables],
    ...options,
  });
};

useRoomSummaryQuery.document = RoomSummaryDocument;

useRoomSummaryQuery.getKey = (variables: RoomSummaryQueryVariables) => ['RoomSummary', variables];

export const SchoolAdminSchoolMembersDocument = `
    query SchoolAdminSchoolMembers($id: ID!) {
  school(schoolId: $id) {
    name
    bubbleId
    members {
      id
      email
      phoneNumber
      firstName
      lastName
      isAdmin
      isVetted
      isEducator
    }
  }
}
    `;

export const useSchoolAdminSchoolMembersQuery = <TData = SchoolAdminSchoolMembersQuery, TError = unknown>(
  variables: SchoolAdminSchoolMembersQueryVariables,
  options?: Omit<UseQueryOptions<SchoolAdminSchoolMembersQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<SchoolAdminSchoolMembersQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<SchoolAdminSchoolMembersQuery, TError, TData>({
    queryFn: useReactQueryFetch<SchoolAdminSchoolMembersQuery, SchoolAdminSchoolMembersQueryVariables>(
      SchoolAdminSchoolMembersDocument
    ).bind(null, variables),
    queryKey: ['SchoolAdminSchoolMembers', variables],
    ...options,
  });
};

useSchoolAdminSchoolMembersQuery.document = SchoolAdminSchoolMembersDocument;

useSchoolAdminSchoolMembersQuery.getKey = (variables: SchoolAdminSchoolMembersQueryVariables) => [
  'SchoolAdminSchoolMembers',
  variables,
];

export const SchoolAdminSchoolRequestsDocument = `
    query SchoolAdminSchoolRequests($id: ID!) {
  school(schoolId: $id) {
    name
    bubbleId
    requests {
      id
      email
      phoneNumber
      firstName
      lastName
    }
  }
}
    `;

export const useSchoolAdminSchoolRequestsQuery = <TData = SchoolAdminSchoolRequestsQuery, TError = unknown>(
  variables: SchoolAdminSchoolRequestsQueryVariables,
  options?: Omit<UseQueryOptions<SchoolAdminSchoolRequestsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<SchoolAdminSchoolRequestsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<SchoolAdminSchoolRequestsQuery, TError, TData>({
    queryFn: useReactQueryFetch<SchoolAdminSchoolRequestsQuery, SchoolAdminSchoolRequestsQueryVariables>(
      SchoolAdminSchoolRequestsDocument
    ).bind(null, variables),
    queryKey: ['SchoolAdminSchoolRequests', variables],
    ...options,
  });
};

useSchoolAdminSchoolRequestsQuery.document = SchoolAdminSchoolRequestsDocument;

useSchoolAdminSchoolRequestsQuery.getKey = (variables: SchoolAdminSchoolRequestsQueryVariables) => [
  'SchoolAdminSchoolRequests',
  variables,
];

export const SchoolAdminSchoolRsvpsDocument = `
    query SchoolAdminSchoolRsvps($id: ID!) {
  school(schoolId: $id) {
    rsvps {
      beginMeetingTime
      endMeetingTime
      id
      eventName
      user {
        firstName
        lastName
        phoneNumber
        email
      }
    }
  }
}
    `;

export const useSchoolAdminSchoolRsvpsQuery = <TData = SchoolAdminSchoolRsvpsQuery, TError = unknown>(
  variables: SchoolAdminSchoolRsvpsQueryVariables,
  options?: Omit<UseQueryOptions<SchoolAdminSchoolRsvpsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<SchoolAdminSchoolRsvpsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<SchoolAdminSchoolRsvpsQuery, TError, TData>({
    queryFn: useReactQueryFetch<SchoolAdminSchoolRsvpsQuery, SchoolAdminSchoolRsvpsQueryVariables>(
      SchoolAdminSchoolRsvpsDocument
    ).bind(null, variables),
    queryKey: ['SchoolAdminSchoolRsvps', variables],
    ...options,
  });
};

useSchoolAdminSchoolRsvpsQuery.document = SchoolAdminSchoolRsvpsDocument;

useSchoolAdminSchoolRsvpsQuery.getKey = (variables: SchoolAdminSchoolRsvpsQueryVariables) => [
  'SchoolAdminSchoolRsvps',
  variables,
];

export const SchoolMemberManagementDocument = `
    query SchoolMemberManagement($id: ID!) {
  school(schoolId: $id) {
    name
    bubbleId
    members {
      id
      email
      phoneNumber
      firstName
      lastName
      isAdmin
      isVetted
      isEducator
    }
    requests {
      id
      email
      phoneNumber
      firstName
      lastName
      isAdmin
      isVetted
      isEducator
    }
  }
}
    `;

export const useSchoolMemberManagementQuery = <TData = SchoolMemberManagementQuery, TError = unknown>(
  variables: SchoolMemberManagementQueryVariables,
  options?: Omit<UseQueryOptions<SchoolMemberManagementQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<SchoolMemberManagementQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<SchoolMemberManagementQuery, TError, TData>({
    queryFn: useReactQueryFetch<SchoolMemberManagementQuery, SchoolMemberManagementQueryVariables>(
      SchoolMemberManagementDocument
    ).bind(null, variables),
    queryKey: ['SchoolMemberManagement', variables],
    ...options,
  });
};

useSchoolMemberManagementQuery.document = SchoolMemberManagementDocument;

useSchoolMemberManagementQuery.getKey = (variables: SchoolMemberManagementQueryVariables) => [
  'SchoolMemberManagement',
  variables,
];

export const SchoolStatsDocument = `
    query SchoolStats($schoolId: ID!) {
  schoolStats(schoolId: $schoolId) {
    name
    totalApprovedVolunteers
    totalPendingVolunteers
    totalEducators
    participatingEducators
    totalVolunteersWeek
    totalVolunteersMonth
    totalVolunteersYear
    backgroundCheckFailed
    backgroundCheckInProgress
    backgroundCheckComplete
    economicImpactMonth
    economicImpactWeek
    economicImpactYTD
    economicImpactAllTime
    ageGroups
    genderDistribution
    minutesThisWeek
    minutesThisMonth
    minutesThisYear
    minutesAllTime
    minutesScheduledThisNextYear
    minutesUnconfirmed
  }
}
    `;

export const useSchoolStatsQuery = <TData = SchoolStatsQuery, TError = unknown>(
  variables: SchoolStatsQueryVariables,
  options?: Omit<UseQueryOptions<SchoolStatsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<SchoolStatsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<SchoolStatsQuery, TError, TData>({
    queryFn: useReactQueryFetch<SchoolStatsQuery, SchoolStatsQueryVariables>(SchoolStatsDocument).bind(null, variables),
    queryKey: ['SchoolStats', variables],
    ...options,
  });
};

useSchoolStatsQuery.document = SchoolStatsDocument;

useSchoolStatsQuery.getKey = (variables: SchoolStatsQueryVariables) => ['SchoolStats', variables];

export const SearchOptionsDocument = `
    query SearchOptions {
  grades: gradeLevels
  meetingDays
  subjects
  activeSchoolDistricts {
    id
    name
    schoolDistrictId
  }
}
    `;

export const useSearchOptionsQuery = <TData = SearchOptionsQuery, TError = unknown>(
  variables?: SearchOptionsQueryVariables,
  options?: Omit<UseQueryOptions<SearchOptionsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<SearchOptionsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<SearchOptionsQuery, TError, TData>({
    queryFn: useReactQueryFetch<SearchOptionsQuery, SearchOptionsQueryVariables>(SearchOptionsDocument).bind(
      null,
      variables
    ),
    queryKey: variables === undefined ? ['SearchOptions'] : ['SearchOptions', variables],
    ...options,
  });
};

useSearchOptionsQuery.document = SearchOptionsDocument;

useSearchOptionsQuery.getKey = (variables?: SearchOptionsQueryVariables) =>
  variables === undefined ? ['SearchOptions'] : ['SearchOptions', variables];

export const SeriesMeetingsDocument = `
    query SeriesMeetings($roomId: ID!, $seriesId: ID!) {
  room(roomId: $roomId) {
    id
    series(seriesId: $seriesId) {
      id
      meetingTimes {
        id
        isFilled
        eventName
        beginMeetingTime
        endMeetingTime
      }
    }
  }
}
    `;

export const useSeriesMeetingsQuery = <TData = SeriesMeetingsQuery, TError = unknown>(
  variables: SeriesMeetingsQueryVariables,
  options?: Omit<UseQueryOptions<SeriesMeetingsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<SeriesMeetingsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<SeriesMeetingsQuery, TError, TData>({
    queryFn: useReactQueryFetch<SeriesMeetingsQuery, SeriesMeetingsQueryVariables>(SeriesMeetingsDocument).bind(
      null,
      variables
    ),
    queryKey: ['SeriesMeetings', variables],
    ...options,
  });
};

useSeriesMeetingsQuery.document = SeriesMeetingsDocument;

useSeriesMeetingsQuery.getKey = (variables: SeriesMeetingsQueryVariables) => ['SeriesMeetings', variables];

export const EventEditDocument = `
    query eventEdit($eventId: ID!) {
  session(eventId: $eventId) {
    id
    name
    description
    isSearchable
    isPublic
    bubbleId
    roomId
    allMeetingTimes {
      capacity
      id
      isFilled
      beginMeetingTime
      endMeetingTime
      seriesId
      eventId
    }
  }
}
    `;

export const useEventEditQuery = <TData = EventEditQuery, TError = unknown>(
  variables: EventEditQueryVariables,
  options?: Omit<UseQueryOptions<EventEditQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<EventEditQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<EventEditQuery, TError, TData>({
    queryFn: useReactQueryFetch<EventEditQuery, EventEditQueryVariables>(EventEditDocument).bind(null, variables),
    queryKey: ['eventEdit', variables],
    ...options,
  });
};

useEventEditQuery.document = EventEditDocument;

useEventEditQuery.getKey = (variables: EventEditQueryVariables) => ['eventEdit', variables];

export const SessionMemberDocument = `
    query SessionMember($eventId: ID!, $userId: ID!) {
  session(eventId: $eventId) {
    id
    rsvps(userId: $userId) {
      id
      beginMeetingTime
      endMeetingTime
      minutesConfirmed
      isMarkedNoShow
    }
  }
}
    `;

export const useSessionMemberQuery = <TData = SessionMemberQuery, TError = unknown>(
  variables: SessionMemberQueryVariables,
  options?: Omit<UseQueryOptions<SessionMemberQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<SessionMemberQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<SessionMemberQuery, TError, TData>({
    queryFn: useReactQueryFetch<SessionMemberQuery, SessionMemberQueryVariables>(SessionMemberDocument).bind(
      null,
      variables
    ),
    queryKey: ['SessionMember', variables],
    ...options,
  });
};

useSessionMemberQuery.document = SessionMemberDocument;

useSessionMemberQuery.getKey = (variables: SessionMemberQueryVariables) => ['SessionMember', variables];

export const SessionMemberManagementDocument = `
    query SessionMemberManagement($id: ID!) {
  session(eventId: $id) {
    isPublic
    facilitators {
      id
    }
    members {
      id
      isAdmin
      isVetted
      isEducator
      isVetted
      email
      firstName
      lastName
    }
    owner {
      id
      isAdmin
      isVetted
      isEducator
      email
      firstName
      lastName
    }
    requests {
      id
      isAdmin
      isVetted
      isEducator
      email
      firstName
      lastName
    }
  }
}
    `;

export const useSessionMemberManagementQuery = <TData = SessionMemberManagementQuery, TError = unknown>(
  variables: SessionMemberManagementQueryVariables,
  options?: Omit<UseQueryOptions<SessionMemberManagementQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<SessionMemberManagementQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<SessionMemberManagementQuery, TError, TData>({
    queryFn: useReactQueryFetch<SessionMemberManagementQuery, SessionMemberManagementQueryVariables>(
      SessionMemberManagementDocument
    ).bind(null, variables),
    queryKey: ['SessionMemberManagement', variables],
    ...options,
  });
};

useSessionMemberManagementQuery.document = SessionMemberManagementDocument;

useSessionMemberManagementQuery.getKey = (variables: SessionMemberManagementQueryVariables) => [
  'SessionMemberManagement',
  variables,
];

export const SessionNameDocument = `
    query SessionName($eventId: ID!) {
  session(eventId: $eventId) {
    id
    name
  }
}
    `;

export const useSessionNameQuery = <TData = SessionNameQuery, TError = unknown>(
  variables: SessionNameQueryVariables,
  options?: Omit<UseQueryOptions<SessionNameQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<SessionNameQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<SessionNameQuery, TError, TData>({
    queryFn: useReactQueryFetch<SessionNameQuery, SessionNameQueryVariables>(SessionNameDocument).bind(null, variables),
    queryKey: ['SessionName', variables],
    ...options,
  });
};

useSessionNameQuery.document = SessionNameDocument;

useSessionNameQuery.getKey = (variables: SessionNameQueryVariables) => ['SessionName', variables];

export const SessionSummaryDocument = `
    query SessionSummary($eventId: ID!) {
  session(eventId: $eventId) {
    id
    bubbleId
    ownerId
    name
    description
    maxParticipants
    userIsFacilitator
    userIsMember
    userIsVetted
    roomId
    meetingTimes {
      capacity
      id
      isFilled
      seriesId
      beginMeetingTime
      endMeetingTime
      userHasRsvpd
      eventId
    }
    eventMilestones {
      id
      description
      dueDate
      name
      eventId
    }
    eventTasks {
      id
      description
      dueDate
      name
      eventId
      userId
    }
    resources {
      id
      name
      description
      url
    }
    isPublic
    isSearchable
    userIsPending
  }
}
    `;

export const useSessionSummaryQuery = <TData = SessionSummaryQuery, TError = unknown>(
  variables: SessionSummaryQueryVariables,
  options?: Omit<UseQueryOptions<SessionSummaryQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<SessionSummaryQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<SessionSummaryQuery, TError, TData>({
    queryFn: useReactQueryFetch<SessionSummaryQuery, SessionSummaryQueryVariables>(SessionSummaryDocument).bind(
      null,
      variables
    ),
    queryKey: ['SessionSummary', variables],
    ...options,
  });
};

useSessionSummaryQuery.document = SessionSummaryDocument;

useSessionSummaryQuery.getKey = (variables: SessionSummaryQueryVariables) => ['SessionSummary', variables];

export const UpcomingRsvpsDocument = `
    query UpcomingRsvps($roomId: ID!, $start: DateTime!, $end: DateTime!) {
  room(roomId: $roomId) {
    roomUpcomingRsvps(start: $start, end: $end) {
      meetingTime {
        beginMeetingTime
        endMeetingTime
      }
      event {
        id
        name
      }
      user {
        phoneNumber
        email
        firstName
        lastName
      }
    }
  }
}
    `;

export const useUpcomingRsvpsQuery = <TData = UpcomingRsvpsQuery, TError = unknown>(
  variables: UpcomingRsvpsQueryVariables,
  options?: Omit<UseQueryOptions<UpcomingRsvpsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<UpcomingRsvpsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<UpcomingRsvpsQuery, TError, TData>({
    queryFn: useReactQueryFetch<UpcomingRsvpsQuery, UpcomingRsvpsQueryVariables>(UpcomingRsvpsDocument).bind(
      null,
      variables
    ),
    queryKey: ['UpcomingRsvps', variables],
    ...options,
  });
};

useUpcomingRsvpsQuery.document = UpcomingRsvpsDocument;

useUpcomingRsvpsQuery.getKey = (variables: UpcomingRsvpsQueryVariables) => ['UpcomingRsvps', variables];

export const GetUserNotificationsDocument = `
    query GetUserNotifications {
  me {
    id
    notifications {
      id
      dateTime
      description
      isRead
      isArchived
      roomId
      roomName
      notificationType
      eventName
      relativeUri
    }
  }
}
    `;

export const useGetUserNotificationsQuery = <TData = GetUserNotificationsQuery, TError = unknown>(
  variables?: GetUserNotificationsQueryVariables,
  options?: Omit<UseQueryOptions<GetUserNotificationsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<GetUserNotificationsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<GetUserNotificationsQuery, TError, TData>({
    queryFn: useReactQueryFetch<GetUserNotificationsQuery, GetUserNotificationsQueryVariables>(
      GetUserNotificationsDocument
    ).bind(null, variables),
    queryKey: variables === undefined ? ['GetUserNotifications'] : ['GetUserNotifications', variables],
    ...options,
  });
};

useGetUserNotificationsQuery.document = GetUserNotificationsDocument;

useGetUserNotificationsQuery.getKey = (variables?: GetUserNotificationsQueryVariables) =>
  variables === undefined ? ['GetUserNotifications'] : ['GetUserNotifications', variables];

export const VolunteerStatsDocument = `
    query VolunteerStats {
  volunteerStats {
    minutesThisMonth
    minutesScheduledThisNextYear
    minutesThisYear
    minutesAllTime
    minutesUnconfirmed
  }
}
    `;

export const useVolunteerStatsQuery = <TData = VolunteerStatsQuery, TError = unknown>(
  variables?: VolunteerStatsQueryVariables,
  options?: Omit<UseQueryOptions<VolunteerStatsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<VolunteerStatsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<VolunteerStatsQuery, TError, TData>({
    queryFn: useReactQueryFetch<VolunteerStatsQuery, VolunteerStatsQueryVariables>(VolunteerStatsDocument).bind(
      null,
      variables
    ),
    queryKey: variables === undefined ? ['VolunteerStats'] : ['VolunteerStats', variables],
    ...options,
  });
};

useVolunteerStatsQuery.document = VolunteerStatsDocument;

useVolunteerStatsQuery.getKey = (variables?: VolunteerStatsQueryVariables) =>
  variables === undefined ? ['VolunteerStats'] : ['VolunteerStats', variables];
