import { Chip, Stack, Tooltip } from '@mui/material';

import { Empty } from '../../../cc-ui/components/Todos/ListContainer';

const tEmpty = 'You have not created any series';
export interface Series {
  id: any;
  description: string;
  name: string;
}

export interface SeriesListProps {
  series: Series[];
  selectedSeriesId?: string;
  onClick: (s: Series) => void;
  onDelete: (s: Series) => void;
}

export const SeriesList = (props: SeriesListProps) => {
  return (
    <Stack
      maxHeight={'11rem'}
      minHeight={'2rem'}
      sx={{ maxWidth: `calc((isMobile ? '100vw' : '100%') - 4rem)`, overflowX: 'hidden', overflowY: 'auto' }}
    >
      {props.series.length ? (
        <Stack direction="row" flexWrap="wrap" sx={{ gap: '1rem' }}>
          {props.series.map((s) => (
            <SeriesChip
              isSelected={s.id === props.selectedSeriesId}
              key={s.id}
              onClick={props.onClick}
              onDelete={props.onDelete}
              series={s}
            />
          ))}
        </Stack>
      ) : (
        <Empty data-testid="no-series" message={tEmpty} />
      )}
    </Stack>
  );
};

type SeriesChipProps = {
  series: Series;
  isSelected: boolean;
  onClick: (s: Series) => void;
  onDelete: (s: Series) => void;
};

const SeriesChip = ({ series, isSelected, onClick, onDelete }: SeriesChipProps) => {
  return (
    <Tooltip arrow placement="top" title={series.description}>
      <Chip
        data-testid="series-chip"
        label={series.name}
        onClick={() => onClick(series)}
        onDelete={() => onDelete(series)}
        sx={{
          '&:hover': {
            backgroundColor: (theme) => (isSelected ? theme.palette.secondary.light : 'default'),
          },
          backgroundColor: (theme) => (isSelected ? theme.palette.secondary.main : 'default'),
        }}
      />
    </Tooltip>
  );
};
