import { useTheme } from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { useCallback } from 'react';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';

export const FIELD_NAME_DATE = 'date';

const tDate = 'Date';

export type DateValue = Dayjs | null;

type ModifiedDatePickerProps = Omit<DatePickerProps<DateValue>, 'localeText' | 'onBlur' | 'onFocus' | 'onChange'> & {
  'data-cy'?: string;
  'data-testid'?: string;
};

export interface DateProps extends Partial<FieldProps<DateValue, FieldRenderProps<DateValue>>> {
  datePickerProps?: ModifiedDatePickerProps;

  helperText?: string;
  label?: string;
  required?: boolean;
}

export const Date = ({ datePickerProps, helperText, label = tDate, required, ...props }: DateProps) => {
  return (
    <Field<DateValue>
      name={FIELD_NAME_DATE}
      render={({ input: { onChange, value }, meta: { error } }) => {
        const theme = useTheme();

        const handleChange = useCallback<NonNullable<DatePickerProps<DateValue>['onChange']>>(
          (dateValue) => {
            onChange({ target: { value: dateValue } });
          },
          [onChange]
        );

        return (
          <DatePicker<DateValue>
            desktopModeMediaQuery={theme.breakpoints.up('md')}
            label={label}
            {...datePickerProps}
            onChange={handleChange}
            slotProps={{
              ...datePickerProps?.slotProps,
              textField: {
                error: Boolean(error),
                fullWidth: true,
                helperText: error || helperText,
                required,
                ...datePickerProps?.slotProps?.textField,
              },
            }}
            value={value || null}
          />
        );
      }}
      {...props}
    />
  );
};
