import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useIsFacilitator } from '../hooks/usePod';

/** goes back to the previous page if the user isn't the facilitator of the current
 * room/pod */
const FacilitatorGuard = ({ children }: { children?: React.ReactNode }) => {
  const navigate = useNavigate();
  const isFacilitator = useIsFacilitator();
  if (!isFacilitator) {
    navigate(-1);
    return null;
  }
  return <>{children}</>;
};

export default FacilitatorGuard;
