import { createAsyncThunk } from '@reduxjs/toolkit';

import { PaymentService, UrlResult } from '../services/ApiService';
import { State } from '../state/state';

/** initiate a payment for a room */
export const onPayRoom = createAsyncThunk<
  UrlResult,
  { roomId: string; ownerId: string; amount: number },
  { state: State }
>('payments/onPayRoom', (payload, thunkAPI) => {
  const userId = thunkAPI.getState().user!.id;
  return PaymentService.paymentSessions({
    body: {
      amount: payload.amount,
      recipientUserId: payload.ownerId,
    },
    userId,
  });
});
