import { Button, Card, CardContent, Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { unwrapResult } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import { FormSpy } from 'react-final-form';

import { getProfile, saveAddress, savePersonalInfo, saveProfilePicture } from '../../actions/profile';
import { PictureUpload } from '../../cc-ui';
import { useDispatch, useSelector } from '../../hooks/useTypedRedux';
import {
  useUserSetDailyEmailNotificationPreferences,
  useUserSetWeeklyEmailNotificationPreferences,
} from '../../services/openapi/generated/CrowdCoursingComponents';
import { successToast } from '../../state/notifications';
import { State } from '../../state/state';
import { ExternalLink } from '../../ui/atoms/ExternalLink';
import { Form, Switch, SwitchValue } from '../../ui/atoms/Form';
import { ProfileCard } from '../../ui/molecules/Profile/Card';
import FeatureGuard from '../FeatureGuard';
import { AddressForm } from './AddressForm';
import { BackgroundCheckPanel } from './BackgroundChecks';
import { PaymentAccountForm } from './PaymentAccountForm';
import { PersonalInformationForm } from './PersonalInformationForm';

const PAPER_ELEVATION = 2;
const tPaymentAccount = 'Payment Account';
const tDailyEmailText = 'Daily Emails';
const tWeeklyEmailText = 'Weekly Emails';
const tPrivacyPolicyTitle = 'Privacy Policy';
const tPrivacyPolicy =
  'Information collected by CrowdCoursing is for internal use by CrowdCoursing and its affiliates only. We will not sell, give away, share, or provide your data to any third party unless we are legally compelled to do so by force of law.';
const tEmailPreferenceTitle = 'Email Preferences';
const tEmailpreferenceDescription =
  'You can decide whether you want to receive our daily and weekly emails by toggling them on and off. Feel free to change your preferences at any time! They will take effect on the next scheduled email.';
const tEmailSuccessMessage = 'Weekly Email Preferences Updated!';
const tBackgroundCheckDescription =
  'Some opportunities may require a background check. Discuss with the event leaders to find out more about their requirements.';
const tBackgroundCheckTitle = 'Background Checks';
const tAddressTitle = 'Address Information';
const tAddressDescription = 'Zip code is used for reminder emails and timezones';
const tContactInformationTitle = 'Contact Information';
const tContactInformationDescription =
  'Your phone number or email can be used to log in to your account. We use MFA to keep our site safe and secure.';
const tSaveButton = 'Save';
const tStripeDescription = 'accounts can be used on CrowdCoursing to send and receive payments.';

const Profile = () => {
  const dispatch = useDispatch();
  const avatarUrl = useSelector((state: State) => state.user?.avatarUrl);
  const onPictureChange = (profilePicture: File) => {
    dispatch(saveProfilePicture(profilePicture));
  };

  return (
    <Grid alignItems="center" container direction="column" spacing={1}>
      <Grid container direction="row" justifyContent="center" marginTop="1em">
        <PictureUpload imageUrl={avatarUrl} name="" onChange={onPictureChange} />
      </Grid>
      <Grid item marginTop="1em" xs={12}>
        <EditProfileForm />
      </Grid>
    </Grid>
  );
};

const EditProfileForm = () => {
  const { userId, dailyNotificationsPreference, weeklyNotificationsPreference } = useSelector((state) => {
    return {
      dailyNotificationsPreference: state.user!.isDailyNotificationsOn,
      userId: state.user!.id,
      weeklyNotificationsPreference: state.user!.isWeeklyNotificationsOn,
    };
  });
  const { mutateAsync: mutateDailyEmail } = useUserSetDailyEmailNotificationPreferences();
  const { mutateAsync: mutateWeeklyEmail } = useUserSetWeeklyEmailNotificationPreferences();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const topMarginForPrivacy = isMobile ? '1em' : '0em';

  return (
    <Grid container direction="column" spacing={2}>
      <Grid id="contact-information" item>
        <ProfileCard description={tContactInformationDescription} title={tContactInformationTitle}>
          <PersonalInformationForm
            onSubmit={async (values) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore // redux-toolkit typing is impossible, switch this react-query
              await dispatch(savePersonalInfo(values)).then(unwrapResult);
            }}
          >
            {({ values, hasValidationErrors, pristine }) => (
              <Grid container direction="row" item justifyContent="flex-end">
                <Grid item marginTop="2em">
                  <Button
                    disabled={pristine || hasValidationErrors || isEmpty(values)}
                    fullWidth
                    size="large"
                    type="submit"
                  >
                    {tSaveButton}
                  </Button>
                </Grid>
              </Grid>
            )}
          </PersonalInformationForm>
        </ProfileCard>
      </Grid>
      <Grid id="address-information" item>
        <ProfileCard description={tAddressDescription} title={tAddressTitle}>
          <AddressForm
            onSubmit={async (values) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore // redux-toolkit typing is impossible, switch this react-query
              await dispatch(saveAddress(values)).then(unwrapResult);
            }}
          >
            {({ values, hasValidationErrors, pristine }) => (
              <Grid container direction="row" item justifyContent="flex-end">
                <Grid item marginTop="2em">
                  <Button
                    disabled={pristine || hasValidationErrors || isEmpty(values)}
                    fullWidth
                    size="large"
                    type="submit"
                  >
                    {tSaveButton}
                  </Button>
                </Grid>
              </Grid>
            )}
          </AddressForm>
        </ProfileCard>
      </Grid>

      <FeatureGuard feature="emailPreferences">
        <Grid id="email-preferences" item>
          <ProfileCard description={tEmailpreferenceDescription} title={tEmailPreferenceTitle}>
            <Form<{ [tDailyEmailText]: SwitchValue }>
              onSubmit={async (values) => {
                await mutateDailyEmail({
                  pathParams: { userId },
                  queryParams: { allowNotifications: Boolean(values[tDailyEmailText]?.includes(tDailyEmailText)) },
                });
                await dispatch(getProfile());
                dispatch(successToast({ message: 'Daily Email Preferences Updated!' }));
              }}
            >
              <FormSpy<unknown>
                render={({ dirty, form }) => {
                  useEffect(() => {
                    if (dirty) {
                      form.submit();
                    }
                  }, [dirty]);
                  return null;
                }}
              />
              <Switch
                initialValue={dailyNotificationsPreference ? [tDailyEmailText] : undefined}
                name={tDailyEmailText}
                switches={[
                  {
                    formControlLabelProps: { label: tDailyEmailText, sx: { height: '2rem' } },
                    switchProps: { defaultChecked: dailyNotificationsPreference, name: tDailyEmailText },
                  },
                ]}
              />
            </Form>
            <Form<{ [tWeeklyEmailText]: SwitchValue }>
              onSubmit={async (values) => {
                await mutateWeeklyEmail({
                  pathParams: { userId },
                  queryParams: { allowNotifications: Boolean(values[tWeeklyEmailText]?.includes(tWeeklyEmailText)) },
                });
                await dispatch(getProfile());
                dispatch(successToast({ message: tEmailSuccessMessage }));
              }}
            >
              <FormSpy<unknown>
                render={({ dirty, form }) => {
                  useEffect(() => {
                    if (dirty) {
                      form.submit();
                    }
                  }, [dirty]);
                  return null;
                }}
              />
              <Switch
                initialValue={weeklyNotificationsPreference ? [tWeeklyEmailText] : undefined}
                name={tWeeklyEmailText}
                switches={[
                  {
                    formControlLabelProps: { label: tWeeklyEmailText, sx: { height: '2rem' } },
                    switchProps: { defaultChecked: weeklyNotificationsPreference, name: tWeeklyEmailText },
                  },
                ]}
              />
            </Form>
          </ProfileCard>
        </Grid>
      </FeatureGuard>
      <FeatureGuard feature="payments">
        <Grid id="payment-account" item>
          <ProfileCard
            data-testid="summary-payment-account"
            description={
              <>
                <ExternalLink url="https://stripe.com/">Stripe</ExternalLink> {tStripeDescription}
              </>
            }
            title={tPaymentAccount}
          >
            <PaymentAccountForm />
          </ProfileCard>
        </Grid>
      </FeatureGuard>
      <FeatureGuard feature="backgroundChecks">
        <Grid id="background-checks" item>
          <ProfileCard description={tBackgroundCheckDescription} title={tBackgroundCheckTitle}>
            <BackgroundCheckPanel />
          </ProfileCard>
        </Grid>
      </FeatureGuard>
      <Grid
        container
        direction="column"
        id="privacy-policy"
        item
        justifyContent="center"
        justifyItems="center"
        marginTop={topMarginForPrivacy}
        xs={12}
      >
        <Card elevation={PAPER_ELEVATION} sx={{ padding: '1rem' }}>
          <CardContent>
            <Grid container direction="row" item justifyContent="center" justifyItems="center">
              <Typography color="#56BBD9" variant="h6">
                {tPrivacyPolicyTitle}
              </Typography>
            </Grid>
            <Grid marginTop="2em">
              <Typography>{tPrivacyPolicy}</Typography>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Profile;
