import { Typography } from '@mui/material';

import { ExternalLink } from '../ExternalLink';

type SlotProps = {
  initiateBackgroundCheck?: { children?: React.ReactNode };
};

export type BackgroundCheckStatusRendererProps = {
  complete: boolean;
  url?: null | string;
  slotProps?: SlotProps;
};

export function BackgroundCheckStatusRenderer({ complete, url, slotProps }: BackgroundCheckStatusRendererProps) {
  if (!url && slotProps?.initiateBackgroundCheck) {
    return slotProps?.initiateBackgroundCheck?.children;
  }

  if (!url) {
    return <Typography>Not started</Typography>;
  }

  if (complete) {
    return (
      <ExternalLink url={url} variant="body2">
        Complete
      </ExternalLink>
    );
  }

  return (
    <ExternalLink url={url} variant="body2">
      Pending
    </ExternalLink>
  );
}
