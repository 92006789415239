import { TypedUseSelectorHook, useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';

import { createStore } from '../configureStore';

// initialized a store here solely for type information

export type Store = ReturnType<typeof createStore>;
export type Dispatch = Store['dispatch'];
export type State = ReturnType<Store['getState']>;
export type GetState = Store['getState'];

const useDispatch = () => useReduxDispatch<Dispatch>();
const useSelector: TypedUseSelectorHook<State> = useReduxSelector;

export { useDispatch, useSelector };
