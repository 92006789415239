import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLayoutEffect, useRef } from 'react';

import { useWrappedChat } from '../../hooks';
import ChatHeader from './ChatHeader';
import ChatInput from './ChatInput';
import ChatWindow from './ChatWindow';

const tMessage = 'Enter message';

export const HorizontalRule = styled('hr', {
  shouldForwardProp: (prop) => !['backgroundColor'].includes(prop.toString()),
})<React.HTMLProps<HTMLHRElement> & { backgroundColor?: React.CSSProperties['backgroundColor'] }>(
  ({ backgroundColor, theme }) => ({
    backgroundColor: backgroundColor ?? theme.palette.primary.main,
    border: 'none',
    height: '1px',
    margin: '0.5rem 0 0.5rem -0.875rem',
    width: 'calc(100% + 2rem)',
  })
);

export const Chat = (props: Parameters<typeof useWrappedChat>[0]) => {
  const chatWindowRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (chatWindowRef?.current) {
      const scrollTop = chatWindowRef.current.scrollHeight - chatWindowRef.current.clientHeight;
      if (chatWindowRef.current.scrollTop !== scrollTop) {
        chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight - chatWindowRef.current.clientHeight;
      }
    }
  });

  const { addMessage: handleMessage, isConnected, members: chatMembers, messages } = useWrappedChat(props);

  const placeholder = `${tMessage}`;

  const chatHeaderProps = { numberOfMembers: chatMembers.length };
  const chatWindowProps = { isConnected, messages };
  const chatInputProps = { handleMessage, placeholder };

  return (
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '600px',
        width: 'calc(100% - 1rem)',
      }}
    >
      <ChatHeader {...chatHeaderProps} />
      <HorizontalRule backgroundColor={'transparent'} />
      <Grid
        ref={chatWindowRef}
        sx={{
          flexBasis: '100%',
          marginRight: '-1rem',
          maxHeight: '100%',
          overflowWrap: 'anywhere',
          overflowX: 'hidden',
          overflowY: 'auto',
          paddingRight: '1rem',
        }}
      >
        <ChatWindow {...chatWindowProps} />
      </Grid>
      <HorizontalRule />
      <Grid item marginBottom={2}>
        <ChatInput {...chatInputProps} />
      </Grid>
    </Grid>
  );
};
