import { Box, Button, Typography } from '@mui/material';
import { FormState } from 'final-form';
import { useCallback, useMemo, useState } from 'react';
import { FormSpy } from 'react-final-form';

import { Dialog } from '../../../../cc-ui/components/Dialog';
import { ExternalLink } from '../../../atoms/ExternalLink';
import {
  Form,
  formatSelectValue,
  FormProps,
  getParsedSelected,
  ResetButton,
  SelectValueSingle,
  SubmitButton,
  TextFieldValue,
} from '../../../atoms/Form';
import {
  DistrictSelect,
  DistrictSelectProps,
  Email,
  FIELD_NAME_DISTRICT_SELECT,
  FIELD_NAME_EMAIL,
  FIELD_NAME_FIRST_NAME,
  FIELD_NAME_LAST_NAME,
  FirstName,
  LastName,
} from '../../../molecules/Form';
import {
  BackgroundCheckProductSelect,
  BackgroundCheckProductSelectProps,
  FIELD_NAME_BG_PRODUCT_SELECT,
} from '../../../molecules/Form/BackgroundCheckProductSelect/BackgroundCheckProductSelect';

const requiredFields = [FIELD_NAME_EMAIL, FIELD_NAME_FIRST_NAME, FIELD_NAME_LAST_NAME, FIELD_NAME_DISTRICT_SELECT];
interface FormValues {
  [FIELD_NAME_EMAIL]: TextFieldValue;
  [FIELD_NAME_FIRST_NAME]: TextFieldValue;
  [FIELD_NAME_LAST_NAME]: TextFieldValue;
  [FIELD_NAME_DISTRICT_SELECT]: SelectValueSingle;
  [FIELD_NAME_BG_PRODUCT_SELECT]: SelectValueSingle;
}

export type UserPaysButtonProps = {
  children?: React.ReactNode;
  onSubmit: FormProps<FormValues>['onSubmit'];
  onDistrictChange: (id: string) => void;
  districts: DistrictSelectProps['selectItems'];
  products: BackgroundCheckProductSelectProps['selectItems'];
};

/**
 * Launches a modal for users to pay for their own background checks.
 *
 * Be careful about re-renders; if the props change too much than the modal will
 * forget that it's supposed to be open.
 */
export const UserPaysButton = ({ children, onSubmit, districts, products, onDistrictChange }: UserPaysButtonProps) => {
  const [open, setOpen] = useState(false);
  const closeDialog = useCallback(() => setOpen(false), []);
  const openDialog = useCallback(() => setOpen(true), []);

  const useFirstDistrict = useMemo(() => districts.length === 1, [districts]);
  const useFirstProduct = useMemo(() => products.length === 1, [products]);

  const onChange = useCallback(
    (f: FormState<FormValues, Partial<FormValues>>) => {
      if (onDistrictChange && f.dirtyFields[FIELD_NAME_DISTRICT_SELECT] && f.values.districtSelect) {
        const id = String(getParsedSelected(f.values.districtSelect).id);
        onDistrictChange(id);
      }
    },
    [onDistrictChange]
  );

  return (
    <>
      <Button onClick={openDialog}>{children ?? 'Pay for my own check'}</Button>
      <Form<FormValues> onSubmit={onSubmit}>
        <FormSpy<FormValues> onChange={onChange} subscription={{ dirty: true, dirtyFields: true, values: true }} />
        <Dialog
          actions={
            <>
              <ResetButton onClick={closeDialog}>Cancel</ResetButton>
              <SubmitButton onClick={closeDialog} requiredFields={requiredFields}>
                Pay now
              </SubmitButton>
            </>
          }
          dialogType="custom"
          onDialogClose={closeDialog}
          open={open}
          title="Request a BG check"
        >
          <Box display="grid" gap="2rem" gridAutoColumns="minmax(0, 1fr)" gridAutoFlow="row" padding="1rem">
            <FirstName />
            <LastName />
            <Email />
            <DistrictSelect
              defaultValue={useFirstDistrict ? formatSelectValue(districts[0]) : undefined}
              selectItems={districts}
              selectProps={{
                disabled: useFirstDistrict,
              }}
            />
            <BackgroundCheckProductSelect
              defaultValue={useFirstProduct ? formatSelectValue(products[0]) : undefined}
              selectItems={products}
              selectProps={{ disabled: useFirstProduct }}
            />
          </Box>
          <Typography variant="body2">
            Payments are securely handled by <ExternalLink url="https://stripe.com/">Stripe.com</ExternalLink>.
          </Typography>
        </Dialog>
      </Form>
    </>
  );
};
