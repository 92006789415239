import { Button, DialogActions } from '@mui/material';
import { FC } from 'react';

import { DialogButtonProps } from './DialogButtonProps';

const tCancel = 'Cancel';
const tSubmit = 'Submit';

export interface DialogActionsPropsCommon {
  cancelProps: Partial<DialogButtonProps>;
  submitProps: Partial<DialogButtonProps>;
}

export const DialogActionsCommon: FC<DialogActionsPropsCommon> = ({ cancelProps, submitProps }) => {
  const { isHidden: isCancelHidden, text: cancelText, ...cancelRest } = cancelProps;
  const { isHidden: isSubmitHidden, text: submitText, ...submitRest } = submitProps;

  const shouldShowCancel = !isCancelHidden;
  const shouldShowSubmit = !isSubmitHidden;

  return shouldShowCancel || shouldShowSubmit ? (
    <DialogActions>
      {shouldShowCancel && (
        <Button variant="text" {...cancelRest}>
          {cancelText ?? tCancel}
        </Button>
      )}
      {shouldShowSubmit && <Button {...submitRest}>{submitText ?? tSubmit}</Button>}
    </DialogActions>
  ) : null;
};
