import { Button, ButtonProps } from '@mui/material';
import { useCallback } from 'react';
import { useForm, useFormState } from 'react-final-form';

const tLabel = 'Submit';

export interface SubmitButtonProps extends ButtonProps {
  /** Contents of submit button. Default: "Submit" */
  children?: React.ReactNode;
  requiredFields?: string[];
  /** if true, will ignore the form's pristine status when deciding if the
   * button is enabled. */
  ignorePristine?: boolean;
}

/** Must be used with react-final-form Form. */
export const SubmitButton = ({
  children,
  onClick,
  requiredFields = [],
  ignorePristine = false,
  ...props
}: SubmitButtonProps) => {
  const { submit } = useForm();
  const { hasValidationErrors, pristine, submitting, values } = useFormState();

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (!hasValidationErrors) {
        if (onClick) {
          onClick(event);
        }
        submit();
      }
    },
    [onClick]
  );

  const hasAllRequiredFields = useCallback(
    (formValues: Record<string, unknown>) => {
      return requiredFields.every((field) => Boolean(formValues[field]));
    },
    [requiredFields]
  );

  return (
    <Button
      data-testid="submit-button"
      disabled={(pristine && !ignorePristine) || hasValidationErrors || !hasAllRequiredFields(values) || submitting}
      onClick={handleClick}
      variant="contained"
      {...props}
    >
      {children ?? tLabel}
    </Button>
  );
};
