import { useCallback } from 'react';

export const useLocalStorage = <T>(key: `CrowdCoursing-${string}`) => {
  /** get item from local storage */
  const getLocalStorageItem = useCallback(() => {
    const item = localStorage.getItem(key);
    if (item) {
      return JSON.parse(item) as T;
    }

    return item as null;
  }, [key]);

  /** set item in local storage. value must be serializable. */
  const setLocalStorageItem = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (value: any) => {
      localStorage.setItem(key, JSON.stringify(value));
    },
    [key]
  );

  return { getLocalStorageItem, setLocalStorageItem };
};
