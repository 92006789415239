import { Stack } from '@mui/material';
import { useCallback } from 'react';

import { CancelButton, Form, FormProps, SubmitButton, TextFieldValue } from '../../atoms/Form';
import {
  Description,
  FIELD_NAME_DESCRIPTION,
  FIELD_NAME_RESOURCE_NAME,
  FIELD_NAME_URL,
  ResourceName,
  Url,
} from '../../molecules/Form';
import { CrudFormProps } from '../Crud/Manage';
import { Resource } from './types';

const tCreate = 'Create';
const tEdit = 'Update';

/** raw data coming from the form */
interface FormValues {
  [FIELD_NAME_DESCRIPTION]: TextFieldValue;
  [FIELD_NAME_RESOURCE_NAME]: TextFieldValue;
  [FIELD_NAME_URL]: TextFieldValue;
}

const requiredFields: Array<keyof FormValues> = [FIELD_NAME_RESOURCE_NAME, FIELD_NAME_URL];

/** adapt some types for generated types vs react-final-form */
const falsyToUndef = (s: string | null | undefined): string | undefined => {
  return s ? s : undefined;
};

/** Create/Update resources */
export const ResourceForm = ({ defaults, onCancel, onSubmit }: CrudFormProps<Resource>) => {
  const saveBtnText = defaults ? tEdit : tCreate;
  const handleSubmit = useCallback<FormProps<FormValues>['onSubmit']>(
    (form: FormValues) => {
      const name = form[FIELD_NAME_RESOURCE_NAME];
      const url = form[FIELD_NAME_URL];
      if (name && url) {
        onSubmit({
          description: form[FIELD_NAME_DESCRIPTION],
          name,
          url,
        });
      }
    },
    [onSubmit]
  );

  return (
    <Form<FormValues> onSubmit={handleSubmit}>
      <Stack spacing={2}>
        <ResourceName initialValue={defaults?.name} />
        <Description initialValue={falsyToUndef(defaults?.description)} />
        <Url initialValue={defaults?.url} />
      </Stack>
      {/* TODO: get these into the Crud somehow?*/}
      <Stack direction="row" justifyContent="end" spacing={2} sx={{ marginTop: '3rem' }}>
        <CancelButton onCancel={onCancel} />
        <SubmitButton requiredFields={requiredFields}>{saveBtnText}</SubmitButton>
      </Stack>
    </Form>
  );
};
