import { createSlice } from '@reduxjs/toolkit';

/**
 *
 * compile-time feature flags
 *
 * Storing these in redux to enable per-bubble features.
 */
export interface Features {
  readonly chatLogs: boolean;
  readonly calendarExport: boolean;
  readonly districtMode: boolean;
  readonly showVolunteerStats: boolean;
  readonly loginAsTestUser: boolean;
  readonly payments: boolean;
  readonly reduxDevtools: boolean;
  readonly supportChat: boolean;
  readonly homePageVideos: boolean;
  readonly homePageStats: boolean;
  readonly notifications: boolean;
  readonly emailPreferences: boolean;
  readonly organizations: boolean;
  /** show background check features */
  readonly backgroundChecks: boolean;
  /** can user buy their own checks? */
  readonly backgroundChecksPaidByUser: boolean;
  /** can schools buy a check for a user? */
  readonly backgroundChecksPaidBySchool: boolean;
  /** show resumes */
  readonly showResumes: boolean;
  /** show candidate search */
  readonly showCandidateSearch: boolean;
  /** show user history */
  readonly showUserHistory: boolean;
  /** Environment-Specific Intro Video */
  readonly isSpaceFlorida: boolean;
}

/** string enum of all feature flags */
export type Feature = keyof Features;

/** convert true-ish strings to booleans */
const toBoolean = (value: string | undefined) => value !== undefined && /^(true|1|yes)$/i.test(value);

export const initialState: Features = {
  backgroundChecks: toBoolean(import.meta.env.REACT_APP_FEATURES_BACKGROUND_CHECKS),
  backgroundChecksPaidBySchool: toBoolean(import.meta.env.REACT_APP_FEATURES_BACKGROUND_CHECKS_PAID_BY_SCHOOL),
  backgroundChecksPaidByUser: toBoolean(import.meta.env.REACT_APP_FEATURES_BACKGROUND_CHECKS_PAID_BY_USER),
  calendarExport: toBoolean(import.meta.env.REACT_APP_FEATURES_CALENDAR_EXPORT),
  chatLogs: toBoolean(import.meta.env.REACT_APP_FEATURES_CHAT_LOGS),
  districtMode: toBoolean(import.meta.env.REACT_APP_FEATURES_DISTRICT_MODE),
  emailPreferences: toBoolean(import.meta.env.REACT_APP_FEATURES_EMAIL_PREFERENCES),
  homePageStats: toBoolean(import.meta.env.REACT_APP_FEATURES_HOME_PAGE_STATS),
  homePageVideos: toBoolean(import.meta.env.REACT_APP_FEATURES_HOME_PAGE_VIDEOS),
  isSpaceFlorida: toBoolean(import.meta.env.REACT_APP_IS_SPACE_FLORIDA),
  loginAsTestUser: toBoolean(import.meta.env.REACT_APP_FEATURES_LOGIN_AS_TEST_USER),
  notifications: toBoolean(import.meta.env.REACT_APP_FEATURES_NOTIFICATIONS),
  organizations: toBoolean(import.meta.env.REACT_APP_FEATURES_ORGANIZATIONS),
  payments: toBoolean(import.meta.env.REACT_APP_FEATURES_PAYMENT),
  reduxDevtools: toBoolean(import.meta.env.REACT_APP_FEATURES_REDUX_DEVTOOLS),
  showCandidateSearch: toBoolean(import.meta.env.REACT_APP_FEATURES_CANDIDATE_SEARCH),
  showResumes: toBoolean(import.meta.env.REACT_APP_FEATURES_RESUMES),
  showUserHistory: toBoolean(import.meta.env.REACT_APP_FEATURES_SHOW_VOLUNTEER_STATS),
  showVolunteerStats: toBoolean(import.meta.env.REACT_APP_FEATURES_DISTRICT_MODE),
  supportChat: toBoolean(import.meta.env.REACT_APP_FEATURES_SUPPORT_CHAT),
};

const slice = createSlice({
  initialState,
  name: 'features',
  reducers: {},
});

export default slice.reducer;

/** per-domain overrides from the .env files  */
export const overrides: Record<string, Partial<Features>> = {
  'alachua.fl.crowdcoursing.com': {
    districtMode: true,
    isSpaceFlorida: false,
    organizations: true,
    payments: false,
    showResumes: false,
    showVolunteerStats: true,
  },
  'app.crowdcoursing.com': {
    districtMode: true,
    isSpaceFlorida: false,
    payments: true,
    showResumes: false,
    showVolunteerStats: true,
  },
  'district-sandbox.crowdcoursing.com': {
    backgroundChecks: true,
    backgroundChecksPaidByUser: true,
    districtMode: true,
    isSpaceFlorida: false,
    organizations: true,
    payments: false,
    showResumes: true,
    showVolunteerStats: true,
  },
  'sandbox.crowdcoursing.com': {
    backgroundChecks: true,
    backgroundChecksPaidByUser: true,
    districtMode: true,
    isSpaceFlorida: false,
    organizations: true,
    payments: true,
    showResumes: true,
    showVolunteerStats: true,
  },
  'space-florida-sandbox.crowdcoursing.com': {
    backgroundChecks: true,
    backgroundChecksPaidByUser: true,
    districtMode: true,
    isSpaceFlorida: true,
    organizations: true,
    payments: false,
    showVolunteerStats: false,
  },
  'space-florida.crowdcoursing.com': {
    backgroundChecks: true,
    backgroundChecksPaidByUser: true,
    districtMode: true,
    isSpaceFlorida: true,
    organizations: true,
    payments: false,
    showVolunteerStats: false,
  },
};
