import { useMemo } from 'react';

import { BackgroundCheckStatusRenderer } from '../../../atoms/DataGrid';
import { DataGrid, DataGridProps } from '../../../molecules/DataGrid';

type BackgroundCheck = {
  id: string;
  schoolDistrictName: string;
  url?: string;
  isCompleted: boolean;
};

type RowData = BackgroundCheck & {
  /** id referenced in data grid operations. MUST BE UNIQUE */
  rowId: string;
};

export type BackgroundChecksProps = {
  data: BackgroundCheck[];
};

const columns: DataGridProps<RowData>['columns'] = [
  { field: 'schoolDistrictName', flex: 1, headerName: 'School District' },
  {
    field: 'url',
    flex: 1,
    headerName: 'Status',
    renderCell: ({ row: { url, isCompleted } }) => <BackgroundCheckStatusRenderer complete={isCompleted} url={url} />,
  },
];

const slotProps: DataGridProps<BackgroundCheck>['slotProps'] = {
  dataGrid: { slots: { toolbar: null }, sx: { minHeight: 0 } },
};

export const BackgroundChecks = ({ data: backgroundChecks }: BackgroundChecksProps) => {
  const rows = useMemo(() => backgroundChecks.map((x) => ({ rowId: x.id, ...x })), [backgroundChecks]);

  return (
    <>
      <DataGrid<BackgroundCheck> columns={columns} rows={rows} slotProps={slotProps} />
    </>
  );
};
