import { Card as MuiCard, styled, Typography } from '@mui/material';

export const Header = styled(Typography)(({ theme }) => {
  return {
    ...theme.typography.h4,
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.body1,
    },
  };
});

export const Card = styled(MuiCard)(({ theme }) => {
  return {
    padding: '1rem',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '93%',
    },
  };
});
