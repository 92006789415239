import { TextField } from '../../../atoms/Form';
import { ConstructedTextField } from '../types';

export const FIELD_NAME_CAPACITY = 'capacity';
const MIN_VALUE = 0;

const tHelperText = 'Set to zero (0) for unlimited';
const tLabel = 'Capacity';

const tRequired = 'Capacity required';
const tFormatNumber = 'Capacity must be a whole number';
const tFormatPositive = 'Capacity must be a positive whole number';
const tLength = 'Capacity cannot exceed nine (9) digits';

export type CapacityProps = ConstructedTextField;

export const Capacity = ({ textFieldProps, ...props }: CapacityProps) => {
  return (
    <TextField
      {...props}
      name={FIELD_NAME_CAPACITY}
      textFieldProps={{
        'data-cy': 'form-field-capacity',
        'data-testid': 'form-field-capacity',
        helperText: tHelperText,
        // inputProps: { inputMode: 'numeric', pattern: CAPACITY_REGEX },
        label: tLabel,
        ...textFieldProps,
      }}
      validate={(value, _, meta) => {
        if (meta?.dirty) {
          if (!value) {
            return tRequired;
          }

          if (isNaN(Number(value))) {
            return tFormatNumber;
          }

          if (value.split('').some((char) => char === 'e' || char === '.')) {
            return tFormatNumber;
          }

          if (value.length < 1 || value.length > 9) {
            return tLength;
          }

          if (isNaN(Number(value)) || Number(value) < MIN_VALUE) {
            return tFormatPositive;
          }
        }

        return '';
      }}
    />
  );
};
