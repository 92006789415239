import { Select } from '../../../atoms/Form';
import { ConstructedSelect } from '../types';

export const FIELD_NAME_BUBBLE_SELECT = 'bubbleSelect';

const tLabel = 'School';
const tRequired = 'School is required';

export type BubbleSelectProps = ConstructedSelect;

export const BubbleSelect = ({ required, selectItems, selectProps, ...props }: BubbleSelectProps) => {
  return (
    <Select
      label={tLabel}
      name={FIELD_NAME_BUBBLE_SELECT}
      selectItems={selectItems}
      selectProps={{
        'data-testid': 'form-field-school-select',
        required,
        ...selectProps,
      }}
      validate={(value, _, meta) => {
        if (meta?.modified && required && !value) {
          return tRequired;
        }

        return '';
      }}
      {...props}
    />
  );
};
