import { CircularProgress, Modal } from '@mui/material';
import React from 'react';

export interface LoadingIndicatorFullPageProps {
  open: boolean;
}

const LoadingIndicatorFullPage: React.FC<LoadingIndicatorFullPageProps> = ({ open }) => {
  return (
    <Modal
      disableAutoFocus
      hideBackdrop
      open={open}
      sx={{
        alignItems: 'center',
        backgroundColor: 'hsla(0,0%,100%,0.97)',
        display: 'flex',
        justifyContent: 'center',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <CircularProgress data-testid="spinner" />
    </Modal>
  );
};

export default LoadingIndicatorFullPage;
