import { Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import {
  useOrganizationCreateOrganization,
  useOrganizationUploadOrganizationPicture,
} from '../../../src/services/openapi/generated/CrowdCoursingComponents.ts';
import { PictureUpload } from '../../cc-ui/components/PictureUpload/PictureUpload';

interface FormValues {
  address: string;
  bubbleId?: string;
  canCreateEvents: boolean;
  description: string;
  name: string;
  file?: File | null;
}

interface FormErrors {
  address?: string;
  description?: string;
  name?: string;
}

interface CreateOrganizationProps {
  onClose: () => void;
}

const CreateOrganization = ({ onClose }: CreateOrganizationProps) => {
  const [formValues, setFormValues] = useState<FormValues>({
    address: '',
    canCreateEvents: false,
    description: '',
    file: undefined,
    name: '',
  });
  const [errors, setErrors] = useState<FormErrors>({});

  const [orgImageUrl, setOrgImageUrl] = useState<string>();
  const [uploadError, setUploadError] = useState<string | undefined>(undefined);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const { mutate: uploadProfilePicture } = useOrganizationUploadOrganizationPicture({
    onError: (uploadError) => {
      setUploadError('Failed to upload profile picture.');
      console.error('Error uploading profile picture:', uploadError);
      setIsUploading(false);
    },
    onSuccess: () => {
      setIsUploading(true);
      onClose();
    },
  });

  const {
    mutate: createOrganization,
    isLoading,
    isError,
    error,
  } = useOrganizationCreateOrganization({
    onError: (error) => {
      console.error('Error creating organization:', error);
    },
    onSuccess: (organizationId: string) => {
      if (formValues.file) {
        const body = new FormData();
        body.append('FormFile', formValues.file);
        uploadProfilePicture({
          // @ts-expect-error expected error using FormData to append file to body
          body,
          headers: { 'Content-Type': 'multipart/form-data' },
          pathParams: { organizationId: organizationId },
        });
        onClose();
      } else {
        onClose();
      }
    },
  });

  const validate = (values: FormValues): FormErrors => {
    const errors: FormErrors = {};
    if (!values.name) {
      errors.name = 'Name is required';
    }
    if (!values.address) {
      errors.address = 'Address is required';
    }
    if (!values.description) {
      errors.description = 'Description is required';
    }
    return errors;
  };

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const validationErrors = validate(formValues);
      setErrors(validationErrors);
      if (Object.keys(validationErrors).length === 0) {
        createOrganization({ body: formValues });
      }
    },
    [formValues, validate, createOrganization]
  );

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value, type, checked } = event.target as HTMLInputElement;
    setFormValues((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const onPictureChange = (file: File) => {
    if (orgImageUrl) {
      URL.revokeObjectURL(orgImageUrl);
    }
    const fileUrl = URL.createObjectURL(file);
    setOrgImageUrl(fileUrl);
    setFormValues((prev) => ({ ...prev, file }));
    setUploadError(undefined);
  };

  useEffect(() => {
    return () => {
      if (orgImageUrl) {
        URL.revokeObjectURL(orgImageUrl);
      }
    };
  }, [orgImageUrl]);

  return (
    <Grid container justifyContent="center">
      <Grid item md={8} xs={10}>
        <Typography sx={{ textAlign: 'center' }} variant="h5">
          New Organization Details
        </Typography>
        <Grid
          container
          direction="row"
          item
          justifyContent="center"
          justifyItems="center"
          style={{ paddingBottom: '1em', paddingTop: '1em' }}
        >
          <PictureUpload imageUrl={orgImageUrl} name="" onChange={onPictureChange} />
        </Grid>
        {uploadError && <Typography color="error">{uploadError}</Typography>}
        <form onSubmit={handleSubmit}>
          <TextField
            disabled={isLoading || isUploading}
            error={!!errors.name}
            fullWidth
            helperText={errors.name}
            id="name"
            label="Organization Name"
            margin="normal"
            name="name"
            onChange={handleFormChange}
            value={formValues.name}
          />
          <TextField
            disabled={isLoading || isUploading}
            error={!!errors.description}
            fullWidth
            helperText={errors.description}
            id="description"
            label="Organization Description"
            margin="normal"
            name="description"
            onChange={handleFormChange}
            value={formValues.description}
          />
          <TextField
            disabled={isLoading || isUploading}
            error={!!errors.address}
            fullWidth
            helperText={errors.address}
            id="address"
            label="Organization Address"
            margin="normal"
            name="address"
            onChange={handleFormChange}
            value={formValues.address}
          />
          <Grid item style={{ marginTop: '2em' }}>
            <Button color="primary" disabled={isLoading || isUploading} type="submit" variant="contained">
              {isLoading || isUploading ? <CircularProgress size={24} /> : 'Create Organization'}
            </Button>
          </Grid>
          {isError && <Typography color="error">{error?.payload}</Typography>}
        </form>
      </Grid>
    </Grid>
  );
};

export default CreateOrganization;
