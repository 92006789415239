import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import { withRetries } from '../utilities/utils';
import { useFeature } from './useFeature';
import { useSelector } from './useTypedRedux';

const createConnection = (baseUrl: string, token: string | null, enableLogs: boolean) => {
  if (token === null) {
    throw new Error('cannot chat without authorization');
  }
  if (!baseUrl) {
    throw new Error('cannot chat without baseUrl');
  }
  return new HubConnectionBuilder()
    .withUrl(`${baseUrl}hubs/podChat`, {
      accessTokenFactory: () => token,
      logger: enableLogs ? LogLevel.Debug : LogLevel.None,
    })
    .withAutomaticReconnect()
    .build();
};

/** try to join the room/session chat */
const joinChat = async (
  connection: HubConnection,
  roomId: string | undefined,
  eventId: string | undefined,
  setIsConnected: Dispatch<SetStateAction<boolean>>
) => {
  const joined = await withRetries(() => connection.invoke('Join', { eventId, roomId }));
  if (!joined) {
    console.error('failed to join to chat');
    return;
  }
  setIsConnected(true);
};

/** try to connect to the room/session chat, sets up a lot of events */
const connect = async (
  connection: HubConnection,
  roomId: string | undefined,
  eventId: string | undefined,
  setIsConnected: Dispatch<SetStateAction<boolean>>,
  setMessages: Dispatch<SetStateAction<ChatMessage[]>>,
  setMembers: Dispatch<SetStateAction<Map<string, ChatMember>>>
): Promise<void> => {
  // clear state if we drop our connection
  connection.onreconnecting(() => {
    setIsConnected(false);
    setMessages([]);
    setMembers(new Map<string, ChatMember>());
  });

  // re-join the chat if we get cut
  connection.onreconnected(() => joinChat(connection, roomId, eventId, setIsConnected));

  // handle new messages
  connection.on('MessageReceived', (msg: ChatMessage) => {
    // de-dupe messages, signalr has some surprising cache/retry behavior
    setMessages((prev) => (prev.some((m) => m.id === msg.id) ? prev : [msg, ...prev]));
  });

  // keep our membership list accurate
  connection.on('UserJoined', (member: ChatMember) => {
    setMembers((m) => new Map(m.set(member.userId, member)));
  });
  connection.on('UserLeft', (member: ChatMember) => {
    setMembers((m) => {
      m.delete(member.userId);
      return new Map(m);
    });
  });

  // actually connect
  await withRetries(() => connection.start());
  if (connection.state !== HubConnectionState.Connected) {
    throw new Error('could not connect');
  }
  await joinChat(connection, roomId, eventId, setIsConnected);
};

/** what is being said? */
export interface ChatMessage {
  id: string;
  body: string;
  timestamp: Date;
  userId: string;
  userName: string;
}

/** who is in the chat? */
export interface ChatMember {
  userId: string;
  userName: string;
}

export type AddMessage = (body: string) => Promise<boolean>;

export interface ChatRoom {
  messages: ChatMessage[];
  members: ChatMember[];
  isConnected: boolean;
  /** send a message to everyone in the group chat */
  addMessage: AddMessage;
}

/** must provide at least one of the fields */
export interface UseChatArgs {
  roomId?: string;
  eventId?: string;
}

/**
 * starts up a real-time chat
 */
export const useChat = ({ eventId, roomId }: UseChatArgs): ChatRoom => {
  const [baseUrl, setBaseUrl] = useState<string | undefined>();

  // signalr doesn't like working through azure CDN; pull the right backend URL off response headers
  // to make a direct connection
  useEffect(() => {
    window.fetch(`${import.meta.env.REACT_APP_BACKEND_URL}readyz`).then((resp) => {
      const url = resp.headers.get('x-cc-backend-base-url');
      setBaseUrl(url || import.meta.env.REACT_APP_BACKEND_URL);
    });
  }, []);

  // make a connection via useMemo so we won't trigger re-renders
  const token = useSelector((state) => state.auth.token);
  const enableLogs = useFeature('chatLogs');
  const connection = useMemo(
    () => baseUrl && createConnection(baseUrl, token, enableLogs),
    [token, enableLogs, baseUrl]
  );
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [members, setMembers] = useState(new Map<string, ChatMember>());
  const [isConnected, setIsConnected] = useState(false);

  // start up the connection
  useEffect(() => {
    if (!connection) {
      return;
    }
    connect(connection, roomId, eventId, setIsConnected, setMessages, setMembers);
    return () => {
      connection.stop();
    };
  }, [connection, roomId, eventId]);

  const addMessage = useCallback(
    (body: string) => {
      if (!connection) {
        return Promise.reject('not ready yet');
      }
      return withRetries(() => connection.invoke<void>('Send', { body, eventId, roomId }));
    },
    [connection, eventId, roomId]
  );

  return { addMessage, isConnected, members: [...members.values()], messages };
};
