import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ToastNotification {
  id: string;
  message: string;
  severity: 'error' | 'info' | 'success' | 'warning';
}

export interface NotificationsState {
  toasts: ToastNotification[];
}

export const initialState: NotificationsState = {
  toasts: [],
};

const slice = createSlice({
  initialState,
  name: 'notifications',
  reducers: {
    cancelToastNotification: (state: NotificationsState, action: PayloadAction<Pick<ToastNotification, 'id'>>) => {
      state.toasts = state.toasts.filter(({ id }) => id !== action.payload.id);
    },
    errorToast: (state: NotificationsState, action: PayloadAction<Pick<ToastNotification, 'message'>>) => {
      state.toasts.push({ id: `${Date.now()}`, message: action.payload.message, severity: 'error' });
    },
    infoToast: (state: NotificationsState, action: PayloadAction<Pick<ToastNotification, 'message'>>) => {
      state.toasts.push({ id: `${Date.now()}`, message: action.payload.message, severity: 'info' });
    },
    successToast: (state: NotificationsState, action: PayloadAction<Pick<ToastNotification, 'message'>>) => {
      state.toasts.push({ id: `${Date.now()}`, message: action.payload.message, severity: 'success' });
    },
    warningToast: (state: NotificationsState, action: PayloadAction<Pick<ToastNotification, 'message'>>) => {
      state.toasts.push({ id: `${Date.now()}`, message: action.payload.message, severity: 'warning' });
    },
  },
});

export const { cancelToastNotification, errorToast, infoToast, successToast, warningToast } = slice.actions;

export default slice.reducer;
