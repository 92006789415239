import { Button, Container, Grid, Step, StepContent, StepLabel, Stepper } from '@mui/material';
import { unwrapResult } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { saveAddress, savePersonalInfo } from '../../actions/profile';
import { useDispatch } from '../../hooks';
import { Route } from '../../router';
import { AddressForm } from './AddressForm';
import { PersonalInformationForm } from './PersonalInformationForm';

const CreateProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);

  const goToNextStep = () => {
    if (activeStep === 1) {
      navigate(Route.HOME);
    } else {
      // TODO: use routing to handle step # so browser back/forth
      // work w/ the wizard
      setActiveStep(activeStep + 1);
    }
  };

  const goToPreviousStep = () => {
    if (activeStep === 0) {
      navigate(-1);
      return;
    }

    setActiveStep(activeStep - 1);
  };

  return (
    <Container>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step>
          <StepLabel>Tell us about yourself</StepLabel>
          <StepContent>
            <PersonalInformationForm
              onSubmit={async (values) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore // redux-toolkit typing is impossible, switch this react-query
                await dispatch(savePersonalInfo(values)).then(unwrapResult);
                goToNextStep();
              }}
            >
              {({ values, hasValidationErrors }) => (
                <WizardButtons
                  nextDisabled={isEmpty(values) || hasValidationErrors}
                  onClickPrevious={goToPreviousStep}
                />
              )}
            </PersonalInformationForm>
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Home Address</StepLabel>
          <StepContent>
            <AddressForm
              onSubmit={async (values) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore // redux-toolkit typing is impossible, switch this react-query
                await dispatch(saveAddress(values)).then(unwrapResult);
                goToNextStep();
              }}
            >
              {({ values, hasValidationErrors }) => (
                <WizardButtons
                  nextDisabled={isEmpty(values) || hasValidationErrors}
                  onClickPrevious={goToPreviousStep}
                />
              )}
            </AddressForm>
          </StepContent>
        </Step>
      </Stepper>
    </Container>
  );
};

interface WizardButtonsProps {
  nextDisabled: boolean;
  onClickPrevious?: () => void;
}

const WizardButtons: React.FC<WizardButtonsProps> = ({ onClickPrevious, nextDisabled }) => (
  <Grid container marginTop={1} spacing={5}>
    <Grid item md={2} sm={3} xs={6}>
      {onClickPrevious && (
        <Button data-cy="previousButton" fullWidth onClick={onClickPrevious} size="medium">
          Previous
        </Button>
      )}
    </Grid>
    <Grid item md={2} sm={3} xs={6}>
      <Button data-cy="nextButton" disabled={nextDisabled} fullWidth size="medium" type="submit">
        Next
      </Button>
    </Grid>
  </Grid>
);

export default CreateProfile;
