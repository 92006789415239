import { Delete as DeleteIcon } from '@mui/icons-material';
import { Avatar, Button, ButtonProps, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { FC, Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { useGetOrganizationsQuery } from '../../../src/services/graphql/generated/react-query';
import { Dialog } from '../../cc-ui';
import { useOrganizationImageUrl } from '../../hooks/useOrganizationImageUrl';
import { Route } from '../../services/router/Route';
import { pageDialogOpen } from '../../state/app';
import CreateOrganization from './CreateOrganization';

const tDeleteOrganizationTooltip = 'Opens the delete organization confirmation modal.';

function NavLink({ children, ...props }: ButtonProps<typeof RouterLink>) {
  return (
    <Button
      color="inherit"
      component={RouterLink}
      fullWidth
      variant="text"
      {...props}
      sx={{ borderRadius: '4px', padding: '1rem' }}
    >
      {children}
    </Button>
  );
}

interface Organization {
  id: string;
  name: string;
  description?: string | null;
  address?: string | null;
  isExternal: boolean;
  canCreateEvents: boolean;
}

interface OrganizationProps {
  organization: Organization;
}

const OrganizationItem: FC<OrganizationProps> = ({ organization }) => {
  const avatarUrl = organization.id ? useOrganizationImageUrl(organization.id) : '';

  const dispatch = useDispatch();
  const handleClickDelete = (event: React.MouseEvent) => {
    event.preventDefault();
    dispatch(
      pageDialogOpen({
        contentProps: { organizationId: organization.id, organizationName: organization.name },
        contentType: 'delete-organization',
      })
    );
  };

  return (
    <Grid alignItems="center" container direction="row" gap="2.5rem" item>
      <Grid container direction="row" item xs>
        <Grid item>
          <Avatar src={avatarUrl} sx={{ height: '3.5rem', width: '3.5rem' }} />
        </Grid>
        <Grid item marginLeft="1em">
          <Typography variant="h6">{organization.name}</Typography>
          <Typography variant="body2">{organization.address}</Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2">{organization.isExternal ? 'External' : 'Internal'}</Typography>
      </Grid>
      <Grid item>
        <Grid alignItems="center" container>
          <IconButton onClick={handleClickDelete}>
            <Tooltip placement="top-start" title={tDeleteOrganizationTooltip}>
              <DeleteIcon color="error" />
            </Tooltip>
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

interface OrganizationsProps {
  organizations?: Organization[];
}

const Organizations: FC<OrganizationsProps> = (props) => {
  const [disabled, setDisabled] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const { data, isLoading, error } = useGetOrganizationsQuery();
  const organizations = props.organizations || data?.organizations || [];

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    console.error(error);
    return <div>Error loading organizations</div>;
  }

  const handleOpenDialog = () => {
    setDisabled(false);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => setIsDialogOpen(false);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography gutterBottom variant="h4">
          Organizations
        </Typography>
        <Grid container justifyContent="flex-end">
          <Button disabled={disabled} onClick={handleOpenDialog}>
            Create Organization
          </Button>
        </Grid>
        <Dialog
          actions={<CreateOrganization onClose={handleCloseDialog}></CreateOrganization>}
          dialogType="custom"
          onDialogClose={handleCloseDialog}
          open={isDialogOpen}
          title="Create New Organization"
        ></Dialog>
        <Grid item marginTop="2em">
          {organizations.map((organization, idx) => (
            <Fragment key={idx}>
              <NavLink to={Route.organization({ organizationId: organization.id! })}>
                <OrganizationItem organization={organization} />
              </NavLink>
              {idx !== organizations.length - 1 && <Divider />}
            </Fragment>
          ))}
          {organizations.length === 0 && <Typography>No new organizations; nothing to see here!</Typography>}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Organizations;
