import { Chip as MUIChip } from '@mui/material';
import type { ReactNode } from 'react';

interface ChipProps {
  label: ReactNode;
  onclick?: () => void;
  'data-testid'?: string;
}

/** Chip is a styled version of the MUI Chip */
export const Chip = ({ label, onclick, ...props }: ChipProps) => {
  return (
    <MUIChip
      data-testid={props['data-testid']}
      label={label}
      onClick={onclick}
      sx={{
        '&:hover': {
          backgroundColor: (theme) => theme.palette.crowdCoursing.ORANGE['100']?.main ?? 'initial',
        },
        backgroundColor: (theme) => theme.palette.crowdCoursing.ORANGE['100']?.light ?? 'initial',
      }}
    />
  );
};
