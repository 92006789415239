import { Grid, Typography } from '@mui/material';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { useSelector } from '../../hooks';
import { DayOfWeek } from '../../services/ApiService';
import {
  EventEditQuery,
  EventRoomSummaryQuery,
  useEventEditQuery,
  useEventRoomSummaryQuery,
  useGetSessionRulesQuery,
  useMyBubblesQuery,
} from '../../services/graphql/generated/react-query';
import { useEventUpdateEvent } from '../../services/openapi/generated/CrowdCoursingComponents';
import type * as Schemas from '../../services/openapi/generated/CrowdCoursingSchemas';
import { MeetingTimeDto } from '../../services/openapi/generated/CrowdCoursingSchemas';
import { Route } from '../../services/router/Route';
import { Breadcrumbs } from './Breadcrumbs';
import { EventInput, SessionForm } from './EventForm';

const tUpdateEvent = 'Update Event';

interface EditEventProps {
  room?: NonNullable<EventRoomSummaryQuery['room']> & { refetch: () => void };
  event: NonNullable<EventEditQuery['session']>;
}

const EditEvent = ({ room, event }: EditEventProps) => {
  const { data: sessionRulesData } = useGetSessionRulesQuery({ eventId: event.id });
  const { mutateAsync: updateEvent } = useEventUpdateEvent();
  const navigate = useNavigate();
  const { data: myBubbles } = useMyBubblesQuery();
  const userId = useSelector((state) => state.user?.id);

  if (!userId) {
    return null;
  }

  if (!myBubbles?.me.bubbles) {
    return null;
  }

  const bubbles = myBubbles.me.bubbles;
  if (!bubbles.length) {
    throw new Error('Cannot create or update event without access to bubbles. How did you get here?');
  }

  const defaults = {
    bubbleId: event.bubbleId,
    description: event.description,
    eventRules: sessionRulesData?.session?.rules,
    isAutoJoin: event.isPublic,
    isOnline: event.isSearchable,
    // graphql gives string|null, everything else wants string|undefined
    meetingTimes: event.allMeetingTimes.map((s) => ({
      ...s,
      seriesId: s.seriesId === null ? undefined : s.seriesId,
    })),
    name: event.name,
  };

  const onSubmit = async (s: EventInput) => {
    const req: Schemas.UpdateEventRequest = {
      bubbleId: s.bubbleId,
      description: s.description,
      eventId: event.id,
      eventRules: s.eventRules,
      facilitatorId: userId,
      isPublic: s.isAutoJoin,
      isSearchable: s.isOnline,
      meetingTimes: s.meetingTimes as MeetingTimeDto[],
      name: s.name,
      roomId: room?.id,
    };

    if (s.schedule) {
      req.sessionSchedule = {
        beginMeetingTime: s.schedule.beginMeetingTime,
        days: s.schedule.days as DayOfWeek[],
        endDate: s.schedule.endDate,
        endMeetingTime: s.schedule.endMeetingTime,
        meetingTimeCapacity: s.schedule.capacity,
        seriesId: s.schedule.seriesId,
        startDate: s.schedule.beginDate,
      };
    }

    await updateEvent({ body: req, pathParams: { eventId: event.id } });
    if (room?.id) {
      navigate(Route.podView({ roomId: room.id }));
    } else {
      navigate(Route.eventView({ eventId: event.id }));
    }
  };

  return (
    <>
      <Grid alignItems="center" container direction="row" justifyContent="center" spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5">{tUpdateEvent}</Typography>
          {room && <Breadcrumbs name={room.name} roomId={room.id} />}
        </Grid>
        <Grid item xs={12}>
          <SessionForm
            {...defaults}
            bubbleSelectItems={bubbles.map(({ id, name: value }) => ({ id, value }))}
            onSubmit={onSubmit}
            room={room ? { id: room.id, refetch: room.refetch } : undefined}
            series={room?.series ?? []}
          />
        </Grid>
      </Grid>
    </>
  );
};

const EditEventRouteValidator = ({ room, eventId }: { room?: EditEventProps['room']; eventId: string }) => {
  const { data: event } = useEventEditQuery({ eventId }, { select: (data) => data.session });

  if (!event) {
    return null;
  }

  // Not all places that link to this page have an event's roomId easily available
  // so we redirect to avoid piping the roomId where it is difficult to load
  if (!room && event.roomId) {
    return <Navigate replace to={Route.roomEditEvent({ eventId, roomId: event.roomId })} />;
  }

  // This is to guard against a mistake where the page was navigated to with a roomId
  // but the event doesn't have an associated room
  if (room && !event.roomId) {
    return <Navigate replace to={Route.eventEdit({ eventId })} />;
  }

  return <EditEvent event={event} room={room} />;
};

const EditEventWithRoom = ({ roomId, eventId }: { roomId: string; eventId: string }) => {
  const { data: room, refetch } = useEventRoomSummaryQuery({ roomId }, { select: (data) => data.room });

  if (!room) {
    return null;
  }

  return <EditEventRouteValidator eventId={eventId} room={{ ...room, refetch }} />;
};

const EditRoomlessEvent = ({ eventId }: { eventId: string }) => {
  return <EditEventRouteValidator eventId={eventId} />;
};

/** wraps EditEvent, validating URL parameters and converting to props to ease types */
const EditEventWrapper = () => {
  const { roomId, eventId } = useParams();

  if (!eventId) {
    return null;
  }

  return roomId ? <EditEventWithRoom eventId={eventId} roomId={roomId} /> : <EditRoomlessEvent eventId={eventId} />;
};

export default EditEventWrapper;
