import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { type PartialDeep } from 'type-fest';

import { DragAndDropFieldArray, TextField } from '~ui/atoms/Form';

import { ResumeWizardNavigation } from '../ResumeWizardNavigation';
import { TypedFormSpy } from './utils';

export const MAX_NUMBER_OF_PROJECTS = 5;
export const DEFAULT_PROJECT: ProjectFieldValue = { description: undefined, name: undefined };

export type ProjectFieldValue = {
  description?: string;
  name?: string;
};

export type ProjectsFieldValues = {
  projects: ProjectFieldValue[];
};

export function getFieldName(name: `${keyof ProjectsFieldValues}`) {
  return name;
}

export function getSubfieldName(base: string, name: `${keyof ProjectFieldValue}`) {
  return `${base}.${name}`;
}

export type ProjectsBaseProps = { initialValues?: PartialDeep<ProjectsFieldValues['projects']> };
export type ProjectsSlotProps = { slotProps?: object };
export type ProjectsProps = ProjectsBaseProps & ProjectsSlotProps;

export function Projects({ initialValues }: ProjectsProps) {
  return (
    <Box data-testid="Portfolio/Projects Form" display="grid" gap="1.5rem">
      <DragAndDropFieldArray<ProjectFieldValue>
        initialValue={initialValues}
        itemTemplate={DEFAULT_PROJECT}
        max={MAX_NUMBER_OF_PROJECTS}
        name={getFieldName('projects')}
        translations={{ add: 'Add Project', remove: 'Remove Project' }}
      >
        {function ({ index, name }) {
          return (
            <>
              <TextField
                name={getSubfieldName(name, 'name')}
                textFieldProps={{
                  'data-testid': getSubfieldName(name, 'name'),
                  label: 'Project Name',
                  variant: 'filled',
                }}
                validate={function (value, values) {
                  const { projects }: ProjectsFieldValues = values as ProjectsFieldValues;
                  if (projects[index]?.description !== undefined && !value) {
                    return 'Project Name required';
                  }
                }}
              />
              <TextField
                name={getSubfieldName(name, 'description')}
                textFieldProps={{
                  'data-testid': getSubfieldName(name, 'description'),
                  label: 'Project Description',
                  variant: 'filled',
                }}
                validate={function (value, values) {
                  const { projects }: ProjectsFieldValues = values as ProjectsFieldValues;
                  if (projects[index]?.name !== undefined && !value) {
                    return 'Project Description required';
                  }
                }}
              />
            </>
          );
        }}
      </DragAndDropFieldArray>
      <TypedFormSpy>
        {function ({ values, hasValidationErrors, form: { submit: onClick }, submitError }) {
          const disabled =
            (values?.projects ?? []).some((project) => Object.values(project).some((value) => value === undefined)) ||
            hasValidationErrors;
          return (
            <>
              <Typography color="error.main" variant="overline">
                {submitError}
              </Typography>
              <ResumeWizardNavigation slotProps={{ next: { disabled, onClick } }} />
            </>
          );
        }}
      </TypedFormSpy>
    </Box>
  );
}
