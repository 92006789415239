import { Select } from '../../../atoms/Form';
import { ConstructedSelect } from '../types';

export const FIELD_NAME_DISTRICT_SELECT = 'districtSelect';

const tLabel = 'School District';
const tRequired = 'District is required';

export type DistrictSelectProps = ConstructedSelect;

export const DistrictSelect = ({ required, selectItems, selectProps, ...props }: DistrictSelectProps) => {
  return (
    <Select
      label={tLabel}
      name={FIELD_NAME_DISTRICT_SELECT}
      selectItems={selectItems}
      selectProps={{ 'data-testid': 'form-field-district-select', required, ...selectProps }}
      validate={(value, _, meta) => {
        if (meta?.modified && required && !value) {
          return tRequired;
        }

        return '';
      }}
      {...props}
    />
  );
};
