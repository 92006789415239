import { RightSidebar } from '../../../cc-ui';
import { Chip } from '../../atoms/Chip';
import { CrudInput, Manage, ManageProps } from '../Crud/Manage';
import { ResourceDeleteConfirm } from './ResourceDeleteConfirm';
import { ResourceForm } from './ResourceForm';
import { ResourceGrid } from './ResourceGrid';
import { Resource } from './types';

const tLabelWithCount = (count: number) => `${count} Resources`;
const tHeading = 'Manage Resources';
const tInfo =
  'A resource is a way to share links to documents, videos, or files from other websites that are relevant to your room.';

interface ManageResourcesChipProps {
  resources: Resource[];
  onSaveResource: (newItem: CrudInput<Resource>, oldItem?: Resource) => void;
  onDeleteResource: (item: Resource) => void;
}

export const ManageResourcesChip = ({ resources, onSaveResource, onDeleteResource }: ManageResourcesChipProps) => {
  const label = tLabelWithCount(resources.length);
  const slots: ManageProps<Resource>['slots'] = {
    deleteConfirm: ResourceDeleteConfirm,
    form: ResourceForm,
    list: ResourceGrid,
  };
  return (
    <RightSidebar
      headingText={tHeading}
      label={(onclick) => <Chip data-testid="manage-resources-button" label={label} onclick={onclick} />}
    >
      <Manage<Resource>
        description={tInfo}
        items={resources}
        noun="Resource"
        onDelete={onDeleteResource}
        onSubmit={onSaveResource}
        slots={slots}
      />
    </RightSidebar>
  );
};
