import { Avatar, Box, Divider, Grid, Link as MuiLink, List, ListItem, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { Link, To } from 'react-router-dom';

import { LoginActions } from '../../actions/login';
import { useDispatch, useSelector } from '../../hooks/useTypedRedux';
import { Route } from '../../router';
import { pageMenuClose } from '../../state/app';

interface LinkDescription {
  displayName: string;
  isHidden?: boolean;
  onClick?: () => void;
  to: To;
  e2e?: string;
}

interface SimpleLinkDescription {
  href: string;
  description: string;
}

const tMySchedule = 'Schedule';
const tMyRooms = 'Rooms';
const tSearch = 'Search';
const tProfile = 'Profile';
const tHome = 'Home';
const tLogout = 'Log Out';
const tAdmin = 'Admin Portal';
const tOrganizations = 'Organizations';
const tExternalResources = 'External Resources';
const tTutorials = 'Tutorials';
const tutorialsLink = 'https://www.youtube.com/channel/UCJITEnFtWleIVvC-KTfTLjw';
const tEmailFeedback = 'Email Support';
const tEmailFeedbackLink = 'https://www.crowdcoursing.com/contact-us';

const LinksList: React.FC<{ links: LinkDescription[] }> = ({ links }) => {
  const avatarUrl = useSelector(({ user }: any) => (user && user.avatarUrl ? user.avatarUrl : ''));
  return (
    <List>
      <Link
        hidden={!(links[0]?.displayName.toLowerCase() === tProfile.toLowerCase())}
        key={tProfile}
        onClick={() => {}}
        to={Route.PROFILE}
      >
        <Grid alignItems="center" container direction="column">
          <Avatar
            src={avatarUrl}
            sx={{ border: '1px solid hsl(0, 0%, 33%)', height: '7rem', marginTop: '1rem', width: '7rem' }}
          />
        </Grid>
      </Link>
      {links.map(({ displayName, isHidden, onClick, to, e2e }) =>
        isHidden ? null : (
          <Link
            data-cy={e2e}
            key={displayName}
            onClick={onClick ?? (() => {})}
            style={{ textDecoration: 'none' }}
            to={to}
          >
            <ListItem>
              <ListItemText primary={displayName} sx={{ textAlign: 'center' }} />
            </ListItem>
          </Link>
        )
      )}
    </List>
  );
};

const ExternalLinksList: React.FC<{ links: SimpleLinkDescription[] }> = ({ links }) => {
  return (
    <List>
      {links.map(({ href, description }) => (
        <ListItem key={description}>
          <ListItemText
            primary={
              <MuiLink href={href} rel="noopener noreferrer" target="_blank">
                {description}
              </MuiLink>
            }
            sx={{ textAlign: 'center' }}
          />
        </ListItem>
      ))}
    </List>
  );
};

const AppNavigationContent: React.FC = () => {
  const dispatch = useDispatch();
  const { isAdministrator } = useSelector((state) => state.user ?? { isAdministrator: false });

  const externalLinks: SimpleLinkDescription[] = [
    { description: tTutorials, href: tutorialsLink },
    { description: tEmailFeedback, href: tEmailFeedbackLink },
  ];

  const profile: LinkDescription[] = [{ displayName: tProfile, to: Route.PROFILE }];

  const navigation: LinkDescription[] = [
    { displayName: tHome, to: Route.HOME },
    { displayName: tSearch, to: Route.PODS_SEARCH },
    { displayName: tMySchedule, e2e: 'my-schedule-link', to: Route.SCHEDULES },
    { displayName: tMyRooms, e2e: 'my-rooms-link', to: Route.PODS },
    { displayName: tLogout, e2e: 'logoutLink', to: Route.LOGIN },
  ];

  // Put the Admin Portal at the top of the navigation set
  if (isAdministrator) {
    navigation.splice(0, 0, { displayName: tAdmin, to: Route.ADMIN });
    navigation.splice(1, 0, { displayName: tOrganizations, to: Route.ORGANIZATIONS });
  }

  const updatedNavigation = React.useMemo(() => {
    const handleClick = () => {
      dispatch(pageMenuClose());
      dispatch(LoginActions.logout());
    };

    // handle logging out
    const logoutIndex = navigation.findIndex(({ displayName }) => displayName === tLogout);
    if (logoutIndex !== -1) {
      navigation[logoutIndex] = {
        ...navigation[logoutIndex],
        onClick: handleClick,
      };
    }

    return navigation;
  }, [dispatch, navigation]);

  return (
    <Grid container direction="column" justifyContent="space-between" sx={{ height: '100%' }}>
      <Grid alignItems="center">
        <Typography variant="overline">
          <Box textAlign="center">Navigation</Box>
          <Divider variant="middle" />
        </Typography>
        <Grid alignItems="center">
          <Typography variant="body1">
            <Grid>
              <LinksList links={profile} />
            </Grid>
            <Divider variant="middle" />
          </Typography>
        </Grid>
        <Grid>
          <LinksList links={updatedNavigation} />
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="overline">
          <Box textAlign="center">{tExternalResources}</Box>
        </Typography>
        <Divider variant="middle" />
        <ExternalLinksList links={externalLinks} />
      </Grid>
    </Grid>
  );
};

export default AppNavigationContent;
