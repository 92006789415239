import { Box, BoxProps, Skeleton, Step, StepContent, StepLabel, Stepper, StepperProps } from '@mui/material';

import { Certifications } from './Forms/Certifications';
import { Education } from './Forms/Education';
import { PersonalInformation } from './Forms/PersonalInformation';
import { ProfessionalExperiences } from './Forms/ProfessionalExperiences';
import { Projects } from './Forms/Projects';
import { Skills } from './Forms/Skills';
import { useResumeWizard } from './ResumeWizardProvider';

const loadingKeys = new Array(6).fill(0).map(() => crypto.randomUUID());

function Loading(props: BoxProps) {
  return (
    <Box {...props}>
      {loadingKeys.map(function (key) {
        return <Skeleton height="50px" key={key} width="100%" />;
      })}
      <Box display="flex" gap="1rem">
        <Skeleton height="34.5px" width="90px" />
        <Skeleton height="34.5px" width="64px" />
      </Box>
    </Box>
  );
}

export type StepNumber = 1 | 2 | 3 | 4 | 5 | 6;

export type StepTitle =
  | 'Personal Information'
  | 'Education'
  | 'Skills'
  | 'Certifications'
  | 'Portfolio/Projects'
  | 'Professional Experience';

export const steps: { component: React.ReactNode; key: StepNumber; title: StepTitle }[] = [
  { component: <PersonalInformation />, key: 1, title: 'Personal Information' },
  { component: <Education />, key: 2, title: 'Education' },
  { component: <Certifications />, key: 3, title: 'Certifications' },
  { component: <Skills />, key: 4, title: 'Skills' },
  { component: <Projects />, key: 5, title: 'Portfolio/Projects' },
  { component: <ProfessionalExperiences />, key: 6, title: 'Professional Experience' },
];

export type ResumeWizardBaseProps = { status: Utils.QueryStatus };

export type ResumeWizardSlotProps = { slotProps?: { stepper?: StepperProps } };

export type ResumeWizardProps = ResumeWizardBaseProps & ResumeWizardSlotProps;

export function ResumeWizard({ slotProps, status }: ResumeWizardProps) {
  const { activeStep } = useResumeWizard();

  return (
    <Stepper activeStep={activeStep} data-testid="ResumeWizard" orientation="vertical" {...slotProps?.stepper}>
      {steps.map(({ component, key, title }) => {
        return (
          <Step key={key}>
            <StepLabel>{title}</StepLabel>
            <StepContent>
              <Box data-testid={title} display="grid" gap="2rem">
                {status === 'pending' && <Loading data-testid={`${title}-loading`} />}
                {status !== 'pending' && component}
              </Box>
            </StepContent>
          </Step>
        );
      })}
    </Stepper>
  );
}
