import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from '@hello-pangea/dnd';
import { Add, Delete, DragHandle } from '@mui/icons-material';
import { Box, Button, Card, Checkbox, FormControlLabel } from '@mui/material';
import { memo, useCallback, useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays';

import { EventRuleDto } from '../../services/openapi/generated/CrowdCoursingSchemas';
import { TextField } from '../../ui/atoms/Form';
import { Description, FIELD_NAME_DESCRIPTION } from '../../ui/molecules/Form';

const tAddRules = 'Add rules';
const tAddRule = 'Add rule';
const tRemoveRule = 'Remove rule';

const tLabel = 'Rule';
const tRequired = 'Rule is required';
const tLengthMax = 'Rule cannot exceed 100 characters';
const tLengthMin = 'Rule must be at least 2 characters';

const MAX_NUMBER_OF_RULES = 25;
const FIELD_NAME_RULE = 'rule';
export const FIELD_NAME_RULES = 'rules';

const DEFAULT_RULE = { [FIELD_NAME_DESCRIPTION]: '', [FIELD_NAME_RULE]: '' };

export type RulesValue = { description?: string | null | undefined; rule: string };

type RulesProps = {
  rules?: RulesValue[];
  onRemoveRule?: (fields: FieldArrayRenderProps<RulesValue, HTMLElement>['fields']) => void;
};

const Rules = ({ onRemoveRule, rules }: RulesProps) => {
  return (
    <FieldArray<RulesValue>
      initialValue={rules?.length ? rules : [DEFAULT_RULE]}
      name={FIELD_NAME_RULES}
      render={({ fields }) => {
        const handleClickAdd = () => {
          if ((fields?.length ?? 0) < MAX_NUMBER_OF_RULES) {
            fields.push(DEFAULT_RULE);
          }
        };

        const handleClickRemove = (index: number) => () => {
          onRemoveRule?.(fields);
          fields.remove(index);
        };

        const handleDragEnd: OnDragEndResponder = useCallback(({ source, destination }) => {
          fields.move(source.index, destination?.index ?? source.index);
        }, []);

        return (
          <>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId={FIELD_NAME_RULES}>
                {(droppable) => {
                  return (
                    <Box {...droppable.droppableProps} display="grid" gap="2rem" ref={droppable.innerRef}>
                      {fields.map((name, index) => {
                        return (
                          <Draggable draggableId={`${name}-${index}`} index={index} key={name}>
                            {(draggable) => {
                              return (
                                <Card
                                  component={Box}
                                  display="grid"
                                  elevation={2}
                                  gridAutoColumns="minmax(0, auto) minmax(0, 1fr)"
                                  gridAutoFlow="column"
                                  {...draggable.draggableProps}
                                  {...draggable.dragHandleProps}
                                  ref={draggable.innerRef}
                                >
                                  <Box
                                    display="grid"
                                    padding="0.5rem"
                                    sx={{
                                      '&:hover': { cursor: 'grab' },
                                      backgroundColor: (theme) => theme.palette.action.hover,
                                      placeItems: 'center',
                                    }}
                                  >
                                    <DragHandle />
                                  </Box>
                                  <Box display="grid" gap="2rem" padding="1.5rem">
                                    <Button
                                      color="error"
                                      onClick={handleClickRemove(index)}
                                      startIcon={<Delete />}
                                      sx={{ marginLeft: '-0.5rem', marginTop: '-0.5rem', width: 'fit-content' }}
                                      variant="text"
                                    >
                                      {tRemoveRule}
                                    </Button>
                                    <Box display="grid" gap="1.5rem">
                                      <TextField
                                        name={`${name}.${FIELD_NAME_RULE}`}
                                        textFieldProps={{
                                          inputProps: { maxLength: 100, minLength: 2 },
                                          label: tLabel,
                                          required: true,
                                        }}
                                        validate={(value, _, meta) => {
                                          if (meta?.modified && !value) {
                                            return tRequired;
                                          }

                                          if (meta?.dirty && value) {
                                            if (value.length < 2) {
                                              return tLengthMin;
                                            }

                                            if (value.length > 100) {
                                              return tLengthMax;
                                            }
                                          }

                                          return '';
                                        }}
                                      />
                                      <Description
                                        name={`${name}.${FIELD_NAME_DESCRIPTION}`}
                                        textFieldProps={{ inputProps: { maxLength: 250, minLength: 2 } }}
                                      />
                                    </Box>
                                  </Box>
                                </Card>
                              );
                            }}
                          </Draggable>
                        );
                      })}
                      {droppable.placeholder}
                    </Box>
                  );
                }}
              </Droppable>
            </DragDropContext>
            <Button
              disabled={(fields.length ?? 0) >= MAX_NUMBER_OF_RULES}
              onClick={handleClickAdd}
              startIcon={<Add />}
              sx={{ width: 'fit-content' }}
              variant="text"
            >
              {tAddRule}
            </Button>
          </>
        );
      }}
    />
  );
};

const MemoizedRules = memo(Rules, (previous, next) => {
  return previous.rules?.every(({ rule }, index) => next.rules?.at(index)?.rule === rule) ?? false;
});

type SessionFormRulesBaseProps = Utils.WithTestIds & { rules?: EventRuleDto[] };
type SessionFormRulesSlotProps = { slotProps?: never };

export type SessionFormRulesProps = SessionFormRulesBaseProps & SessionFormRulesSlotProps;

export const SessionFormRules = memo(function SessionFormRulesBase({ rules }: SessionFormRulesProps) {
  const { reset, change, registerField } = useForm();
  const { values, initialValues } = useFormState();
  const [checked, setChecked] = useState(Boolean(rules?.length));

  useEffect(() => {
    registerField('hadRules', () => {}, {});
  }, []);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        if (values.hadRules) {
          change('hadRules', undefined);
        }
      }

      if (!event.target.checked) {
        if (initialValues.rules?.length) {
          change('hadRules', true);
        }
      }

      change(FIELD_NAME_RULES, []);
      setChecked(event.target.checked);
    },
    [setChecked, reset, values]
  );

  const handleRemoveRule = useCallback<NonNullable<RulesProps['onRemoveRule']>>(
    (fields) => {
      if (fields.length === 1) {
        setChecked(false);
      }
    },
    [setChecked]
  );

  return (
    <>
      <FormControlLabel control={<Checkbox checked={checked} onChange={handleChange} />} label={tAddRules} />
      {checked && (
        <MemoizedRules
          onRemoveRule={handleRemoveRule}
          rules={rules?.map(({ name, description }) => ({ description: description ?? '', rule: name ?? '' }))}
        />
      )}
    </>
  );
});
