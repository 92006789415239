import { Tooltip, Typography } from '@mui/material';
import { generatePath, Link } from 'react-router-dom';

import { Route } from '../../router';

const tRoomLinkTooltip = 'Link to the room';

interface BreadcrumbsProps {
  roomId: string;
  name: string;
}

export const Breadcrumbs = ({ roomId, name }: BreadcrumbsProps) => {
  return (
    <Typography variant="h6">
      {'in '}
      <Tooltip placement="right-end" title={tRoomLinkTooltip}>
        <Link
          data-testid="open-room-link"
          style={{ textDecoration: 'none' }}
          to={generatePath(Route.POD_VIEW, { roomId: roomId })}
        >
          {name}
        </Link>
      </Tooltip>
    </Typography>
  );
};
