import { createSelector } from '@reduxjs/toolkit';

import { State } from '../../hooks/useTypedRedux';

/**
 * Derive user role from state.
 *
 * @example
 *
 * import { userRoleSelector } from '../path/to/selectors';
 * import { useSelector } from '../path/to/hooks';
 *
 * export const Consumer = (props: ConsumerProps) => {
 *  // 'unknown' | 'owner' | 'facilitator' | 'member'
 *  const userRole = useSelector(userRoleSelector);
 *
 *  // component logic elided
 * };
 */
export const userRoleSelector = createSelector(
  [(state: State) => state.app.selectedPod, (state: State) => state.user],
  (pod, user) => {
    if (!pod || !user?.id) {
      return 'unknown';
    }

    if (user.id === pod.ownerId) {
      return 'owner';
    }

    if (pod.facilitatorIds?.includes(user.id)) {
      return 'facilitator';
    }

    if (pod.memberIds?.includes(user.id)) {
      return 'member';
    }

    return 'visitor';
  }
);
