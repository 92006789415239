import { Button } from '@mui/material';

const tCancel = 'Cancel';

export interface CancelButtonProps {
  onCancel: () => void;
}
export const CancelButton = ({ onCancel }: CancelButtonProps) => {
  return (
    <Button data-testid="cancel-button" onClick={onCancel} variant="text">
      {tCancel}
    </Button>
  );
};
