import { Select, SelectItem } from '../../../atoms/Form';
import { ConstructedSelect } from '../types';

export const FIELD_NAME_MEETING_DAYS_SELECT = 'meetingDaysSelect';

const tLabel = 'Select Meeting Days';
const tRequired = 'Meeting days are required';

export const dayValues: SelectItem[] = [
  { id: 0, value: 'Sunday' },
  { id: 1, value: 'Monday' },
  { id: 2, value: 'Tuesday' },
  { id: 3, value: 'Wednesday' },
  { id: 4, value: 'Thursday' },
  { id: 5, value: 'Friday' },
  { id: 6, value: 'Saturday' },
];

export type MeetingDaysSelectProps = Omit<ConstructedSelect, 'selectItems'>;

export const MeetingDaysSelect = ({ required, selectProps, ...props }: MeetingDaysSelectProps) => {
  return (
    <Select
      label={tLabel}
      multiple
      name={FIELD_NAME_MEETING_DAYS_SELECT}
      selectItems={dayValues}
      selectProps={{ 'data-testid': 'form-field-meeting-days-select', required, ...selectProps }}
      validate={(value, _, meta) => {
        if (meta?.modified && required && !value) {
          return tRequired;
        }

        return '';
      }}
      {...props}
    />
  );
};
