import { Avatar as MuiAvatar, Box, Card, CardContent as MuiCardContent, Grid, styled, Typography } from '@mui/material';
import React, { useState } from 'react';
import { generatePath, Link as RouterLink } from 'react-router-dom';

import { Route } from '../../router';
import { Chip } from '../../ui/atoms/Chip';
import SessionsTable, { Row } from '../Events/EventsTable';
import NoRoomsFoundIcon from '../Icons/No_Rooms_Found.png';
import CollapsibleText from '../Shared/CollapsibleText';
import { makeBubbleAvatarUrl, makeRoomAvatarUrl } from './../../utilities/utils';

const tNoRooms = 'No rooms found...';

const Link = styled(RouterLink)({ textDecoration: 'none' });

const Avatar = styled(MuiAvatar)(({ theme }) => {
  return {
    height: theme.spacing(12),
    width: theme.spacing(12),
  };
});

const CardContent = styled(MuiCardContent)(({ theme }) => {
  return {
    '& > *': {
      margin: theme.spacing(1),
    },
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: '0 !important',
  };
});

const PAPER_HOVER_ELEVATION = 15;
const PAPER_ELEVATION = 5;

export interface SearchResult {
  id: string;
  bubbleId: string;
  bubbleName: string;
  address: string;
  resultName: string;
  description: string;
  isAutojoinEnabled: boolean;
  ownerFirstName: string;
  ownerLastName: string;
  resultType: number;
  eventPreviews: Row[];
  tags: string[];
}

const SearchResultCard: React.FC<{ searchResult: SearchResult }> = ({ searchResult }) => {
  const [isCardHovering, setIsCardHovering] = useState(false);

  const roomAvatarUrl = makeRoomAvatarUrl(searchResult.id ?? '');
  const bubbleAvatarUrl = makeBubbleAvatarUrl(searchResult.bubbleId ?? '');

  const cardElevation = isCardHovering ? PAPER_HOVER_ELEVATION : PAPER_ELEVATION;

  const handleMouseOver = () => setIsCardHovering(true);
  const handleMouseOut = () => setIsCardHovering(false);

  const {
    resultName,
    description,
    isAutojoinEnabled,
    eventPreviews,
    tags,
    ownerFirstName,
    ownerLastName,
    bubbleName,
    resultType,
  } = searchResult;
  const ownerName = `${ownerFirstName} ${ownerLastName}`;
  let accessibilityLine = isAutojoinEnabled ? 'Open ' : 'Private ';
  if (resultType === 0) {
    accessibilityLine += 'Room';
  } else {
    accessibilityLine += 'Event';
  }

  accessibilityLine += ` hosted by ${ownerName}`;
  const linkPath =
    resultType == 0
      ? generatePath(Route.POD_VIEW, { roomId: searchResult.id! })
      : generatePath(Route.EVENT_VIEW, { eventId: searchResult.id! });
  const location = bubbleName == null ? '' : bubbleName;
  return (
    <Card
      elevation={cardElevation}
      onMouseOut={handleMouseOut}
      onMouseOver={handleMouseOver}
      sx={{ cursor: 'pointer', margin: '1rem', padding: '1rem' }}
    >
      <Link to={linkPath}>
        <CardContent data-cy="podCard">
          <Grid container direction="row" item justifyContent="flex-end">
            <Grid item>
              <Grid alignContent="flex-end" alignItems="flex-end" justifyContent="flex-end" justifyItems="flex-end">
                <Avatar src={bubbleAvatarUrl} sx={{ height: '4rem', width: '4rem' }} variant="square">
                  <svg height="100" width="100" xmlns="http://www.w3.org/2000/svg">
                    <rect fill="white" height="100" width="100" />
                  </svg>
                </Avatar>
              </Grid>
            </Grid>
          </Grid>
          <Avatar src={roomAvatarUrl} />
          <Grid>
            <Grid>
              <Typography align="center" variant="h5">
                <b>{resultName}</b>
              </Typography>
            </Grid>
            <Grid>
              <Typography align="center" color="black">
                {accessibilityLine}
              </Typography>
            </Grid>
            <Grid>
              <Typography align="center" color="black">
                {location}
              </Typography>
            </Grid>
          </Grid>
          {tags && (
            <Grid>
              {tags.map((tag, index) => (
                <Chip key={index} label={tag} />
              ))}
            </Grid>
          )}
          <SessionsTable sessions={eventPreviews!} />
          <CollapsibleText>
            <Typography color="black" component="p" variant="body2">
              {description}
            </Typography>
          </CollapsibleText>
        </CardContent>
      </Link>
    </Card>
  );
};

export const NoRooms: React.FC = () => {
  return (
    <>
      <Grid>
        <img src={NoRoomsFoundIcon} style={{ width: '300px' }} />
      </Grid>
      <Typography variant="body1">{tNoRooms}</Typography>
    </>
  );
};

type SearchGridProps = {
  searchResults: SearchResult[];
};

export const SearchGrid = ({ searchResults }: SearchGridProps) => {
  return (
    <Grid alignItems="flex-start" container direction="row" justifyContent="flex-start" spacing={0}>
      {searchResults.length > 0 ? (
        searchResults.map((result, index) => (
          <Grid item key={`${result.resultName}-${index}`} md={6} xs={12}>
            <SearchResultCard searchResult={result} />
          </Grid>
        ))
      ) : (
        <Box
          alignItems="center"
          data-testid="no-pods"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          minHeight="20rem"
          width="100%"
        >
          <NoRooms />
        </Box>
      )}
    </Grid>
  );
};
