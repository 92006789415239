import { Refresh as RefreshIcon } from '@mui/icons-material';
import {
  IconButton as MuiIconButton,
  Skeleton,
  styled,
  Table as MuiTable,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

import TableCell from './TableCell';
import TablePaginationActions from './TablePaginationActions';

const tError = 'Error encountered while fetching data';
const tRetry = 'Click to retry your request';
const tEmpty = 'No sign-ups yet!';

const Container = styled('div')(({ theme }) => {
  return {
    display: 'grid',
    gap: '1rem',
    gridAutoFlow: 'row',
    gridAutoRows: 'auto',
    [theme.breakpoints.down('sm')]: {
      gap: '2rem',
    },
  };
});

const TableStateContainer = styled('div')(({ theme }) => {
  return {
    border: `1px solid ${theme.palette.crowdCoursing.GREY['200']?.main ?? 'initial'}`,
    display: 'grid',
    gridAutoColumns: '1fr',
    gridAutoFlow: 'column',
    placeContent: 'center',
  };
});

const TableStateContainerContentContainer = styled('div')(() => {
  return {
    '> *': {
      display: 'grid',
      justifySelf: 'center',
    },
    display: 'grid',
    gap: '1rem',
    gridAutoFlow: 'row',
    gridAutoRows: 'auto',
    justifyContent: 'center',
  };
});

const IconButton = styled(MuiIconButton)(({ theme }) => {
  return {
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    width: 'fit-content',
  };
});

const getEmptyRows = (rows: any[], page: number, rowsPerPage: number) =>
  rows.length === 0 ? rowsPerPage : rowsPerPage - Math.min(rowsPerPage, Math.abs(rows.length - page * rowsPerPage));

const getPaginatedRows = (rows: any[], page: number, rowsPerPage: number) =>
  rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

export interface TableConfig {
  /** number of rows to render on each page. default 6 */
  rowsPerPage: number;
  /** height of a row. default 49 */
  rowHeight: number;
  /** height; used by empty, error, loading states. default "100%" */
  height: React.CSSProperties['height'];
  /** width; used by empty, error, loading states. default "100%" */
  width: React.CSSProperties['width'];
}

const DEFAULT_TABLE_CONFIG: TableConfig = {
  height: '100%',
  rowHeight: 49,
  rowsPerPage: 6,
  width: '100%',
};

export interface TableProps<
  HeaderType extends React.ReactNode = string,
  RecordType extends React.ReactNode[] = React.ReactNode[]
> {
  config?: Partial<TableConfig>;
  error?: boolean;
  headers: HeaderType[];
  loading?: boolean;
  onClickRetry?: (() => void) | (() => Promise<void>);
  records: RecordType[];
}

export const Table = <
  HeaderType extends React.ReactNode = string,
  RecordType extends React.ReactNode[] = React.ReactNode[]
>({
  config: tableConfig,
  error,
  headers,
  loading,
  onClickRetry = async () => {},
  records,
}: TableProps<HeaderType, RecordType>) => {
  const [page, setPage] = useState(0);
  const [emptyRows, setEmptyRows] = useState(0);
  const [paginatedRows, setPaginatedRows] = useState<typeof records>([]);

  const config = useMemo(() => {
    return {
      ...DEFAULT_TABLE_CONFIG,
      ...tableConfig,
      height: `${
        (tableConfig?.rowHeight ?? DEFAULT_TABLE_CONFIG.rowHeight) *
          (tableConfig?.rowsPerPage ?? DEFAULT_TABLE_CONFIG.rowsPerPage) +
        58 +
        54
      }px`,
    };
  }, [tableConfig]);

  useEffect(() => {
    const updatedEmptyRows = getEmptyRows(records, page, config.rowsPerPage);
    setEmptyRows(updatedEmptyRows);

    const updatedPaginatedRows = getPaginatedRows(records, page, config.rowsPerPage);
    setPaginatedRows(updatedPaginatedRows);
  }, [records, page, setPaginatedRows]);

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => setPage(newPage);

  return (
    <Container>
      {/* loading state */}
      {loading && (
        <TableStateContainer
          sx={{ borderColor: (theme) => theme.palette.crowdCoursing.ORANGE['100']?.main ?? 'initial' }}
        >
          <Skeleton
            height={config.height}
            sx={{ border: 'unset', borderRadius: 'unset' }}
            variant="rectangular"
            width={config.width}
          />
        </TableStateContainer>
      )}

      {/* error state */}
      {error && (
        <TableStateContainer sx={{ height: config.height, width: config.width }}>
          <TableStateContainerContentContainer>
            <Typography variant="h6">{tError}</Typography>
            <Typography variant="subtitle1">{tRetry}</Typography>
            <IconButton disableRipple onClick={onClickRetry}>
              <RefreshIcon />
            </IconButton>
          </TableStateContainerContentContainer>
        </TableStateContainer>
      )}

      {/* empty state */}
      {!loading && !error && records.length === 0 && (
        <TableStateContainer sx={{ width: config.width }}>
          <TableStateContainerContentContainer>
            <Typography variant="h6">{tEmpty}</Typography>
          </TableStateContainerContentContainer>
        </TableStateContainer>
      )}

      {!loading && !error && Boolean(records.length) && (
        <TableContainer>
          <MuiTable>
            <TableHead>
              <TableRow>
                {headers.map((header, index) => {
                  return (
                    <TableCell
                      key={index}
                      typographyProps={{ noWrap: true, sx: { fontWeight: 'bold' }, variant: 'overline' }}
                      value={header}
                    />
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedRows.map((row, rowIndex) => (
                <TableRow key={rowIndex} sx={{ verticalAlign: 'top' }}>
                  {row.map((value, dataIndex) => (
                    <TableCell key={dataIndex} typographyProps={{ noWrap: true, variant: 'body2' }} value={value} />
                  ))}
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow sx={{ height: config.rowHeight * emptyRows }}>
                  <TableCell tableCellProps={{ colSpan: records.length }} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  ActionsComponent={TablePaginationActions}
                  colSpan={Number.MAX_SAFE_INTEGER}
                  count={records.length}
                  onPageChange={handleChangePage}
                  page={page}
                  rowsPerPage={config.rowsPerPage}
                  rowsPerPageOptions={[]}
                />
              </TableRow>
            </TableFooter>
          </MuiTable>
        </TableContainer>
      )}
    </Container>
  );
};
