import { Checkbox, CheckboxProps } from '../../../atoms/Form';

export const FIELD_NAME_CREATE_MEETING_TIMES = 'createMeetingTimes';

const tLabel = 'Create Event Times';

export type CreateMeetingTimesProps = Omit<CheckboxProps, 'checkboxes'>;

export const CreateMeetingTimes = ({ formControlProps, ...props }: CreateMeetingTimesProps) => {
  return (
    <Checkbox
      checkboxes={[
        {
          checkboxProps: {
            'data-cy': 'form-field-create-meeting-times-checkbox',
            name: FIELD_NAME_CREATE_MEETING_TIMES,
          },
          formControlLabelProps: { label: tLabel },
        },
      ]}
      formControlProps={{ ...formControlProps }}
      name={FIELD_NAME_CREATE_MEETING_TIMES}
      {...props}
    />
  );
};
