import { type ConfigOptions, type CrowdcoursingApiContext } from './CrowdcoursingApiContext';

export type ErrorWrapper<TError> = TError | { status: 'unknown'; payload: string };

export type CrowdcoursingApiFetcherOptions<TBody, THeaders, TQueryParams, TPathParams> = {
  url: string;
  method: string;
  body?: TBody;
  headers?: THeaders;
  queryParams?: TQueryParams;
  pathParams?: TPathParams;
  signal?: AbortSignal;
} & CrowdcoursingApiContext['fetcherOptions'] &
  ConfigOptions;

export async function crowdcoursingApiFetch<
  TData,
  TError,
  TBody extends {} | FormData | undefined | null,
  THeaders extends {},
  TQueryParams extends {},
  TPathParams extends {},
>({
  url,
  method,
  body,
  headers,
  pathParams,
  queryParams,
  signal,
  baseUrl,
  onUnauthorized,
}: CrowdcoursingApiFetcherOptions<TBody, THeaders, TQueryParams, TPathParams>): Promise<TData> {
  try {
    const requestHeaders: HeadersInit = {
      'Content-Type': 'application/json',
      ...headers,
    };

    /**
     * As the fetch API is being used, when multipart/form-data is specified
     * the Content-Type header must be deleted so that the browser can set
     * the correct boundary.
     * https://developer.mozilla.org/en-US/docs/Web/API/FormData/Using_FormData_Objects#sending_files_using_a_formdata_object
     */
    if (requestHeaders['Content-Type'].toLowerCase().includes('multipart/form-data')) {
      delete requestHeaders['Content-Type'];
    }

    const response = await window.fetch(`${resolveUrl(baseUrl ?? '', url, queryParams, pathParams)}`, {
      body: body ? (body instanceof FormData ? body : JSON.stringify(body)) : undefined,
      headers: requestHeaders,
      method: method.toUpperCase(),
      signal,
    });
    if (!response.ok) {
      if (response.status === 401) {
        onUnauthorized?.();
      }

      // todo: better error handling; this does not share any relevant information about the error encountered
      let error: ErrorWrapper<TError>;
      try {
        error = await response.json();
      } catch (e) {
        error = {
          payload: e instanceof Error ? `Unexpected error (${e.message})` : 'Unexpected error',
          status: 'unknown' as const,
        };
      }

      throw error;
    }

    if (response.headers.get('content-type')?.includes('json')) {
      return await response.json();
    } else {
      // if it is not a json response, assume it is a blob and cast it to TData
      return (await response.blob()) as unknown as TData;
    }
  } catch (e) {
    const errorObject: Error = {
      message: e instanceof Error ? `Network error (${e.message})` : 'Network error',
      name: 'unknown' as const,
      stack: e as string,
    };
    throw errorObject;
  }
}

/**
 * makes a valid absolute or relative URL, splicing in query/path params.
 *
 * prevents building urls with double slashes like http://BASEURL//api/v1/...
 */
export const resolveUrl = (
  baseUrl: string,
  url: string,
  queryParams: Record<string, string> = {},
  pathParams: Record<string, string> = {},
) => {
  if (!url.startsWith('/')) {
    throw new Error(`openapi URLs must start in with a /`);
  }
  let query = new URLSearchParams(queryParams).toString();
  if (query) query = `?${query}`;
  let relpath = url.replace(/\{\w*\}/g, (key) => pathParams[key.slice(1, -1)]) + query;

  if (baseUrl.endsWith('/')) {
    relpath = relpath.substring(1);
  }

  return baseUrl + relpath;
};
