import { Theme, useMediaQuery } from '@mui/material';
import { FC } from 'react';

import { TableCell as BaseTableCell, TableCellProps as BaseTableCellProps } from '../TableCell';

export interface TableCellBodyProps {
  value: BaseTableCellProps['value'];
}

export const TableCellBody: FC<TableCellBodyProps> = ({ value }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return (
    <BaseTableCell
      tableCellProps={{ sx: { maxWidth: isMobile ? '33%' : '20%', width: '100%' } }}
      typographyProps={{ noWrap: true, variant: 'body2' }}
      value={value}
    />
  );
};

export interface TableCellHeadProps {
  value: BaseTableCellProps['value'];
}

export const TableCellHead: FC<TableCellHeadProps> = ({ value }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return (
    <BaseTableCell
      tableCellProps={{ sx: { maxWidth: isMobile ? '33%' : '20%', width: '100%' } }}
      typographyProps={{ sx: { fontWeight: 'bold' }, variant: 'overline' }}
      value={value}
    />
  );
};

export const EmptyTableCell: FC = () => <BaseTableCell tableCellProps={{ colSpan: 6 }} />;
