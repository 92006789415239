import { Grid, Typography } from '@mui/material';
import { FC } from 'react';

import { useDispatch } from '../../hooks/useTypedRedux';
import { pageMenuOpen } from './../../state/app';

export interface ChatHeaderProps {
  numberOfMembers: number;
}

export const ChatHeader: FC<ChatHeaderProps> = ({ numberOfMembers }) => {
  const tMembers = numberOfMembers == 1 ? '1 person online' : `${numberOfMembers} people online`;
  const dispatch = useDispatch();

  const handleClickMembers = () => dispatch(pageMenuOpen('chat-members'));

  return (
    <>
      <Grid
        sx={{
          alignItems: 'center',
          cursor: 'pointer',
          display: 'flex',
        }}
      >
        <Grid flex="1" onClick={handleClickMembers}>
          <Typography variant="h6">Chat</Typography>
          <Typography color={(theme) => theme.palette.primary.main} variant="body1">
            {tMembers}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default ChatHeader;
