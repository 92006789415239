import { useFeature } from '~hooks/useFeature';

import YoutubeEmbed from '../Shared/YouTubeEmbed';

const tEmbedTitle = 'CrowdCoursing Volunteer Video';

export const LoginVideo = () => {
  const isSpaceFlorida = useFeature('isSpaceFlorida');
  const embedId = isSpaceFlorida ? 'pQiUCByJ39I' : 'tVWQLcLBTUQ';
  return (
    <>
      <YoutubeEmbed embedId={embedId} title={tEmbedTitle} width="100%" />
    </>
  );
};
