import { createAsyncThunk } from '@reduxjs/toolkit';

import { SchoolDistrictService } from '../services/ApiService';

export const addMember = createAsyncThunk('app/addMemberSchool', async (body: { bubbleId: string; userId: string }) => {
  try {
    await SchoolDistrictService.approve(body);
  } catch (error) {
    window.console.log('error: ', JSON.stringify(error, undefined, 2));
  }
});

export const removeMember = createAsyncThunk(
  'app/removeMemberSchool',
  async (body: { bubbleId: string; userId: string }) => {
    try {
      await SchoolDistrictService.remove(body);
    } catch (error) {
      window.console.log('error: ', JSON.stringify(error, undefined, 2));
    }
  }
);
