import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  FormGroup,
  FormHelperText,
  FormLabel,
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
} from '@mui/material';
import { useState } from 'react';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';

export type ModifiedSwitchProps = Omit<
  MuiSwitchProps,
  'onChange' | 'onBlur' | 'onFocus' | 'value' | 'checked' | 'type' | 'multiple'
> & { 'data-cy'?: string; 'data-testid'?: string; name: string };

export type SwitchValue = string[] | undefined;

export interface SwitchProps extends FieldProps<SwitchValue, FieldRenderProps<SwitchValue>> {
  helperText?: string;
  label?: string;
  switches: { switchProps: ModifiedSwitchProps; formControlLabelProps: Omit<FormControlLabelProps, 'control'> }[];
  formControlProps?: Omit<FormControlProps, 'error'>;
}

export const Switch = ({ switches, formControlProps, helperText, label, ...props }: SwitchProps) => {
  return (
    <Field
      render={({ input: { onChange }, meta: { error } }) => {
        const [selections, setSelections] = useState<string[]>(props.initialValue ?? []);

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          const updatedSelections = event.target.checked
            ? [...selections, event.target.name]
            : selections.filter((selection) => selection !== event.target.name);

          onChange({
            ...event,
            target: { ...event.target, value: updatedSelections.length > 0 ? updatedSelections : undefined },
          });

          setSelections(updatedSelections);
        };

        return (
          <>
            <FormControl error={Boolean(error)} {...formControlProps}>
              {label && <FormLabel>{label}</FormLabel>}
              <FormGroup>
                {switches.map(({ formControlLabelProps, switchProps }) => {
                  return (
                    <FormControlLabel
                      control={
                        <MuiSwitch
                          {...switchProps}
                          defaultChecked={selections.includes(switchProps.name)}
                          onChange={handleChange}
                        />
                      }
                      key={switchProps.name}
                      {...formControlLabelProps}
                    />
                  );
                })}
              </FormGroup>
              {(error || helperText) && <FormHelperText>{error || helperText}</FormHelperText>}
            </FormControl>
          </>
        );
      }}
      {...props}
    />
  );
};
