import { lazy } from 'react';

import { ResumeBuilderTemplate } from '~ui/templates/ResumeBuilderTemplate/ResumeBuilderTemplate';

import { useResumeBuilder } from './useResumeBuilder';

export function ResumeBuilder() {
  const { resumeBuilderTemplateProps: props } = useResumeBuilder();
  return <ResumeBuilderTemplate {...props} />;
}

export const ResumeBuilderAsync = lazy<typeof ResumeBuilder>(async function () {
  return new Promise<{ default: typeof ResumeBuilder }>(function (resolve) {
    resolve({ default: ResumeBuilder });
  });
});
