import { useEffect } from 'react';

import { setChatMembers } from '../state/app';
import { useChat } from './useChat';
import { useDispatch } from './useTypedRedux';

export const useWrappedChat = (opts: Parameters<typeof useChat>[0]) => {
  const dispatch = useDispatch();
  const chat = useChat(opts);

  useEffect(() => {
    dispatch(setChatMembers(chat.members));
  }, [dispatch, chat.members]);

  return { ...chat };
};
