import { Box, Card, CardContent, Typography } from '@mui/material';
import React from 'react';

export type ProfileCardProps = {
  title: React.ReactNode;
  description: React.ReactNode;
  children?: React.ReactNode;
  'data-testid'?: string;
};

export const ProfileCard = ({ title, description, children, ...props }: ProfileCardProps) => (
  <Card {...props} elevation={2} sx={{ padding: '1rem' }}>
    <CardContent>
      <Typography fontWeight="bold">{title}</Typography>
      <Box marginBottom="1em" marginTop="1em">
        <Typography variant="body2">{description} </Typography>
      </Box>
      {children}
    </CardContent>
  </Card>
);
