import { useEffect, useState } from 'react';

import { serviceOptions } from '../services/ApiService';

/**
 * useSVG returns a data URI suitable for <img src>. Uses axios to fetch the url
 * so it's authenticated.
 * */
export const useSVG = (url?: string) => {
  const [dataUri, setDataUri] = useState<string | undefined>();
  useEffect(() => {
    if (!url || !serviceOptions.axios) {
      return;
    }
    serviceOptions.axios.get(url).then((resp) => {
      const svg = resp.data as string;
      const blob = new Blob([svg], { type: 'image/svg+xml' });
      setDataUri(URL.createObjectURL(blob));
    });
  }, [url]);

  return dataUri;
};
