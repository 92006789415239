import { Typography } from '@mui/material';
import { useMemo } from 'react';

import { Select, SelectOption } from '../../cc-ui';

const tSelectNone = '';
const tSeriesSelectText = 'Add a series to event times';
const tSeriesSelectLabel = 'Event Series (optional)';
const noSeriesOption = {
  id: 'none',
  name: <Typography variant="caption">{tSelectNone}</Typography>,
};

export interface SeriesDropdownProps {
  series: { id: string; name: string }[];
  onChange: (id?: string) => void;
  selectedId?: string;
  variant?: 'normal' | 'compact';
}

export const SeriesDropdown = ({ series, onChange, selectedId, variant = 'normal' }: SeriesDropdownProps) => {
  const seriesOptions = useMemo(() => [noSeriesOption, ...series], [series]);
  const onSelectOption = ({ id }: SelectOption) => {
    if (id === noSeriesOption.id) {
      onChange();
    } else {
      onChange(id);
    }
  };
  const isNormal = variant == 'normal';
  return (
    <Select
      defaultSelectedId={selectedId ?? noSeriesOption.id}
      helperText={isNormal ? tSeriesSelectText : ''}
      label={isNormal ? tSeriesSelectLabel : ''}
      onSelectOption={onSelectOption}
      options={seriesOptions}
    />
  );
};
