import React from 'react';

import { useFeature } from '../hooks/useFeature';
import { Feature } from '../state/features';

interface FeatureGuardProps {
  children?: React.ReactNode;
  feature: Feature;
}

/** renders children only if the given feature is enabled */
const FeatureGuard: React.FC<FeatureGuardProps> = ({ feature, children }) =>
  useFeature(feature) ? <>{children}</> : null;

export default FeatureGuard;
