import { Grid, Skeleton, Typography, Zoom } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC } from 'react';

import { useBulkUploadSheetValidation, useSheetReader, WorksheetGeneral } from '../../hooks';
import { FileUpload, FileUploadProps } from '../FileUpload';
import { BulkUploadPreview } from '../Tables';

const tExampleSheet = 'Download Template';
const tErrorText = 'Errors In Sheet';

const DOWNLOAD_LINK = '/assets/bulk_upload_template.xlsx';

const LoadingIndicator = styled(Skeleton)(({ theme }) => ({
  border: `1px solid ${theme.palette.crowdCoursing.ORANGE['100']?.main ?? 'initial'}`,
  height: '397px',
  position: 'absolute',
  width: '100%',
  zIndex: 1,
}));

export interface BulkUploadProps {
  schools?: string[];
  onSubmit: (worksheet?: WorksheetGeneral) => FileUploadProps['onSubmit'];
}

export const BulkUpload: FC<BulkUploadProps> = ({ onSubmit, schools }) => {
  const {
    isLoading,
    error: sheetReaderError,
    onFilesReceived,
    worksheets,
  } = useSheetReader({
    emptyCellDefaultValue: '',
    hasHeaderRow: true,
    rowFormat: 1,
    shouldIncludeBlankRows: false,
  });

  const worksheet = worksheets ? (worksheets[0] as WorksheetGeneral) : undefined;

  // todo: lift validation logic out of bulk upload component
  const { errors: validationErrors } = useBulkUploadSheetValidation({ schools, worksheet });

  return (
    <FileUpload
      acceptFileTypes="spreadsheet"
      error={validationErrors.length > 0 ? { errors: validationErrors, text: tErrorText } : undefined}
      example={{ link: DOWNLOAD_LINK, name: tExampleSheet }}
      fullWidth
      isLoading={isLoading}
      onChange={onFilesReceived}
      onSubmit={onSubmit(worksheet)}
    >
      <Grid container marginTop="2rem" maxWidth="100%" position="relative">
        {isLoading && <LoadingIndicator variant="rectangular" />}
        {sheetReaderError && <Typography>{sheetReaderError}</Typography>}
        {!sheetReaderError && !isLoading && !worksheet && (
          <Grid alignContent="center">
            <Typography color="#FF3616" variant="h6">
              Upload template to generate preview
            </Typography>
          </Grid>
        )}
        {!sheetReaderError && (
          <Zoom in={!isLoading && !!worksheet}>
            <Grid container direction="row">
              <BulkUploadPreview worksheet={worksheet} />
            </Grid>
          </Zoom>
        )}
      </Grid>
    </FileUpload>
  );
};
