import { TextField } from '../../../atoms/Form';
import { ConstructedTextField } from '../types';

export const FIELD_NAME_ZIPCODE = 'zipCode';

const tLabel = 'Zipcode';
const tRequired = 'Zipcode is required';
const tLength = 'Zipcode must be 5 digits, e.g. 12345';
const tType = 'Zipcode may only contain numbers';

export type ZipcodeProps = ConstructedTextField & { required?: boolean };

export const Zipcode = ({ required = true, textFieldProps, ...props }: ZipcodeProps) => {
  return (
    <TextField
      name={FIELD_NAME_ZIPCODE}
      textFieldProps={{
        'data-cy': 'form-field-zipcode',
        inputProps: { maxLength: 5, minLength: 0 },
        label: tLabel,
        required,
        ...textFieldProps,
      }}
      type="tel"
      validate={(value, _, meta) => {
        if (meta?.dirty) {
          if (required && !value) {
            return tRequired;
          }

          if (value?.split('').some((character) => isNaN(Number(character)))) {
            return tType;
          }

          if (value && (value.length > 5 || value.length < 5)) {
            return tLength;
          }
        }

        return '';
      }}
      {...props}
    />
  );
};
