import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useDispatch } from '../../hooks';
import { useEventEventCheckIn } from '../../services/openapi/generated/CrowdCoursingComponents';
import { Route } from '../../services/router/Route';
import { successToast } from '../../state/notifications';

const tCheckingIn = 'Checking in...';
const tCheckInSuccess = 'You have been checked in';

/** checks volunteers into the given room */
export const CheckIn = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mutateAsync } = useEventEventCheckIn();
  useEffect(() => {
    mutateAsync({ pathParams: { eventId: eventId! } }).then(() => {
      navigate(Route.eventView({ eventId: eventId! }));
      dispatch(successToast({ message: tCheckInSuccess }));
    });
  }, [eventId, navigate]);

  return <>{tCheckingIn}</>;
};
