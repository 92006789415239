import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';

import { useSelector } from '../../hooks';
import { useRoomDeleteRoom as useRoomDeletePod } from '../../services/openapi/generated/CrowdCoursingComponents';
import { RoomDto } from '../../services/openapi/generated/CrowdCoursingSchemas';

interface DeletePodContentProps {
  handleClose: () => void;
  roomId: string;
  podName?: string;
}

const tTitle = 'Delete $podName?';
const tBody =
  'You are about to delete $podName and all associated events. This action cannot be undone. Enter "confirm" in the text field below if you wish to continue.';
const tConfirmation = 'confirm';
const tDelete = 'confirm';
const tCancel = 'Cancel';
const tYourPod = 'Your Room';

const DeletePodContent: React.FC<DeletePodContentProps> = ({ handleClose, roomId, podName = tYourPod }) => {
  const [value, setValue] = React.useState<string>('');
  const userId = useSelector((state) => state.user?.id);
  const queryClient = useQueryClient();
  const queryKey = ['api', 'v1', 'Pod', 'my-pods', userId!];

  const { mutate } = useRoomDeletePod({
    onError: (_, __, context) =>
      queryClient.setQueryData(queryKey, (context as Record<'previous', RoomDto[]>).previous),
    onMutate: async (variables) => {
      await queryClient.cancelQueries({ queryKey });
      const previous = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, (current?: RoomDto[]) =>
        current?.filter((room) => room.id !== variables.pathParams.roomId)
      );
      return { previous };
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey }),
  });

  const isDisabled = !value || value.toLowerCase() !== tDelete.toLowerCase();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleClickDelete = () => {
    mutate({ pathParams: { roomId } });
    handleClose();
  };

  return (
    <>
      <DialogTitle id="alert-dialog-slide-title">{tTitle.replace('$podName', podName)}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">{tBody.replace('$podName', podName)}</DialogContentText>
        <TextField onChange={handleChange} placeholder={tConfirmation} sx={{ margin: '2rem 0' }} value={value} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{tCancel}</Button>
        <Button color="error" disabled={isDisabled} onClick={handleClickDelete}>
          {tDelete}
        </Button>
      </DialogActions>
    </>
  );
};

export default DeletePodContent;
