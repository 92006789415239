import { Grid, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { savePicture, updatePod } from '../../actions/pods';
import { PictureUpload } from '../../cc-ui';
import { useRoomImageUrl } from '../../hooks';
import { useDispatch } from '../../hooks/useTypedRedux';
import { useRoomEditableQuery } from '../../services/graphql/generated/react-query';
import { Form, FormProps, SubmitButton, SwitchValue, TextFieldValue } from '../../ui/atoms/Form';
import {
  Description,
  FIELD_NAME_DESCRIPTION,
  FIELD_NAME_IS_AUTOJOIN,
  FIELD_NAME_IS_SEARCHABLE,
  FIELD_NAME_ROOM_NAME,
  FIELD_NAME_ROOM_OWNER_NAME,
  FIELD_NAME_ZIPCODE,
  IsAutojoin,
  IsSearchable,
  RoomName,
  Zipcode,
} from '../../ui/molecules/Form';

const tUpdateRoomTitle = 'Update Room';
const tUpdateRoomButton = 'Update';
const tRequiredField = '** Required Fields';

interface FormValues {
  [FIELD_NAME_DESCRIPTION]: TextFieldValue;
  [FIELD_NAME_IS_AUTOJOIN]: SwitchValue;
  [FIELD_NAME_IS_SEARCHABLE]: SwitchValue;
  [FIELD_NAME_ROOM_NAME]: TextFieldValue;
  [FIELD_NAME_ROOM_OWNER_NAME]: TextFieldValue;
  [FIELD_NAME_ZIPCODE]: TextFieldValue;
}

const requiredFields: Array<keyof FormValues> = ['description', 'roomName', 'zipCode'];

interface UpdatePodProps {
  id: string;
  ownerId: string;
  name: string;
  zipCode: string;
  description: string;
  isPublic: boolean;
  isSearchable: boolean;
}

const UpdatePod = ({
  description: initialDescription,
  id: roomId,
  isPublic: initialIsPublic,
  isSearchable: initialIsSearchable,
  name: initialName,
  ownerId: facilitatorId,
  zipCode: initialZipcode,
}: UpdatePodProps) => {
  const dispatch = useDispatch();
  const roomUrl = useRoomImageUrl(roomId);

  const handleSubmit = useCallback<FormProps<FormValues>['onSubmit']>(
    ({ description, isAutojoin, isSearchable, roomName: name, zipCode }) => {
      dispatch(
        updatePod({
          description,
          facilitatorId,
          isPublic: Boolean(isAutojoin),
          isSearchable: Boolean(isSearchable),
          name,
          roomId,
          zipCode,
        })
      );
    },
    []
  );

  const onPictureChange = useCallback(
    (roomPicture: File) => {
      dispatch(savePicture({ formFile: roomPicture, roomId }));
    },
    [dispatch, roomId]
  );

  return (
    <div data-testid="UpdatePod">
      <Grid alignItems="center" container direction="column" item justifyContent="center" justifyItems="center">
        <Typography variant="h4">{tUpdateRoomTitle}</Typography>
        <PictureUpload imageUrl={roomUrl} name="" onChange={onPictureChange} />
      </Grid>
      <Grid alignItems="center" container direction="column" justifyContent="center">
        <Form onSubmit={handleSubmit}>
          <Grid container marginBottom="2rem" spacing={3}>
            <Grid item md={6} xs={12}>
              <RoomName initialValue={initialName} />
            </Grid>
            <Grid alignItems="center" container direction="row" item md={6} spacing={5} xs={12}>
              <Grid container item justifyContent="center" md={6} xs={12}>
                <IsAutojoin initialValue={initialIsPublic ? [FIELD_NAME_IS_AUTOJOIN] : undefined} useDefault={false} />
              </Grid>
              <Grid container item justifyContent="center" md={6} xs={12}>
                <IsSearchable initialValue={initialIsSearchable ? [FIELD_NAME_IS_SEARCHABLE] : undefined} />
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              <Zipcode initialValue={initialZipcode} />
            </Grid>
            <Grid item xs={12}>
              <Description initialValue={initialDescription} required />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">{tRequiredField}</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid alignItems="flex-end" container direction="column">
              <Grid item xs={6}>
                <SubmitButton data-testid="create-room-button" requiredFields={requiredFields}>
                  {tUpdateRoomButton}
                </SubmitButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </Grid>
    </div>
  );
};

const UpdatePodContainer = () => {
  const { roomId } = useParams();
  const { data, isLoading } = useRoomEditableQuery({ id: roomId! });
  if (isLoading || !data) {
    return null;
  }

  return <UpdatePod {...data.room!} ownerId={data.room!.owner.id} />;
};

export default UpdatePodContainer;
