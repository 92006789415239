import { Button, CardContent, Grid, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useFormState } from 'react-final-form';
import { useLocation } from 'react-router-dom';

import { LoginActions } from '../../actions/login';
import { useDispatch, useSelector } from '../../hooks/useTypedRedux';
import { Form, FormProps } from '../../ui/atoms/Form';
import {
  EmailOrPhone,
  FIELD_NAME_EMAIL_OR_PHONE,
  FIELD_NAME_TERMS_OF_SERVICE,
  TermsOfService,
} from '../../ui/molecules/Form';
import FeatureGuard from '../FeatureGuard';
import Logo from '../Logo/Logo';
import { Card, Header } from './Login.styles';
import { LoginAsTestUserButton } from './LoginAsTestUserButton';
import { LoginVideo } from './LoginVideo';

const tSignIn = 'Sign In';
const tSignInDescription =
  '** If you do not already have an account, one will be created for you upon entering your information.';

interface LoginFormValues {
  [FIELD_NAME_EMAIL_OR_PHONE]: string;
  [FIELD_NAME_TERMS_OF_SERVICE]: string[] | undefined;
}

const LoginFormContent = () => {
  // Dirty workaround; please don't remove disabledOnClick.
  // It fixes a weird bug where folks weren't getting passwords and the login test was failing intermittently
  let disabledOnClick = false;
  const { hasValidationErrors, values } = useFormState<LoginFormValues>();
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <EmailOrPhone />
      </Grid>
      <Grid container direction="row" item>
        <Grid alignItems="center" container item lg={6} xs={12}>
          <TermsOfService />
        </Grid>
        <Grid
          container
          item
          justifyContent="flex-end"
          lg={6}
          marginTop={(theme) => (theme.breakpoints.down('sm') ? '0rem' : '1rem')}
          xs={12}
        >
          <Button
            data-cy="loginButton"
            disabled={disabledOnClick || hasValidationErrors || !values[FIELD_NAME_TERMS_OF_SERVICE]}
            onClick={() => (disabledOnClick = true)}
            size="large"
            type="submit"
          >
            {tSignIn}
          </Button>
        </Grid>
      </Grid>
      <FeatureGuard feature="loginAsTestUser">
        <Grid item>
          <LoginAsTestUserButton />
        </Grid>
      </FeatureGuard>
    </Grid>
  );
};

export const Login = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.auth);

  const handleSubmit = useCallback<FormProps<LoginFormValues>['onSubmit']>((values) => {
    if (!isLoading) {
      const { emailOrPhone, termsOfService } = values;
      if (termsOfService && emailOrPhone) {
        dispatch(LoginActions.login(emailOrPhone, location.state));
      }
    }
  }, []);

  return (
    <Grid
      alignContent="center"
      alignItems="center"
      container
      direction="column"
      justifyContent="center"
      justifyItems="center"
    >
      <Grid alignContent="center" container item justifyContent="center" marginBottom="3rem" width="100%" xs={12}>
        <Logo />
      </Grid>
      <Grid container direction="row" item width="100%" xs={12}>
        <Card elevation={2}>
          <CardContent>
            <Grid container direction="row" item xs={12}>
              <Grid marginBottom="2em">
                <Header color="#359dd8" variant="h2">
                  {tSignIn}
                </Header>
              </Grid>
            </Grid>
            <Form<LoginFormValues> onSubmit={handleSubmit}>
              <LoginFormContent />
            </Form>
            <Typography variant="body2">{tSignInDescription}</Typography>
          </CardContent>
        </Card>
        <Grid container direction="row" item marginTop="1em" minHeight="500px" width="100%" xs={12}>
          <LoginVideo />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
