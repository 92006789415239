import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@mui/material';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';

export type TextFieldValue = string | undefined;

type ModifiedTextFieldProps = Omit<
  MuiTextFieldProps,
  'onChange' | 'onBlur' | 'onFocus' | 'name' | 'value' | 'checked' | 'type' | 'multiple' | 'fullWidth'
> & { 'data-cy'?: string; 'data-testid'?: string }; // TODO: stop using data-cy, data-testid is preferred

export interface TextFieldProps extends FieldProps<TextFieldValue, FieldRenderProps<TextFieldValue>> {
  textFieldProps?: ModifiedTextFieldProps;
}

export const TextField = ({
  textFieldProps: { helperText, ...textFieldProps } = { helperText: '' },
  ...props
}: TextFieldProps) => {
  return (
    <Field
      render={({ input, meta: { error } }) => {
        return (
          <MuiTextField
            error={Boolean(error)}
            fullWidth
            helperText={error || helperText}
            required={props.required}
            {...textFieldProps}
            {...input}
          />
        );
      }}
      {...props}
    />
  );
};
