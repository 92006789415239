import { createTheme } from '@mui/material';
import { CcColors } from '@mui/material/styles/createPalette';

const DEFAULT_COLOR = 'initial';
const MENU_ITEM_FOCUS_VISIBLE_CLASSNAME = 'MuiMenuItem-focus-visible';

/*
    https://convertacolor.com/

    `dark`:  - 5-6%L
    `light`: + 5-6%L
*/

const Color: CcColors = {
  BLACK: {
    100: { main: '#373737' },
    200: { main: '#272727' },
    300: { main: '#171717' },
    400: { main: '#070707' },
    500: { main: '#000000' },
  },
  BLUE: {
    100: { light: '#56C3EC', lighter: '#71CDEF', main: '#3AB9E9' },
    200: { main: '#18A3D8' },
    300: {
      dark: '#106B8F',
      main: '#1380AA' /* CrowdCoursing Blue; primary #359DD8 */,
    },
    400: { main: '#0E5E7C' },
    500: { main: '#093B4E' },
  },
  GREEN: {
    100: { main: '#D8F8AA' },
    200: { main: '#C1F17C' },
    300: { main: '#AFE958' /* success */ },
    400: { main: '#93D335' },
    500: { main: '#72B116' },
  },
  GREY: {
    100: { main: '#FAFAFA' },
    200: { main: '#F5F5F5' },
    300: { main: '#EEEEEE' },
    400: { main: '#E0E0E0' },
    500: { main: '#BDBDBD' },
    600: { main: '#9E9E9E' },
    700: { main: '#757575' },
    800: { main: '#616161' },
    900: { main: '#424242' },
  },
  ORANGE: {
    100: { light: '#FFEBD6', lighter: '#FFFAF5', main: '#FFDCB8' },
    200: { light: '#FFD2A3', main: '#FFC487' },
    300: { main: '#FFAF60' /* secondary */ },
    400: { main: '#FF9F3D' },
    500: { light: '#FF9A33', main: '#FF8C16' },
  },
  PURPLE: {
    100: { main: '#BBC7F7' },
    200: { main: '#8A9DEC' },
    300: { main: '#6A7EDF' /* info */ },
    400: { main: '#4560CF' },
    500: { main: '#2543BD' },
  },
  RED: {
    100: { main: '#FFC2B8' },
    200: { main: '#FF9787' },
    300: { main: '#FF7460' /* danger */ },
    400: { main: '#FF573D' },
    500: { main: '#FF3616' },
  },
  WHITE: {
    100: { main: '#FFFFFF' },
    200: { main: '#FEFEFE' },
    300: { main: '#FDFDFD' },
    400: { main: '#FCFCFC' },
    500: { main: '#FBFBFB' },
  },
  YELLOW: {
    100: { main: '#FFEAB8' },
    200: { main: '#FFDB87' },
    300: { main: '#FFCE60' /* warn */ },
    400: { main: '#FFC53D' },
    500: { main: '#FFB916' },
  },
};

const getAvatarColors = () =>
  [
    {
      backgroundColor: Color.BLUE['400']?.main ?? 'inherit',
      chars: 'ab',
      color: Color.WHITE['200']?.main ?? 'inherit',
    },
    {
      backgroundColor: Color.GREEN['400']?.main ?? 'inherit',
      chars: 'cd',
      color: Color.WHITE['200']?.main ?? 'inherit',
    },
    {
      backgroundColor: Color.ORANGE['400']?.main ?? 'inherit',
      chars: 'ef',
      color: Color.WHITE['200']?.main ?? 'inherit',
    },
    {
      backgroundColor: Color.PURPLE['400']?.main ?? 'inherit',
      chars: 'gh',
      color: Color.WHITE['200']?.main ?? 'inherit',
    },
    { backgroundColor: Color.RED['400']?.main ?? 'inherit', chars: 'jk', color: Color.WHITE['200']?.main ?? 'inherit' },
    {
      backgroundColor: Color.YELLOW['400']?.main ?? 'inherit',
      chars: 'lm',
      color: Color.WHITE['200']?.main ?? 'inherit',
    },
    {
      backgroundColor: Color.BLUE['500']?.main ?? 'inherit',
      chars: 'no',
      color: Color.WHITE['200']?.main ?? 'inherit',
    },
    {
      backgroundColor: Color.GREEN['500']?.main ?? 'inherit',
      chars: 'pq',
      color: Color.WHITE['200']?.main ?? 'inherit',
    },
    {
      backgroundColor: Color.ORANGE['500']?.main ?? 'inherit',
      chars: 'rs',
      color: Color.WHITE['200']?.main ?? 'inherit',
    },
    {
      backgroundColor: Color.PURPLE['500']?.main ?? 'inherit',
      chars: 'tu',
      color: Color.WHITE['200']?.main ?? 'inherit',
    },
    { backgroundColor: Color.RED['500']?.main ?? 'inherit', chars: 'vw', color: Color.WHITE['200']?.main ?? 'inherit' },
    {
      backgroundColor: Color.YELLOW['500']?.main ?? 'inherit',
      chars: 'xy',
      color: Color.WHITE['200']?.main ?? 'inherit',
    },
    { backgroundColor: Color.BLUE['400']?.main ?? 'inherit', chars: 'z', color: Color.WHITE['200']?.main ?? 'inherit' },
  ]
    .map(({ chars, backgroundColor, color }) =>
      chars
        .split('')
        .map((char) => ({
          [`&[data-content^=${char.toLocaleUpperCase()}]`]: { backgroundColor, color },
        }))
        .reduce((a, b) => ({ ...a, ...b }), {})
    )
    .reduce((a, b) => ({ ...a, ...b }), {});

const theme = createTheme({
  breakpoints: {
    values: {
      lg: 992,
      md: 768,
      sm: 576,
      xl: 1200,
      xs: 0,
    },
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: {
          ...getAvatarColors(),
        },
      },
    },
    MuiButton: {
      defaultProps: {
        color: 'primary',
        variant: 'contained',
      },
      styleOverrides: {
        root: { textTransform: 'none' },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        svg: {
          '&:focus': {
            outline: 'unset',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paperWidthSm: {
          minWidth: '375px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          backgroundColor: Color.GREY[100]?.main ?? DEFAULT_COLOR,
          padding: '16px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '32px 16px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: Color.GREY[100]?.main ?? DEFAULT_COLOR,
          padding: '16px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 'unset',
          marginRight: 'unset',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          padding: '0.5rem',
        },
        paper: {
          margin: '0.25rem 0',
        },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        focusVisibleClassName: MENU_ITEM_FOCUS_VISIBLE_CLASSNAME,
      },
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: Color.BLUE[100]?.lighter ?? DEFAULT_COLOR,
          },

          color: Color.BLUE[500]?.main ?? DEFAULT_COLOR,
          margin: '0 -0.5rem',
          minHeight: '32px',

          [`&.${MENU_ITEM_FOCUS_VISIBLE_CLASSNAME}`]: {
            backgroundColor: Color.BLUE[100]?.lighter ?? DEFAULT_COLOR,
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
      styleOverrides: {
        select: {
          '&:focus': {
            backgroundColor: Color.BLUE[100]?.lighter ?? DEFAULT_COLOR,
          },
        },
      },
    },
    MuiSkeleton: {
      defaultProps: { animation: 'wave', width: '100%' },
      styleOverrides: {
        root: {
          backgroundColor: Color.ORANGE[100]?.lighter ?? DEFAULT_COLOR,
          border: `1px solid ${Color.ORANGE[100]?.light ?? DEFAULT_COLOR}`,
          borderRadius: '4px',
          transform: 'unset',
        },
        wave: {
          '::after': {
            // rgb value is ORANGE['100'].light
            background: `linear-gradient(90deg, transparent, rgba(255,235,214,0.4), transparent)`,
          },
        },
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          border: `1px solid ${Color.GREY[200]?.main ?? 'transparent'}`,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${Color.ORANGE[100]?.light ?? 'default'}`,
          padding: '12px',

          // eslint-disable-next-line sort-keys-fix/sort-keys-fix
          '&:empty': {
            borderBottom: `1px solid ${Color.GREY[200]?.main ?? 'transparent'}`,
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        displayedRows: {
          padding: '0 32px',
        },
        root: {
          border: 'unset',
        },
        spacer: {
          flex: 'unset',
        },
        toolbar: {
          justifyContent: 'center',
          padding: '0',

          // eslint-disable-next-line sort-keys-fix/sort-keys-fix
          '>': {
            '.MuiIconButton-root': {
              color: Color.ORANGE[500]?.main ?? 'default',

              // eslint-disable-next-line sort-keys-fix/sort-keys-fix
              '&:disabled': {
                color: Color.ORANGE[100]?.main ?? 'default',
              },
            },
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: `${Color.ORANGE[100]?.main ?? 'default'}`,

            // eslint-disable-next-line sort-keys-fix/sort-keys-fix
            '&:hover': {
              backgroundColor: `${Color.ORANGE[100]?.lighter ?? 'default'}`,
            },
          },

          '&.MuiTableRow-hover': {
            '&:hover': {
              backgroundColor: `${Color.ORANGE[100]?.lighter ?? 'default'}`,
            },
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
      styleOverrides: {
        arrow: {
          color: Color.GREY[700]?.main ?? DEFAULT_COLOR,
        },
        tooltip: {
          backgroundColor: Color.GREY[700]?.main ?? DEFAULT_COLOR,
          color: Color.WHITE[300]?.main ?? DEFAULT_COLOR,
          fontSize: '0.75rem',
          padding: '0.5rem 1rem',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: ['Century Gothic', 'CenturyGothic', 'AppleGothic', 'sans-serif'],
        },
      },
    },
  },
  palette: {
    background: {
      default: Color.WHITE[200]?.main ?? DEFAULT_COLOR,
      paper: Color.WHITE[200]?.main ?? DEFAULT_COLOR,
    },
    crowdCoursing: {
      ...Color,
    },
    error: {
      main: Color.RED[300]?.main ?? DEFAULT_COLOR,
    },
    info: {
      main: Color.PURPLE[300]?.main ?? DEFAULT_COLOR,
    },
    primary: {
      contrastText: Color.WHITE[100]?.main ?? DEFAULT_COLOR,
      dark: Color.BLUE[300]?.dark ?? DEFAULT_COLOR,
      main: Color.BLUE[300]?.main ?? DEFAULT_COLOR,
    },
    secondary: {
      main: Color.ORANGE[300]?.main ?? DEFAULT_COLOR,
    },
    success: {
      main: Color.GREEN[300]?.main ?? DEFAULT_COLOR,
    },
    warning: {
      main: Color.YELLOW[300]?.main ?? DEFAULT_COLOR,
    },
  },
  spacing: 8,
});

export default theme;
