import { isTokenExpired } from '../utilities/utils';
import { useSelector } from './useTypedRedux';

/**
 * Check if someone is logged in, or in the process of logging in
 */
export const useIsLoggedIn = (): boolean => {
  const token = useSelector((state) => state.auth.token);
  return token !== null && !isTokenExpired(token);
};
