import { Button, Stack, Typography } from '@mui/material';
import { useMemo, VFC } from 'react';

import { SeriesForm } from './SeriesForm';
import { Series } from './SeriesList';

const tCreate = 'Create';
const tUpdate = 'Update';
const tDeleteWithName = (name: string) => `Delete Series "${name}"`;
const tCancel = 'Cancel';
const tSeriesForm = 'series-form';
const tDeleteSpiel = 'Deleting a series does not affect any associated meeting times or RSVPs.';
/** SeriesDetails is a state machine, these are the states */
export type DetailsState = 'CREATE' | 'READ' | 'UPDATE' | 'DELETE';

export interface SeriesDetailsProps {
  onSubmit: (s: Partial<Series>) => void;
  onCancel: () => void;
  series?: Series;
  state: DetailsState;
}

export const SeriesDetails: VFC<SeriesDetailsProps> = ({ onCancel, onSubmit, series, state }) => {
  const memoized = useMemo(() => {
    switch (state) {
      case 'CREATE':
        return {
          formProps: {
            nameProps: { required: true },
            onSubmit,
          },
          submitText: tCreate,
        };
      case 'READ':
        return {
          formProps: {
            descriptionProps: { disabled: true, value: series?.description },
            nameProps: { disabled: true, value: series?.name },
          },
        };
      case 'UPDATE':
        return {
          formProps: {
            descriptionProps: {
              InputLabelProps: { shrink: true },
              defaultValue: series?.description,
            },
            nameProps: {
              InputLabelProps: { shrink: true },
              defaultValue: series?.name,
              required: true,
            },
            onSubmit,
          },
          submitText: tUpdate,
        };
      default:
        return { formProps: {} };
    }
  }, [series, state, onSubmit]);

  if (state === 'DELETE' && series) {
    return (
      <>
        <Typography>{tDeleteSpiel}</Typography>
        <Stack direction="row" justifyContent={'flex-end'} spacing={2}>
          <Button onClick={onCancel} sx={{ width: 'fit-content' }} variant="text">
            {tCancel}
          </Button>
          <Button data-testid="delete-button" onClick={() => onSubmit(series)} sx={{ width: 'fit-content' }}>
            {tDeleteWithName(series.name)}
          </Button>
        </Stack>
      </>
    );
  }

  return (
    <>
      <SeriesForm {...memoized.formProps} />
      {['CREATE', 'UPDATE'].includes(state) && (
        <Stack direction="row" justifyContent={'flex-end'} spacing={2}>
          <Button onClick={onCancel} sx={{ width: 'fit-content' }} variant="text">
            {tCancel}
          </Button>
          <Button data-testid="create-or-update-button" form={tSeriesForm} sx={{ width: 'fit-content' }} type="submit">
            {memoized.submitText}
          </Button>
        </Stack>
      )}
    </>
  );
};
