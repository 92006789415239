import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { useCallback } from 'react';

import {
  Date,
  DateValue,
  FIELD_NAME_DATE,
  Form,
  FormProps,
  ResetButton,
  SubmitButton,
  TextFieldValue,
} from '../../../ui/atoms/Form';
import { Description, FIELD_NAME_DESCRIPTION, FIELD_NAME_NAME, Name } from '../../../ui/molecules/Form';

const tCancel = 'Cancel';

const today = dayjs().startOf('day');

const requiredFields = [FIELD_NAME_DATE, FIELD_NAME_DESCRIPTION, FIELD_NAME_NAME];

interface FormValues {
  [FIELD_NAME_NAME]: TextFieldValue;
  [FIELD_NAME_DATE]: DateValue;
  [FIELD_NAME_DESCRIPTION]: TextFieldValue;
}

export interface EditProps {
  onSubmit: (formValues: { date: Date; name: string; description: string }) => void;
  onCancel: () => void;
}

export const Edit = ({ onCancel, onSubmit }: EditProps) => {
  const handleSubmit = useCallback<FormProps<FormValues>['onSubmit']>(
    ({ name, date, description }) => {
      if (date && description && name) {
        onSubmit({ date: date.toDate(), description, name });
        onCancel();
      }
    },
    [onSubmit]
  );

  return (
    <Form<FormValues>
      onSubmit={handleSubmit}
      slotProps={{
        renderContainer: { display: 'grid', gap: '2rem', gridAutoColumns: 'minmax(0, auto)', gridAutoFlow: 'row' },
      }}
    >
      <Name required />
      <Date datePickerProps={{ minDate: today }} required />
      <Description required />
      <Box display="flex" gap="1rem" justifyContent="end">
        <ResetButton>{tCancel}</ResetButton>
        <SubmitButton requiredFields={requiredFields} />
      </Box>
    </Form>
  );
};
