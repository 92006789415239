import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { loginWithToken } from '../../actions/login';
import { useDispatch } from '../../hooks/useTypedRedux';
import { Route } from '../../router';
import { EndToEndService } from '../../services/ApiService';

export const LoginAsTestUserButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onClick = async () => {
    const result = await EndToEndService.seedDb();
    await loginWithToken(result.existingUserJwt || '', dispatch);
    navigate(Route.HOME);
  };
  return (
    <Button color="secondary" fullWidth onClick={onClick} size="large">
      <span aria-label="danger" role="img">
        🛑
      </span>{' '}
      Login as a test user
    </Button>
  );
};
