import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { FC } from 'react';

export interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
}

export const TablePaginationActions: FC<TablePaginationActionsProps> = ({ count, page, rowsPerPage, onPageChange }) => {
  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => onPageChange(event, 0);
  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => onPageChange(event, page - 1);
  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => onPageChange(event, page + 1);
  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

  return (
    <>
      <IconButton aria-label="first page" disabled={page === 0} onClick={handleFirstPageButtonClick} size="small">
        <FirstPage />
      </IconButton>
      <IconButton aria-label="previous page" disabled={page === 0} onClick={handleBackButtonClick} size="small">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        aria-label="next page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleNextButtonClick}
        size="small"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        aria-label="last page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleLastPageButtonClick}
        size="small"
      >
        <LastPage />
      </IconButton>
    </>
  );
};

export default TablePaginationActions;
