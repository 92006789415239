import { createSlice } from '@reduxjs/toolkit';

import { loadProfileOptionStates } from '../actions/profile';
import { ProfileOptions } from './state';

const initialState: ProfileOptions = {
  states: [],
};

const slice = createSlice({
  extraReducers: (builder) =>
    builder.addCase(loadProfileOptionStates.fulfilled, (state, action) => {
      state.states = action.payload;
    }),
  initialState,
  name: 'formOptions',
  reducers: {},
});

export const profileOptionsReducer = slice.reducer;
