import { createContext, useContext } from 'react';

import { useStepper } from './useStepper';

export type ResumeWizardContextValues = ReturnType<typeof useStepper>;

const initialContext: ResumeWizardContextValues = {
  activeStep: 0,
  isFirstStep: true,
  isLastStep: false,
  onClickNext: () => {},
  onClickPrevious: () => {},
};

const ResumeWizardContext = createContext<ResumeWizardContextValues>(initialContext);

export function ResumeWizardProvider({ children }: React.PropsWithChildren) {
  return <ResumeWizardContext.Provider value={useStepper(6)}>{children}</ResumeWizardContext.Provider>;
}

export function useResumeWizard() {
  return useContext(ResumeWizardContext);
}
