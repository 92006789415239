import { Checkbox, CheckboxProps } from '../../../atoms/Form';

export const FIELD_NAME_TERMS_OF_SERVICE = 'termsOfService';

const tTos1 = 'I accept the ';
const tTos2 = 'Terms of Use';

const tRequired = 'Must Accept Terms of Use';

const tosLink = 'https://drive.google.com/drive/folders/16rRco74DP3DIpCKNsYIk6KoiTTYQz5c1?usp=drive_link';

const CheckboxLabel = () => (
  <>
    {tTos1}
    <a href={tosLink} rel="noopener noreferrer" target="_blank">
      {tTos2}
    </a>
  </>
);

export type TermsOfServiceProps = Omit<CheckboxProps, 'checkboxes'>;

export const TermsOfService = ({ formControlProps, ...props }: TermsOfServiceProps) => {
  return (
    <Checkbox
      checkboxes={[
        {
          checkboxProps: { 'data-cy': 'form-field-terms-of-service-checkbox', name: 'tosCheckbox' },
          formControlLabelProps: { label: <CheckboxLabel /> },
        },
      ]}
      formControlProps={{ ...formControlProps, required: true }}
      name={FIELD_NAME_TERMS_OF_SERVICE}
      validate={(value, _, meta) => {
        if (meta?.modified && !value) {
          return tRequired;
        }

        return '';
      }}
      {...props}
    />
  );
};
