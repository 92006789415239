import { Select } from '../../../atoms/Form';
import { ConstructedSelect } from '../types';

export const FIELD_NAME_EVENT_SERIES_SELECT = 'eventSeriesSelect';

const tLabel = 'Event Series (optional)';
const tHelper = 'Add a series to event times';
const tRequired = 'Event series is required';

export type EventSeriesSelectProps = ConstructedSelect;

export const EventSeriesSelect = ({ required, selectItems, selectProps, ...props }: EventSeriesSelectProps) => {
  return (
    <Select
      helperText={tHelper}
      label={tLabel}
      name={FIELD_NAME_EVENT_SERIES_SELECT}
      selectItems={selectItems}
      selectProps={{ 'data-testid': 'form-field-event-series-select', required, ...selectProps }}
      validate={(value, _, meta) => {
        if (meta?.modified && required && !value) {
          return tRequired;
        }

        return '';
      }}
      {...props}
    />
  );
};
