import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@mui/material';
import { useCallback } from 'react';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';

// https://github.com/SUI-Components/sui-components/pull/2354/files#diff-1b53cc2617035418d9525e8ef47df498e5c086f8c089bc877d7f6a82a29a18cc
const allowedCharacter = /(^\d*$)|(^\d*\.{1}\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/;

export type NumberFieldValue = number | undefined;

type ModifiedTextFieldProps = Omit<
  MuiTextFieldProps,
  'onChange' | 'onBlur' | 'onFocus' | 'name' | 'value' | 'checked' | 'type' | 'multiple' | 'fullWidth'
>;

export interface NumberFieldProps extends FieldProps<NumberFieldValue, FieldRenderProps<NumberFieldValue>> {
  textFieldProps?: ModifiedTextFieldProps;
}

export const NumberField = ({
  textFieldProps: { helperText, ...textFieldProps } = { helperText: '' },
  ...props
}: NumberFieldProps) => {
  return (
    <Field<NumberFieldValue>
      render={({ input, meta: { error } }) => {
        const handleKeyDown = useCallback(function (event: React.KeyboardEvent<HTMLInputElement>) {
          return !event.key.match(allowedCharacter) && event.preventDefault();
        }, []);

        return (
          <MuiTextField
            error={Boolean(error)}
            fullWidth
            helperText={error || helperText}
            required={props.required}
            type="number"
            {...textFieldProps}
            {...input}
            inputProps={{ inputMode: 'numeric', numeric: 'true', ...textFieldProps.inputProps }}
            onKeyDown={handleKeyDown}
          />
        );
      }}
      type="number"
      {...props}
      parse={function (value, name) {
        if (isNaN(Number(value)) || !value) {
          return undefined;
        }

        const formatted = Number(value);
        return props?.parse?.(formatted, name) ?? formatted;
      }}
    />
  );
};
