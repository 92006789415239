import { useState } from 'react';
import type { Sheet2JSONOpts, WorkBook } from 'xlsx';
const KnownErrors = {
  EXPECTED_EXCEL_FILE_FORMAT: 'Expected file in Microsoft Excel compatible format',
  FILEREADER_API_NOT_AVAILABLE: 'FileReader API not available',
  FILEREADER_READASARRAYBUFFER_FAILED: "Failed to execute 'readAsArrayBuffer' on 'FileReader'",
  FILEREADER_READ_ERROR: 'Error while reading file',
  NO_FILE_DATA: 'No file data received',
  UNKNOWN: 'Unknown FileReader error',
};

export interface OnFilesReceivedOptions {
  files: FileList | null;
  isAcceptedType?: boolean;
}

export interface UseSheetReaderOptions {
  /** Set default value for empty cells */
  emptyCellDefaultValue?: Sheet2JSONOpts['defval'];
  /** Is there a header row? */
  hasHeaderRow?: boolean;
  /**
   *  Header row format options. Only affects output if `hasHeaderRow` true.
   *
   *  @example `number` = 1
   *  options = { headerRow: 1 };
   *
   *  worksheet = {
   *    headers: [ $VALUE_A1, $VALUE_B1, $VALUE_C1 ],
   *    rows: [
   *      [ $VALUE_A2, $VALUE_B2, $VALUE_C2 ],
   *      [ $VALUE_A3, $VALUE_B3, $VALUE_C3 ],
   *         ...
   *      [ $VALUE_AN, $VALUE_BN, $VALUE_CN ],
   *    ],
   *  };
   *
   *  @example `number` = 2
   *  options = { headerRow: 2 };
   *
   *  worksheet = {
   *    headers: { $VALUE_A1: $VALUE_A1, $VALUE_B1: $VALUE_B1, $VALUE_C1: $VALUE_C1 },
   *    rows: [
   *      { $VALUE_A1: $VALUE_A2, $VALUE_B1: $VALUE_B2, $VALUE_C1: $VALUE_C2 },
   *      { $VALUE_A1: $VALUE_A3, $VALUE_B1: $VALUE_B3, $VALUE_C1: $VALUE_C3 },
   *         ...
   *      { $VALUE_A1: $VALUE_AN, $VALUE_B1: $VALUE_BN, $VALUE_C1: $VALUE_CN },
   *    ],
   *  };
   *
   *  @example `A`
   *  options = { headerRow: 'A' };
   *
   *  worksheet = {
   *    headers: { A: $VALUE_A1, B: $VALUE_B1, C: $VALUE_C1 },
   *    rows: [
   *      { A: $VALUE_A2, B: $VALUE_B2, C: $VALUE_C2 },
   *      { A: $VALUE_A3, B: $VALUE_B3, C: $VALUE_C3 },
   *         ...
   *      { A: $VALUE_AN, B: $VALUE_BN, C: $VALUE_CN },
   *    ],
   *  };
   *
   *  @example `string[]`
   *  options = { headerRow: ['one', 'two', 'three'] };
   *
   *  worksheet = {
   *    headers: { one: $VALUE_A1, two: $VALUE_B1, three: $VALUE_C1 },
   *    rows: [
   *      { one: $VALUE_A2, two: $VALUE_B2, three: $VALUE_C2 },
   *      { one: $VALUE_A3, two: $VALUE_B3, three: $VALUE_C3 },
   *         ...
   *      { one: $VALUE_AN, two: $VALUE_BN, three: $VALUE_CN },
   *    ],
   *  };
   */
  rowFormat?: Sheet2JSONOpts['header'];
  /** Should we include blank rows? */
  shouldIncludeBlankRows?: Sheet2JSONOpts['blankrows'];
  /** A minimum loading time is perfect for preventing a potentially jarring loading transition */
  minimumLoadingTime?: number;
}

export interface MappedRow {
  [key: string]: any;
}
export interface WorksheetMapped {
  headers?: MappedRow[];
  rows: MappedRow[];
}

export interface WorksheetGeneral {
  headers?: any[][];
  rows: any[][];
}

export type Worksheet = WorksheetGeneral | WorksheetMapped;

export interface UseSheetReaderReturn {
  error: string;
  isLoading: boolean;
  onFilesReceived: (options: OnFilesReceivedOptions) => void;
  workbook?: WorkBook;
  worksheets?: Worksheet[];
}

type UseSheetReader = (options?: UseSheetReaderOptions) => UseSheetReaderReturn;

export const useSheetReader: UseSheetReader = (options) => {
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [workbook, setWorkbook] = useState<WorkBook | undefined>(undefined);
  const [worksheets, setWorksheets] = useState<Worksheet[] | undefined>(undefined);

  const resetError = () => setError('');
  const resetWorkbook = () => setWorkbook(undefined);
  const resetWorksheets = () => setWorksheets(undefined);

  const parseWorkbook = async (data: string | ArrayBuffer) => {
    const { read, utils } = await import('xlsx');
    try {
      const incomingWorkbook = read(data);
      setWorkbook(incomingWorkbook);
      resetWorksheets();

      incomingWorkbook.SheetNames.forEach((sheetName) => {
        const sheetData = utils.sheet_to_json<Worksheet['rows']>(incomingWorkbook.Sheets[sheetName], {
          blankrows: options?.shouldIncludeBlankRows,
          defval: options?.emptyCellDefaultValue,
          header: options?.rowFormat,
        });

        let worksheet: Worksheet = { rows: sheetData };
        if (options?.hasHeaderRow) {
          const headers = sheetData.shift() ?? [];
          worksheet = { headers: [headers], rows: sheetData };
        }

        setWorksheets((current) => (current ? [...current, worksheet] : [worksheet]));
      });
    } catch (error) {
      setError(KnownErrors.FILEREADER_READ_ERROR);
      console.error(error);
    }
  };

  const onFilesReceived: (options: OnFilesReceivedOptions) => void = ({ files, isAcceptedType = true }) => {
    if (error) {
      resetError();
    }

    if (!isAcceptedType) {
      setError(KnownErrors.EXPECTED_EXCEL_FILE_FORMAT);
      return;
    }

    if (!files) {
      resetWorkbook();
      resetWorksheets();
      return;
    }

    try {
      const reader = new FileReader();

      reader.onerror = () => {
        setError(KnownErrors.UNKNOWN);
      };

      reader.onloadstart = () => {
        setIsLoading(true);
      };

      reader.onloadend = () => {
        if (options?.minimumLoadingTime) {
          setTimeout(() => setIsLoading(false), options.minimumLoadingTime);
          return;
        }

        setIsLoading(false);
      };

      reader.onload = (event) => {
        const data = event.target?.result;
        if (!data) {
          setError(KnownErrors.NO_FILE_DATA);
          return;
        }

        parseWorkbook(data);
      };

      // FileReader finished and there are no files to read - typically caused by clicking cancel from the OS prompt
      if (reader.DONE && files.length === 0) {
        resetWorkbook();
        resetWorksheets();
      }

      // FileReader finished and there are files to read
      if (reader.DONE && files.length > 0) {
        try {
          reader.readAsArrayBuffer(files[0]);
        } catch (error) {
          setError(KnownErrors.FILEREADER_READASARRAYBUFFER_FAILED);
          console.error(error);
        }
      }
    } catch (error) {
      setError(KnownErrors.FILEREADER_API_NOT_AVAILABLE);
      console.error(error);
    }
  };

  return { error, isLoading, onFilesReceived, workbook, worksheets };
};
