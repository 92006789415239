import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { getSelectedPod } from './actions/pods';
import { loadProfileOptionStates } from './actions/profile';
import { LoadingIndicator } from './cc-ui';
import FeatureGuard from './components/FeatureGuard';
import PageDialog from './components/PageDialog/PageDialog';
import PageMenu from './components/PageMenu/PageMenu';
import ToastNotificationsContainer from './components/Shared/ToastNotificationsContainer';
import { useAutoLogout, useDispatch, useIsLoading } from './hooks';
import { setSessionId } from './state/app';
import { AppNavigationLayout } from './ui/pages';

export const AppLayout = () => {
  const { roomId, eventId } = useParams();
  const dispatch = useDispatch();

  const isLoading = useIsLoading();

  useEffect(() => {
    // * prevent drag and drop everywhere in the app
    // * allow drag and drop in `MuiDropzoneArea`s
    const doNothing = (event: any) => {
      const isDropzoneArea = event.target.classList.value.includes('MuiDropzoneArea');
      if (!isDropzoneArea) {
        event.stopPropagation();
        event.preventDefault();
        if (event?.dataTransfer) {
          event.dataTransfer.dropEffect = 'none';
        }
        return false;
      }
    };
    window.addEventListener('dragover', doNothing);
    window.addEventListener('drop', doNothing);

    return () => {
      window.removeEventListener('dragover', doNothing);
      window.removeEventListener('drop', doNothing);
    };
  }, []);

  useEffect(() => {
    dispatch(loadProfileOptionStates());
  }, [dispatch]);

  // setup redux so any future pages/components wanting data from `useSession` have
  // the right stuff.
  //
  // TODO: remove once we use graphql instead of `useSession`
  useEffect(() => {
    dispatch(setSessionId(eventId));
  }, [eventId]);

  // setup redux so any future pages/components wanting data from `usePod` have
  // the right stuff.
  //
  // TODO: remove once we use graphql instead of `usePod`
  useEffect(() => {
    if (roomId) {
      dispatch(getSelectedPod(roomId));
    }
  }, [roomId]);

  useAutoLogout();

  return (
    <>
      <AppNavigationLayout />
      <LoadingIndicator open={isLoading} />
      <PageMenu />
      <PageDialog />
      <ToastNotificationsContainer />
      <FeatureGuard feature="supportChat">
        <TawkMessengerReact propertyId="6261aa957b967b11798bddb9" widgetId="1g16o4m33" />
      </FeatureGuard>
    </>
  );
};
