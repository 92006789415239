import { Button, CircularProgress } from '@mui/material';

export interface ActionButtonProps {
  children?: React.ReactNode;
  isLoading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  'data-testid'?: string;
}

const ActionButton: React.FC<ActionButtonProps> = (props) => {
  const { disabled, isLoading, children, onClick } = props;
  const handleClick = (e: React.MouseEvent) => {
    if (onClick) {
      e.stopPropagation();
      onClick();
    }
  };

  return (
    <Button data-testid={props['data-testid']} disabled={disabled || isLoading} onClick={handleClick}>
      {isLoading ? <CircularProgress disableShrink /> : children}
    </Button>
  );
};

export default ActionButton;
