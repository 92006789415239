import { Grid, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { useSelector } from '../../hooks';
import { DayOfWeek } from '../../services/ApiService';
import {
  RoomEventFieldsQuery,
  useMyBubblesQuery,
  useRoomEventFieldsQuery,
} from '../../services/graphql/generated/react-query';
import { useEventCreateEvent } from '../../services/openapi/generated/CrowdCoursingComponents';
import type * as Schemas from '../../services/openapi/generated/CrowdCoursingSchemas';
import { Route } from '../../services/router/Route';
import { Breadcrumbs } from './Breadcrumbs';
import { EventInput, SessionForm } from './EventForm';

const tCreateSession = 'Create Event';
const tSubtitle = 'Events are activities that take place in your room';

interface CreateSessionProps {
  room?: RoomEventFieldsQuery['room'] & { refetch: () => void };
}

const CreateSession = ({ room }: CreateSessionProps) => {
  const navigate = useNavigate();
  const { data: myBubbles } = useMyBubblesQuery();
  const { mutateAsync: createEvent } = useEventCreateEvent();
  const userId = useSelector((state) => state.user?.id);

  if (!userId) {
    return null;
  }

  if (!myBubbles?.me.bubbles) {
    return null;
  }

  const bubbles = myBubbles.me.bubbles;
  if (!bubbles.length) {
    throw new Error('Cannot create or update event without access to bubbles. How did you get here?');
  }

  const onSubmit = async (s: EventInput) => {
    const req: Schemas.CreateEventRequest = {
      bubbleId: s.bubbleId,
      description: s.description,
      eventRules: s.eventRules,
      isPublic: s.isAutoJoin,
      isSearchable: s.isOnline,
      name: s.name,
      ownerId: userId,
      roomId: room?.id,
    };
    if (s.schedule) {
      req.eventSchedule = {
        beginMeetingTime: s.schedule.beginMeetingTime,
        days: s.schedule.days as DayOfWeek[],
        endDate: s.schedule.endDate,
        endMeetingTime: s.schedule.endMeetingTime,
        meetingTimeCapacity: s.schedule.capacity,
        seriesId: s.schedule.seriesId,
        startDate: s.schedule.beginDate,
      };
    }

    const { id: newEventId } = await createEvent({ body: req });
    if (room?.id) {
      navigate(Route.podView({ roomId: room.id }));
    } else if (newEventId) {
      navigate(Route.eventView({ eventId: newEventId }));
    }
  };

  return (
    <Grid alignItems="center" container direction="row" justifyContent="center" spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5">{tCreateSession}</Typography>
        {room && (
          <>
            <Breadcrumbs name={room.name} roomId={room.id} />
            <Typography marginBottom="2rem" variant="subtitle1">
              {tSubtitle}
            </Typography>
          </>
        )}
      </Grid>
      <Grid item xs={12}>
        <SessionForm
          bubbleSelectItems={bubbles.map(({ id, name: value }) => ({ id, value }))}
          onSubmit={onSubmit}
          room={room ? { id: room.id, refetch: room.refetch } : undefined}
          series={room?.series ?? []}
        />
      </Grid>
    </Grid>
  );
};

const CreateSessionWithRoom = ({ roomId }: { roomId: string }) => {
  const { data: room, refetch } = useRoomEventFieldsQuery({ roomId }, { select: (data) => data.room });

  if (!room) {
    return null;
  }

  return <CreateSession room={{ ...room, refetch }} />;
};

const CreateRoomlessSession = () => {
  return <CreateSession />;
};

/** wraps CreateSession, validating URL parameters and converting to props to ease types */
const CreateSessionWrapper = () => {
  const { roomId } = useParams<{ roomId?: string }>();
  return roomId ? <CreateSessionWithRoom roomId={roomId} /> : <CreateRoomlessSession />;
};

export default CreateSessionWrapper;
