import { Button, Stack, Typography } from '@mui/material';

import { DetailsState } from './SeriesDetails';
import { Series } from './SeriesList';

const tEdit = 'Edit';
const tDelete = 'Delete';
const tCreateNewSeries = 'Create New Series';
const tReadWithName = (name: string) => `Series "${name}" Details`;
const tUpdateWithName = (name: string) => `Update Series "${name}"`;
const tDeleteWithName = (name: string) => `Delete Series "${name}"`;

export interface SeriesDetailHeaderProps {
  series?: Series;
  state: DetailsState;
  onEdit: () => void;
  onDelete: () => void;
}

const getHeader = (state: DetailsState, series?: Series) => {
  if (state === 'CREATE') {
    return tCreateNewSeries;
  }
  if (!series) throw new Error(`must have a series for ${state}`);
  switch (state) {
    case 'READ':
      return tReadWithName(series.name);
    case 'UPDATE':
      return tUpdateWithName(series.name);
    case 'DELETE':
      return tDeleteWithName(series.name);
    default:
      throw new Error(`unhandled state ${state}`);
  }
};

export const SeriesDetailHeader = ({ series, state, onDelete, onEdit }: SeriesDetailHeaderProps) => {
  const headerText = getHeader(state, series);
  return (
    <Stack alignItems="center" direction="row" justifyContent="space-between">
      <Typography>{headerText}</Typography>
      {state === 'READ' && (
        <Stack alignItems="center" direction="row">
          <Button onClick={onEdit} variant="text">
            {tEdit}
          </Button>
          <Button onClick={() => onDelete()} variant="text">
            {tDelete}
          </Button>
        </Stack>
      )}
    </Stack>
  );
};
