import { Box, BoxProps, Typography, TypographyProps } from '@mui/material';

const tEmpty = 'Nothing to show here';

export interface EmptyStateProps {
  message?: string;
  slotProps?: {
    container?: BoxProps;
    message?: TypographyProps;
  };
}

export const EmptyState = ({ message, slotProps }: EmptyStateProps) => {
  return (
    <Box
      {...slotProps?.container}
      sx={{ display: 'grid', gridAutoFlow: 'row', placeContent: 'center', ...slotProps?.container?.sx }}
    >
      <Typography {...slotProps?.message}>{message ?? tEmpty}</Typography>
    </Box>
  );
};
