import { useParams } from 'react-router-dom';

import { addMember, removeMember } from '../../actions/schools';
import { useSchoolMemberManagementQuery } from '../../services/graphql/generated/react-query';
import { MemberManagementContainer, MemberManagementContainerProps } from '../Shared/MemberManagement';

const tHeading = 'School Member Management';
const tTitle = 'Remove from $name';
const tBody =
  'Removing a member from $name cannot be undone. The member will have to request membership and await the approval process if they want to rejoin this school.';
const tActionText = 'Remove From School';

const SchoolMembers = () => {
  const { id } = useParams();
  const { data, isLoading, refetch } = useSchoolMemberManagementQuery({ id: id! }, { select: (data) => data.school });
  if (!data) {
    return null;
  }

  const schoolName = data.name;
  const body = { bubbleId: data.bubbleId };
  const mgmtProps: MemberManagementContainerProps = {
    approveRequestActionCreator: (userId) => addMember({ userId, ...body }),
    heading: schoolName,
    query: () => {
      return {
        data: data && { ...data, requests: data.requests.map((request) => ({ ...request, isVetted: false })) },
        isLoading,
        refetch,
      };
    },
    rejectRequestActionCreator: (userId) => removeMember({ userId, ...body }),
    removeDialog: {
      actionText: tActionText,
      body: tBody.replace('$name', schoolName),
      title: tTitle.replace('$name', schoolName),
    },
    removeMemberActionCreator: (userId) => removeMember({ userId, ...body }),
    subHeading: tHeading,
  };
  return <MemberManagementContainer {...mgmtProps} />;
};

export default SchoolMembers;
