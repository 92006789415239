import { Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';
import { Grid, IconButton, IconButtonProps, Tooltip, TooltipProps, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useCallback, VFC } from 'react';

import { List } from './List';

const tCompleted = 'Task complete!';
const tRemoved = 'Task removed';
const tComplete = 'Complete task';
const tRemove = 'Remove task';
const tDue = 'Target Date';

export interface ActionButtonProps extends IconButtonProps {
  tooltipProps: Omit<TooltipProps, 'children'>;
  variant: 'error' | 'success';
}

export const ActionButton: VFC<ActionButtonProps> = ({ tooltipProps, variant, ...props }) => {
  return (
    <Tooltip {...tooltipProps}>
      <IconButton
        {...props}
        sx={{
          '&:hover': { backgroundColor: `${variant}.light` },
          backgroundColor: `${variant}.main`,
          border: (theme) => `1px solid ${theme.palette.crowdCoursing.GREY[100]?.main ?? 'initial'}`,
          ...props.sx,
        }}
      >
        {variant === 'error' && <CloseIcon sx={{ color: `${variant}.text.primary` }} />}
        {variant === 'success' && <CheckIcon sx={{ color: `${variant}.text.primary` }} />}
      </IconButton>
    </Tooltip>
  );
};

export interface TaskListItem {
  id: string;
  name: string;
  description: string;
  dueDate: Date | string;
  userId?: string;
}

export interface TaskListProps {
  isFacilitator: boolean;
  items: TaskListItem[];
  /**
   * @description callback called when complete is clicked
   */
  onComplete: (item: TaskListItem) => void;
  /**
   * @description callback called when remove is clicked
   */
  onRemove: (item: TaskListItem) => void;
}

export const TaskList: VFC<TaskListProps> = ({ isFacilitator, items, onComplete, onRemove }) => {
  const handleClickComplete = useCallback(
    (item: TaskListItem) => () => {
      onComplete(item);
    },
    [onComplete]
  );

  const handleClickRemove = useCallback(
    (item: TaskListItem) => () => {
      onRemove(item);
    },
    [onRemove]
  );

  return (
    <List
      items={items.map((item) => ({
        primary: item.name,
        secondary: item.description,
        secondaryTypographyProps: { variant: 'caption' as const },
        slots: {
          left: (
            <Grid sx={{ display: 'flex', flexDirection: 'column', marginRight: '2rem' }}>
              <Typography>{tDue}</Typography>
              <Typography variant="caption">{dayjs(item.dueDate).format('MM/DD/YYYY')}</Typography>
            </Grid>
          ),
          right: (
            <>
              {isFacilitator && (
                <Grid>
                  <ActionButton
                    aria-label={tRemoved}
                    onClick={handleClickRemove(item)}
                    tooltipProps={{ title: tRemove }}
                    variant="error"
                  />
                  <ActionButton
                    aria-label={tCompleted}
                    onClick={handleClickComplete(item)}
                    tooltipProps={{ title: tComplete }}
                    variant="success"
                  />
                </Grid>
              )}
            </>
          ),
        },
        ...item,
      }))}
    />
  );
};
