import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const DEFAULT_SEARCH_PARAM_KEY = 'step';
export const COUNTER_OFFSET = 1;

function initializeCounter(value: string | null, offset: number) {
  if (!value) {
    return 0;
  }

  const maybeNumber = Number(value);
  if (!Number.isNaN(maybeNumber)) {
    return maybeNumber - offset;
  }

  return 0;
}

export type UseStepperOptions = { searchParamKey?: string };

/** Generic stepper logic that keeps track of activeStep and syncs to search params. */
export function useStepper(numberOfSteps: number, options?: UseStepperOptions) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [counter, setCounter] = useState(
    initializeCounter(searchParams.get(options?.searchParamKey ?? DEFAULT_SEARCH_PARAM_KEY), COUNTER_OFFSET)
  );

  const decrement = useCallback(() => {
    setCounter((previous) => {
      const current = previous - 1;
      if (current < 0) {
        return 0;
      }
      return current;
    });
  }, []);

  const increment = useCallback(() => {
    setCounter((previous) => previous + 1);
  }, []);

  useEffect(() => {
    setSearchParams((previous) => {
      return new URLSearchParams({
        ...Object.fromEntries(previous.entries()),
        [options?.searchParamKey ?? DEFAULT_SEARCH_PARAM_KEY]: String(counter + COUNTER_OFFSET),
      });
    });
  }, [counter]);

  return {
    activeStep: counter,
    isFirstStep: counter === 0,
    isLastStep: counter === numberOfSteps - 1,
    onClickNext: increment,
    onClickPrevious: decrement,
  };
}
