import { QueryClient, QueryClientProvider, QueryClientProviderProps } from '@tanstack/react-query';

const DEFAULT_STALE_TIME = parseInt(import.meta.env.REACT_APP_DEFAULT_STALE_TIME_MILLIS);

export const queryClient: QueryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onSettled() {
        queryClient.invalidateQueries();
      },
    },
    queries: {
      refetchOnWindowFocus: false,
      staleTime: DEFAULT_STALE_TIME,
    },
  },
});

export type QueryProviderProps = React.PropsWithChildren<Partial<QueryClientProviderProps>>;

export const QueryProvider = ({ children, ...props }: QueryProviderProps) => {
  return (
    <QueryClientProvider client={queryClient} {...props}>
      {children}
    </QueryClientProvider>
  );
};
