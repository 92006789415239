import { TextField } from '../../../atoms/Form';
import { ConstructedTextField } from '../types';

export const FIELD_NAME_EMAIL_OR_PHONE = 'emailOrPhone';
const SIMPLE_EMAIL_REGEX = /.+@.+\..+/;

const tLabel = 'Email or Phone';

const tRequired = 'Email or Phone required';
const tFormatEmail = 'Email must be of format "a@b.c"';
const tLengthEmail = 'Email must contain at least 5 characters, e.g. "a@b.c"';
const tLengthPhone = 'Phone number must be 10 digits, e.g. 5558675309';

export type EmailOrPhoneProps = ConstructedTextField;

export const EmailOrPhone = ({ textFieldProps }: EmailOrPhoneProps) => {
  return (
    <TextField
      name={FIELD_NAME_EMAIL_OR_PHONE}
      textFieldProps={{
        autoFocus: true,
        'data-cy': 'claimUserData',
        label: tLabel,
        required: true,
        ...textFieldProps,
      }}
      validate={(value, _, meta) => {
        if (meta?.dirty) {
          if (!value) {
            return tRequired;
          }

          // email
          if (value.split('').some((character) => isNaN(Number(character)))) {
            if (value.length < 5) {
              return tLengthEmail;
            }

            if (!value.match(SIMPLE_EMAIL_REGEX)) {
              return tFormatEmail;
            }
          }

          // phone
          if (value.split('').every((character) => !isNaN(Number(character)))) {
            if (value.length !== 10) {
              return tLengthPhone;
            }
          }
        }

        return '';
      }}
    />
  );
};
