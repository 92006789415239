import type { BoxProps } from '@mui/material';
import { Box } from '@mui/material';
import arrayMutators from 'final-form-arrays';
import { Form as ReactFinalFormForm, type FormProps as ReactFinalFormFormProps } from 'react-final-form';

export type FormBaseProps<FormFieldValues = Record<PropertyKey, unknown>> = ReactFinalFormFormProps<FormFieldValues>;
export type FormSlotProps = { slotProps?: { renderContainer?: BoxProps<'form'> } };
export type FormProps<FormFieldValues = Record<PropertyKey, unknown>> = FormBaseProps<FormFieldValues> & FormSlotProps;

export function Form<FormFieldValues>({ children, onSubmit, slotProps, ...props }: FormProps<FormFieldValues>) {
  return (
    <ReactFinalFormForm
      {...props}
      mutators={{ ...arrayMutators, ...props.mutators }}
      onSubmit={async (values, form) => {
        if (form.getState().hasValidationErrors) {
          return;
        }

        try {
          await onSubmit(values, form);
        } catch (exception) {
          console.error(`(Form)(onSubmit): Error submitting form data.`);
          console.error({ data: values, ...form.getState() });
          console.error(JSON.stringify({ exception }, undefined, 2));
        }
      }}
      render={(renderProps) => {
        return (
          <Box noValidate {...slotProps?.renderContainer} component="form" onSubmit={renderProps.handleSubmit}>
            {typeof children === 'function' ? children(renderProps) : children}
          </Box>
        );
      }}
    />
  );
}
