import { Box, FormControl, FormHelperText, FormLabel, Typography, useTheme } from '@mui/material';
import { renderTimeViewClock, TimePicker, TimePickerProps } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { useCallback, useRef, useState } from 'react';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export const FIELD_NAME_TIME_RANGE = 'timeRange';

const MINUTES_STEP = 5;

const tEnd = 'End Time';
const tStart = 'Start Time';
const tStartIsSameOrAfter = 'Start time cannot be after end time';
const tEndIsSameOrBefore = 'End time cannot be before start time';

type Value = Dayjs | null;

export type TimeRangeValue = [Value, Value];

type ModifiedTimeRangePickerProps = Omit<TimePickerProps<Value>, 'localeText' | 'onBlur' | 'onFocus' | 'onChange'> & {
  'data-cy'?: string;
  'data-testid'?: string;
};

const TimeRangePicker = ({
  error,
  required,
  ...props
}: TimePickerProps<Value> & { error?: boolean; required?: boolean }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const closeMenu = useCallback(() => setOpen(false), []);

  const handleAccept = useCallback<NonNullable<TimePickerProps<Value>['onAccept']>>((value) => {
    if (props.onAccept) {
      props.onAccept(value);
    }

    closeMenu();
  }, []);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  return (
    <TimePicker
      closeOnSelect={false}
      desktopModeMediaQuery={theme.breakpoints.up('md')}
      localeText={{ toolbarTitle: `Select ${props.label}` }}
      minutesStep={MINUTES_STEP}
      onClose={closeMenu}
      open={open}
      slotProps={{
        ...props.slotProps,
        inputAdornment: { 'aria-hidden': true, sx: { display: 'none', visibility: 'hidden' } },
        textField: { error, onClick: handleClick, required },
      }}
      viewRenderers={{
        hours: renderTimeViewClock,
        minutes: renderTimeViewClock,
        seconds: renderTimeViewClock,
      }}
      {...props}
      onAccept={handleAccept}
    />
  );
};

export interface TimeRangeProps extends Omit<FieldProps<TimeRangeValue, FieldRenderProps<TimeRangeValue>>, 'name'> {
  timeRangePickerProps?: ModifiedTimeRangePickerProps;
  endLabel?: string;
  helperText?: string;
  label?: string;
  required?: boolean;
  startLabel?: string;
}

export const TimeRange = ({
  timeRangePickerProps,
  endLabel = tEnd,
  helperText,
  label,
  required,
  startLabel = tStart,
  ...props
}: TimeRangeProps) => {
  return (
    <Field<TimeRangeValue>
      name={FIELD_NAME_TIME_RANGE}
      render={({ input: { onChange, value }, meta: { error } }) => {
        const endDateRef = useRef<HTMLInputElement>(null);

        const handleChangeEnd: TimePickerProps<Value>['onChange'] = (changeValue, { validationError }) => {
          if (!changeValue?.isValid()) {
            onChange({ target: { value: [value[0], null] } });
            return;
          }

          let newChangeValue = changeValue;
          if (validationError === 'minutesStep') {
            newChangeValue = dayjs(changeValue.minute(Math.floor(changeValue.minute() / 10) * 10));
          }

          onChange({ target: { value: [value[0], newChangeValue] } });
        };

        const handleChangeStart: TimePickerProps<Value>['onChange'] = (changeValue, { validationError }) => {
          if (!changeValue?.isValid()) {
            onChange({ target: { value: [null, value[1]] } });
            return;
          }

          let newChangeValue = changeValue;
          if (validationError === 'minutesStep') {
            newChangeValue = dayjs(changeValue.minute(Math.floor(changeValue.minute() / 10) * 10));
          }

          onChange({ target: { value: [newChangeValue, value[1]] } });
        };

        const handleAcceptStart = useCallback<NonNullable<TimePickerProps<Value>['onAccept']>>(() => {
          if (endDateRef.current) {
            endDateRef.current.click();
          }
        }, [endDateRef]);

        return (
          <FormControl error={Boolean(error)} fullWidth required={required}>
            {label && <FormLabel sx={{ marginBottom: '0.5rem' }}>{label}</FormLabel>}
            <Box alignItems="center" display="grid" gap="1rem" gridAutoColumns="1fr auto 1fr" gridAutoFlow="column">
              <TimeRangePicker
                label={startLabel}
                required={required}
                {...timeRangePickerProps}
                error={Boolean(error)}
                onAccept={handleAcceptStart}
                onChange={handleChangeStart}
                value={value[0]}
              />
              <Typography paddingTop="14px">–</Typography>
              <TimeRangePicker
                label={endLabel}
                required={required}
                {...timeRangePickerProps}
                error={Boolean(error)}
                inputRef={endDateRef}
                onChange={handleChangeEnd}
                value={value[1]}
              />
            </Box>
            {(error || helperText) && (
              <FormHelperText sx={{ marginTop: '0.5rem' }}>{error || helperText}</FormHelperText>
            )}
          </FormControl>
        );
      }}
      {...props}
      validate={(values, _, meta) => {
        if (meta?.dirty && values) {
          const [start, end] = values ?? [];
          if (start && end) {
            if (start.isSameOrAfter(end)) {
              return tStartIsSameOrAfter;
            }

            if (end.isSameOrBefore(start)) {
              return tEndIsSameOrBefore;
            }
          }
        }
      }}
    />
  );
};
