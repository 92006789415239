import { TextField } from '../../../atoms/Form';
import { ConstructedTextField } from '../types';

export const FIELD_NAME_RESOURCE_NAME = 'resourceName';

const tLabel = 'Resource Name';
const tRequired = 'Resource name is required';
const tLengthMax = 'Resource name cannot exceed 100 characters';
const tLengthMin = 'Resource name must be at least 2 characters';

export type ResourceNameProps = ConstructedTextField & { required?: boolean };

export const ResourceName = ({ required = true, textFieldProps, ...props }: ResourceNameProps) => {
  return (
    <TextField
      name={FIELD_NAME_RESOURCE_NAME}
      textFieldProps={{
        'data-testid': 'form-field-resource-name',
        inputProps: { maxLength: 100, minLength: 2 },
        label: tLabel,
        required,
        ...textFieldProps,
      }}
      validate={(value, _, meta) => {
        if (required && meta?.modified && !value) {
          return tRequired;
        }

        if (meta?.dirty && value) {
          if (value.length < 2) {
            return tLengthMin;
          }

          if (value.length > 255) {
            return tLengthMax;
          }
        }

        return '';
      }}
      {...props}
    />
  );
};
