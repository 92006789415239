import { Alert as MuiAlert, AlertProps, AlertTitle, Slide, Snackbar, SnackbarProps, styled } from '@mui/material';
import React from 'react';

const tError = 'Error';
const tInfo = 'Info';
const tSuccess = 'Success';
const tWarning = 'Warning';

const getAlertTitle = (severity: AlertProps['severity']) => {
  switch (severity) {
    case 'error':
      return tError;
    case 'success':
      return tSuccess;
    case 'warning':
      return tWarning;
    case 'info':
    default:
      return tInfo;
  }
};

const Alert = styled(MuiAlert)(({ theme }) => {
  return {
    '@global': {
      '.MuiAlert-action': {
        alignItems: 'flex-start',
        paddingTop: theme.spacing(0.75),
      },
      '.MuiAlert-icon': {
        lineHeight: 0.9,
        paddingTop: theme.spacing(0.75),
      },
      '.MuiTypography-gutterBottom': {
        marginBottom: 0,
      },
    },
    padding: theme.spacing(0, 2),
    width: theme.spacing(38), // '304px' iPhone 5 width - 32px
  };
});

export interface NotificationProps {
  action?: AlertProps['action'];
  isOpen: boolean;
  message: string;
  onClose: AlertProps['onClose'];
  severity?: AlertProps['severity'];
  TransitionProps?: SnackbarProps['TransitionProps'];
}

const Notification: React.FC<NotificationProps> = ({
  action,
  isOpen,
  message,
  onClose,
  severity = 'info',
  TransitionProps = {},
}) => {
  const autoHideDuration = Math.max(message.length * 100, 1500);
  const alertTitle = getAlertTitle(severity);
  return (
    <Snackbar
      TransitionComponent={Slide}
      TransitionProps={TransitionProps}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      autoHideDuration={autoHideDuration}
      disableWindowBlurListener
      // @ts-expect-error we do not actually care about the event object for onClose
      onClose={onClose}
      open={isOpen}
    >
      <Alert
        action={action}
        data-testid={`notification-${severity}`}
        elevation={6}
        onClose={onClose}
        severity={severity}
      >
        <AlertTitle>{alertTitle}</AlertTitle>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
