import arrayMutators from 'final-form-arrays';

import { TypedForm, type TypedFormProps, TypedFormSpy } from './Forms/utils';
import { ResumePreview } from './ResumePreview';
import { ResumeWizard } from './ResumeWizard';
import { ResumeWizardProvider } from './ResumeWizardProvider';

export type ResumeBuilderTemplateBaseProps = {
  initialValues?: TypedFormProps['initialValues'];
  onSubmit: TypedFormProps['onSubmit'];
  status: Utils.QueryStatus;
};
export type ResumeBuilderTemplateSlotProps = { slotProps?: object };
export type ResumeBuilderTemplateProps = ResumeBuilderTemplateBaseProps & ResumeBuilderTemplateSlotProps;

export function ResumeBuilderTemplate({ status, ...props }: ResumeBuilderTemplateProps) {
  return (
    <TypedForm
      mutators={{ ...arrayMutators }}
      {...props}
      slotProps={{
        renderContainer: {
          display: 'grid',
          gap: '2rem',
          gridAutoColumns: '1fr auto',
          gridAutoFlow: 'column',
        },
      }}
    >
      <ResumeWizardProvider>
        <ResumeWizard slotProps={{ stepper: { sx: { height: 'fit-content' } } }} status={status} />
      </ResumeWizardProvider>
      <TypedFormSpy>
        {function ({ values }) {
          return <ResumePreview status={status} values={values} />;
        }}
      </TypedFormSpy>
    </TypedForm>
  );
}
