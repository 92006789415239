import { Alert, Grid, Typography } from '@mui/material';
import Linkify from 'linkify-react';

import CollapsibleText from '../Shared/CollapsibleText';
import type { Event as MTSession, Series } from './MeetingTimesContainer';
import MeetingTimesContainer from './MeetingTimesContainer';

export interface Event extends MTSession {
  description: string;
}

interface EventDescriptionProps {
  children?: React.ReactNode;
  isFacilitator: boolean;
  isMember: boolean;
  event: Event;
  series: Series[];
  /** callback for if we changed data */
  refetch: () => void;
}

const tDescription = 'Description';
const tMeetingTimesHeader = 'Upcoming Events';
const tSeriesAlert =
  'If the event time belongs to a series, you will be signing up for all of the event times that are labeled with that series.';
const tMemberAlert = 'WARNING: You need to join this event in order to RSVP for these event times.';

const EventDescription: React.FC<EventDescriptionProps> = ({
  children,
  isFacilitator,
  isMember,
  event,
  series,
  refetch,
}) => {
  return (
    <>
      <Grid item key="DescriptionRow" xs={12}>
        <Grid item marginTop="1em" xs={12}>
          <Typography variant="h6">{tDescription}</Typography>
        </Grid>
        <Grid item marginTop="1em" xs={12}>
          <CollapsibleText>
            <Typography style={{ whiteSpace: 'pre-line' }} variant="body1">
              <Linkify options={{ target: '_blank' }}>{event.description}</Linkify>
            </Typography>
          </CollapsibleText>
        </Grid>
      </Grid>

      <Grid item key="MeetingTimeRow" marginTop="2em" xs={12}>
        <Grid>
          <Typography variant="h6">{tMeetingTimesHeader}</Typography>
        </Grid>
        {!isMember && !isFacilitator && (
          <Grid container direction="row" xs={12}>
            <Alert severity="warning">{tMemberAlert}</Alert>
          </Grid>
        )}
        <Grid container direction="row" marginTop="1em">
          <Alert severity="info">{tSeriesAlert}</Alert>
        </Grid>
        <Grid item marginTop="0.5em" xs={12}>
          <MeetingTimesContainer event={event} isFacilitator={isFacilitator} refetch={refetch} series={series} />
        </Grid>
      </Grid>
      {children}
    </>
  );
};

export default EventDescription;
