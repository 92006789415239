import { FormControl, FormHelperText, FormLabel, useTheme } from '@mui/material';
import { DateRangePicker, DateRangePickerProps } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { useCallback } from 'react';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';

export const FIELD_NAME_DATE_RANGE = 'dateRange';

const tEnd = 'End Date';
const tStart = 'Start Date';

type Value = Dayjs | null;

export type DateRangeValue = [Value, Value];

type ModifiedDateRangePickerProps = Omit<
  DateRangePickerProps<Value>,
  'localeText' | 'onBlur' | 'onFocus' | 'onChange'
> & {
  'data-cy'?: string;
  'data-testid'?: string;
};

export interface DateRangeProps extends Omit<FieldProps<DateRangeValue, FieldRenderProps<DateRangeValue>>, 'name'> {
  dateRangePickerProps?: ModifiedDateRangePickerProps;
  endLabel?: string;
  helperText?: string;
  label?: string;
  required?: boolean;
  startLabel?: string;
}

export const DateRange = ({
  dateRangePickerProps,
  endLabel = tEnd,
  helperText,
  label,
  required,
  startLabel = tStart,
  ...props
}: DateRangeProps) => {
  return (
    <Field
      name={FIELD_NAME_DATE_RANGE}
      render={({ input: { onChange, value }, meta: { error } }) => {
        const theme = useTheme();

        const handleChange = useCallback<NonNullable<DateRangePickerProps<Value>['onChange']>>(
          (dateRangeValue) => {
            onChange({ target: { value: dateRangeValue } });
          },
          [onChange]
        );

        return (
          <FormControl error={Boolean(error)} fullWidth required={required}>
            {label && <FormLabel sx={{ marginBottom: '0.5rem' }}>{label}</FormLabel>}
            <DateRangePicker
              desktopModeMediaQuery={theme.breakpoints.up('md')}
              localeText={{ end: endLabel, start: startLabel }}
              {...dateRangePickerProps}
              onChange={handleChange}
              slotProps={{ textField: { error: Boolean(error), required } }}
              value={value || [null, null]}
            />
            {(error || helperText) && (
              <FormHelperText sx={{ marginTop: '0.5rem' }}>{error || helperText}</FormHelperText>
            )}
          </FormControl>
        );
      }}
      {...props}
    />
  );
};
