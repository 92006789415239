import { SvgIcon, SvgIconProps } from '@mui/material';

export function CrowdCoursingLogo({ ...props }: SvgIconProps) {
  return (
    <SvgIcon
      id="crowdsmeet_type"
      // @ts-expect-error I have no idea why `enabledBackground` is not on the style type
      style={{ enableBackground: 'new 0 0 375.5 62.6' }}
      viewBox="0 0 375.5 62.6"
      xmlSpace="preserve"
      {...props}
      sx={{ height: 'unset', width: 'unset', ...props.sx }}
    >
      <g>
        <path
          d="M328.3,32.2c0.8-2.9,4.1-4.9,8.4-4.9c5.5,0,9.1,3.3,9.1,9.4v16h-5.7v-15c0-3.7-1.8-5.8-4.9-5.8
		c-3.7,0-6.8,2.6-6.9,7.3v13.5h-5.6V27.9h5.6L328.3,32.2L328.3,32.2z"
          fill="#7d7c79"
        />
        <path
          d="M309.6,20.5c0-1.9,1.5-3.3,3.3-3.3s3.3,1.5,3.3,3.3c0,1.8-1.5,3.3-3.3,3.3S309.6,22.3,309.6,20.5z M310.3,52.8
		V27.9h5.4v24.8H310.3z"
          fill="#7d7c79"
        />
        <path
          d="M294.6,53.4c-3.8,0-7.5-1.4-9.9-3.7l2.3-4.2c1.7,1.7,5,3.3,7.8,3.3c3.5,0,4.1-1.8,4.1-2.8c0-2-2.3-2.7-4.8-3.2
		c-3.9-0.8-8.8-1.9-8.8-7.6c0-3.7,2.8-7.6,9.1-7.6c3.3,0,6.4,1.1,8.9,3.2l-2.1,4c-1.6-1.7-4.2-2.7-6.7-2.7c-2.3,0-3.8,1-3.8,2.6
		c0,1.8,2.1,2.4,4.5,3c3.8,0.8,9,1.9,9,8C304.2,50.3,300.4,53.4,294.6,53.4L294.6,53.4z"
          fill="#7d7c79"
        />
        <path
          d="M267.7,27.9h5.5l0,3.6c1.9-2.2,5.8-4.5,9.3-4.2v5.5c-1-0.1-3.2-0.2-5.5,1c0,0,0,0,0,0
		c-0.9,0.5-1.7,1.2-2.3,2.2c-0.8,1.2-1.2,2.5-1.4,3.9l0,0.4c0,0.4,0,0.8,0,1.3v11.2l-5.5,0L267.7,27.9L267.7,27.9z"
          fill="#7d7c79"
        />
        <path
          d="M255.1,52.8v-3.3c-1.8,2-4.9,3.9-8.9,3.9c-5.4,0-8-2.8-8-7.9V27.9h5.5v15.6c0,3.9,2,5,5,5
		c2.8,0,5.1-1.5,6.4-3.2V27.9h5.5v24.9H255.1L255.1,52.8z"
          fill="#7d7c79"
        />
        <path
          d="M206.5,40.4c0-7.1,4.8-12.9,12.6-12.9s12.7,5.9,12.7,12.9s-4.8,13-12.7,13S206.5,47.5,206.5,40.4z M226.3,40.4
		c0-4.3-2.5-8.2-7.1-8.2s-7.1,3.8-7.1,8.2s2.5,8.2,7.1,8.2S226.3,44.8,226.3,40.4z"
          fill="#7d7c79"
        />
        <path
          d="M172.9,36.3c0-10.2,7.7-17.1,17.4-17.1c7.1,0,11.3,3.5,13.8,7.4l-5,2.5c-1.6-2.7-4.9-4.8-8.7-4.8
		c-6.4,0-11.4,5-11.4,11.9s5,11.9,11.4,11.9c3.9,0,7.1-2,8.7-4.8l5,2.5c-2.6,4-6.7,7.4-13.8,7.4C180.6,53.4,172.9,46.4,172.9,36.3
		L172.9,36.3z"
          fill="#7d7c79"
        />
        <path
          d="M160,50c-0.6,0.7-1.8,2.1-3.9,2.8c-1.4,0.5-2.5,0.5-3.1,0.5c0,0-3,0-5.7-1.6c-3.8-2.2-6.1-6.8-6.2-11.4
		c-0.1-4.4,2-9.8,6.3-11.9c2.4-1.2,5-1.1,6.1-1.1c1.3,0,3,0.1,4.9,1.1c0.7,0.4,1.3,0.9,1.6,1.2c0-3.7,0-7.4,0-11.2h7.4v34.3H160
		L160,50L160,50z M159.8,39.5c0-2-0.9-3.9-2.5-5c-0.6-0.4-1.4-0.8-2.2-0.8c-3.2-0.3-6.1,2.8-6.4,6c-0.4,3.4,2.1,7.2,5.5,7.4
		c1.3,0.1,2.6-0.5,3.7-1.4c1.3-1.1,1.9-2.7,1.9-4.4L159.8,39.5L159.8,39.5z"
          fill="#1380AA"
        />
        <path
          d="M118.5,39.1l-4.6,13.7h-8.5l-7.4-25.3h8.1l4,15.5l4.9-15.5h7l4.9,15.5l4-15.5h8.2l-8.4,25.3h-7.5
		C123.1,52.8,118.5,39.1,118.5,39.1z"
          fill="#1380AA"
        />
        <path
          d="M95.8,39.2c-0.4-4.9-2.9-8.6-6.9-10.5c-0.1-0.1-0.3-0.1-0.4-0.2c-0.2-0.1-0.4-0.2-0.6-0.2
		c-0.2-0.1-0.5-0.2-0.7-0.2c-0.1,0-0.3-0.1-0.4-0.1c-0.3-0.1-0.6-0.2-0.9-0.2c-0.1,0-0.3,0-0.4-0.1c-0.3,0-0.6-0.1-0.9-0.1
		c-0.3,0-0.6,0-0.9,0c-0.2,0-0.3,0-0.5,0h0h0c-0.2,0-0.3,0-0.5,0c-0.3,0-0.6,0-0.9,0c-0.3,0-0.6,0.1-0.9,0.1c-0.1,0-0.3,0-0.4,0.1
		c-0.3,0.1-0.6,0.1-0.9,0.2c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0.1-0.5,0.2-0.7,0.2c-0.2,0.1-0.4,0.2-0.6,0.2c-0.1,0.1-0.3,0.1-0.4,0.2
		c-3.9,1.8-6.5,5.6-6.9,10.5c0,0.4-0.1,0.8-0.1,1.2c0,6.5,4.3,13,12.6,13h0h0c8.3,0,12.6-6.5,12.6-13C95.9,40,95.9,39.6,95.8,39.2
		L95.8,39.2z M83.2,46.9c-1,0-1.9-0.3-2.7-0.7c-1.7-1-2.7-3.1-2.7-5.7c0-1.5,0.4-2.9,1-3.9c0.9-1.5,2.5-2.5,4.4-2.5
		c1.9,0,3.4,0.9,4.4,2.5c0.6,1,1,2.4,1,3.9c0,2.6-1,4.7-2.7,5.7C85.1,46.6,84.2,46.8,83.2,46.9L83.2,46.9z"
          fill="#1380AA"
        />
        <path
          d="M52.4,27.3h7.4v2.6c1.8-2.3,4.2-3,7.9-2.7l0.1,7.2c-0.4,0-0.9-0.1-1.5,0c-0.4,0-1.3,0.1-2.1,0.4
		c-1.5,0.5-2.3,1.6-2.7,2c-0.6,0.7-0.9,1.4-1,1.8c-0.1,0.2-0.2,0.5-0.2,0.9c-0.2,0.9-0.3,1.9-0.3,2.8v10.5h-7.4L52.4,27.3L52.4,27.3
		z"
          fill="#1380AA"
        />
        <path
          d="M43.8,41.7c-0.6,0.8-1.8,2.1-3.7,3.1c-0.6,0.3-2.3,1.2-4.5,1.2c-0.3,0-4.7,0-7.5-3.3c-3-3.5-3.2-9.4,0-12.9
		c3.1-3.5,7.9-3,8.5-3c4.4,0.5,6.8,3.6,7.2,4.2c1.8-1.6,3.7-3.1,5.5-4.7c-1-1.4-2.9-3.6-5.9-5.2c-5.4-2.8-12.7-2.7-18.2,1.3
		c-6.6,4.8-7.1,12.4-7.1,13.2c0,0.9-0.3,9.4,6.8,14.5c6.7,4.9,14.2,2.7,15.1,2.4c4.8-1.5,7.6-4.7,8.6-6.1
		C47,44.9,45.4,43.3,43.8,41.7L43.8,41.7z"
          fill="#1380AA"
        />
        <path
          d="M8.8,25.9L9,21.5l-1.5-0.1l-0.3,4.3L5.5,29c-0.2,0.4-0.1,1,0.3,1.2l0.7,0.4C6.9,30.8,7.3,31,7.8,31l0,0
		c0.5,0,1-0.1,1.5-0.3l0.5-0.2c0.4-0.2,0.7-0.7,0.5-1.2L8.8,25.9L8.8,25.9z"
          fill="#1380AA"
        />
        <circle cx="8.2" cy="24.4" fill="#1380AA" r="1.7" />
        <path
          d="M43.5,13.3c-0.4-0.1-0.8-0.3-1.3-0.4c-7.2-2.1-15.1-0.8-21.2,3.6c-2.7,1.9-4.9,4.4-6.5,7.2l3,5.4
		c1.1-2.8,3-5.9,6.5-8.5c5.9-4.3,13.9-4.9,20.3-1.5c0.1,0.1,0.2,0.1,0.4,0.2C44.7,19.4,43.5,13.3,43.5,13.3L43.5,13.3z"
          fill="#1380AA"
        />
        <path
          d="M19.9,14.9c6.5-4.7,15.1-6.2,22.9-3.9c0.1,0,0.2,0.1,0.3,0.1l8.4-8.4L20.7,0L0,21.1l13.4,0.7
		C13.4,21.8,17.3,16.8,19.9,14.9z"
          fill="#1380AA"
        />
      </g>
      <line fill="none" x1="160.9" x2="168.4" y1="52.1" y2="52.1" />
      <path
        d="M370.1,27.9v3.6c-0.2-0.3-0.4-0.6-0.6-0.9c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.2-0.3-0.3-0.4-0.5
	c0,0-0.1-0.1-0.2-0.2c-1.5-1.4-3.6-2.1-6.1-2.1c-6.5,0-10.8,4.5-10.9,12.4c0.1,7.9,4.4,12.4,10.9,12.4c2.6,0,4.6-0.8,6.1-2.1
	c0.1,0,0.1-0.1,0.2-0.2c0.1-0.1,0.3-0.3,0.4-0.5c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.3,0.4-0.6,0.6-0.9c0.1,0.5,0.1,1.4,0.1,2.4
	c0,0.4,0,0.7-0.1,1.1c-0.1,1.1-0.3,2.2-1,3.2c-0.8,1.3-1.9,1.9-2.3,2.1c-1.3,0.7-2.5,0.8-3.6,0.8c-0.4,0-2.1,0.1-4.2-0.7
	c-1.5-0.5-2.6-1.2-3.5-1.8l-2.7,3.6c0.9,0.7,2.2,1.6,3.9,2.3c3,1.3,5.7,1.3,6.5,1.3c1.8,0,6.6-0.1,9.6-3.5c1.5-1.7,2.2-3.7,2.4-7.2
	l0,0c0-0.5,0-1,0-1.6c0-3.2,0-6.6,0.1-10.1c0-3.8,0.2-12.3,0.2-12.3L370.1,27.9L370.1,27.9z M368.2,45.5c-0.3,0.3-2.5,2.6-5.7,2.2
	c-3.3-0.4-4.9-3.4-5.2-3.9c-0.1-0.2-0.4-0.7-0.6-1.5c-0.3-0.9-0.3-1.7-0.3-2.3s0-1.4,0.3-2.3c0.2-0.8,0.5-1.3,0.6-1.5
	c0.3-0.6,1.9-3.5,5.2-3.9c3.1-0.4,5.3,1.9,5.7,2.2c1.5,1.6,1.8,3.5,1.9,4.6v2C370.1,42,369.8,43.9,368.2,45.5z"
        fill="#7d7c79"
      />
    </SvgIcon>
  );
}
