import { Share as ShareIcon } from '@mui/icons-material';
import { Avatar, Card, CardContent, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

import { Schedule as ScheduleTable, ScheduleMeetingTime } from '../../../../cc-ui';
import { useDispatch } from '../../../../hooks/useTypedRedux';
import { Route } from '../../../../services/router/Route';
import { setSessionId } from '../../../../state/app';
import { successToast } from '../../../../state/notifications';
import { fullyQualifiedUrl, makeRoomAvatarUrl } from '../../../../utilities/utils';
import CollapsibleText from '../../../Shared/CollapsibleText';

const tCopyTooltip = 'Copies a link to this room';
const tCopySuccess = 'Room link copied to clipboard!';
const tRoomLinkTooltip = "Goes to the room's page";

export interface RoomProps {
  description: string;
  id: string;
  name: string;
  onClickRow: (eventId: string) => void;
  sessions: ScheduleMeetingTime[];
}

export const Room = ({ description, id, name, onClickRow, sessions }: RoomProps) => {
  const sharableRoomLink = fullyQualifiedUrl(Route.podView({ roomId: id }));
  const dispatch = useDispatch();
  const roomAvatarUrl = makeRoomAvatarUrl(id);

  return (
    <Grid container direction="column" marginBottom="1em" width="100%">
      <Card>
        <CardContent>
          <Grid item>
            <Grid container direction="row" key="TitleRow" xs={12}>
              <Grid item xs={9}>
                <Link
                  data-testid="open-room-link"
                  style={{ textDecoration: 'none' }}
                  to={Route.podView({ roomId: id })}
                >
                  <IconButton color="inherit" data-cy="accountMenuButton" edge="end" sx={{ marginRight: 1 }}>
                    <Avatar src={roomAvatarUrl} sx={{ height: '3rem', width: '3rem' }} />
                    <Grid marginLeft="1em">
                      <Tooltip placement="right-end" title={tRoomLinkTooltip}>
                        <Typography variant="body1">{name}</Typography>
                      </Tooltip>
                    </Grid>
                  </IconButton>
                </Link>
              </Grid>
              <Grid container justifyContent="flex-end" justifyItems="flex-end" xs={3}>
                <Grid item>
                  <Tooltip placement="top-start" title={tCopyTooltip}>
                    <ShareIcon
                      onClick={() => {
                        navigator.clipboard.writeText(sharableRoomLink);
                        dispatch(successToast({ message: tCopySuccess }));
                      }}
                      style={{
                        cursor: 'pointer',
                        filter:
                          'invert(88%) sepia(37%) saturate(4038%) hue-rotate(162deg) brightness(92%) contrast(83%)',
                        width: '100px',
                      }}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item marginTop="2em">
            <CollapsibleText>
              <Typography style={{ whiteSpace: 'pre-line' }}>{description}</Typography>
            </CollapsibleText>
          </Grid>
          <Grid item marginTop="3em">
            <Typography variant="h5">Upcoming Events</Typography>
            <ScheduleTable meetingTimes={sessions} onClickRow={onClickRow} roomId={id} />
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export const RoomContainer = ({ ...props }: Omit<RoomProps, 'onClickRow'>) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onClickRow = (eventId: string) => {
    dispatch(setSessionId(eventId));
    navigate(Route.roomViewEvent({ eventId, roomId: props.id }));
  };
  const roomProps = { ...props, onClickRow };
  return <Room {...roomProps} />;
};
