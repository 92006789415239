import { Close as CloseIcon, Done as DoneIcon } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { VFC } from 'react';

import { Member, MemberCallback } from './types';
import { UserList } from './UserList';

const tNoPendingRequests = 'No pending requests';

interface RequestActionsProps {
  onApprove: () => void;
  onReject: () => void;
}

const RequestActions: VFC<RequestActionsProps> = ({ onApprove, onReject }) => (
  <>
    <IconButton onClick={onReject}>
      <CloseIcon color="error" data-testid="reject-member-button" />
    </IconButton>
    <IconButton onClick={onApprove}>
      <DoneIcon color="success" data-testid="approve-member-button" />
    </IconButton>
  </>
);

export interface RequestListProps {
  requests: Member[];
  onApprove: MemberCallback;
  onReject: MemberCallback;
}

export const RequestList: VFC<RequestListProps> = ({ requests, onApprove, onReject }) => {
  const hasRequests = requests.length > 0;

  if (!hasRequests) {
    return <Typography>{tNoPendingRequests}</Typography>;
  }

  return (
    <UserList
      actions={(user) => <RequestActions onApprove={() => onApprove(user)} onReject={() => onReject(user)} />}
      users={requests}
    />
  );
};
