import { Grid, Link, Paper, Typography } from '@mui/material';
import { useId } from 'react';

import { Avatar } from '..';

const tNoResources = '';
const displayNameLength = 40;
const displayDescriptionLength = 255;

/**
 * an external resource we want to link to
 */
export interface Resource {
  id: string;
  name: string;
  url: string;
  description?: string | null;
}

export interface ResourceListProps {
  items: Resource[];
}

const ResourceLink = ({ name, url, description }: Resource) => {
  // TODO: use atoms/ExternalLink
  const displayName = name?.length > displayNameLength ? name.substring(0, displayNameLength) + '...' : name;
  let displayDescription = description;
  if (description !== undefined && description !== null && description.length > displayDescriptionLength) {
    displayDescription = description.substring(0, displayDescriptionLength) + '...';
  }
  const faviconUrl = 'https://s2.googleusercontent.com/s2/favicons?domain=' + url + '&sz=256';
  const linkId = useId();
  return (
    <Link aria-labelledby={linkId} href={url} rel="noopener" target="_blank" underline="none" variant="body1">
      <Grid spacing={2}>
        <Grid marginLeft="0.5em" marginRight="0.5em" marginTop="1em" sx={{ wordBreak: 'break-word' }} xs={12}>
          <Typography component="span" variant="body1">
            <Avatar src={faviconUrl} /> <span id={linkId}>{displayName}</span>
          </Typography>
        </Grid>
        <Grid marginLeft="0.5em" marginRight="0.5em" marginTop="1.5em" sx={{ wordBreak: 'break-word' }} xs={12}>
          {description && <Typography variant="subtitle2">{displayDescription}</Typography>}
        </Grid>
      </Grid>
    </Link>
  );
};

// TODO: move to molecules
export const ResourceList = ({ items }: ResourceListProps) => {
  if (items.length == 0) {
    // TODO: use atoms/Empty
    return <Typography variant="body1">{tNoResources}</Typography>;
  }

  return (
    <Grid container spacing={2} xs={12}>
      {items.map((i) => (
        <Grid item key={i.id} lg={3} sm={6} xs={12}>
          <Paper data-testid="resource-card" elevation={1}>
            <ResourceLink {...i} />
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};
