import { Select } from '../../../atoms/Form';
import { ConstructedSelect } from '../types';

export const FIELD_NAME_BG_PRODUCT_SELECT = 'backgroundCheckProductSelect';

const tLabel = 'Select Background Check Product';
const tRequired = 'Background Check Product is required';

export type BackgroundCheckProductSelectProps = ConstructedSelect;

export const BackgroundCheckProductSelect = ({
  required,
  selectItems,
  selectProps,
  ...props
}: BackgroundCheckProductSelectProps) => {
  return (
    <Select
      label={tLabel}
      name={FIELD_NAME_BG_PRODUCT_SELECT}
      selectItems={selectItems}
      selectProps={{ 'data-testid': 'form-field-background-check-product-select', required, ...selectProps }}
      validate={(value, _, meta) => {
        if (meta?.modified && required && !value) {
          return tRequired;
        }

        return '';
      }}
      {...props}
    />
  );
};
