import { Grid } from '@mui/material';
import { FieldValidator, FormState } from 'final-form';
import { useMemo } from 'react';
import { useFormState } from 'react-final-form';

import { useSelector } from '../../hooks';
import { Form, FormProps, TextField, TextFieldProps, TextFieldValue } from '../../ui/atoms/Form';

const FIELD_NAME_FIRST = 'firstName';
const FIELD_NAME_LAST = 'lastName';
const FIELD_NAME_PHONE = 'phoneNumber';
const FIELD_NAME_EMAIL = 'email';

const SIMPLE_EMAIL_REGEX = /.+@.+\..+/;

const tFirst = 'First Name';
const tLast = 'Last Name';
const tPhone = 'Phone Number';
const tEmail = 'Email';

const tRequiredFirst = 'First name is required';
const tRequiredLast = 'Last name is required';
const tRequiredPhone = 'Phone number is required';
const tRequiredEmail = 'Email is required';

const tLengthPhone = 'Phone number must be 10 digits, e.g. 5558675309';
const tLengthEmail = 'Email must contain at least 5 characters';

const tTypePhone = 'Phone may only contain numbers';

const tFormatEmail = 'Email must be of format "a@b.c"';

type RecordKey = typeof FIELD_NAME_FIRST | typeof FIELD_NAME_LAST | typeof FIELD_NAME_PHONE | typeof FIELD_NAME_EMAIL;
export type PersonalInformationFormFieldValues = Record<RecordKey, string>;

const Validate: Record<RecordKey, FieldValidator<TextFieldValue>> = {
  email: (value, _, meta) => {
    if (!meta?.pristine) {
      if (!value || value.length === 0) {
        return tRequiredEmail;
      }

      if (value.length < 5) {
        return tLengthEmail;
      }

      if (!value.match(SIMPLE_EMAIL_REGEX)) {
        return tFormatEmail;
      }
    }

    return '';
  },
  firstName: (value, _, meta) => {
    if (!meta?.pristine) {
      if (!value || value.length === 0) {
        return tRequiredFirst;
      }
    }

    return '';
  },
  lastName: (value, _, meta) => {
    if (!meta?.pristine) {
      if (!value || value.length === 0) {
        return tRequiredLast;
      }
    }

    return '';
  },
  phoneNumber: (value, _, meta) => {
    if (!meta?.pristine) {
      if (!value || value.length === 0) {
        return tRequiredPhone;
      }

      if (value.split('').some((character) => isNaN(Number(character)))) {
        return tTypePhone;
      }

      if (value.length > 10 || value.length < 10) {
        return tLengthPhone;
      }
    }

    return '';
  },
};

const FormContent = ({
  children,
}: {
  children?: React.ReactNode | ((formState: FormState<PersonalInformationFormFieldValues>) => React.ReactNode);
}) => {
  const { firstName, email, lastName, phoneNumber } = useSelector(
    (state) => state.user ?? { email: '', firstName: '', lastName: '', phoneNumber: '' }
  );
  const formState = useFormState<PersonalInformationFormFieldValues>();

  const fieldProps = useMemo<TextFieldProps[]>(
    () => [
      {
        initialValue: firstName,
        name: FIELD_NAME_FIRST,
        textFieldProps: { 'data-cy': 'firstNameInput', fullWidth: true, label: tFirst, required: true },
        validate: Validate.firstName,
      },
      {
        initialValue: lastName,
        name: FIELD_NAME_LAST,
        textFieldProps: { 'data-cy': 'lastNameInput', fullWidth: true, label: tLast, required: true },
        validate: Validate.lastName,
      },
      {
        initialValue: phoneNumber,
        name: FIELD_NAME_PHONE,
        textFieldProps: { fullWidth: true, inputProps: { maxLength: 10 }, label: tPhone, required: true },
        type: 'tel',
        validate: Validate.phoneNumber,
      },
      {
        initialValue: email,
        name: FIELD_NAME_EMAIL,
        textFieldProps: {
          'data-cy': 'emailInput',
          fullWidth: true,
          inputProps: { minLength: 6 },
          label: tEmail,
          required: true,
        },
        type: 'email',
        validate: Validate.email,
      },
    ],
    [email, firstName, lastName, phoneNumber]
  );

  return (
    <>
      <Grid container justifyContent="flex-end" spacing={5}>
        {fieldProps.map((props) => (
          <Grid item key={props.name} sm={6} xs={12}>
            <TextField {...props} />
          </Grid>
        ))}
      </Grid>
      {typeof children === 'function' ? children(formState) : children}
    </>
  );
};

export interface PersonalInformationFormProps {
  children?: React.ReactNode | ((formState: FormState<PersonalInformationFormFieldValues>) => React.ReactNode);
  onSubmit: FormProps<PersonalInformationFormFieldValues>['onSubmit'];
}

export const PersonalInformationForm = ({ children, onSubmit }: PersonalInformationFormProps) => {
  return (
    <Form onSubmit={onSubmit}>
      {/* eslint-disable-next-line react/no-children-prop */}
      <FormContent children={children} />
    </Form>
  );
};
