import { RightSidebar, RightSidebarProps } from '../../../cc-ui';
import { ManageSeries, ManageSeriesProps } from './ManageSeries';

const tManageSeries = 'Manage Series';
export interface ManageSeriesSidebarProps extends ManageSeriesProps, Pick<RightSidebarProps, 'label'> {}

export const ManageSeriesSidebar = (props: ManageSeriesSidebarProps) => {
  return (
    <RightSidebar headingText={tManageSeries} label={props.label}>
      <ManageSeries {...props} />
    </RightSidebar>
  );
};
