import {
  Grid,
  List as MuiList,
  ListItem as MuiListItem,
  ListItemText as MuiListItemText,
  ListItemTextProps as MuiListItemTextProps,
  Slide,
} from '@mui/material';
import { useRef } from 'react';
import { TransitionGroup } from 'react-transition-group';

interface Item extends MuiListItemTextProps {
  id: string;
  slots?: { left?: React.ReactChild; right?: React.ReactChild };
}

export interface ListProps<T extends Item> {
  items: T[];
}

export const List = <T extends Item>({ items }: ListProps<T>) => {
  const containerRef = useRef(null);
  return (
    <Grid ref={containerRef}>
      <TransitionGroup component={MuiList} style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
        {items.map(({ id, slots, ...props }) => (
          <Slide container={containerRef.current} direction="left" key={id} mountOnEnter unmountOnExit>
            <MuiListItem
              data-testid={`list-item-${id}`}
              sx={{
                '&:hover': {
                  backgroundColor: (theme) => theme.palette.crowdCoursing.GREY[100]?.main ?? 'initial',
                },
                border: (theme) => `1px solid ${theme.palette.crowdCoursing.GREY[200]?.main ?? 'initial'}`,
                borderRadius: '0.25rem',
                cursor: 'pointer',
                gap: '1rem',
                padding: '0.5rem',
                position: 'relative',
              }}
            >
              {slots?.left}
              <MuiListItemText
                {...props}
                primaryTypographyProps={{ noWrap: true, ...props.primaryTypographyProps }}
                secondaryTypographyProps={{ noWrap: true, ...props.secondaryTypographyProps }}
              />
              {slots?.right}
            </MuiListItem>
          </Slide>
        ))}
      </TransitionGroup>
    </Grid>
  );
};
