import { StyledEngineProvider, ThemeProvider as MuiThemeProvider } from '@mui/material';

import theme from './theme';

const ThemeProvider = ({ children }: React.PropsWithChildren) => (
  <StyledEngineProvider injectFirst>
    <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
  </StyledEngineProvider>
);

export default ThemeProvider;
