import { DragDropContext, DragDropContextProps, Draggable, Droppable, OnDragEndResponder } from '@hello-pangea/dnd';
import { Add, Delete, DragHandle } from '@mui/icons-material';
import Box, { BoxProps } from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card, { CardProps } from '@mui/material/Card';
import merge from 'lodash/merge';
import { useCallback, useMemo } from 'react';
import { UseFieldConfig } from 'react-final-form';
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays';

const translationsTemplate: DragAndDropFieldArrayProps<object>['translations'] = {
  add: 'Add',
  remove: 'Remove',
};

type SanitizedFieldArrayProps<T extends object> = UseFieldConfig<T[]> & {
  isEqual?: (a: T[], b: T[]) => boolean;
  name: string;
};

export type DragAndDropFieldArrayBaseProps<
  T extends object,
  U extends HTMLElement = HTMLElement
> = SanitizedFieldArrayProps<T> & {
  children?:
    | React.ReactNode
    | ((props: FieldArrayRenderProps<T, U> & { index: number; name: string }) => React.ReactNode);
  itemTemplate: T;
  max?: number;
  onAdd?: (fields: FieldArrayRenderProps<T, U>['fields']) => void;
  onDragEnd?: (fields: FieldArrayRenderProps<T, U>['fields']) => void;
  onRemove?: (fields: FieldArrayRenderProps<T, U>['fields']) => void;
  translations?: { add?: string; remove?: string };
};
export type DragAndDropFieldArraySlotProps = {
  slotProps?: {
    dropzoneContainer?: BoxProps;
    draggableContainer?: CardProps<typeof Box>;
    dragDropContext?: Omit<DragDropContextProps, 'onDragEnd'>;
  };
};
export type DragAndDropFieldArrayProps<
  T extends object,
  U extends HTMLElement = HTMLElement
> = DragAndDropFieldArrayBaseProps<T, U> & DragAndDropFieldArraySlotProps;

export function DragAndDropFieldArray<T extends object, U extends HTMLElement = HTMLElement>({
  children,
  itemTemplate,
  max = Number.MAX_SAFE_INTEGER,
  onAdd,
  onDragEnd,
  onRemove,
  slotProps,
  translations = {},
  ...props
}: DragAndDropFieldArrayProps<T, U>) {
  const memoizedTranslations = useMemo(() => {
    return merge(translationsTemplate, translations);
  }, [translations]);

  return (
    <FieldArray<T, U> {...props}>
      {function ({ fields, meta }) {
        const handleClickAdd = () => {
          if ((fields?.length ?? 0) < max) {
            onAdd?.(fields);
            fields.push(itemTemplate);
          }
        };

        const handleClickRemove = (index: number) => () => {
          onRemove?.(fields);
          fields.remove(index);
        };

        const handleDragEnd: OnDragEndResponder = useCallback(({ source, destination }) => {
          onDragEnd?.(fields);
          fields.move(source.index, destination?.index ?? source.index);
        }, []);

        return (
          <>
            <DragDropContext {...slotProps?.dragDropContext} onDragEnd={handleDragEnd}>
              <Droppable droppableId={props.name}>
                {function (droppable) {
                  return (
                    <Box
                      {...slotProps?.dropzoneContainer}
                      sx={{ display: 'grid', gap: '2rem', ...slotProps?.dropzoneContainer?.sx }}
                      {...droppable.droppableProps}
                      ref={droppable.innerRef}
                    >
                      {fields.map(function (name, index) {
                        return (
                          <Draggable draggableId={`${name}-${index}`} index={index} key={name}>
                            {function (draggable) {
                              return (
                                <Card
                                  data-testid={name}
                                  elevation={2}
                                  sx={{
                                    display: 'grid',
                                    gridAutoColumns: 'minmax(0, auto) minmax(0, 1fr)',
                                    gridAutoFlow: 'column',
                                    ...slotProps?.draggableContainer?.sx,
                                  }}
                                  {...slotProps?.draggableContainer}
                                  {...draggable.draggableProps}
                                  {...draggable.dragHandleProps}
                                  component={Box}
                                  ref={draggable.innerRef}
                                >
                                  <Box
                                    sx={{
                                      '&:hover': { cursor: 'grab' },
                                      backgroundColor: (theme) => theme.palette.action.hover,
                                      display: 'grid',
                                      padding: '0.5rem',
                                      placeItems: 'center',
                                    }}
                                  >
                                    <DragHandle />
                                  </Box>
                                  <Box sx={{ display: 'grid', gap: '2rem', padding: '1.5rem' }}>
                                    <Button
                                      color="error"
                                      onClick={handleClickRemove(index)}
                                      startIcon={<Delete />}
                                      sx={{ marginLeft: '-0.5rem', marginTop: '-0.5rem', width: 'fit-content' }}
                                      variant="text"
                                    >
                                      {memoizedTranslations.remove}
                                    </Button>
                                    <Box sx={{ display: 'grid', gap: '1.5rem' }}>
                                      {typeof children === 'function'
                                        ? children({ fields, index, meta, name })
                                        : children}
                                    </Box>
                                  </Box>
                                </Card>
                              );
                            }}
                          </Draggable>
                        );
                      })}
                      {droppable.placeholder}
                    </Box>
                  );
                }}
              </Droppable>
            </DragDropContext>
            <Button
              disabled={(fields.length ?? 0) >= max}
              onClick={handleClickAdd}
              startIcon={<Add />}
              sx={{ width: 'fit-content' }}
              variant="text"
            >
              {memoizedTranslations.add}
            </Button>
          </>
        );
      }}
    </FieldArray>
  );
}
