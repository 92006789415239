import { Button } from '@mui/material';
import { useState } from 'react';

import { Dialog } from '../../../cc-ui';
import AddMemberDialog from './AddMemberDialog';

interface AddMemberButtonProps {
  roomId?: string;
  eventId?: string;
}

const AddMemberButton = ({ roomId, eventId }: AddMemberButtonProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <Button disabled={isDialogOpen} onClick={handleOpenDialog}>
        Add Member
      </Button>
      <Dialog
        actions={<AddMemberDialog eventId={eventId} onClose={handleCloseDialog} roomId={roomId} />}
        dialogType="custom"
        onDialogClose={handleCloseDialog}
        open={isDialogOpen}
        title="Add Member"
      />
    </>
  );
};

export default AddMemberButton;
