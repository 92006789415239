import { Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { useCallback, useState } from 'react';

import {
  useEventAddToEventByEmail,
  useRoomAddToRoomByEmail,
} from '../../../services/openapi/generated/CrowdCoursingComponents';

interface AddMemberProps {
  onClose: () => void;
  roomId?: string;
  eventId?: string;
}

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
}

interface FormErrors {
  firstName?: string;
  lastName?: string;
  email?: string;
}

const AddMemberDialog = ({ onClose, roomId, eventId }: AddMemberProps) => {
  const [formValues, setFormValues] = useState<FormValues>({
    email: '',
    firstName: '',
    lastName: '',
  });
  const [errors, setErrors] = useState<FormErrors>({});
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const {
    mutate: addMemberToRoom,
    isLoading: isLoadingRoom,
    isError: isErrorRoom,
    error: errorRoom,
  } = useRoomAddToRoomByEmail({
    onError: (error) => {
      console.error('Error adding member:', error);
      setIsUploading(false);
    },
    onSuccess: () => {
      console.log('Member added successfully');
      setIsUploading(false);
      onClose();
    },
  });

  const {
    mutate: addMemberToEvent,
    isLoading: isLoadingEvent,
    isError: isErrorEvent,
    error: errorEvent,
  } = useEventAddToEventByEmail({
    onError: (error) => {
      console.error('Error adding member:', error);
      setIsUploading(false);
    },
    onSuccess: () => {
      console.log('Member added successfully');
      setIsUploading(false);
      onClose();
    },
  });

  const validate = (values: FormValues): FormErrors => {
    const errors: FormErrors = {};
    if (!values.firstName) {
      errors.firstName = 'First name is required';
    }
    if (!values.lastName) {
      errors.lastName = 'Last name is required';
    }
    if (!values.email) {
      errors.email = 'Email is required';
    }
    return errors;
  };

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const validationErrors = validate(formValues);
      setErrors(validationErrors);
      if (Object.keys(validationErrors).length === 0) {
        setIsUploading(true);
        if (roomId) {
          addMemberToRoom({ body: formValues, pathParams: { roomId: roomId } });
        } else if (eventId) {
          addMemberToEvent({ body: formValues, pathParams: { eventId: eventId } });
        }
      }
    },
    [formValues, validate, addMemberToRoom, addMemberToEvent]
  );

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  return (
    <Grid container justifyContent="center">
      <Grid item md={8} xs={10}>
        <Typography sx={{ textAlign: 'center' }} variant="h5">
          Add Member Details
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <TextField
                disabled={isLoadingRoom || isLoadingEvent || isUploading}
                error={!!errors.firstName}
                fullWidth
                helperText={errors.firstName}
                id="firstName"
                label="First Name"
                margin="normal"
                name="firstName"
                onChange={handleFormChange}
                value={formValues.firstName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={isLoadingRoom || isLoadingEvent || isUploading}
                error={!!errors.lastName}
                fullWidth
                helperText={errors.lastName}
                id="lastName"
                label="Last Name"
                margin="normal"
                name="lastName"
                onChange={handleFormChange}
                value={formValues.lastName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={isLoadingRoom || isLoadingEvent || isUploading}
                error={!!errors.email}
                fullWidth
                helperText={errors.email}
                id="email"
                label="Email"
                margin="normal"
                name="email"
                onChange={handleFormChange}
                value={formValues.email}
              />
            </Grid>
            <Grid item style={{ marginTop: '2em' }} xs={12}>
              <Button
                color="primary"
                disabled={isLoadingRoom || isLoadingEvent || isUploading}
                fullWidth
                type="submit"
                variant="contained"
              >
                {isLoadingRoom || isLoadingEvent || isUploading ? <CircularProgress size={24} /> : 'Add Member'}
              </Button>
            </Grid>
          </Grid>
          {isErrorRoom && <Typography color="error">{errorRoom?.payload}</Typography>}
          {isErrorEvent && <Typography color="error">{errorEvent?.payload}</Typography>}
        </form>
      </Grid>
    </Grid>
  );
};

export default AddMemberDialog;
