import { Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { PaymentService } from '../../services/ApiService';
import type { State } from '../../state/state';
import { ExternalLink } from '../../ui/atoms/ExternalLink';

const tSetupToRecieve = 'You are all setup to receive payments.';
const tYouCanSeePayments = 'You can see payments and update your settings on your';
const tLoading = 'Loading...';
const tOpenStripe = 'Open Stripe settings in a new window';
const tAlmostComplete = 'Your payment account is almost complete.';
const tFinishSetup = 'Finish Setup';
const tWePartnerWith = 'We partner with';
const tToProcessPayments = 'to securely process payments.';
const tSetupPayments = 'Setup Payments';

/**
 * Helper to load short-lived payment-related data from the backend
 */
function usePaymentApi<T>(effect: (props: { userId: string }) => Promise<T>): [boolean, T | undefined] {
  const userId = useSelector((state: State) => state!.user!.id);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<T>();

  // fetch the payment account from the backend on demand; this data can change
  // outside our app so be sure we have the latest
  useEffect(() => {
    const f = async () => {
      try {
        setLoading(true);
        const data = await effect({ userId });
        setValue(data);
      } finally {
        setLoading(false);
      }
    };
    f();
  }, [setLoading, setValue, userId, effect]);

  return [loading, value];
}

const redirectToStripeProfileCreation = async (userId: string): Promise<void> => {
  const result = await PaymentService.paymentAccount({ userId });
  // send them off to Stripe
  window.location.assign(result.url!);
};

/**
 * User has a fully configured payment account
 */
const PaymentProfileReady: React.FC = () => {
  const [loading, login] = usePaymentApi(PaymentService.login);
  if (loading || login?.url === undefined) {
    return <>{tLoading}</>;
  }
  return (
    <>
      <Typography variant="body2">
        {tSetupToRecieve}
        <br />
        <br />
        {tYouCanSeePayments}&nbsp;
        <ExternalLink title={tOpenStripe} url={login.url}>
          Stripe
        </ExternalLink>
      </Typography>
    </>
  );
};

/**
 * User has a partially configured payment account
 */
const PaymentProfileIncomplete: React.FC = () => {
  const [loading, login] = usePaymentApi(PaymentService.paymentAccount);

  if (loading || login?.url === undefined) {
    return <>{tLoading}</>;
  }

  return (
    <Typography variant="body2">
      {tAlmostComplete}&nbsp;
      <br /> <br />
      <ExternalLink title={tFinishSetup} url={login.url}>
        {tFinishSetup}
      </ExternalLink>
    </Typography>
  );
};

/**
 * User has no payment account
 */
const PaymentProfileAbsent: React.FC = () => {
  const userId = useSelector((state: State) => state!.user!.id);
  const [disabled, setDisabled] = useState(false);
  const onClick = async () => {
    setDisabled(true);
    await redirectToStripeProfileCreation(userId);
  };

  return (
    <Typography variant="body2">
      {tWePartnerWith}&nbsp;
      <ExternalLink title="open Stripe home page" url="https://stripe.com">
        Stripe
      </ExternalLink>
      &nbsp;{tToProcessPayments}
      <br />
      <br />
      <Button disabled={disabled} onClick={onClick}>
        {tSetupPayments}
      </Button>
    </Typography>
  );
};

export const PaymentAccountForm: React.FC = () => {
  const [loading, paymentAccount] = usePaymentApi(PaymentService.paymentAccount1);

  if (loading || paymentAccount === undefined) {
    return <>Loading...</>;
  } else if (!paymentAccount.accountCreated) {
    return <PaymentProfileAbsent />;
  } else if (paymentAccount.accountCreated && !paymentAccount.payoutsEnabled) {
    return <PaymentProfileIncomplete />;
  } else {
    return <PaymentProfileReady />;
  }
};

/**
 * Implements the stripe profile creation
 * {@link https://stripe.com/docs/api/account_links/create#create_account_link-refresh_url refresh_url}
 *
 * > The URL that the user will be redirected to if the account link is no
 * > longer valid. Your refresh_url should trigger a method on your server to
 * > create a new account link using this API, with the same parameters, and
 * > redirect the user to the new account link.
 */
export const ProfileRefresh: React.FC = () => {
  const userId = useSelector((state: State) => state!.user!.id);

  useEffect(() => {
    redirectToStripeProfileCreation(userId);
  }, [userId]);

  return null;
};
