import { Grid, Typography } from '@mui/material';

import { Table, useTableProps } from './Table';
import { SessionsProps } from './types';

const tSessions = 'Upcoming Events';

export const Sessions = (props: SessionsProps) => {
  const { tableProps } = useTableProps(props);
  return (
    <Grid
      sx={{
        display: 'grid',
        gridTemplateAreas: "'heading' 'manage-series' 'table'",
        gridTemplateRows: 'auto',
        rowGap: '.25rem',
      }}
    >
      <Grid sx={{ gridArea: 'heading' }}>
        <Typography variant="h5">{tSessions}</Typography>
      </Grid>
      <Grid sx={{ gridArea: 'table' }}>
        <Table {...tableProps} />
      </Grid>
    </Grid>
  );
};
