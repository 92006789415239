import { TextField } from '../../../atoms/Form';
import { ConstructedTextField } from '../types';

export const FIELD_NAME_ROOM_NAME = 'roomName';

const tLabel = 'Room Name';
const tRequired = 'Room name is required';
const tLengthMax = 'Room owner name cannot exceed 255 characters';
const tLengthMin = 'Room owner name must be at least 2 characters';

export type RoomNameProps = ConstructedTextField & { required?: boolean };

export const RoomName = ({ required = true, textFieldProps, ...props }: RoomNameProps) => {
  return (
    <TextField
      name={FIELD_NAME_ROOM_NAME}
      textFieldProps={{
        'data-cy': 'form-field-room-name',
        inputProps: { maxLength: 255, minLength: 2 },
        label: tLabel,
        required,
        ...textFieldProps,
      }}
      validate={(value, _, meta) => {
        if (required && meta?.modified && !value) {
          return tRequired;
        }

        if (meta?.dirty && value) {
          if (value.length < 2) {
            return tLengthMin;
          }

          if (value.length > 255) {
            return tLengthMax;
          }
        }

        return '';
      }}
      {...props}
    />
  );
};
