import { Close as CloseIcon } from '@mui/icons-material';
import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogProps as MuiDialogProps,
  DialogTitle,
  Grid,
  IconButton,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { FC } from 'react';

import { DialogActionsCommon } from './DialogActionsCommon';
import { DialogButtonProps } from './DialogButtonProps';

export interface DialogBaseProps {
  dialogType: 'common' | 'custom';
  onDialogClose: () => void;
  shouldShowCloseIcon?: boolean;
  title?: null | string;
}

export interface DialogBaseCommonProps extends DialogBaseProps {
  dialogType: 'common';
  cancelProps: Partial<DialogButtonProps>;
  submitProps: Partial<DialogButtonProps>;
}

export interface DialogBaseCustomProps extends DialogBaseProps {
  dialogType: 'custom';
  actions: React.ReactNode;
}

export type DialogProps = (Partial<DialogBaseCommonProps> | DialogBaseCustomProps) & MuiDialogProps;

export const Dialog: FC<DialogProps> = ({
  children,
  dialogType = 'common',
  onDialogClose,
  open,
  shouldShowCloseIcon = true,
  title,
  ...props
}) => {
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  const dialogActionsProps = {
    cancelProps: {
      isHidden: false,
      onClick: onDialogClose,
      ...(dialogType === 'common' && (props as DialogBaseCommonProps).cancelProps),
    },
    submitProps: {
      isHidden: false,
      onClick: onDialogClose,
      ...(dialogType === 'common' && (props as DialogBaseCommonProps).submitProps),
    },
  };

  return (
    <MuiDialog TransitionProps={{ unmountOnExit: true }} fullScreen={isMobile} onClose={onDialogClose} open={open}>
      {title && (
        <DialogTitle
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {title}
          {shouldShowCloseIcon && (
            <IconButton aria-label="close" onClick={onDialogClose}>
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
      )}
      <Grid container style={{ marginTop: '-4em' }}>
        <DialogContent>{children}</DialogContent>
      </Grid>
      {dialogType === 'common' && <DialogActionsCommon {...dialogActionsProps} />}
      {dialogType === 'custom' && <DialogActions>{(props as DialogBaseCustomProps).actions}</DialogActions>}
    </MuiDialog>
  );
};
