const hasFileReaderApi = (() => 'FileReader' in window)();
const hasFormDataApi = (() => 'FormData' in window)();
const hasWorkerApi = (() => 'Worker' in window)();
const hasDragAndDropEvents = (() => {
  const div = document.createElement('div');
  return 'draggable' in div || ('onDragstart' in div && 'ondrop' in div);
})();

const canReadFileAsBinaryString = hasFileReaderApi && !!window.FileReader?.prototype.readAsBinaryString;

export const Browser = {
  canReadFileAsBinaryString,
  hasDragAndDropEvents,
  hasFileReaderApi,
  hasFormDataApi,
  hasWorkerApi,
};
