import { isValidUrl } from '../../../../utilities/utils';
import { TextField } from '../../../atoms/Form';
import { ConstructedTextField } from '../types';

export const FIELD_NAME_URL = 'url';

const tLabel = 'Link';
const tRequired = 'Link is required';
const tLengthMax = 'Link cannot exceed 1024 characters';
const tLengthMin = 'Link must be at least 2 characters';
const tValidUrl = 'Link must be a valid URL';

export type UrlProps = ConstructedTextField & { required?: boolean };

export const Url = ({ required = true, textFieldProps, ...props }: UrlProps) => {
  return (
    <TextField
      name={FIELD_NAME_URL}
      textFieldProps={{
        'data-testid': 'form-field-url',
        inputProps: { maxLength: 1024, minLength: 2 },
        label: tLabel,
        required,
        ...textFieldProps,
      }}
      validate={(value, _, meta) => {
        if (required && meta?.modified && !value) {
          return tRequired;
        }

        if (meta?.dirty && value) {
          if (value.length < 2) {
            return tLengthMin;
          }

          if (value.length > 255) {
            return tLengthMax;
          }
        }

        if (value && !isValidUrl(value.valueOf())) {
          return tValidUrl;
        }

        return '';
      }}
      {...props}
    />
  );
};
