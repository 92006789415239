import { RoomDto } from '../services/ApiService';
import { useSelector } from './useTypedRedux';

export const useIsFacilitator = (): boolean => {
  const { userId, pod } = useSelector((state) => ({
    pod: state.app.selectedPod,
    userId: state.user?.id,
  }));

  if (!pod || !userId) {
    return false;
  }

  return userId === pod?.ownerId || (pod?.facilitatorIds?.includes(userId) ?? false);
};

/**
 * Get various information about `state.app.selectedPod`
 * TODO: replace me with graphql
 */
export const usePod = (): Pick<RoomDto, 'id' | 'ownerId'> | undefined =>
  useSelector((state) => {
    const sp = state.app.selectedPod;
    return sp && { id: sp.id, ownerId: sp.ownerId };
  });
