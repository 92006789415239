import { Avatar as MuiAvatar, AvatarProps as MuiAvatarProps, ButtonProps, Tooltip, TooltipProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC } from 'react';

const StatusRing = styled('div', {
  shouldForwardProp: (prop) => !['color', 'variant'].includes(prop.toString()),
})<{ color?: ButtonProps['color']; variant?: MuiAvatarProps['variant'] }>(({ color, variant, theme }) => {
  const boxShadowColor = color && color !== 'inherit' ? theme.palette[color].light : 'inherit';

  const style: Partial<React.CSSProperties> = {
    backgroundColor: 'transparent',
    boxShadow: `0 0 1px 5px ${boxShadowColor}`,
    boxSizing: 'border-box',
    display: 'inline-block',
    padding: '0.25rem',
    width: 'fit-content',
  };

  switch (variant) {
    case 'rounded':
      style.borderRadius = theme.shape.borderRadius;
      return style;
    case 'square':
      return style;
    case 'circular':
    default:
      style.borderRadius = '50%';
      return style;
  }
});

const getInitials = (name?: string) =>
  name
    ? name
        .split(' ')
        .map((x) => x.charAt(0).toLocaleUpperCase())
        .slice(0, 2)
    : '';

export interface AvatarBaseProps {
  color: ButtonProps['color'];
  tooltipTitle: TooltipProps['title'];
}

export type AvatarProps = Partial<AvatarBaseProps> & MuiAvatarProps;

export const Avatar: FC<AvatarProps> = ({ color, tooltipTitle = '', ...props }) => {
  const initials = getInitials(props.alt);
  return (
    <Tooltip
      disableFocusListener={!tooltipTitle}
      disableHoverListener={!tooltipTitle}
      disableInteractive={!tooltipTitle}
      disableTouchListener={!tooltipTitle}
      title={tooltipTitle}
    >
      <StatusRing color={color} variant={props.variant}>
        <MuiAvatar data-content={initials} {...props}>
          {initials}
        </MuiAvatar>
      </StatusRing>
    </Tooltip>
  );
};
