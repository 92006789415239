import { Box, BoxProps, Skeleton, SkeletonProps } from '@mui/material';
import { useMemo } from 'react';

const tAriaLabel = 'Loading';

export interface ListLoadingStateProps {
  count?: number;
  slotProps?: {
    container?: BoxProps;
    listItem?: SkeletonProps;
  };
}

export const ListLoadingState = ({ count = 5, slotProps }: ListLoadingStateProps) => {
  const keys = useMemo(function memoize() {
    return new Array(count).fill(0).map(() => crypto.randomUUID());
  }, []);
  return (
    <Box
      aria-label={tAriaLabel}
      {...slotProps?.container}
      sx={{
        alignContent: 'center',
        display: 'grid',
        gap: '0.5rem',
        gridAutoColumns: 'minmax(0, auto)',
        gridAutoFlow: 'row',
        gridAutoRows: 'min-content',
        padding: '0 0.5rem',
        ...slotProps?.container?.sx,
      }}
    >
      {new Array(count).fill(0).map((_, index) => (
        <Skeleton
          key={keys[index]}
          variant="rectangular"
          {...slotProps?.listItem}
          sx={{ height: '2.5rem', ...slotProps?.listItem?.sx }}
        />
      ))}
    </Box>
  );
};
