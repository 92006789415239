import { createAsyncThunk } from '@reduxjs/toolkit';

import { Dispatch, State } from '../hooks/useTypedRedux';
import { SearchDto, SearchResultDto, SearchService } from '../services/ApiService';

const tSearchSuccessPrefix = 'Your ';
const tSearchError = 'Something went wrong; please try again!';

interface AsyncThunkOptions {
  dispatch: Dispatch;
  state: State;
  extra: unknown;
}

interface Notification {
  id: string;
  message: string;
  severity: 'success' | 'info' | 'error' | 'warning';
}

// todo: make this concept a shared one
export const getSearchNotification = createAsyncThunk(
  'schoolDistrict/getSchoolsNotifications',
  async (notification: Partial<Notification>) => {
    const notificationId = Date.now();
    return { id: notificationId, ...notification };
  }
);

export const search = createAsyncThunk<SearchResultDto[], SearchDto, AsyncThunkOptions>(
  'search/search',
  async (
    {
      schoolDistrictBubbleId,
      schoolBubbleId,
      zipCode,
      subjects,
      gradeLevels,
      meetingDays,
      roomName,
      searchTime,
      startDate,
      endDate,
    },
    { dispatch }
  ) => {
    try {
      const res = await SearchService.search({
        body: {
          endDate,
          gradeLevels,
          meetingDays,
          roomName,
          schoolBubbleId,
          schoolDistrictBubbleId,
          searchTime,
          startDate,
          subjects,
          zipCode,
        },
      });
      const successMessage = tSearchSuccessPrefix + res.length + ' results are below';
      dispatch(getSearchNotification({ message: successMessage, severity: 'success' }));
      return res ?? [];
    } catch (error) {
      window.console.error(error);
      dispatch(getSearchNotification({ message: tSearchError, severity: 'error' }));
    }

    return [];
  }
);
