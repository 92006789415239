import {
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';

import { TablePaginationActions } from '../TablePaginationActions';
import { EmptyTableCell, TableCellBody, TableCellHead } from './TableCell';

const ROW_HEIGHT = 45.02;
const ROWS_PER_PAGE = 6;

const getEmptyRows = (rows: any[], page: number, rowsPerPage: number) =>
  rows.length === 0 ? rowsPerPage : rowsPerPage - Math.min(rowsPerPage, Math.abs(rows.length - page * rowsPerPage));

const getPaginatedRows = (rows: any[], page: number, rowsPerPage: number) =>
  rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

export interface BulkUploadPreviewProps {
  worksheet?: {
    headers?: Array<any[]>;
    rows?: Array<any[]>;
  };
}

export const BulkUploadPreview: FC<BulkUploadPreviewProps> = ({ worksheet }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [page, setPage] = useState(0);
  const [emptyRows, setEmptyRows] = useState(0);
  const [paginatedRows, setPaginatedRows] = useState<Array<any[]>>([]);

  useEffect(() => {
    // select smallest number - error occurs when worksheet updates and previously selected page is out of range
    const updatedPage = Math.min(page, Math.max((worksheet?.rows?.length ?? 0) - 1, 0));
    setPage(updatedPage);

    const updatedEmptyRows = getEmptyRows(worksheet?.rows ?? [], updatedPage, ROWS_PER_PAGE);
    setEmptyRows(updatedEmptyRows);

    const updatedPaginatedRows = getPaginatedRows(worksheet?.rows ?? [], updatedPage, ROWS_PER_PAGE);
    setPaginatedRows(updatedPaginatedRows);
  }, [page, worksheet]);

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => setPage(newPage);

  return (
    <TableContainer>
      <Table sx={{ marginBottom: '1rem', wordBreak: 'break-word' }}>
        {worksheet?.headers && (
          <TableHead>
            {worksheet.headers.map((rows, outerIndex) => (
              <TableRow key={outerIndex}>
                {rows.map((value, index) =>
                  isMobile && index > 2 ? null : <TableCellHead key={`${value}-${index}`} value={value?.toString()} />
                )}
              </TableRow>
            ))}
          </TableHead>
        )}
        <TableBody>
          {paginatedRows.map((rows, outerIndex) => (
            <TableRow key={outerIndex}>
              {rows.map((value, index) =>
                isMobile && index > 2 ? null : <TableCellBody key={`${value}-${index}`} value={value?.toString()} />
              )}
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: ROW_HEIGHT * emptyRows }}>
              <EmptyTableCell />
            </TableRow>
          )}
        </TableBody>
        {worksheet?.rows && (
          <TableFooter>
            <TableRow>
              <TablePagination
                ActionsComponent={TablePaginationActions}
                colSpan={Number.MAX_SAFE_INTEGER}
                count={worksheet.rows.length}
                onPageChange={handleChangePage}
                page={page}
                rowsPerPage={ROWS_PER_PAGE}
                rowsPerPageOptions={[]}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};
