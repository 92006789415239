import { Button, Card, CardContent as MuiCardContent, Grid, Input, styled, Typography, useTheme } from '@mui/material';
import { unwrapResult } from '@reduxjs/toolkit';
import React, { ChangeEvent } from 'react';
import { useLocation } from 'react-router-dom';

import { resend, verify } from '../../actions/login';
import { useDispatch } from '../../hooks/useTypedRedux';
import tSearchIcon from '../Icons/008-magnifier.png';
import Logo from '../Logo/Logo';

const tAuthFailed = 'Verification code did not match.';
const tEmailFeedbackLink = 'mailto:contact@crowdcoursing.com?subject=User%20Feedback';
const tRequired = 'Requires six digits';
const tosLink = 'https://drive.google.com/drive/folders/16rRco74DP3DIpCKNsYIk6KoiTTYQz5c1?usp=drive_link';
const tResendCode = 'Resend Code';

const PAPER_ELEVATION = 2;
const tSubTitle = 'Two-factor Authentication';
const tTermsOfService = 'Terms of Use';
const tEmailUs = 'Email Support';

const CardContent = styled(MuiCardContent)(({ theme }) => {
  return {
    '& > *': {
      margin: theme.spacing(1),
    },
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: '0 !important',
  };
});

const Verification: React.FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();

  const [error, setError] = React.useState('');
  const refs = [
    React.useRef<HTMLInputElement>(null),
    React.useRef<HTMLInputElement>(null),
    React.useRef<HTMLInputElement>(null),
    React.useRef<HTMLInputElement>(null),
    React.useRef<HTMLInputElement>(null),
    React.useRef<HTMLInputElement>(null),
  ];
  const [isDisabled, setIsDisabled] = React.useState(false);

  const reset = () => {
    refs.forEach((ref) => {
      if (ref && ref.current) {
        ref.current.value = '';
      }
    });

    refs[0]?.current?.focus();
  };

  const handleVerification = async () => {
    const authCodeString = refs.map((ref) => ref?.current?.value ?? '').reduce((a, b) => `${a}${b}`);
    const authCodeNumber = Number(authCodeString);
    if (authCodeString.length !== refs.length || isNaN(authCodeNumber)) {
      reset();
      setError(tRequired);
      return;
    }

    try {
      const opts = {
        authCode: authCodeNumber,
        redirect: location.state,
      };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore // redux-toolkit typing is impossible, switch this react-query
      await dispatch(verify(opts)).then(unwrapResult);
    } catch (error) {
      reset();
      setError(tAuthFailed);
    }
  };

  const handleChange = (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
    if (error) {
      setError('');
    }

    const ref = refs[index];
    if (ref && ref.current) {
      ref.current.value = event.target.value.charAt(0);
    }

    if (index < refs.length - 1 && event.target.value.length === 1) {
      const nextRef = refs[index + 1];
      nextRef?.current?.focus();
      return;
    }

    if (index === refs.length - 1) {
      handleVerification();
    }
  };

  const handleKeyDown = (index: number) => (event: React.KeyboardEvent<HTMLInputElement>) => {
    const backspaceKey = ['Backspace', 8];
    const deleteKey = ['Delete', 46];

    const keys = [...backspaceKey, ...deleteKey];
    if (keys.includes(event.key) || keys.includes(event.keyCode)) {
      if (index > 0) {
        const ref = refs[index - 1];
        ref?.current?.focus();
        ref?.current?.select();
      }
    }
  };

  const resendCode = async () => {
    setIsDisabled(true);
    try {
      await dispatch(resend());
    } finally {
      setTimeout(() => setIsDisabled(false), 60000);
    }
  };

  return (
    <Grid alignItems="center" container direction="column" justifyItems="center" margin="0" spacing={0}>
      <Grid item>
        <Grid alignItems="center" container direction="column" justifyItems="center" spacing={0} xs={12}>
          <Grid alignItems="center" container direction="column" justifyItems="center" spacing={0}>
            <Logo />
            <Grid alignItems="center" container direction="column" justifyItems="center" spacing={0}>
              <Typography variant="h6">{tSubTitle}</Typography>
            </Grid>
            <Card elevation={PAPER_ELEVATION} sx={{ padding: '2rem' }}>
              <CardContent>
                <Grid alignItems="center" container direction="column" margin="0" spacing={2}>
                  <Grid
                    display="grid"
                    gridTemplateAreas="'label' 'input-container' 'feedback'"
                    gridTemplateColumns="auto"
                    gridTemplateRows="auto"
                    rowGap="0.25rem"
                  >
                    <Grid alignItems="center" container direction="column" justifyItems="center" spacing={0}>
                      <img
                        src={tSearchIcon}
                        style={{
                          filter:
                            'invert(88%) sepia(37%) saturate(4038%) hue-rotate(162deg) brightness(92%) contrast(83%)',
                          width: '50px',
                        }}
                      />
                      <Grid item>
                        <Grid
                          alignItems="center"
                          container
                          direction="column"
                          justifyItems="center"
                          marginTop="1em"
                          spacing={2}
                        >
                          <Typography variant="body1">Enter the code to verify your identity</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid gridArea="input-container">
                      {refs.map((ref, index) => (
                        <Input
                          aria-describedby={`auth-code-digit-${index + 1}`}
                          autoFocus={index === 0}
                          disableUnderline
                          inputProps={{ maxLength: 1 }}
                          inputRef={ref}
                          key={index}
                          onChange={handleChange(index)}
                          onKeyDown={handleKeyDown(index)}
                          sx={{
                            '& input': {
                              '&:focus': {
                                border: '1px solid transparent',
                                boxShadow: `0 0 0 2px ${theme.palette.info.dark}`,
                              },
                              border: `1px solid ${theme.palette.grey[400]}`,
                              borderRadius: '0.25rem',
                              height: '1.5rem',
                              padding: '0.25rem',
                              textAlign: 'center',
                              width: '1.5rem',
                              [theme.breakpoints.up('sm')]: {
                                height: '2rem',
                                padding: '0.5rem',
                                width: '2rem',
                              },
                              ...(error
                                ? {
                                    '&:focus': {
                                      boxShadow: `0 0 0 2px ${theme.palette.error.dark}`,
                                    },
                                    border: `1px solid ${theme.palette.error.dark}`,
                                  }
                                : {}),
                            },
                            '&:not(:first-child)': {
                              marginLeft: '0.5rem',
                            },
                          }}
                          type="number"
                        />
                      ))}
                    </Grid>
                    {error && (
                      <Typography color="error" fontStyle="italic" gridArea="feedback" variant="subtitle2">
                        {error}
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    alignItems="center"
                    container
                    direction="column"
                    justifyItems="center"
                    marginTop="2em"
                    spacing={2}
                  >
                    <Grid item>
                      <Typography variant="body1">We just sent you a verification code</Typography>
                    </Grid>
                    <Grid>
                      <Typography color="error" fontStyle="italic" gridArea="feedback" variant="subtitle2">
                        Your code expires in 5 minutes
                      </Typography>
                    </Grid>
                    <Button disabled={isDisabled} onClick={resendCode}>
                      {tResendCode}
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid alignItems="center" container direction="column" justifyContent="center" marginTop={1} spacing={0}>
            <Grid container direction="row" marginTop={1}>
              <Grid container item justifyContent="center" xs={6}>
                <Typography variant="body2">
                  <a href={tosLink} rel="noopener noreferrer" target="_blank">
                    {tTermsOfService}
                  </a>
                </Typography>
              </Grid>
              <Grid container item justifyContent="center" xs={6}>
                <Typography variant="body2">
                  <a href={tEmailFeedbackLink} rel="noopener noreferrer" target="_blank">
                    {tEmailUs}
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Verification;
