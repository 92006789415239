import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

import { CancelButton } from '../../atoms/Form';

const tNew = (noun: string) => `Create New ${noun}`;
const tEdit = (noun: string) => `Edit Existing ${noun}`;
const tDelete = (noun: string) => `Delete Existing ${noun}`;

type State = 'CREATE' | 'READ' | 'UPDATE' | 'DELETE';

const labels: Record<State, (noun: string) => string> = {
  CREATE: tNew,
  DELETE: tDelete,
  READ: (s) => s, // unused, needed to make typescript happy
  UPDATE: tEdit,
};

/** minimum requirements for the items we're trying to do CRUD on */
interface Crud {
  id: string;
}

/** the values we expect to get from a form on create or update */
export type CrudInput<T extends Crud> = Omit<T, 'id'>;

/** CRUD edit form components must accept these props. The form is expected to
 * have a submit and cancel UX. */
export interface CrudFormProps<T extends Crud, TInput = CrudInput<T>> {
  defaults?: TInput;
  onSubmit: (s: TInput) => void;
  onCancel: () => void;
}

/** CRUD list components must accept these props */
export interface CrudListProps<T extends Crud> {
  items: T[];
  onSelect: (item: T) => void;
}

/** CRUD delete confirmation components must accept these props. */
export interface CrudDeleteProps<T extends Crud> {
  item: T;
}

export interface ManageProps<T extends Crud> {
  description: string;
  /** what is the thing we're managing here? Used in labels and data-testids */
  noun: string;
  /** child components controlled by the manager */
  slots: {
    /** form to render when creating / updating */
    form: (props: CrudFormProps<T>) => JSX.Element;
    /** component to display the items */
    list: (props: CrudListProps<T>) => JSX.Element;
    /** displays information about the item prior to deletion */
    deleteConfirm: (props: CrudDeleteProps<T>) => JSX.Element;
  };
  /** the items being managed */
  items: T[];
  /** callback for when a create/update is submitted */
  onSubmit: (newItem: CrudInput<T>, oldItem?: T) => void;
  /** callback for when a delete is confirmed */
  onDelete: (item: T) => void;
}

/** Manage implements a basic generic CRUD state machine for lists of items. */
export const Manage = <T extends Crud>({ description, noun, onSubmit, onDelete, items, slots }: ManageProps<T>) => {
  const [state, setState] = useState<State>('READ');
  const [selected, setSelected] = useState<T | undefined>();

  const onCreate = () => {
    setSelected(undefined);
    setState('CREATE');
  };
  const onCancel = () => {
    setSelected(undefined);
    setState('READ');
  };
  const onSelect = (item: T) => {
    setSelected(item);
    setState('UPDATE');
  };
  const onDeleteStarted = () => setState('DELETE');

  const onFormSubmit = (newItem: CrudInput<T>) => {
    onSubmit(newItem, selected);
    onCancel();
  };

  const onDeleteConfirm = () => {
    selected && onDelete(selected);
    onCancel();
  };

  // always create the sub-elements so we don't get "Rendered fewer hooks than
  // expected" errors. TODO: change this API to accept react nodes instead of
  // render props, maybe via contexts
  const itemForm = slots.form({
    defaults: selected,
    onCancel,
    onSubmit: onFormSubmit,
  });
  const itemList = slots.list({ items, onSelect });

  const showForm = state === 'UPDATE' || state === 'CREATE';
  const showList = state === 'READ';
  const showDeleteConfirm = state === 'DELETE' && selected;
  const label = labels[state](noun);

  return (
    <>
      <Typography>{description}</Typography>
      {showList && (
        <>
          <Button
            data-testid={`create-${noun.toLowerCase()}-button`}
            onClick={onCreate}
            sx={{ alignSelf: 'flex-end', width: 'fit-content' }}
            variant="text"
          >
            {tNew(noun)}
          </Button>
          {itemList}
        </>
      )}
      {showDeleteConfirm && (
        <Stack justifyContent="space-between" spacing={2}>
          <Typography>{label}</Typography>
          {slots.deleteConfirm({ item: selected })}
          <Stack direction="row" justifyContent="end" spacing={2} sx={{ marginTop: '3rem' }}>
            <CancelButton onCancel={onCancel} />
            <Button color="error" data-testid="delete-confirm-button" onClick={onDeleteConfirm} variant="contained">
              {tDelete(noun)}
            </Button>
          </Stack>
        </Stack>
      )}
      {showForm && (
        <>
          <Stack direction="row" justifyContent="space-between">
            <Typography>{label}</Typography>
            {selected && (
              <Button data-testid="delete-button" onClick={onDeleteStarted} variant="text">
                {tDelete(noun)}
              </Button>
            )}
          </Stack>
          {itemForm}
        </>
      )}
    </>
  );
};
