import { Dialog, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { pageDialogClose, PageDialogContentState } from '../../state/app';
import DeleteOrganizationContent from './DeleteOrganizationContent';
import DeletePodContent from './DeletePodContent';
import DeleteSessionContent from './DeleteSessionContent';
import PodPaymentWizardContent from './PodPaymentWizardContent';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

interface PageDialogContentProps {
  contentState: PageDialogContentState;
  handleClose: () => void;
}

const PageDialogContent: React.FC<PageDialogContentProps> = ({ contentState, handleClose }) => {
  /* eslint-disable no-case-declarations */
  switch (contentState.contentType) {
    case 'delete-pod':
      const deletePodContentProps = { handleClose, ...contentState.contentProps };
      return <DeletePodContent {...deletePodContentProps} />;
    case 'delete-session':
      const deleteSessionContent = { handleClose, ...contentState.contentProps };
      return <DeleteSessionContent {...deleteSessionContent} />;
    case 'delete-organization':
      const deleteOrganizationContent = { handleClose, ...contentState.contentProps };
      return <DeleteOrganizationContent {...deleteOrganizationContent} />;
    case 'pod-payment-wizard':
      const podPaymentWizardContent = { handleClose, ...contentState.contentProps };
      return <PodPaymentWizardContent {...podPaymentWizardContent} />;
    case 'unknown':
    default:
      return null;
  }
  /* eslint-enable no-case-declarations */
};

const PageDialog: React.FC = () => {
  const dispatch = useDispatch();
  const isVisible: boolean = useSelector((state: any) => state.app.isPageDialogVisible ?? false);
  const contentState: PageDialogContentState = useSelector((state: any) => state.app.pageDialogContentState);

  const handleClose = () => dispatch(pageDialogClose());

  const contentProps = { contentState, handleClose };

  return (
    <Dialog
      TransitionComponent={Transition}
      aria-describedby="alert-dialog-slide-description"
      aria-labelledby="alert-dialog-slide-title"
      onClose={handleClose}
      open={isVisible}
    >
      <PageDialogContent {...contentProps} />
    </Dialog>
  );
};

export default PageDialog;
