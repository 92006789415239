import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';

import TablePaginationActions from '../../../cc-ui/components/Tables/TablePaginationActions';
import { useSeriesMeetingsQuery } from '../../../services/graphql/generated/react-query';
import { getDate, getTime } from '../../../utilities/utils';

const tBeginTimeLabel = 'Begin Time';
const tEndTimeLabel = 'End Time';
const tDate = 'Date';
const tNoTimes = 'No event times are related to this series';

export interface MeetingTimeTableProps {
  roomId: string;
  seriesId: string;
  rowsPerPage?: number;
}

export const MeetingTimeTable = ({ rowsPerPage = 10, ...props }: MeetingTimeTableProps) => {
  const [page, setPage] = useState(0);
  const { isLoading, data: meetingTimes } = useSeriesMeetingsQuery(props, {
    select: (data) => data.room?.series[0]?.meetingTimes,
  });

  const headers = [tDate, tBeginTimeLabel, tEndTimeLabel];
  const handlePageChange = (_: React.MouseEvent<HTMLButtonElement> | null, pageNumber: number) => setPage(pageNumber);

  if (isLoading || meetingTimes === undefined) {
    return null;
  }

  if (meetingTimes.length === 0) {
    return (
      <>
        <Typography variant="body2">{tNoTimes}</Typography>
      </>
    );
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map((header, index) => (
            <TableCell key={`${header} - ${index}`}>{header}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {meetingTimes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((meetingTime) => (
          <TableRow hover={true} key={meetingTime.id}>
            <TableCell>{getDate(meetingTime.beginMeetingTime)}</TableCell>
            <TableCell>{getTime(meetingTime.beginMeetingTime)}</TableCell>
            <TableCell>{getTime(meetingTime.endMeetingTime)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            ActionsComponent={TablePaginationActions}
            colSpan={headers.length}
            count={meetingTimes.length}
            onPageChange={handlePageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[]}
          />
        </TableRow>
      </TableFooter>
    </Table>
  );
};
