import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { type PartialDeep } from 'type-fest';

import { NumberField, TextArrayField, TextField } from '~ui/atoms/Form';

import { ResumeWizardNavigation } from '../ResumeWizardNavigation';
import { TypedFormSpy } from './utils';

export const currentYear = new Date().getFullYear();
// ?: Do these values make sense
export const minGraduationYear = currentYear - 100;
export const maxGraduationYear = currentYear + 12;

export type EducationFieldValues = {
  education: {
    school: string;
    program: string;
    graduationYear: number;
    gpaWeighted: number;
    gpaUnweighted: number;
    achievements: string[];
  };
};

export function getFieldName(name: `${keyof EducationFieldValues}.${keyof EducationFieldValues['education']}`) {
  return name;
}

export type EducationBaseProps = { initialValues?: PartialDeep<EducationFieldValues>['education'] };
export type EducationSlotProps = { slotProps?: object };
export type EducationProps = EducationBaseProps & EducationSlotProps;

export function Education({ initialValues }: EducationProps) {
  return (
    <Box data-testid="Education Form" display="grid" gap="1.5rem">
      <TextField
        initialValue={initialValues?.school}
        name={getFieldName('education.school')}
        required
        textFieldProps={{ 'data-testid': getFieldName('education.school'), label: 'School Name', variant: 'filled' }}
        validate={function (value, _, meta) {
          if (meta?.initial === undefined && !meta?.modified) {
            return;
          }

          if (!value) {
            return 'School Name required';
          }
        }}
      />
      <TextField
        initialValue={initialValues?.program}
        name={getFieldName('education.program')}
        textFieldProps={{ 'data-testid': getFieldName('education.program'), label: 'Program Name', variant: 'filled' }}
      />
      <NumberField
        initialValue={initialValues?.graduationYear}
        name={getFieldName('education.graduationYear')}
        required
        textFieldProps={{
          'data-testid': getFieldName('education.graduationYear'),
          label: 'Graduation Year',
          variant: 'filled',
        }}
        type="number"
        validate={function (value, _, meta) {
          if (meta?.initial === undefined && !meta?.modified) {
            return;
          }

          if (!value && value !== 0) {
            return 'Graduation Year required';
          }

          if (String(value).length !== 4) {
            return `Graduation Year must be exactly 4 digits, e.g. ${currentYear}`;
          }

          if (value < minGraduationYear || value > maxGraduationYear) {
            return `Graduation Year must be between ${minGraduationYear} and ${maxGraduationYear}`;
          }
        }}
      />
      <NumberField
        initialValue={initialValues?.gpaWeighted}
        name={getFieldName('education.gpaWeighted')}
        parse={(value) => Number(value?.toFixed(2))}
        required
        textFieldProps={{
          'data-testid': getFieldName('education.gpaWeighted'),
          label: 'Current GPA (Weighted)',
          variant: 'filled',
        }}
        type="number"
        validate={function (value, _, meta) {
          if (meta?.initial === undefined && !meta?.modified) {
            return;
          }

          if (!value && value !== 0) {
            return;
          }

          if (value < 0) {
            return 'GPA must be positive';
          }
        }}
      />
      <NumberField
        initialValue={initialValues?.gpaUnweighted}
        name={getFieldName('education.gpaUnweighted')}
        parse={(value) => Number(value?.toFixed(2))}
        required
        textFieldProps={{
          'data-testid': getFieldName('education.gpaUnweighted'),
          label: 'Current GPA (Unweighted)',
          variant: 'filled',
        }}
        type="number"
        validate={function (value, _, meta) {
          if (meta?.initial === undefined && !meta?.modified) {
            return;
          }

          if (!value && value !== 0) {
            return;
          }

          if (value < 0) {
            return 'GPA must be positive';
          }
        }}
      />
      <TextArrayField
        initialValue={initialValues?.achievements}
        name={getFieldName('education.achievements')}
        textFieldProps={{
          'data-testid': getFieldName('education.achievements'),
          helperText: 'Each line is a separate achievement',
          label: 'Academic Achievements',
          variant: 'filled',
        }}
      />
      <TypedFormSpy>
        {({ values, hasValidationErrors, form: { submit: onClick }, submitError }) => {
          const disabled =
            !values.education ||
            !values.education.gpaUnweighted ||
            !values.education.gpaWeighted ||
            !values.education.graduationYear ||
            !values.education.school ||
            hasValidationErrors;

          return (
            <>
              <Typography color="error.main" variant="overline">
                {submitError}
              </Typography>
              <ResumeWizardNavigation slotProps={{ next: { disabled, onClick } }} />
            </>
          );
        }}
      </TypedFormSpy>
    </Box>
  );
}
