import { TextField } from '../../../atoms/Form';
import { ConstructedTextField } from '../types';

export const FIELD_NAME_DESCRIPTION = 'description';

const MIN_ROWS = 3;

const tLabel = 'Description';
const tRequired = 'Description is required';
const tLengthMin = 'Description must be at least 2 characters';

export interface DescriptionProps extends ConstructedTextField {
  required?: boolean;
}

export const Description = ({ required, textFieldProps, ...props }: DescriptionProps) => {
  return (
    <TextField
      name={FIELD_NAME_DESCRIPTION}
      textFieldProps={{
        'data-testid': 'form-field-description',
        label: tLabel,
        multiline: true,
        required,
        rows: MIN_ROWS,
        ...textFieldProps,
      }}
      validate={(value, _, meta) => {
        if (required && meta?.modified && !value) {
          return tRequired;
        }

        if (meta?.dirty && value) {
          if (value.length < 2) {
            return tLengthMin;
          }
        }

        return '';
      }}
      {...props}
    />
  );
};
