import CopyAllSharpIcon from '@mui/icons-material/CopyAllSharp';
import {
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useState, VFC } from 'react';
import { useDispatch } from 'react-redux';

import { CreateMeetingTimeRequest, EventService } from '../../../../services/ApiService';
import { successToast } from '../../../../state/notifications';
import { getTimeRangeWithDate } from '../../../../utilities/utils';
import TableCell from '../TableCell';
import TablePaginationActions from '../TablePaginationActions';

const tDate = 'Event Time';
const tStatus = 'Status';
const tRsvpd = 'RSVP';
const tTags = 'Series';
const tCapacity = 'Seats';
const tCopy = 'Copy';
const tCopyEventTimeTooltip =
  'Copying an event time will result in a new one being created without a series. The page will reload.';

const ROW_HEIGHT = 45.02;
const ROWS_PER_PAGE = 6;

const getEmptyRows = (rows: MeetingTime[], page: number, rowsPerPage: number) =>
  rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

const getPaginatedRows = (rows: MeetingTime[], page: number, rowsPerPage: number) =>
  rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

export interface MeetingTime {
  // TODO: make these required when we're not worried about compat with REST
  // generated types
  isFilled?: boolean;
  beginMeetingTime?: Date | string;
  endMeetingTime?: Date | string;
  tags?: React.ReactChild;
  rsvpCheckbox: React.ReactChild;
  capacity: number;
}

export interface MeetingTimesProps {
  meetingTimes: MeetingTime[];
  isFacilitator: boolean;
  eventId: string;
}

export const MeetingTimes: VFC<MeetingTimesProps> = ({ meetingTimes, isFacilitator, eventId }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [emptyRows, setEmptyRows] = useState(0);
  const [paginatedRows, setPaginatedRows] = useState<MeetingTime[]>([]);

  useEffect(() => {
    const updatedEmptyRows = getEmptyRows(meetingTimes, page, ROWS_PER_PAGE);
    setEmptyRows(updatedEmptyRows);

    const updatedPaginatedRows = getPaginatedRows(meetingTimes, page, ROWS_PER_PAGE);
    setPaginatedRows(updatedPaginatedRows);
  }, [meetingTimes, page, setPaginatedRows]);

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => setPage(newPage);
  const headerValues = [tRsvpd, tCapacity, tStatus, tDate, tTags, tCopy];

  const duplicateEventTime = async (eventTime: CreateMeetingTimeRequest) => {
    await EventService.meetingTime({ body: eventTime, eventId: eventTime.eventId! });
    window.location.reload();
    dispatch(successToast({ message: 'Event Time copied!' }));
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {headerValues
              .filter((value) => (!isFacilitator ? value !== tCopy : !!value))
              .map((value) => {
                let align: NonNullable<React.ComponentProps<typeof TableCell>['tableCellProps']>['align'] = 'left';
                if ([tRsvpd, tCapacity, tCopy].includes(value)) {
                  align = 'center';
                }

                return (
                  <TableCell
                    key={value}
                    tableCellProps={{ align }}
                    typographyProps={{ sx: { fontWeight: 'bold' }, variant: 'overline' }}
                    value={value}
                  />
                );
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedRows.map(({ capacity, isFilled, beginMeetingTime, endMeetingTime, tags, rsvpCheckbox }, index) => (
            <TableRow hover key={index} sx={{ cursor: 'pointer' }}>
              <TableCell
                tableCellProps={{ align: 'center' }}
                typographyProps={{ variant: 'body2' }}
                value={rsvpCheckbox}
              />
              <TableCell
                tableCellProps={{ align: 'center' }}
                typographyProps={{ variant: 'body2' }}
                value={capacity == 0 ? 'Unlimited' : capacity}
              />
              <TableCell typographyProps={{ color: '#', variant: 'body2' }} value={isFilled ? 'Full' : 'Available'} />
              <TableCell
                typographyProps={{ variant: 'body2' }}
                value={getTimeRangeWithDate(beginMeetingTime!, endMeetingTime!)}
              />
              <TableCell
                tableCellProps={{ align: isFacilitator ? 'left' : 'right' }}
                typographyProps={{ variant: 'body2' }}
                value={tags}
              />
              {isFacilitator && (
                <TableCell
                  tableCellProps={{ align: 'center' }}
                  typographyProps={{ variant: 'body2' }}
                  value={
                    <Tooltip placement="top-start" title={tCopyEventTimeTooltip}>
                      <CopyAllSharpIcon
                        onClick={() =>
                          duplicateEventTime({
                            beginMeetingTime: beginMeetingTime ? new Date(beginMeetingTime) : undefined,
                            endMeetingTime: endMeetingTime ? new Date(endMeetingTime) : undefined,
                            eventId: eventId,
                            maximumSlots: 1,
                          })
                        }
                      />
                    </Tooltip>
                  }
                />
              )}
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: ROW_HEIGHT * emptyRows }}>
              <TableCell tableCellProps={{ colSpan: 6 }} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              ActionsComponent={TablePaginationActions}
              colSpan={headerValues.length}
              count={meetingTimes.length}
              onPageChange={handleChangePage}
              page={page}
              rowsPerPage={ROWS_PER_PAGE}
              rowsPerPageOptions={[]}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
