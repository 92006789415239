import { Box, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useParams } from 'react-router-dom';

import { useSVG } from '../../hooks/useSVG';
import { useRoomQrQuery } from '../../services/graphql/generated/react-query';

dayjs.extend(duration);

/**
 * CheckInCode is a bare-bones display of the check-in QR code, intended to be
 * scanned by volunteers on site, and printed by facilitators.
 */
export const CheckInCode = () => {
  const { roomId } = useParams();
  const { data } = useRoomQrQuery({ roomId: roomId! }, { cacheTime: dayjs.duration(10, 'minutes').milliseconds() });
  const svg = useSVG(data?.room?.checkInImageUri);

  if (!data?.room || !svg) {
    return null;
  }

  return (
    <Box textAlign={'center'}>
      <Grid marginTop="1em">
        <Typography variant="h3">{data.room.name}</Typography>
      </Grid>
      <Grid marginTop="1em">
        <img src={svg} style={{ height: '60%', objectFit: 'contain', width: '60%' }} />
      </Grid>
      <Grid marginTop="1em">
        <Typography variant="h5">Check-In QR Code</Typography>
      </Grid>
      <Grid marginTop="1em">
        <Typography variant="h5">Scan the code above to join the room and check in</Typography>
      </Grid>
    </Box>
  );
};
