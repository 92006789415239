import { useEffect, useRef, useState } from 'react';

// * has to be written in the format to use generics :/
export const useConditionalTooltip = <T extends HTMLElement>(condition?: boolean) => {
  const [shouldShow, setShouldShow] = useState(false);
  const ref = useRef<T | null>(null);

  useEffect(() => {
    if (ref.current) {
      if (condition === undefined) {
        setShouldShow(ref.current.scrollWidth > ref.current.clientWidth);
        return;
      }

      setShouldShow(condition);
    }
  }, [ref]);

  return {
    disableListeners: {
      disableFocusListener: !shouldShow,
      disableHoverListener: !shouldShow,
      disableTouchListener: !shouldShow,
    },
    ref,
    shouldShow,
  };
};
