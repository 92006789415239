import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@mui/material';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';

export type TextArrayFieldValue = string[] | string | undefined;

type ModifiedTextFieldProps = Omit<
  MuiTextFieldProps,
  'onChange' | 'onBlur' | 'onFocus' | 'name' | 'value' | 'checked' | 'type' | 'multiple' | 'fullWidth'
>;

export interface TextArrayFieldProps extends FieldProps<TextArrayFieldValue, FieldRenderProps<TextArrayFieldValue>> {
  textFieldProps?: ModifiedTextFieldProps;
}

export const TextArrayField = ({
  textFieldProps: { helperText, ...textFieldProps } = { helperText: '' },
  ...props
}: TextArrayFieldProps) => {
  return (
    <Field<TextArrayFieldValue>
      render={({ input, meta: { error } }) => {
        return (
          <MuiTextField
            error={Boolean(error)}
            fullWidth
            helperText={error || helperText}
            minRows={3}
            multiline
            required={props.required}
            {...textFieldProps}
            {...input}
          />
        );
      }}
      {...props}
      format={function (values, name) {
        if (!Array.isArray(values)) {
          return undefined;
        }

        const formatted = values.join('\n');
        return props?.format?.(formatted, name) ?? formatted;
      }}
      parse={function (value, name) {
        if (typeof value !== 'string' || value.trim() === '') {
          return undefined;
        }

        const formatted = value.split('\n');
        return props?.parse?.(formatted, name) ?? formatted;
      }}
    />
  );
};
