import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Auth } from './state';
import { actions as userActions } from './userSlice';

export const initialState: Auth = {
  identifier: '',
  isLoading: false,
  token: null,
};

const slice = createSlice({
  extraReducers: (builder) =>
    builder.addCase(userActions.logout, (state) => {
      state.identifier = initialState.identifier;
      state.token = initialState.token;
    }),
  initialState,
  name: 'auth',
  reducers: {
    loginFailed(state) {
      state.isLoading = false;
    },
    loginRequested(state) {
      state.isLoading = true;
    },
    loginSuccess(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.identifier = action.payload;
    },
    verifySuccess(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.token = action.payload;
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;
