import {
  TableCell as MuiTableCell,
  TableCellProps as MuiTableCellProps,
  Tooltip,
  TooltipProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import classNames from 'classnames';
import { VFC } from 'react';

import { useConditionalTooltip } from '../../hooks';

export interface StringContentProps {
  tooltip?: TooltipProps['title'];
  typographyProps: TypographyProps;
  value: string;
}

export const StringContent: VFC<StringContentProps> = ({ tooltip, typographyProps, value }) => {
  const { ref, shouldShow } = useConditionalTooltip<HTMLTableCellElement>();
  return (
    <Tooltip arrow disableHoverListener={!shouldShow} title={<>{tooltip || value}</>}>
      <Typography ref={ref} {...typographyProps}>
        {value}
      </Typography>
    </Tooltip>
  );
};

export interface TableCellProps {
  ref?: React.Ref<HTMLTableCellElement | null>;
  tableCellProps?: MuiTableCellProps;
  typographyProps?: TypographyProps;
  value?: React.ReactNode;
  tooltip?: string;
}

export const TableCell: VFC<TableCellProps> = ({ tableCellProps = {}, typographyProps = {}, value, tooltip }) => {
  return (
    <MuiTableCell {...tableCellProps} className={classNames(tableCellProps.className)}>
      {typeof value === 'string' ? (
        <StringContent tooltip={tooltip} typographyProps={typographyProps} value={value} />
      ) : (
        <>{value}</>
      )}
    </MuiTableCell>
  );
};

export default TableCell;
